import React from 'react';
import Modal from "react-modal";
import CallToAction from './CallToAction';
import styled from 'styled-components';
import './styles.scss';
import colors from '../globalStyles.scss';
import { btnUnderline } from '../mixins';
import { Form } from 'react-final-form';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import ConditionalRender from './ConditionalRender';
import classnames from 'classnames';
import { useTheme } from '../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';

const StyledModal = styled(Modal)`
    background: ${(props) => props.theme === 'light' ? colors.gostWhite : colors.background};
        h3, h4, p, label, div {
        color: ${(props) => props.theme === 'light' ? colors.black : colors.white}};
    }
    .InputElement {
        color: ${({ theme }) => theme === 'light' ? colors.black : colors.white} !important;
    }
    position: absolute;
    box-shadow: ${(props) => !props.shaddow ? 'none' : '0 0 10px 0 rgba(0, 0, 0, 0.2)'};
    top: 50%;
    left: 50%;
        &.desktop {
            width: ${(props) => props.width || '80%'};
            height: ${(props) => props.height || '80%'};
    }
    &.tablet, &.phone {
        width: ${(props) => props.width || '100%'} !important;
        height: ${(props) => props.height || '100%'} !important;
    }
    transform: translate(-50%, -50%);
    border-radius: 4px;
    outline: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $black;
    .modal-body {
      flex: 1 1;
      height: 100%;
      width: 100%;
      font-size: 1.5rem;
      overflow-y: scroll;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
      padding-right: 1em;
      .editor {
            padding-right: 1em;
      }
    }
`


const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    color: ${colors.black};
    font-family: ${colors.openSans};
  }
  .confirm {
    height: 100%;
    padding-bottom: 5px;
    position: relative;
    color: ${(props) => props?.theme === 'light' ? colors.black : colors.white};
    font-family: ${colors.openSans};
    ${(props) => btnUnderline(props?.theme === 'light' ? colors.black : colors.white)}
    }
`;

const StyledPagination = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    // z-index: 999999999999;
    width: 120%;
    svg {
        color: ${colors.white};
        font-size: 2.5em;
        animation: pulse 1.5s infinite;
    }
    .previous {
        visibility: ${(props) => props.hasPrevious ? 'visible' : 'hidden'};
    }
    .next {
        visibility: ${(props) => props.hasNext ? 'visible' : 'hidden'};
    }

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
`;

Modal.setAppElement('#root');

const ConfirmModal = ({
    isOpen,
    toggle,
    children,
    onSubmit, // expect an onSubmit function instead of onClick for forms
    onClick,
    text,
    className = '',
    btnStyles,
    disabled,
    isForm, // new prop to determine if this modal should behave as a form
    initialValues, // initial form values,
    width = '50%',
    height = '50%',
    noOverlay,
    shaddow = false,
    withPagination = false

}) => {
    const modalText = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone, loading } = useScreenSize();

    const renderPaginationControls = () => {
        if (!withPagination) return null;

        const { paginationItems, currentIndex, onNext, onPrevious } = withPagination;
        const hasPrevious = currentIndex > 0;
        const hasNext = currentIndex < paginationItems.length - 1;

        return (
            <StyledPagination
                hasPrevious={hasPrevious}
                hasNext={hasNext}
                className='pagination-controls'>
                <button
                    disabled={!hasPrevious}
                    onClick={onPrevious}
                    className="caret previous"
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </button>
                <button
                    disabled={!hasNext}
                    onClick={onNext}
                    className="caret next"
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </button>
            </StyledPagination>
        );
    };

    // Form content that will be wrapped by react-final-form if isForm is true
    const formContent = ({ handleSubmit }) => (
        <form className='flex flex-column' onSubmit={handleSubmit} style={{
            width: '100%',
            height: ' 100%'
        }}>
            <div className='modal-body scroll-container'>
                {children}
            </div>
            <StyledButtons theme={theme} btnStyles={btnStyles} className='modal-buttons'>
                <CallToAction
                    text={modalText?.text?.modal?.close}
                    onClick={toggle}
                    style={{
                        alignSelf: 'start'
                    }}
                />
                {!disabled && (
                    <button type="submit" className='confirm'>
                        {text}
                    </button>
                )}
            </StyledButtons>
        </form>
    );

    // Conditional rendering based on isForm
    const modalContent = isForm ? (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={formContent}
        />
    ) : (
        <>
            <ConditionalRender renderIf={true} isLoading={loading}>
                <div className='modal-body scroll-container' style={{
                    zIndex: '2'
                }}>
                    {children}
                </div>
                <StyledButtons theme={theme} btnStyles={btnStyles} className='modal-buttons'>
                    <CallToAction
                        text={modalText?.text?.modal?.close}
                        onClick={toggle}
                        style={{
                            alignSelf: 'start'
                        }}
                    />
                    {(onClick && !disabled) && (
                        <button className='confirm' onClick={onClick}>
                            {text}
                        </button>
                    )}
                </StyledButtons>
            </ConditionalRender>
        </>
    );

    return (
        <ConditionalRender renderIf={true} isLoaded={loading}>
            <StyledModal
                shaddow={shaddow}
                style={{
                    overlay: {
                        backgroundColor: noOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.75)',
                        zIndex: '9999999'
                    }
                }}
                isOpen={isOpen}
                onRequestClose={toggle}
                toggle={toggle}
                className={classnames({
                    'confirm-modal': true,
                    [className]: className,
                    desktop: isDesktop,
                    tablet: isTablet,
                    phone: isPhone

                })}
                width={width}
                height={height}
                theme={theme}
            >
                <ConditionalRender renderIf={isDesktop}>
                    {renderPaginationControls()}
                </ConditionalRender>
                {modalContent}
            </StyledModal>
        </ConditionalRender>
    )
}

export default ConfirmModal;
