import { useState, useEffect } from 'react';

const useTypingEffect = (text, typingSpeed = 150, delayBeforeRestart = 2000) => {
    const [typedText, setTypedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (!text) return;

        if (index < text.length) {
            const timeout = setTimeout(() => {
                setTypedText((prev) => prev + text[index]); // Add next character
                setIndex(index + 1);
            }, typingSpeed);

            return () => clearTimeout(timeout);
        } else {
            // Wait before restarting
            const restartTimeout = setTimeout(() => {
                setTypedText('');
                setIndex(0);
            }, delayBeforeRestart);

            return () => clearTimeout(restartTimeout);
        }
    }, [text, index, typingSpeed, delayBeforeRestart]);

    return typedText;
};

export default useTypingEffect;
