import { Link } from "react-router-dom";
import { renderLengthLabel, renderWeightLabel, renderVolumeLabel, proposalStatus, titleCase } from "../utils/helperFunctions";
import PriorityCheckbox from "./PriorityCheckbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMagnifyingGlass, faClock } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from "../Core/HoverPopup";
import moment from 'moment'

export const columns = ({
    handlePriorityChange,
    inventoryColumns,
    theme,
    text,
    isProject,
    handleQuickView,
    navigate,
    isDesktop,
    showAdvanceFields,
    fieldNames
}) => {
    const columnsArray = [
        {
            headerName: text?.inventory?.landing?.table?.columns?.project,
            field: 'projectId',
            width: 200,
            hide: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {row?.projectId?.projectName}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.inventory?.landing?.table?.columns?.category,
            field: 'inventoryCategory',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {titleCase(row?.inventoryCategory)}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.inventory?.landing?.table?.columns?.quantity,
            field: 'inventoryQuantity', // Keep the parent field only
            width: 100,
            type: 'number', // Ensure MUI treats this as a numeric column
            sortComparator: (v1, v2, row1, row2) => {
                const num1 = row1?.value?.value ? Number(row1.value.value) : 0;
                const num2 = row2?.value?.value ? Number(row2.value.value) : 0;
                return num1 - num2;
            },
            renderCell: (params) => (
                <p>
                    <span style={{ minWidth: "50px" }} className="truncate details">
                        {Number(params?.row?.inventoryQuantity?.value)}
                    </span>
                </p>
            ),
        },
    ]

    // Check if "Priority" column should be added
    const priorityColumn = inventoryColumns?.find(column => column.fieldName === 'isPriority' && column.isActive);
    if (priorityColumn) {
        const priorityColumnIndex = priorityColumn.sortOrder; // Get the sortOrder value of the priority column
        columnsArray.splice(priorityColumnIndex, 0, {
            headerName: text?.inventory?.landing?.table?.columns?.priority,
            field: priorityColumn.fieldName,
            sortOrder: parseInt(priorityColumn.sortOrder, 10),
            width: 100,
            renderCell: ({ row }) => {
                const fieldValue = row[priorityColumn.fieldName];
                return (
                    <p>
                        <span style={{ maxWidth: "100px" }} className="truncate">
                            <PriorityCheckbox
                                handlePriorityChange={handlePriorityChange}
                                productId={row.productId}
                                initialValue={fieldValue}
                            />
                        </span>
                    </p>
                );
            },
        });
    }


    // Add the "Details" column at the beginning
    columnsArray?.unshift({
        headerName: '',
        field: '',
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
            const id = row?.productId;

            return (
                <div className='flex items-center'>
                    <HoverPopup
                        className='mr-sm'
                        placement={'top'}
                        id={`details_inventory_${id}`}
                        text={text?.tasks?.home?.columns?.details}
                        onClick={() => {
                            navigate(`/inventory/details?id=${id}`);
                        }}
                        style={{
                            background: 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faEdit}
                        />
                    </HoverPopup>
                    <HoverPopup
                        className='mr-sm'
                        placement={'top'}
                        id={`view_inventory_${id}`}
                        text={isDesktop && text?.tasks?.home?.columns?.view}
                        onClick={() => handleQuickView(row)}
                        style={{
                            background: 'transparent'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                        />
                    </HoverPopup>

                </div>

            );
        },
    },
        {
            headerName: text?.inventory?.landing?.table?.columns?.name,
            field: 'inventoryName',
            width: 250,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{ minWidth: "100px" }}
                            className="truncate details">
                            {row?.inventoryName}
                        </span>
                    </p>
                );
            },
        },
    );

    if (showAdvanceFields) {
        fieldNames.forEach(({ inputType, label, name }) => {
            const fieldKey = `${inputType}-${name}`;
            const fieldType = inputType;

            columnsArray.push({
                headerName: label,
                field: fieldKey,
                width: 200,

                renderCell: ({ row }) => {
                    let fieldValue = row?.[fieldKey];

                    if (fieldType === "amount") {
                        fieldValue = fieldValue ? `$${Number(fieldValue)}` : "$0";
                    } else if (fieldType === "number") {
                        fieldValue = Number(fieldValue) || '';
                    } else if (fieldType === "length") {
                        const [lengthValue, lengthUnit] = fieldValue.split("-");
                        fieldValue = `${lengthValue} ${renderLengthLabel(lengthUnit, text)}`;
                    } else if (fieldType === "weight") {
                        const [weightValue, weightUnit] = fieldValue.split("-");
                        fieldValue = `${weightValue} ${renderWeightLabel(weightUnit, text)}`;
                    } else if (fieldType === "volume") {
                        const [volumeValue, volumeUnit] = fieldValue.split("-");
                        fieldValue = `${volumeValue} ${renderVolumeLabel(volumeUnit, text)}`;
                    } else if (moment(fieldValue, moment.ISO_8601, true).isValid()) {
                        fieldValue = moment(fieldValue).format("MM-DD-YYYY h:mm a");
                    }

                    return (
                        <p>
                            <span style={{ minWidth: "100px" }} className="truncate details">
                                {fieldValue}
                            </span>
                        </p>
                    );
                },

                sortComparator: (v1, v2, row1, row2) => {
                    if (fieldType === "amount") {
                        return (Number(row1?.value) || 0) - (Number(row2?.value) || 0);
                    } else if (fieldType === "number") {
                        return (Number(row1?.value) || 0) - (Number(row2?.value) || 0);
                    }
                    return row1?.value?.toString().localeCompare(row2?.value?.toString());
                },
            });
        });
    }



    columnsArray?.sort((a, b) => a.sortOrder - b.sortOrder)

    return columnsArray.filter((column) => !column.hide);
};

export const employeesColumns = ({
    theme,
    text,
    navigate,
    userRole,
    handleQuickView,
    isDesktop
}) => {
    return userRole === 'Admin' ? (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`view_employees_${id}`}
                                text={isDesktop && text?.tasks?.home?.columns?.view}
                                onClick={() => handleQuickView(row)}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                />
                            </HoverPopup>
                        </div>

                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'View Logs',
                field: 'viewLogs',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                <Link
                                    to={`/logs?id=${row?._id}`}
                                >
                                    {text?.employees?.landing?.viewLogs}
                                </Link>
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'roleName',
                width: 110,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.roleName === 'Admin' ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                            </span>
                        </p>
                    );
                },
            }
        ]
    ) : (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`view_employees_${id}`}
                                text={isDesktop && text?.tasks?.home?.columns?.view}
                                onClick={() => handleQuickView(row)}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                />
                            </HoverPopup>
                        </div>

                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'Email',
                field: 'email',
                width: 300,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.email}
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'roleName',
                width: 110,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.roleName === 'Admin' ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                            </span>
                        </p>
                    );
                },
            }
        ]
    )
}

export const tasksColumns = ({
    theme,
    text,
    status,
    priority,
    isProject,
    navigate,
    handleQuickView,
    isDesktop
}) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?._id;
                const uuid = row?.uuid;
                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_tasks_${id}`}
                            text={text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/tasks/details?id=${id}&uuid=${uuid}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`view_tasks_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.view}
                            onClick={() => handleQuickView(row)}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </HoverPopup>

                    </div>

                );
            },
        },
        {
            headerName: '#',
            field: 'uuid',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <div>
                        <p>
                            <span>
                                {`${row?.uuid}`}
                            </span>
                        </p>
                    </div>

                );
            }
        },
        {
            headerName: text?.tasks?.home?.columns?.title,
            field: 'taskTitle',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <div className='flex items-center'>
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.taskTitle}
                            </span>
                        </p>
                    </div>

                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.status,
            field: 'taskStatus',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {status(text).find(s => s.value === row?.taskStatus)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.priority,
            field: 'taskPriority',
            width: 100,
            formatter: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {priority(text).find(s => s.value === row?.taskPriority)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.project,
            field: 'taskProject',
            width: 150,
            hide: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            <Link
                                to={`/project/details?id=${row?.taskProject?.id}`}
                            >
                                {row?.taskProject?.projectName}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.deadline,
            field: 'taskDeadline',
            width: 200,
            renderCell: ({ row }) => {
                const id = row?._id;
                const deadlineDate = moment(row?.taskDeadline);
                const currentDate = moment();
                const diffInDays = deadlineDate.diff(currentDate, 'days');
                let color;
                let title;
                if (diffInDays >= 5) {
                    color = 'green';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else if (diffInDays >= 3) {
                    color = 'yellow';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else if (diffInDays >= 0) {
                    color = 'red';
                    title = `${text?.tasks?.home?.columns?.dueIn} ${diffInDays} ${text?.tasks?.home?.columns?.days}`;
                } else {
                    color = 'red';
                    title = text?.tasks?.home?.columns?.pastDue;
                }

                return (
                    <HoverPopup style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'transparent',
                        border: 'none',
                        hoverBackground: 'transparent',
                    }}
                        placement={'top'}
                        id={`viewDetails_tasks_pastDue_${id}`}
                        text={title}
                    >
                        <p>
                            <span
                                style={{
                                    minWidth: "50px",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }} className="truncate details">
                                <p
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                        marginRight: '10px'
                                    }}
                                />
                                <p>
                                    {moment(row?.taskDeadline).format('MM-DD-YYYY')}
                                </p>
                            </span>
                        </p>
                    </HoverPopup>

                );
            },
        },
        {
            headerName: text?.tasks?.details?.subtasks,
            field: 'subtasks',
            width: 150,
            sortComparator: (v1, v2) => {
                const length1 = Array.isArray(v1) ? v1.length : 0;
                const length2 = Array.isArray(v2) ? v2.length : 0;
                return length1 - length2;
            },
            hide: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">

                            {row?.subtasks?.length}
                        </span>
                    </p>
                );
            },
        },

    ]
}

export const clientsColumns = ({ theme, text, navigate }) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?.id;

                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_client_${id}`}
                            text={text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/clients/details?id=${id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                    </div>

                );
            },
        },
        {
            headerName: text?.client?.create?.name,
            field: 'name',
            width: 200,
            renderCell: ({ row, rowIndex }) => {
                return (
                    <div>
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {`${row?.name}`}
                            </span>
                        </p>
                    </div>
                );
            }
        },
        {
            headerName: text?.client?.create?.email,
            field: 'email',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }}
                            className="truncate details"
                        >
                            <a
                                href={`mailto:${row?.email}`}
                                target="_blank" // Opens in a new tab/window (optional)
                                rel="noreferrer" // Security measure to prevent opening links from untrusted sources (optional)
                                style={{ textDecoration: 'none', color: 'inherit' }} // Optional: For email link styling
                            >
                                {row?.email}
                            </a>
                        </span>
                    </p>
                );
            },
        },


        {
            headerName: text?.client?.create?.phone,
            field: 'phone',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px",
                                cursor: 'pointer'
                            }} className="truncate details">
                            <a href={`tel:${row?.phone}`}>
                                {`${row?.phone || ''}`}
                            </a>
                        </span>
                    </p>
                );
            }
        },
        {
            headerName: text?.client?.create?.address,
            field: 'address',
            width: 350,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px",
                                cursor: 'pointer'
                            }} className="truncate details">
                            <a href={`https://www.google.com/maps/search/?api=1&query=${row?.address}`} target="_blank" rel="noreferrer">
                                {`${row?.address || ''}`}
                            </a>
                        </span>
                    </p>
                );
            }
        }
    ]
}

export const proposalColumns = ({
    theme,
    text,
    isProject,
    handleQuickView,
    navigate,
    isDesktop
}) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const id = row?._id;
                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`details_proposals_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.details}
                            onClick={() => {
                                navigate(`/proposals/details?id=${id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faEdit}
                            />
                        </HoverPopup>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`view_proposals_${id}`}
                            text={isDesktop && text?.tasks?.home?.columns?.view}
                            onClick={() => handleQuickView(row)}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </HoverPopup>

                    </div>

                );
            },
        },
        {
            headerName: '#',
            field: 'uuid',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <div>
                        <p>
                            <span>
                                {`${row?.uuid}`}
                            </span>
                        </p>
                    </div>

                );
            }
        },
        {
            headerName: text?.tasks?.home?.columns?.title,
            field: 'title',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {row?.title}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.status,
            field: 'status',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {proposalStatus(text).find(s => s.value === row?.status)?.label}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.tasks?.home?.columns?.project,
            field: 'projectId',
            width: 100,
            hidable: isProject,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            <Link
                                to={`/project/details?id=${row?.projectId?.id}`}
                            >
                                {row?.projectId?.projectName}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.client,
            field: 'clientId',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            <Link
                                to={`/clients/details?id=${row?.clientId?.id}`}
                            >
                                {row?.clientId?.name}
                            </Link>
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.draft,
            field: 'isDraft',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {row?.isDraft ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.submitted,
            field: 'isSubmitted',
            width: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {row?.isSubmitted ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.proposals?.landing?.updated,
            field: 'updatedAt',
            headerWidth: 100,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "100px"
                            }} className="truncate details">
                            {moment(row?.updatedAt).format('MM-DD-YYYY')}
                        </span>
                    </p>
                );
            },
        }
    ]
}

export const timeLogsColumns = ({
    theme,
    text,
    user,
    formatDate,
    setTimeLogIsOpen,
    timeLogIsOpen,
    setDailyLog,
    navigate,
    projectId
}) => {
    return [
        { field: '_id', headerName: 'ID', width: 90, hidable: true },
        {
            headerName: '',
            field: '',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => {
                const userId = row?.userId;

                return (
                    <div className='flex items-center'>
                        <HoverPopup
                            className='mr-sm'
                            placement={'top'}
                            id={`time_logs_${userId?._id}`}
                            onClick={() => {
                                navigate(`/logs?id=${userId?._id}`);
                            }}
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faClock}
                            />
                        </HoverPopup>


                    </div>

                );
            },
        },
        {
            headerName: text?.timeTracker?.name,
            field: 'name',
            width: 200,
            renderCell: ({ row }) => {
                return (
                    <div>
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {`${row?.userId?.firstName} ${row?.userId?.lastName}`}
                            </span>
                        </p>
                    </div>

                );
            }
        },
        {
            headerName: text?.timeTracker?.clockIn,
            field: 'clockIn',
            width: 300,
            renderCell: ({ row }) => {
                return (
                    <p>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {formatDate(row?.clockIn)}
                        </span>
                    </p>
                );
            },
        },
        {
            headerName: text?.timeTracker?.dailyLog,
            field: 'dailyLog',
            width: 150,
            renderCell: ({ row }) => {
                return (
                    <p
                        className='pointer'
                        onClick={() => {
                            setTimeLogIsOpen(!timeLogIsOpen);
                            setDailyLog(row)
                        }}>
                        <span
                            style={{
                                minWidth: "50px"
                            }} className="truncate details">
                            {text?.timeTracker?.viewLog || 'No Daily Log'}
                        </span>
                    </p>
                );
            },
        },
    ]
}

export const assignedEmployeesColumns = ({
    theme,
    text,
    userRole,
    project,
    navigate,
    handleQuickView,
    isDesktop
}) => {
    return userRole === 'Admin' ? (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`view_employees_${id}`}
                                text={isDesktop && text?.tasks?.home?.columns?.view}
                                onClick={() => handleQuickView(row)}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                />
                            </HoverPopup>
                        </div>


                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: text?.projects?.create?.admin,
                field: 'isAdmin',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            {
                                project?.adminEmployees?.find((x) => x.id === row._id) ? (
                                    <span>{text?.proposals?.landing?.yes}</span>
                                ) : (
                                    <span>{text?.proposals?.landing?.no}</span>
                                )
                            }
                        </p>
                    );
                },
            },
            {
                headerName: text?.employees?.landing?.viewLogs,
                field: 'viewLogs',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <Link className='ml-md' to={`/logs?id=${row._id}`}>
                                {text?.employees?.landing?.viewLogs}
                            </Link>
                        </p>
                    );
                },
            }
        ]
    ) : (
        [
            { field: '_id', headerName: 'ID', width: 90, hidable: true },
            {
                headerName: '',
                field: '',
                width: 100,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    const id = row?.id;

                    return (
                        <div className='flex items-center'>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`details_employee_${id}`}
                                text={text?.tasks?.home?.columns?.details}
                                onClick={() => {
                                    navigate(`/settings/employee?id=${id}`);
                                }}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </HoverPopup>
                            <HoverPopup
                                className='mr-sm'
                                placement={'top'}
                                id={`view_employees_${id}`}
                                text={isDesktop && text?.tasks?.home?.columns?.view}
                                onClick={() => handleQuickView(row)}
                                style={{
                                    background: 'transparent'
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                />
                            </HoverPopup>
                        </div>

                    );
                },
            },
            {
                headerName: text?.employees?.landing?.name,
                field: 'firstName',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <div>
                            <p>
                                <span
                                    style={{
                                        minWidth: "50px"
                                    }} className="truncate details">
                                    {`${row?.firstName} ${row?.lastName}`}
                                </span>
                            </p>
                        </div>

                    );
                },
            },
            {
                headerName: 'Email',
                field: 'email',
                width: 300,
                sortable: false,
                filterable: false,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            <span
                                style={{
                                    minWidth: "50px"
                                }} className="truncate details">
                                {row?.email}
                            </span>
                        </p>
                    );
                },
            },
            {
                headerName: 'Admin',
                field: 'isAdmin',
                width: 200,
                renderCell: ({ row }) => {
                    return (
                        <p>
                            {
                                project?.adminEmployees?.find((x) => x.id === row._id) ? (
                                    <span>{text?.proposals?.landing?.yes}</span>
                                ) : (
                                    <span>{text?.proposals?.landing?.no}</span>
                                )
                            }
                        </p>
                    );
                },
            }
        ]
    )

}
