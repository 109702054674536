export const closeSuperAdminConversation = async (
    superAdminConversationId,
    callback,
    ws,
    setConversations,
    setSelectedConversation
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    ws.current.emit('closeSuperAdminConversation', { superAdminConversationId }, (response) => {
        if (response.success) {
            // Update the local state to reflect the closed conversation
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === superAdminConversationId
                        ? { ...conversation, status: 'closed', isArchived: true }
                        : conversation
                )
            );

            // If the selected conversation is the one being closed, reset it
            setSelectedConversation((prevSelected) =>
                prevSelected && prevSelected._id === superAdminConversationId
                    ? { ...prevSelected, status: 'closed', isArchived: true }
                    : prevSelected
            );

            // Call callback if provided
            callback?.(true);
        } else {
            console.error('Failed to close conversation:', response.error);
            callback?.(false, response.error);
        }
    });
};