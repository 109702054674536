import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styled from 'styled-components';
import NotificationsContainer from '../Notifications/NotificationsContainer';
import { WebSocketProvider } from '../context/WebSocketContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMessage } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import NavLink from './NavLink';
import { btnUnderline } from '../mixins';
import { useLanguage } from '../context/LanguageContext';
import logo from '../images/ardidflow_logo_small.png';
import useScreenSize from '../context/useScreenSize';
import Footer from '../Footer/Footer';
import './styles.scss';
import ConditionalRender from '../Core/ConditionalRender';
import { GlobalContext } from '../context/GlobalContext';

const StyledNavigation = styled.div`
  background: ${colors.black};
  width: 100%;
  height: ${props => props.isTop && props.isHomePage && !props.isLoggedIn && props.isDesktop ? '10%' : '5%'};
  transition: height 0.5s;
  position: fixed;
  z-index: 1;
  .navigation {
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      padding: 0.2em 0;
      transition: all 0.5s;
      img {
        object-fit: contain;
        height: 100%;
        opacity: 0.8;

      }
      .site-name {
        color: ${colors.white};
        font-family: ${colors.openSans};
        font-size: 1em;
        margin-left: 10px;
      }
    }
    .menu-toggle {
      cursor: pointer;
      color: ${colors.white};
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      width: 100%;
      li {
        position: relative;
        height: 100%;
        cursor: pointer;
        margin: 0 15px;
        color: ${colors.white};
        font-family: ${colors.roboto};
        a {
          color: ${colors.white};
          font-family: ${colors.roboto};
          height: 100%;
        }
        ${btnUnderline(colors.white)}
      }
      li.logout {
        ${btnUnderline(colors.white)}
      }
    }
    .beta {
      color: ${colors.blue};
      font-family: ${colors.openSans};
    }
  }

  .full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    z-index: 10000000;
    .logo-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 10px;
      height: 100%;
      width: 100%;
      img {
        object-fit: contain;
        height: 30px;
      }
      .site-name {
        color: ${colors.white};
        font-family: ${colors.openSans};
        font-size: 1em;
        margin-left: 10px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 1em;
      margin-bottom: 1em;
      list-style: none;
      li {
        margin: 0.5em 0;
        font-size: 1em;
        font-family: ${colors.roboto};
        color: ${colors.white};
        a {
          color: ${colors.white};
          font-family: ${colors.roboto};
        }
      }
    }
    .close-icon {
      font-size: 2em;
      cursor: pointer;
      color: ${colors.white};
      margin-right: 10px;
    }
  }
`;

const Navigation = ({
  isLoggedIn,
  isLoggedInAsClient,
  logoutUser,
  clientLogout,
  isCompanyPremium,
  user,
  handleToggleChat,
  showHelp,
  handleSendMessage,
  conversationHistory,
  thinking,
  chatErrorMessage,
  isSuperAdmin,
  typing,
  handleLinkClick,
  toggleMenu,
  menuActive,
  setCollapsed,
  setMenuActive
}) => {
  const navigate = useNavigate();
  const { text } = useLanguage();
  const { isDesktop, isTablet, isPhone } = useScreenSize();
  const { isTop } = useContext(GlobalContext);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  return (
    <StyledNavigation
      isTop={isTop}
      isHomePage={isHomePage}
      isLoggedIn={isLoggedIn || isLoggedInAsClient}
      isDesktop={isDesktop}
    >
      <div className='navigation'>

        {/* client users */}
        <ConditionalRender renderIf={isLoggedInAsClient}>
          <div className='logo-container' onClick={() => navigate('/')}>
            <img src={logo} alt='logo' />
            <span className='site-name'>{'ArdidFlow'}</span>
          </div>
          <ul style={{ display: isDesktop ? 'flex' : 'none' }}>
            <NavLink to="/" onClick={handleLinkClick}>{text?.navigation?.dashboard}</NavLink>
            <NavLink logoutUser={clientLogout} onClick={handleLinkClick}>
              {text?.navigation?.logout}
            </NavLink>
          </ul>
        </ConditionalRender>

        {/* regular users */}
        <ConditionalRender renderIf={!isLoggedInAsClient}>
          <ConditionalRender renderIf={!isTop || !isHomePage || isLoggedIn || !isDesktop}>
            <div className='logo-container' onClick={() => navigate('/')}>
              <img src={logo} alt='logo' />
              <span className='site-name'>{'ArdidFlow'}</span>
            </div>
          </ConditionalRender>
          <ConditionalRender renderIf={isPhone || isTablet}>
            <div className='flex items-center justify-between'>
              <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
                <FontAwesomeIcon
                  onClick={() => {
                    navigate('/calendar');
                    handleLinkClick();
                  }}
                  icon={faCalendarAlt} style={{
                    color: colors.white,
                    cursor: 'pointer',
                    marginRight: '1em'
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    navigate('/chat');
                    handleLinkClick();
                  }}
                  icon={faMessage} style={{
                    color: colors.white,
                    cursor: 'pointer',
                    marginRight: '1em'
                  }}
                />
                <NotificationsContainer setCollapsed={setCollapsed} user={user} userId={user?._id} />
              </ConditionalRender>
              <div className='menu-toggle ml-md' onClick={toggleMenu}>
                ☰
              </div>
            </div>
          </ConditionalRender>
          <ul style={{ display: isDesktop ? 'flex' : 'none' }}>
            <NavLink to="/" onClick={handleLinkClick}>{(isLoggedIn && isCompanyPremium) ? text?.navigation?.dashboard : text?.navigation?.home}</NavLink>
            {!isLoggedIn ? (
              <>
                <NavLink to="/flow" onClick={handleLinkClick}>{text?.navigation?.flow}</NavLink>
                {/* <NavLink to="/subscriptions" onClick={handleLinkClick}>{text?.navigation?.subscriptions}</NavLink> */}
                <NavLink to="/register" onClick={handleLinkClick}>{text?.navigation?.register}</NavLink>
                <NavLink to="/login" onClick={handleLinkClick}>{text?.navigation?.login}</NavLink>
              </>
            ) : (
              <>
                <NavLink logoutUser={logoutUser} onClick={handleLinkClick}>
                  {text?.navigation?.logout}
                </NavLink>
                <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
                  <FontAwesomeIcon
                    onClick={() => navigate('/calendar')}
                    icon={faCalendarAlt} style={{
                      color: colors.white,
                      cursor: 'pointer',
                      marginRight: '1em'
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => navigate('/chat')}
                    icon={faMessage} style={{
                      color: colors.white,
                      cursor: 'pointer',
                      marginRight: '1em'
                    }}
                  />
                </ConditionalRender>
                <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
                  <ConditionalRender renderIf={isDesktop}>
                    <NotificationsContainer user={user} userId={user?._id} />
                  </ConditionalRender>
                </ConditionalRender>
              </>
            )}
          </ul>
        </ConditionalRender>
      </div>
      {
        menuActive && (
          <div className='full-screen-menu'>
            <div className='flex items-center justify-space-between'>
              <div className='logo-container' onClick={() => navigate('/')}>
                <img src={logo} alt='logo' />
                <span className='site-name'>{'ArdidFlow.com'}</span>
              </div>
              <div className='close-icon' onClick={toggleMenu}>
                &times;
              </div>
            </div>

            <div className='h-100'>
              <ul>
                <NavLink to="/" onClick={handleLinkClick}>{(isLoggedIn && isCompanyPremium) ? text?.navigation?.dashboard : text?.navigation?.home}</NavLink>
                {!isLoggedIn ? (
                  <>
                    <NavLink to="/flow" onClick={handleLinkClick}>{text?.navigation?.flow}</NavLink>
                    {/* <NavLink to="/subscriptions" onClick={handleLinkClick}>{text?.navigation?.subscriptions}</NavLink> */}
                    <NavLink to="/register" onClick={handleLinkClick}>{text?.navigation?.register}</NavLink>
                    <NavLink to="/login" onClick={handleLinkClick}>{text?.navigation?.login}</NavLink>
                  </>
                ) : (
                  <>
                    <NavLink logoutUser={logoutUser} onClick={handleLinkClick}>
                      {text?.navigation?.logout}
                    </NavLink>

                  </>
                )}
              </ul>
              <Footer
                handleToggleChat={handleToggleChat}
                showHelp={showHelp}
                isCompanyPremium={isCompanyPremium}
                isLoggedIn={isLoggedIn}
                handleSendMessage={handleSendMessage}
                conversationHistory={conversationHistory}
                thinking={thinking}
                chatErrorMessage={chatErrorMessage}
                isSuperAdmin={isSuperAdmin}
                typing={typing}

                handleLinkClick={handleLinkClick}
                toggleMenu={toggleMenu}
                menuActive={menuActive}
                isAdmin={user?.roleName === 'Admin'}

              />
            </div>
          </div>
        )
      }
    </StyledNavigation >
  );
};

export default Navigation;
