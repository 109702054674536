import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useWebSocket } from '../context/WebSocketContext'
import SearchInput from '../Core/Inputs/SearchInput'
import CreatableInput from '../Core/Inputs/CreatableInput'
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import useScreenSize from '../context/useScreenSize'
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender'
import SelectableBox from '../Core/SelectableBox'
import CheckBox from '../Core/Inputs/CheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faClose, faAt, faHashtag, faUserEdit, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import Switch from '@mui/material/Switch';
import ChatMentions from './ChatMentions'
import ChannelContainer from './ChannelContainer'
import classnames from 'classnames'
import { isEmpty, debounce } from 'lodash'
import ChatWindow from './ChatWindow'
import 'react-chat-elements/dist/main.css';
import PaginatedData from '../Core/PaginatedData'
import ConfirmModal from '../Core/ConfirmModal'
import ConversationHeader from './ConversationHeader '
import HoverPopup from '../Core/HoverPopup'
import UnreadIndicator from './UnreadIndicator'
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader'
import styled from 'styled-components'

import {
    getConversations,
    getClientConversations
} from '../utils/calls'

const StyledDiv = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
        svg {
                color: ${colors.white};
        }
    .sidebar {
        width: 25%;
        background-color: ${({ theme }) => theme === 'dark' ? colors.background : colors.darkGray};
        padding: 0.5em;
        .selected {
            background-color: ${({ theme }) => theme === 'dark' ? colors.primary : colors.primary};
            p {
                color: ${({ theme }) => theme === 'dark' ? colors.white : colors.white};
            }
        }
        .conversations {
            display: flex;
            flex-direction: column;
            .SelectableBox {
                position: relative;
            .unread {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: ${colors.red};
                position: absolute;
                right: -5px;
                top: -5px;
            }
           }
        }
    }
    .mymention, .taskmention {
            text-shadow: 0 0 1.5px ${colors.secondary};
            font-size: 1em;
    }
    .taskmention {
        background: ${({ theme }) => theme === 'dark' ? colors.task : colors.task};
        color: ${({ theme }) => theme === 'dark' ? colors.white : colors.white} !important;
        border-radius: 5px;
        padding: 2px 5px; 

        a {
            color: ${({ theme }) => theme === 'dark' ? colors.white : colors.white} !important;
        }
    }
    .mymention {
        background: ${({ theme }) => theme === 'dark' ? colors.primary : colors.primary} !important;
        color: ${({ theme }) => theme === 'dark' ? colors.white : colors.white} !important;
        border-radius: 5px;
        padding: 2px 5px; 
    }

`

const ChatWrapper = ({
    user,
    isClient,
    adminProjects
}) => {
    const location = useLocation();
    const id = queryString.parse(location.search)?.id
    const { text } = useLanguage();
    let { theme } = useTheme();
    theme = isClient ? 'dark' : theme;
    const { isDesktop, isPhone } = useScreenSize();
    const [conversationsIsLoading, setConversationsIsLoading] = useState(true);
    const {
        selectedConversationId,
        selectedProjectId,
        createPrivateConversation,
        setConversations,
        conversations,
        participants,
        setParticipants,
        setChannelAdmins,
        channelAdmins,
        setClientParticipants,
        clientParticipants,
        handleUpdateParticipants,
        setProjectConversations,
        setChannelConversations,
        channelConversations,
        projectConversations,
        setSelectedConversation,
        selectedConversation,
        selectConversation,
        setSelectedConversationId,
        createMessage,
        disabledNotification,
        setMessages,
        messages,
        markAsRead,
        handleDeleteConversation,
        isSendingMessage,
        setIsSendingMessage,
        setImagePreviews,
        imagePreviews,
        setFilesPreviews,
        filesPreviews,
        onlineUsers,
        chatData,
        setUnseenMentions,
        unseenMentions,
        setUnseenReplies,
        unseenReplies,
        handleGetUnseenMentions,
        handleGetChannels,
        channels
    } = useWebSocket();
    const firstUnreadRef = useRef(null);

    const [messageContent, setMessageContent] = useState('');
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [participantsIsOpen, setParticipantsIsOpen] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [projectSelected, setProjectSelected] = useState(false);
    const [channelSelected, setChannelSelected] = useState(false);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});

    const [isDisabled, setIsDisabled] = useState(false);
    const [firstUnreadIndex, setFirstUnreadIndex] = useState(null);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
    const [showMentions, setShowMentions] = useState(false);
    const [showCreateChannel, setShowCreateChannel] = useState(false);

    const navigate = useNavigate();

    const addQueryParam = (key, value) => {
        const searchParams = new URLSearchParams(); // Start fresh (removes all existing params)
        searchParams.set(key, value); // Set only the new parameter

        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };



    useEffect(() => {
        // Assuming `selectedConversation` contains the conversation data
        if (selectedConversation) {
            const isUserSilenced = selectedConversation.silenceNotifications?.some(
                (notification) => notification.recipient === user?._id
            );
            setIsDisabled(isUserSilenced);
        }
    }, [selectedConversation, user]);


    useEffect(() => {
        if (id && conversations.length > 0) {
            const conversation = conversations.find((conversation) => conversation._id === id);
            if (conversation) {
                handleSelectConversation(conversation);
            }
        }
        if (id && projectConversations.length > 0) {
            const conversation = projectConversations.find((conversation) => conversation._id === id);
            if (conversation) {
                handleSelectConversation(conversation);
            }
        }

        if (channelConversations.length > 0 && id) {
            const conversation = channelConversations.find((conversation) => conversation._id === id);
            if (conversation) {
                handleSelectConversation(conversation);
            }
        }

        // eslint-disable-next-line
    }, [id, conversations.length, projectConversations.length]);


    useEffect(() => {
        if (chatData?.length) {
            setData(chatData);
        }
    }, [chatData]);

    const containerRef = useRef(null);
    const conversationIdRef = useRef(selectedConversationId);
    const selectedProjectIdRef = useRef(selectedProjectId);
    const selectedConversationRef = useRef(selectedConversation);

    // Sync the ref with the latest selected conversation ID
    useEffect(() => {
        conversationIdRef.current = selectedConversationId;
    }, [selectedConversationId]);

    useEffect(() => {
        selectedProjectIdRef.current = selectedProjectId;
    }, [selectedProjectId]);

    useEffect(() => {
        selectedConversationRef.current = selectedConversation;
    }, [selectedConversation]);


    const handleImageChange = (imageData) => {
        setImagePreviews(imageData);
    };

    const handleFileChange = (fileData) => {
        setFilesPreviews(fileData);
    }

    const handleSendMessage = useCallback(
        ({ message, images, files, mentions }) => {
            const text = message;
            const contentToSend = text || messageContent.trim();
            const imgs = images || imagePreviews;
            const filesToSend = files || filesPreviews;

            if (
                (!isEmpty(imgs) || (isEmpty(imgs) && contentToSend)) ||
                (!isEmpty(filesToSend) || (isEmpty(filesToSend) && contentToSend))
            ) {
                // Start sending state
                createMessage(
                    conversationIdRef.current,
                    contentToSend,
                    'text',
                    user?._id,
                    imgs,
                    filesToSend,
                    (message, error) => {
                        if (message) {
                            setMessageContent('');
                            setTimeout(() => {
                                if (containerRef.current) {
                                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                                }
                            }, 100);
                        } else {
                            console.error('Error sending message:', error);
                        }
                    },
                    mentions
                );
            }
        },

        // eslint-disable-next-line
        [imagePreviews, filesPreviews, messageContent, user?._id]
    );


    const handleCreateConversation = () => {
        const companyId = user?.companyId;
        const currentUserId = user?._id;
        if (projectSelected) {
            const conversation = projectConversations.find(conversation => conversation?.projectId?._id === userIds[0]);
            if (conversation) {
                handleSelectConversation(conversation);
                setSearchIsOpen(false);
                addQueryParam('id', conversation._id)
            }
            return
        }
        if (channelSelected) {
            const conversation = channels.find(conversation => conversation?._id === userIds[0]);
            if (conversation) {
                handleSelectConversation(conversation);
                setSearchIsOpen(false);
                addQueryParam('id', conversation._id)
            }
            return
        }

        if (!projectSelected && !channelSelected) {
            createPrivateConversation(userIds, companyId, currentUserId, clientIds, (conversation, error) => {
                if (conversation) {
                    // Optionally, set it as the selected conversation
                    handleSelectConversation(conversation);
                    setSearchIsOpen(false);
                    handleGetConversations();
                    addQueryParam('id', conversation._id)

                } else {
                    console.error('Error creating conversation:', error);
                }
            });
        }

    };

    const toggleSearch = () => {
        setSearchIsOpen(!searchIsOpen);
        setFilters({});
        setUserIds([]);
        setClientIds([]);
        setProjectSelected(false);
        setChannelSelected(false);
        setData(chatData);
        setSelectedFilters({ query: '', type: null });
    }

    const toggleParticipants = () => {
        setParticipantsIsOpen(!participantsIsOpen);
        setParticipants(selectedConversation?.participants?.map(participant => participant?._id));
        setChannelAdmins(selectedConversation?.channelAdmins);
        setClientParticipants(selectedConversation?.clientParticipants?.map(client => client?._id));
        setData(chatData);
        setFilters({});
        setSelectedFilters({ query: '', type: null });
    }

    const [selectedFilters, setSelectedFilters] = useState({
        query: '',
        type: null
    });

    const debouncedSetFilters = useMemo(
        () =>
            debounce((updatedFilters) => {
                setFilters((prev) => ({
                    ...prev,
                    ...updatedFilters,
                }));
            }, 500),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSetFilters.cancel();
        };
    }, [debouncedSetFilters]);

    const handleSearchChange = (e) => {
        const queryValue = e?.target?.value ?? '';
        setSelectedFilters((prev) => ({ ...prev, query: queryValue }));
        debouncedSetFilters({ query: queryValue });
    };

    const handleSelectChange = (e) => {
        setSelectedFilters((prev) => ({ ...prev, type: e }));
        debouncedSetFilters({ type: e });
    };


    const filterData = useCallback(() => {
        const filterOptions = {};

        const query = filters?.query ? String(filters.query).toLowerCase() : '';

        if (query) {
            filterOptions.query = query;
        }

        if (filters?.type) {
            filterOptions.type = filters.type.value;
        }

        let filteredData = chatData || [];

        if (filterOptions.query) {
            filteredData = filteredData.filter(
                (item) =>
                    item?.name?.toLowerCase().includes(filterOptions.query) ||
                    item?.type?.toLowerCase().includes(filterOptions.query)
            );
        }

        if (filterOptions.type) {
            filteredData = filteredData.filter((item) => item?.type === filterOptions.type);
        }

        setData(filteredData);
    }, [filters, chatData]);

    useEffect(() => {
        if (filters.query || filters.type) {
            filterData();
        } else {
            setData(chatData);
        }

        // eslint-disable-next-line
    }, [filters]);

    const handleGetConversations = useCallback(async () => {
        setConversationsIsLoading(true);
        try {
            const res = isClient
                ? await getClientConversations({ companyId: user?.companyId, clientId: user?._id })
                : await getConversations();

            if (res.status === 200) {
                setConversationsIsLoading(false);
                setConversations(res.data?.filter(x => !x?.projectId && !x?.isChannel));
                setProjectConversations(res.data?.filter(x => x?.projectId));
                setChannelConversations(res.data?.filter(x => x?.isChannel));
                setMessageContent('');
            }
        } catch (error) {
            setConversationsIsLoading(false);
            console.error('Failed to fetch conversations:', error);
        }

        // eslint-disable-next-line
    }, [isClient, user?.companyId, user?._id]);



    useEffect(() => {
        handleGetConversations();
        handleGetUnseenMentions();
        handleGetChannels();

        // eslint-disable-next-line
    }, []);



    const handleSelectConversation = (conversation) => {
        const companyId = user?.companyId;
        const userId = user?._id;
        setSelectedConversation(conversation);
        setParticipants(conversation?.participants?.map(participant => participant?._id));
        setChannelAdmins(conversation?.channelAdmins);
        setClientParticipants(conversation?.clientParticipants?.map(client => client?._id));
        selectConversation(conversation, companyId, userId);
        setSelectedConversationId(conversation?._id);
        if (selectedConversationId !== conversation?._id) {
            setMessageContent('');
        }
    }


    const [allRead, setAllRead] = useState(false);

    // Mark all messages as read manually
    const handleMarkAllAsRead = () => {
        const unreadMessageIds = messages
            .filter((message) => message.isUnread)
            .map((message) => message._id);

        if (unreadMessageIds.length > 0) {
            markAsRead(selectedConversationId, unreadMessageIds, user);
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    unreadMessageIds.includes(message._id)
                        ? { ...message, isUnread: false }
                        : message
                )
            );

            // Determine if the conversation is a project conversation
            setConversations((prevConversations) =>
                prevConversations.map((conversation) => {
                    if (conversation._id === selectedConversationId && !conversation.projectId) {
                        return { ...conversation, hasUnreadMessages: false };
                    }
                    return conversation;
                })
            );

            setProjectConversations((prevProjectConversations) =>
                prevProjectConversations.map((conversation) => {
                    if (conversation._id === selectedConversationId && conversation.projectId) {
                        return { ...conversation, hasUnreadMessages: false };
                    }
                    return conversation;
                })
            );

            setChannelConversations((prevChannelConversations) =>
                prevChannelConversations.map((conversation) => {
                    if (conversation._id === selectedConversationId) {
                        return { ...conversation, hasUnreadMessages: false };
                    }
                    return conversation;
                }
                )
            );

            setAllRead(true);
        }
    };

    return (
        <StyledDiv
            theme={isClient ? 'dark' : theme}
        >
            <ConditionalRender renderIf={true} isLoading={conversationsIsLoading}>
                <ConditionalRender renderIf={isDesktop && !isEmpty([...conversations, ...projectConversations])}>
                    <div className='sidebar h-100'>
                        <div className='conversations scroll-container h-100 pr-md'>
                            <div className='h-100'>
                                <ConditionalRender renderIf={!isEmpty(channelConversations)}>
                                    <h4 className='tx-left'>
                                        {text?.chat?.channels}
                                    </h4>
                                    {channelConversations
                                        ?.map(conversation => (
                                            <SelectableBox
                                                isDark={isClient}
                                                className={classnames({
                                                    'selected': selectedConversationId === conversation._id
                                                })}
                                                onClick={() => {
                                                    handleSelectConversation(conversation)
                                                    addQueryParam('id', conversation._id)
                                                }}
                                                key={conversation._id}
                                            >
                                                <p className='tx-left'>
                                                    {`# ${conversation.channelName}`}
                                                </p>
                                                <ConditionalRender renderIf={conversation.hasUnreadMessages}>
                                                    <div className='unread' />
                                                </ConditionalRender>
                                            </SelectableBox>
                                        ))
                                    }
                                </ConditionalRender>
                                <ConditionalRender renderIf={!isEmpty(projectConversations)}>
                                    <h4 className='tx-left mt-md'>
                                        {text?.chat?.activeProjects}
                                    </h4>
                                    {projectConversations
                                        ?.filter((x) => x?.projectId?.projectStatus !== 'completed')
                                        ?.map(conversation => (
                                            <SelectableBox
                                                isDark={isClient}
                                                className={classnames({
                                                    'selected': selectedConversationId === conversation._id
                                                })}
                                                onClick={() => {
                                                    handleSelectConversation(conversation)
                                                    addQueryParam('id', conversation._id)
                                                }}
                                                key={conversation._id}
                                            >
                                                <p className='tx-left'>
                                                    {conversation.conversationName}
                                                </p>
                                                <ConditionalRender renderIf={conversation.hasUnreadMessages}>
                                                    <div className='unread' />
                                                </ConditionalRender>
                                            </SelectableBox>
                                        ))
                                    }
                                </ConditionalRender>
                                <ConditionalRender renderIf={!isEmpty(conversations)}>
                                    <h4 className='mt-md tx-left' style={{
                                        color: (theme === 'dark' || isClient) ? colors.white : colors.black
                                    }}>
                                        {text?.chat?.conversations}
                                    </h4>
                                    {conversations
                                        ?.map(conversation => (
                                            <SelectableBox
                                                isDark={isClient}
                                                className={classnames({
                                                    'selected': selectedConversationId === conversation._id
                                                })}
                                                onClick={() => {
                                                    handleSelectConversation(conversation)
                                                    addQueryParam('id', conversation._id)
                                                }}
                                                key={conversation._id}
                                            >
                                                <p className='tx-left'>
                                                    {conversation.conversationName}
                                                </p>
                                                <ConditionalRender renderIf={conversation.hasUnreadMessages}>
                                                    <div className='unread' />
                                                </ConditionalRender>
                                            </SelectableBox>
                                        ))
                                    }
                                </ConditionalRender>

                            </div>
                        </div>
                    </div>
                </ConditionalRender>
                <div className="w-100 chat-wrapper">
                    <div style={{
                        height: '5%'
                    }}>
                        <div className='flex items-start justify-space-between'>
                            <div className='flex items-center'>

                                <ConditionalRender renderIf={selectedConversationId}>
                                    <ConditionalRender renderIf={!isClient}>
                                        <HoverPopup
                                            onClick={() => { }}
                                            style={{
                                                padding: '0',
                                                background: 'transparent'
                                            }}
                                            placement={'bottom'}
                                            id='silance-popup-employee_create_project_employee'
                                            text={text?.chat?.disabledNotification}
                                        >
                                            <Switch
                                                checked={isDisabled} // Boolean to track the switch state
                                                onChange={(event) => {
                                                    const newDisabledState = event.target.checked; // Get new state
                                                    setIsDisabled(newDisabledState); // Update local state

                                                    // Emit the toggle event
                                                    disabledNotification(selectedConversationId, user?._id, newDisabledState);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}

                                            />
                                        </HoverPopup>
                                    </ConditionalRender>
                                    {
                                        messages?.map((message, index) => (
                                            <React.Fragment key={index}>
                                                {!allRead && index === firstUnreadIndex && (
                                                    <UnreadIndicator
                                                        firstUnreadRef={firstUnreadRef}
                                                        theme={isClient ? 'dark' : theme}
                                                        markAllAsRead={handleMarkAllAsRead}
                                                        isClient={isClient}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))
                                    }
                                </ConditionalRender>
                            </div>
                            <ConditionalRender renderIf={!isClient}>
                                <div className='flex'>
                                    <HoverPopup
                                        onClick={() => {
                                            setShowCreateChannel(!showCreateChannel);
                                        }}
                                        placement={'bottom'}
                                        id='mentions-popup-employee_create_project_employee'
                                    >
                                        <FontAwesomeIcon
                                            icon={faHashtag}
                                            style={{
                                                fontSize: '1.5em'
                                            }} />
                                    </HoverPopup>
                                    <ConditionalRender renderIf={!isEmpty(unseenMentions) || !isEmpty(unseenReplies)}>
                                        <HoverPopup
                                            onClick={() => {
                                                setShowMentions(!showMentions);
                                                handleGetUnseenMentions();
                                            }}
                                            placement={'bottom'}
                                            id='mentions-popup-employee_create_project_employee'
                                        >
                                            <FontAwesomeIcon
                                                icon={faAt}
                                                style={{
                                                    fontSize: '1.5em'
                                                }} />
                                        </HoverPopup>
                                    </ConditionalRender>
                                    <HoverPopup
                                        onClick={toggleSearch}
                                        placement={'bottom'}
                                        id='search-popup-employee_create_project_employee'
                                        text={text?.employees?.landing?.search}
                                        style={{
                                            width: '100%'
                                        }}
                                        controlledTooltipProps={{
                                            isDark: true,
                                            text: text?.actionTracking?.searchChat,
                                            placement: 'bottom',
                                            actionParams: {
                                                feature: 'chat',
                                                action: 'search'
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            style={{
                                                fontSize: '1.5em'
                                            }} />
                                    </HoverPopup>
                                    <ConditionalRender renderIf={selectedConversationId}>
                                        <ConditionalRender renderIf={
                                            selectedConversation?.isChannel
                                            && !selectedConversation?.participants?.some(participant => participant?._id === user?._id)

                                        }>
                                            <HoverPopup
                                                onClick={() => {
                                                    handleUpdateParticipants(
                                                        conversationIdRef.current,
                                                        [...participants, user?._id],
                                                        clientParticipants,
                                                        channelAdmins,
                                                        user,
                                                        selectedProjectIdRef.current,
                                                        selectedConversationRef.current?.isChannel
                                                    )
                                                }}
                                                placement={'bottom'}
                                                id='participants-join-popup-employee_create_project_employee'
                                                style={{
                                                    width: '100%'
                                                }}
                                                text={text?.chat?.join}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    style={{
                                                        fontSize: '1.5em'
                                                    }} />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        <ConditionalRender
                                            renderIf={
                                                // 1) User is an Admin
                                                user?.roleName === 'Admin' ||

                                                // 2) Conversation has a project, and the user is an admin of that project
                                                adminProjects?.some(
                                                    (project) =>
                                                        project?._id === (selectedConversation?.projectId?._id || selectedConversation?.projectId)
                                                ) ||

                                                // 3) It's a channel (no project) and the user is a channel admin
                                                (
                                                    !selectedConversation?.projectId &&
                                                    selectedConversation?.isChannel &&
                                                    selectedConversation?.channelAdmins?.includes(user?._id)
                                                ) ||

                                                // 4) It's neither a project nor a channel
                                                (
                                                    !selectedConversation?.projectId &&
                                                    !selectedConversation?.isChannel
                                                )
                                            }
                                        >
                                            <HoverPopup
                                                onClick={toggleParticipants}
                                                placement={'bottom'}
                                                id='participants-popup-employee_create_project_employee'
                                                style={{
                                                    width: '100%'
                                                }}
                                                text={text?.chat?.participants}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faUserEdit}
                                                    style={{
                                                        fontSize: '1.5em'
                                                    }} />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        <ConditionalRender
                                            renderIf={
                                                (selectedConversation?.createdBy !== user?._id && !selectedConversation?.projectId)
                                            }
                                        >
                                            <HoverPopup
                                                onClick={() => {
                                                    handleUpdateParticipants(
                                                        conversationIdRef.current,
                                                        participants.filter(participant => participant !== user?._id),
                                                        clientParticipants,
                                                        channelAdmins,
                                                        user,
                                                        selectedProjectIdRef.current,
                                                        selectedConversationRef.current?.isChannel
                                                    )
                                                }}
                                                placement={'bottom'}
                                                id='participants-leave-popup-employee_create_project_employee'
                                                style={{
                                                    width: '100%'
                                                }}
                                                text={text?.chat?.leave}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faRightFromBracket}
                                                    style={{
                                                        fontSize: '1.5em'
                                                    }} />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        <ConditionalRender
                                            renderIf={
                                                // (1) must NOT be a project
                                                !selectedConversation?.projectId
                                                &&
                                                // (2) EITHER not a channel OR (channel and I'm a channel admin)
                                                (
                                                    !selectedConversation?.isChannel
                                                    || (
                                                        selectedConversation?.isChannel
                                                        && selectedConversation?.channelAdmins?.includes(user?._id)
                                                    )
                                                )
                                                // you must be the creator
                                                && selectedConversation?.createdBy === user?._id
                                            }
                                        >
                                            <HoverPopup
                                                onClick={() => setToggleDeleteModal(!toggleDeleteModal)}
                                                placement={'bottom'}
                                                id='participants-popup-remove-participants'
                                                style={{
                                                    width: '100%'
                                                }}
                                                text={text?.chat?.deleteConversation}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    style={{
                                                        fontSize: '1.5em',
                                                        color: colors.red
                                                    }} />
                                            </HoverPopup>
                                        </ConditionalRender>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>

                    {selectedConversationId ? (
                        <div className="flex flex-column h-95">
                            <h4 className='mb-md'
                                style={{
                                    color: (theme === 'dark' || isClient) ? colors.white : colors.black
                                }}
                            >
                                <ConversationHeader
                                    selectedConversation={selectedConversation}
                                    onlineUsers={onlineUsers}
                                    currentUserId={user?._id}
                                />
                            </h4>
                            <ChatWindow
                                user={user}
                                containerRef={containerRef}
                                firstUnreadRef={firstUnreadRef}
                                setAllRead={setAllRead}
                                allRead={allRead}
                                setFirstUnreadIndex={setFirstUnreadIndex}
                                firstUnreadIndex={firstUnreadIndex}
                                isClient={isClient}
                            />
                            <RichEditorWithImageUploader
                                onImageUpload={handleImageChange}
                                onFileUpload={handleFileChange}
                                maxFiles={5}
                                customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                                title={text?.chat?.message}
                                showForm={false}
                                onSubmit={(values) => handleSendMessage(values)}
                                text={text}
                                submitText={text?.tasks?.details?.button}
                                name="message"
                                isSendingMessage={isSendingMessage}
                                setIsSendingMessage={setIsSendingMessage}
                                imagePreviews={imagePreviews}
                                filesPreviews={filesPreviews}
                                setImagePreviews={setImagePreviews}
                                isDark={isClient}
                                hideSave
                            />
                        </div>
                    ) : (
                        <h1
                            style={{
                                color: (theme === 'dark' || isClient) ? colors.white : colors.black
                            }}
                            className="h-95 w-100 flex justify-center items-center">
                            {text?.chat?.selectConversation}
                        </h1>
                    )}
                </div>
            </ConditionalRender>

            <ConfirmModal isOpen={toggleDeleteModal} toggle={() => setToggleDeleteModal(!toggleDeleteModal)} width='50%' height='50%' onClick={() => {
                handleDeleteConversation(selectedConversationId, user?._id, user?.companyId);
                setToggleDeleteModal(false);
                addQueryParam('id', null)
            }} text={text?.chat?.deleteConversation}>
                <div className='w-100'>
                    <h4 className='mb-md'>{text?.chat?.deleteModal}</h4>
                </div>
            </ConfirmModal>

            <ConfirmModal
                isOpen={searchIsOpen}
                toggle={toggleSearch}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                onClick={(!isEmpty(userIds) || !isEmpty(clientIds)) ? handleCreateConversation : null}
                text={text?.chat?.chatButton}
            >
                <div className='w-100'>
                    <SearchInput
                        className='pb-md w-100'
                        value={selectedFilters.query}
                        onChange={handleSearchChange}
                        label={text?.employees?.landing?.search}
                        placeHolder={'...'}
                    />

                    <CreatableInput
                        initValue={selectedFilters.type}
                        isSearchable={false}
                        menuPlacement={'bottom'}
                        isClearable
                        placeholder={text?.employees?.landing?.selectType}
                        name="type"
                        onChange={handleSelectChange}

                        options={[
                            { value: 'employee', label: 'Employee' },
                            { value: 'client', label: 'Client' },
                            { value: 'project', label: 'Project' },
                            { value: 'channel', label: 'Channel' },
                        ]}
                        style={{
                            width: '100%'
                        }}
                    />
                    <ConditionalRender renderIf={selectedFilters.type?.value === 'channel'}>
                        <p className='mt-md'>
                            {text?.chat?.privateJoinInfo}
                        </p>
                    </ConditionalRender>

                    <PaginatedData
                        className='mt-md'
                        data={data}
                        defaultItemsPerPage={5}
                        noDataText={text?.employees?.landing?.noData}
                        searching={filters?.query}
                        showLightColors
                        ItemComponent={({ item }) => {
                            return (
                                <SelectableBox
                                    showLightColors
                                    className='w-100'
                                    onClick={() => {
                                        if (item.type === 'employee') {
                                            setUserIds([item._id]);
                                        }
                                        if (item.type === 'client') {
                                            setClientIds([item._id]);
                                        }
                                        if (item.type === 'channel') {
                                            setChannelSelected(true);
                                            setUserIds([item._id]);
                                        } else {
                                            setChannelSelected(false);
                                        }
                                        if (item.type === 'project') {
                                            setUserIds([item._id]);
                                            setProjectSelected(true);
                                        } else {
                                            setProjectSelected(false);
                                        }
                                    }}
                                    isSelected={userIds.includes(item._id) || clientIds.includes(item._id)}
                                >

                                    <div className='flex items-center'>
                                        <ConditionalRender renderIf={item.type !== 'project' && item.type !== 'channel'}>
                                            <ConditionalRender renderIf={item.isOnline}>
                                                <span
                                                    className='mr-md'
                                                    style={{
                                                        background: colors.green,
                                                        height: '10px',
                                                        width: '10px',
                                                        borderRadius: '50%'
                                                    }} />
                                            </ConditionalRender>
                                            <ConditionalRender renderIf={!item.isOnline}>
                                                <span
                                                    className='mr-md'
                                                    style={{
                                                        background: colors.red,
                                                        height: '10px',
                                                        width: '10px',
                                                        borderRadius: '50%'
                                                    }} />
                                            </ConditionalRender>
                                        </ConditionalRender>
                                        <p>
                                            {item.name} ({item.type})
                                        </p>
                                    </div>
                                </SelectableBox>
                            )
                        }
                        }
                    />
                </div>
            </ConfirmModal>
            <ConfirmModal
                isOpen={participantsIsOpen}
                toggle={toggleParticipants}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                onClick={() => {
                    handleUpdateParticipants(
                        conversationIdRef.current,
                        participants,
                        clientParticipants,
                        channelAdmins,
                        user,
                        selectedProjectIdRef.current,
                        selectedConversationRef.current?.isChannel
                    );
                    setParticipantsIsOpen(false);
                }}
                text={[...participants, ...clientParticipants]?.length > 1 ? text?.chat?.update : null}
            >
                <div className={classnames({
                    'flex w-100': true,
                    'flex-column': isPhone,
                })}>
                    <div className={classnames({
                        'w-50': !isPhone,
                        'mr-md': !isPhone,
                    })}>
                        <SearchInput
                            className='pb-md w-100'
                            value={selectedFilters.query}
                            onChange={handleSearchChange}
                            label={text?.employees?.landing?.search}
                            placeHolder={'...'}
                        />

                        <PaginatedData
                            className="mt-md"
                            data={data?.filter(item =>
                                !participants.includes(item._id) && !clientParticipants.includes(item._id) &&
                                item?.type !== 'project' && item?._id !== user?._id
                            )} // Exclude checked items
                            defaultItemsPerPage={5}
                            noDataText={text?.table?.noDataText}
                            searching={filters?.query}
                            showLightColors
                            ItemComponent={({ item }) => {
                                const isChecked = participants.includes(item._id) || clientParticipants.includes(item._id);

                                return (
                                    <SelectableBox
                                        style={{ width: '100%' }}
                                        showLightColors
                                        onClick={() => {
                                            if (item?.type === 'employee') {
                                                setParticipants((prev) => {
                                                    const updated = new Set(prev);
                                                    isChecked ? updated.delete(item._id) : updated.add(item._id);
                                                    return [...updated];
                                                });
                                            }
                                            if (item?.type === 'client') {
                                                setClientParticipants((prev) => {
                                                    const updated = new Set(prev);
                                                    isChecked ? updated.delete(item._id) : updated.add(item._id);
                                                    return [...updated]; // ✅ Convert back to array for state consistency
                                                });
                                            }
                                        }}
                                    >
                                        <CheckBox
                                            id={`participants_${item._id}`}
                                            disabledPopUp
                                            checked={isChecked}
                                            disabled={item._id === user?._id}
                                            onChange={() => { }}
                                            label={
                                                <div className="flex items-center">
                                                    <span
                                                        className="mr-sm ml-sm"
                                                        style={{
                                                            background: item.isOnline ? colors.green : colors.red,
                                                            height: '10px',
                                                            width: '10px',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                    {item.name} ({item.type})
                                                </div>
                                            }
                                        />
                                    </SelectableBox>
                                );
                            }}
                        />


                    </div>
                    <div className={classnames({
                        'w-50': !isPhone,
                        'ml-md': !isPhone,
                    })}>
                        <label className='mb-sm b'>{text?.paginated?.selected}</label>
                        <div>
                            {/* Participants rendering without useMemo */}
                            {participants.map(participantId => {
                                const participant = data.find(item => item._id === participantId);
                                const createdBy = participantId === selectedConversation?.createdBy;
                                const isAdmin = channelAdmins?.includes(participantId);

                                if (!participant) return null;

                                return (
                                    <SelectableBox
                                        key={participant._id}
                                        showLightColors
                                        disabled={createdBy}
                                    >
                                        <div className='flex items-center justify-content-between'>

                                            <CheckBox
                                                id={`selected_${participant._id}`}
                                                disabledPopUp
                                                disabled={createdBy}
                                                checked={true}
                                                label={
                                                    <div className="flex items-center">
                                                        <span
                                                            className='mr-sm ml-sm'
                                                            style={{
                                                                background: participant.isOnline ? colors.green : colors.red,
                                                                height: '10px',
                                                                width: '10px',
                                                                borderRadius: '50%'
                                                            }}
                                                        />
                                                        {participant.name} ({participant.type})
                                                    </div>
                                                }
                                                onChange={() => {
                                                    setParticipants(prevParticipants =>
                                                        prevParticipants.filter(id => id !== participant._id)
                                                    );
                                                }}
                                            />
                                            <ConditionalRender renderIf={selectedConversation?.isChannel}>
                                                {/* The admin checkbox */}
                                                <CheckBox
                                                    id={`admin_${participant._id}`}
                                                    disabledPopUp
                                                    checked={isAdmin} // true if channelAdmins includes participantId
                                                    label={text?.projects?.create?.admin}
                                                    disabled={createdBy}
                                                    onChange={() => {
                                                        setChannelAdmins(prevAdmins => {
                                                            // If participant is already in channelAdmins, remove them
                                                            if (isAdmin) {
                                                                return prevAdmins.filter(id => id !== participant._id);
                                                            }
                                                            // Otherwise, add them
                                                            else {
                                                                return [...prevAdmins, participant._id];
                                                            }
                                                        });
                                                    }}
                                                />

                                            </ConditionalRender>
                                        </div>
                                    </SelectableBox>
                                );
                            })}

                            {/* Client Participants rendering without useMemo */}
                            {clientParticipants.map(clientId => {
                                const client = data.find(item => item._id === clientId);
                                if (!client) return null;

                                return (
                                    <SelectableBox key={client._id} showLightColors>
                                        <CheckBox
                                            id={`selected_${client._id}`}
                                            disabledPopUp
                                            checked={true}
                                            label={
                                                <div className="flex items-center">
                                                    <span
                                                        className='mr-sm ml-sm'
                                                        style={{
                                                            background: client.isOnline ? colors.green : colors.red,
                                                            height: '10px',
                                                            width: '10px',
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                                    {client.name} ({client.type})
                                                </div>
                                            }
                                            onChange={() => {
                                                setClientParticipants(prevParticipants =>
                                                    prevParticipants.filter(id => id !== client._id)
                                                );
                                            }}
                                        />
                                    </SelectableBox>
                                );
                            })}


                        </div>
                    </div>
                </div>
            </ConfirmModal>
            <ChatMentions
                showMentions={showMentions}
                setShowMentions={setShowMentions}
            />
            <ChannelContainer
                showCreateChannel={showCreateChannel}
                setShowCreateChannel={setShowCreateChannel}
                user={user}
            />

        </StyledDiv>
    )
}

const ChatContainer = ({
    user,
    isClient,
    projects
}) => {
    const adminProjects = projects
        ?.filter((project) => project?.projectStatus !== 'completed')
        ?.filter(project => {
            // Check if the user is an admin
            return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
        });


    return (
        <ChatWrapper
            user={user}
            isClient={isClient}
            adminProjects={adminProjects}
        />
    )
}

export default ChatContainer
