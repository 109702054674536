import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RegisterContext } from '../context/RegisterContext';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext';
import ConditionalRender from './ConditionalRender';

const ControlledTooltip = ({
  children,
  text,
  placement = 'top',
  isDark,
  isVisible = true,
  actionParams,
  style
}) => {
  let { theme } = useTheme();
  theme = isDark ? 'dark' : theme;
  const { handleRegisterAction, actions, loading } = React.useContext(RegisterContext);

  // Check if the action has already been registered
  const isActionRegistered = actions?.some(
    (action) => action?.feature === actionParams?.feature && action?.action === actionParams?.action
  );

  // Tooltip visibility is only controlled by `isVisible` and `isActionRegistered`
  const open = isVisible && !isActionRegistered;

  const handleActionClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await handleRegisterAction(actionParams);
    } catch (error) {
      console.error('Failed to register action:', error);
    }
  };

  const tooltipStyles = {
    backgroundColor: theme === 'dark' ? colors.primary : colors.primary,
    color: '#fff',
    fontSize: '0.9rem',
    borderRadius: '4px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    overflow: 'visible',
    minWidth: '200px',
  };

  return (
    <ConditionalRender renderIf={true} isLoading={loading}>
      <Tooltip
        style={style}
        className={'ControlledTooltip'}
        open={open}
        disableInteractive
        title={
          <div style={{ padding: '1em', position: 'relative' }}>
            <IconButton
              onClick={handleActionClick}
              size="small"
              style={{
                color: colors.white,
                backgroundColor: colors.red,
                padding: '0.1em',
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '0',
                borderRadius: '0',
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            {text}
          </div>
        }
        placement={placement}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport', // Ensure tooltip stays within the viewport
              },
            },
          ],
          style: {
            zIndex: '9999',
            overflow: 'visible',
            pointerEvents: 'all',
          },
        }}
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
          arrow: {
            sx: { color: theme === 'dark' ? colors.primary : colors.primary }, // Match arrow color to tooltip
          },
        }}
      >
        <div>{children}</div>
      </Tooltip>
    </ConditionalRender>
  );
};

export default ControlledTooltip;
