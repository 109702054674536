import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from "react-modal";
import CallToAction from './CallToAction';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext';
import ConditionalRender from './ConditionalRender';
import classnames from 'classnames';
import useScreenSize from '../context/useScreenSize';
import Wrapper from './Wrapper';
import SelectableBox from './SelectableBox';
import CarouselWithCustomArrows from './CarouselWithCustomArrows';
import { GlobalContext } from '../context/GlobalContext';
import { useToast } from '../context/ToastContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from './HoverPopup';

const StyledUploader = styled.div`
    max-height: 150px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 1em;
    text-align: center;
    cursor: pointer;
    transition: border 0.24s ease-in-out;
    &:hover {
        background: ${(props) => (props.theme === 'light' ? colors.secondary : colors.backgroundSecondary)}; 
      border-color: #888888;
    }
  }
  .error {
    color: ${colors.red};
    font-size: 12px;
    margin-top: 1em;
  }
  .wrapper {
    margin-top: 1em;
    img {
        object-fit: cover;
    }
  }
`;


const StyledDiv = styled.div`
.no-header {
    .header-wrapper {
        background: transparent !important;
    }
}
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${(props) => props.imageSize || '250px'}, 1fr));
    gap: 1%;
    margin-top: 1em;
    margin-bottom: 1em;
}

.dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1em;
    min-height: 40px;
    background: ${(props) => (props.theme === 'light' ? colors.blue : colors.secondary)};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    p {
        font-family: ${colors.openSans};
        color: ${(props) => (props.theme === 'light' ? colors.white : colors.black)};
    }
    &:hover {
        background: ${(props) => (props.theme === 'light' ? colors.secondary : colors.backgroundSecondary)}; 
        p {
            color: ${colors.blue};
        }
    }
}

.image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
        object-fit: cover;
        width: ${props => props.imageSize || '250px'};
        height: ${props => props.imageSize || '250px'};
        cursor: pointer;
    }
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        background: ${colors.red};
        svg {
            color: ${colors.white};
            font-size: 1rem;
        }
    }

    /* Ensure all images maintain the same width */
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
        grid-column: span 1;
    }
}
`

const ImageUploader = ({
    onImageUpload,
    onFileUpload,
    customMessage,
    title = '',
    children,
    showLightColors,
    isLoading = false,
    noHeader = false,
    isEditing = false,
    imageSize = '250px',
    customButton = null,
    isSendingMessage = false,
    isDark = false,
    noButton = false,
    hideSave = false,
    icon = false
}) => {
    const { UPLOAD_LIMIT, MAX_IMAGES, MAX_FILES } = useContext(GlobalContext);
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedFileTypes = ['text/plain', 'application/pdf'];

    const { notify } = useToast();

    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [imageError, setImageError] = useState('');
    const [fileError, setFileError] = useState('');

    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [currentImagesLength, setCurrentImagesLength] = useState(0);

    useEffect(() => {
        if (uploadedImages?.length >= UPLOAD_LIMIT) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }

        // Count only 'new' images
        const newImages = uploadedImages?.filter((image) => image.new);
        setCurrentImagesLength(newImages?.length);

        // eslint-disable-next-line
    }, [uploadedImages]);


    useEffect(() => {
        if (currentImagesLength >= UPLOAD_LIMIT) {
            notify(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`, 'warning');
            setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }
        // eslint-disable-next-line
    }, [currentImagesLength]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
            const otherFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

            // 🔹 **Image Upload Logic (Same as Before)**
            if (uploadedImages.length >= MAX_IMAGES) {
                setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${MAX_IMAGES} ${MAX_IMAGES > 1 ? text?.projects?.create?.uploader?.images : text?.projects?.create?.uploader?.image}`);
                return;
            }

            const newImagesCount = uploadedImages.filter(img => img.new).length;
            const UPLOAD_LIMIT = MAX_IMAGES; // Keeping this to ensure previous logic works

            if (newImagesCount >= UPLOAD_LIMIT) {
                setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
                return;
            }

            const remainingImageSlots = Math.min(UPLOAD_LIMIT - newImagesCount, MAX_IMAGES - uploadedImages.length);
            const imagesToUpload = imageFiles.slice(0, remainingImageSlots);

            if (imagesToUpload.length > 0) {
                const newImagesPromises = imagesToUpload.map(file =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve({ file, dataUrl: reader.result, originalName: file.name, new: true });
                        reader.readAsDataURL(file);
                        reader.onerror = reject;
                    })
                );

                Promise.all(newImagesPromises)
                    .then(newImages => {
                        setUploadedImages(prevImages => {
                            const updatedImages = [...newImages, ...prevImages];
                            onImageUpload(updatedImages.map(img => ({
                                dataUrl: img.dataUrl,
                                originalName: img.originalName,
                                new: img.new
                            })));
                            return updatedImages;
                        });
                        setImageError('');
                    })
                    .catch(() => setImageError(text?.projects?.create?.uploader?.validations?.error));
            }

            // 🔹 **File Upload Logic (Handled Separately)**
            const validFiles = otherFiles.filter(file => {
                if (!allowedFileTypes.includes(file.type)) {
                    setFileError(`${text?.uploader?.fileType} ${file.type} ${text?.uploader?.isNot}`);
                    return false;
                }
                if (file.size > maxFileSize) {
                    setFileError(`${text?.uploader?.file} ${file.name} ${text?.uploader?.toLarge}`);
                    return false;
                }
                setFileError('');
                return true;
            });

            const remainingFileSlots = MAX_FILES - uploadedFiles.length;
            const filesToUpload = validFiles.slice(0, remainingFileSlots);

            if (filesToUpload.length > 0) {
                setUploadedFiles(prevFiles => {
                    const updatedFiles = [...filesToUpload, ...prevFiles];
                    onFileUpload(updatedFiles);
                    return updatedFiles;
                });
            }
        },
        [uploadedImages, uploadedFiles, text, MAX_IMAGES, MAX_FILES, onImageUpload, onFileUpload]
    );


    useEffect(() => {
        if (isSendingMessage) {
            setUploadedImages([]);
            setUploadedFiles([]);
            setImageError('');
        }
    }, [isSendingMessage])

    const handleRemoveImage = (index) => {
        setUploadedImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            onImageUpload(updatedImages); // Pass the correct updated state
            return updatedImages; // Ensure state updates properly
        });
    };

    const handleRemoveFile = (index) => {
        setUploadedFiles((prevFiles) => {
            const updatedFiles = prevFiles.filter((_, i) => i !== index);
            onFileUpload(updatedFiles); // Pass the correct
            return updatedFiles; // Ensure state updates properly
        });
    };



    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            className={classnames({
                'image-uploader': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            theme={theme}
            noHeader={noHeader}
            imageSize={imageSize}
        >
            <Wrapper
                className={classnames({
                    'no-header': noHeader
                })}
                isLoading={isLoading}
                width={'100%'}
                title={title}
                showLightColors={showLightColors}
                bodyClassName={''}
                isDark={isDark}
                button={
                    <ConditionalRender renderIf={!noButton}>
                        <ConditionalRender renderIf={!customButton}>

                            {/* The dropzone container stays inside HoverPopup */}
                            <div {...getRootProps()} className="dropzone-inner">
                                <input {...getInputProps()} />
                                {/* Wrap default dropzone in HoverPopup */}
                                <HoverPopup
                                    // Customize props (className, style, text, etc.) as needed
                                    noBG
                                    className="dropzone"
                                    style={{ width: '100%' }}
                                    placement="top"
                                    id="uploader-hover"
                                    // Example of controlling the tooltip
                                    controlledTooltipProps={{
                                        isDark: true,
                                        text: text?.actionTracking?.upload,
                                        placement: 'left',
                                        actionParams: {
                                            feature: 'chat',
                                            action: 'upload',
                                        },
                                    }}
                                >
                                    <ConditionalRender renderIf={!customMessage && !icon}>
                                        <p>
                                            {`${text?.projects?.create?.uploader?.title} ${MAX_IMAGES} ${MAX_IMAGES > 1
                                                ? text?.projects?.create?.uploader?.validations?.atAtime
                                                : text?.projects?.create?.uploader?.image
                                                } ${text?.projects?.create?.uploader?.here}`}
                                        </p>
                                        <p>{text?.projects?.create?.uploader?.or}</p>
                                    </ConditionalRender>

                                    <ConditionalRender renderIf={customMessage}>
                                        <p>{customMessage}</p>
                                    </ConditionalRender>

                                    <ConditionalRender renderIf={icon}>
                                        <div className="flex justify-content-center items-center icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                    </ConditionalRender>
                                </HoverPopup>
                            </div>
                        </ConditionalRender>

                        {/* If a customButton was passed, just render it instead */}
                        <ConditionalRender renderIf={customButton}>
                            {customButton}
                        </ConditionalRender>
                    </ConditionalRender>
                }

            >

                <StyledUploader className='scroll-container'>
                    {imageError && <p className="error">{imageError}</p>}
                    {fileError && <p className="error">{fileError}</p>}

                    <div className="wrapper">
                        {uploadedImages.length > 0 && (
                            uploadedImages.map((img, index) => (
                                <SelectableBox
                                    key={index}
                                    className="w-100"
                                    removeButton
                                    onClickRemove={() => handleRemoveImage(index)}
                                >
                                    <div className='flex justify-content-between items-center'>
                                        <img src={img.dataUrl} alt={img.originalName} width={100} height={100} />
                                    </div>
                                </SelectableBox>
                            ))
                        )}
                    </div>

                    <div className="wrapper mb-md">
                        {uploadedFiles.length > 0 && (
                            uploadedFiles.map((file, index) => (
                                <SelectableBox
                                    key={index}
                                    className="w-100"
                                    removeButton
                                    onClickRemove={() => handleRemoveFile(index)}
                                >
                                    {file.name}
                                </SelectableBox>
                            ))
                        )}
                    </div>
                </StyledUploader>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            backgroundColor: colors.backgroundOverlay,
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "20px",
                            width: '100vw',
                            height: '100vh',
                            background: colors.mutedBlue,
                            display: 'flex',
                            flexDirection: 'column',
                            border: 'none'
                        },
                    }}
                >
                    <CarouselWithCustomArrows
                        images={uploadedImages}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        hideSave={hideSave}
                    />
                    <CallToAction
                        text={'Close'}
                        onClick={closeModal}
                        style={{
                            marginTop: '15px',
                            alignSelf: 'start'
                        }}
                    />
                </Modal>
                <ConditionalRender renderIf={children}>
                    {children}
                </ConditionalRender>
            </Wrapper>
        </StyledDiv >
    );
};

export default ImageUploader;
