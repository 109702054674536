import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import ConditionalRender from '../Core/ConditionalRender';
import Wrapper from '../Core/Wrapper';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../Core/HoverPopup';
import RadioButton from '../Core/Inputs/RadioButton';
import colors from '../globalStyles.scss'
import { isEmpty } from 'lodash';
import PaginatedData from '../Core/PaginatedData';
import SearchInput from '../Core/Inputs/SearchInput';
import ConfirmModal from '../Core/ConfirmModal';
import { removeClients } from '../utils/calls';
import { useToast } from '../context/ToastContext';
import CustomTable from '../Core/Tables/CustomTable';
import { clientsColumns } from '../Invetory/columns';
import useScreenSize from '../context/useScreenSize';


const ClientsContainerExpanded = ({
    user,
    fetchAllClients,
    clients,
    clientsIsLoading,
    isProject,
    showLightColors,
    title,
    btnWidth,
    setSelectedClient,
    selectedClient,
    fetchCompanyProjects,
    project
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const { isDesktop, isTablet, loading } = useScreenSize();


    useEffect(() => {
        const clientId = project?.client?._id;

        const sortedClients = clients && [...clients].sort((a, b) => {
            if (a._id === clientId) return -1;
            if (b._id === clientId) return 1;
            return 0;
        });


        if (clients?.length === 0) {
            setTableData([]);
        } else {
            setTableData(sortedClients);
        }
    }, [clients, project, clientsIsLoading]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });

    };

    const filterTableData = async () => {
        const filterOptions = {};
        const clientId = project?.client?._id;

        const sortedClients = clients && [...clients].sort((a, b) => {
            if (a._id === clientId) return -1;
            if (b._id === clientId) return 1;
            return 0;
        });

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        let filteredClients = sortedClients;

        if (filterOptions.query) {
            filteredClients = filteredClients.filter(client =>
                client?.name.toLowerCase().includes(filterOptions.query)
            );
        }
        setTableData(filteredClients);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, clients, project, clientsIsLoading]);

    const [createIsOpen, setCreateIsOpen] = useState(false)

    const handleCreateToggle = () => {
        setCreateIsOpen(!createIsOpen)
    }

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const handleDeleteSelection = async () => {
        try {
            const res = await removeClients(selectedIds);
            if (res.status === 200) {
                fetchAllClients();
                fetchCompanyProjects()
                setToggleModal(!toggleModal)
                setSelectedIds([]);
                setSelectedItems([]);
                notify(text?.notificationsUI?.clients?.deleted, 'success')
            }
        } catch (error) {
            notify(`Error:: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const handleClientSelection = (e) => {
        e.preventDefault();
        setSelectedClient(e.target?.value);

    }

    return (
        <ConditionalRender renderIf={true} isLoading={loading}>
            <Wrapper
                isLoading={clientsIsLoading}
            >
                <div className='flex flex-column'>
                    <h4 className='mb-md' style={{
                        fontSize: '1.5em',
                    }}>
                        {text?.client?.create?.clients}
                    </h4>
                    <SearchInput
                        value={filters.query}
                        onChange={handleSearchChange}
                        placeHolder={text?.tasks?.home?.filters?.search}
                    />
                </div>

                <ConditionalRender renderIf={isProject}>
                    <div className='flex flex-column align-start mt-md'>
                        <PaginatedData
                            className='mt-md'
                            data={tableData}
                            dataIsLoading={clientsIsLoading}
                            noDataText={'No Clients Found'}
                            searching={filters?.query}
                            showLightColors
                            ItemComponent={({ item }) => {
                                return (
                                    <RadioButton
                                        id={`client_${item.id}`}
                                        name={`client_${item.id}`}
                                        value={item.id}
                                        checked={selectedClient === item.id}
                                        onChange={handleClientSelection}
                                        label={`${item.name}(${item.email})`}
                                        showLightColors
                                        goto={`/clients/details?id=${item.id}`}
                                    />
                                )
                            }}
                        />
                    </div>
                </ConditionalRender>

                <ConditionalRender renderIf={!isProject}>
                    <div className='flex flex-column align-start mt-md'>
                        <CustomTable
                            keyField="_id"
                            columns={clientsColumns({ theme, text, navigate })}
                            rows={tableData}
                            onRowSelectionChange={handleRowSelectionChange}
                            theme={theme}
                            noDataText={'No Clients Found'}
                            showLightColors
                        />
                        <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                            <div style={{
                                color: colors.red,
                                fontFamily: colors.openSans,
                                cursor: 'pointer',
                                display: 'flex',
                                marginBottom: '5px'
                            }} onClick={() => setToggleModal(true)} >
                                {'Delete Selected Clients'}
                            </div>
                            <ConfirmModal
                                onClick={handleDeleteSelection}
                                text={text?.inventory?.landing?.table?.modal?.button}
                                toggle={() => setToggleModal(!toggleModal)}
                                isOpen={toggleModal}
                                btnStyles={{
                                    color: colors.red
                                }}
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '50%' : '100%'}

                            >
                                <div>
                                    <h4 style={{ marginBottom: '15px' }}>
                                        {text?.inventory?.landing?.table?.modal?.title}
                                    </h4>
                                    <ul>
                                        {selectedItems?.map((x) => {
                                            return (
                                                <li style={{ fontSize: '0.9rem' }}>
                                                    - {x?.name}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </ConfirmModal>
                        </ConditionalRender>
                    </div>
                </ConditionalRender>
            </Wrapper>
        </ConditionalRender>
    )
}

export default ClientsContainerExpanded