import React from 'react';
import { Field } from 'react-final-form';
import CreatableInput from './Inputs/CreatableInput';

const SelectInput = ({
    options,
    name,
    style,
    showLightColors = false,
    isDark = false,
    ...props
}) => {
    return (
        <Field
            name={name}
            component={CreatableInput}
            options={options}
            style={style}
            showLightColors={showLightColors}
            isDark={isDark}
            {...props}
        />
    );
};

export default SelectInput;
