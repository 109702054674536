import React, { useMemo, useState, useCallback } from 'react';
import { GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import colors from '../globalStyles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from './HoverPopup';
import styled from 'styled-components';
import { useLanguage } from '../context/LanguageContext';

const StyledDiv = styled.div`
    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.2);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    .UncontrolledTooltip {
        z-index: 100;
        display: flex;
        margin-top: 1em;
        justify-content: flex-end;
    }
    svg {
        font-size: 1em;
        animation: pulse 1.5s infinite;
    }
`

const ShowJustMap = ({ location }) => {
    const { text } = useLanguage();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [autocompleteValue, setAutocompleteValue] = useState('');
    const [infoWindowOpen, setInfoWindowOpen] = useState(true);
    const [isMapVisible, setIsMapVisible] = useState(true); // Track map visibility

    const handleSelectLocation = useCallback((place) => {
        if (place.formatted_address && place.geometry && place.geometry.location) {
            setSelectedLocation(place);
            setAutocompleteValue(place.formatted_address);
        }
    }, []);

    useMemo(() => {
        const fetchInitialLocation = (address) => {
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    handleSelectLocation(results[0]);
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                }
            });
        };

        fetchInitialLocation(location);
    }, [handleSelectLocation, location]);

    const mapCenter = useMemo(() => {
        const lat = parseFloat(selectedLocation?.geometry?.location?.lat());
        const lng = parseFloat(selectedLocation?.geometry?.location?.lng());
        return { lat, lng };
    }, [selectedLocation]);

    const onLoad = useCallback((map) => {
        if (selectedLocation) {
            map.panTo(new window.google.maps.LatLng(mapCenter?.lat, mapCenter?.lng));
        }
    }, [mapCenter, selectedLocation]);

    const handleMarkerClick = () => {
        setInfoWindowOpen(!infoWindowOpen);
    };

    return (
        <StyledDiv className='relative'>
            {/* Toggle Button */}
            <HoverPopup
                text={isMapVisible ? text?.client?.create?.hideMapLabel : text?.client?.create?.showMapLabel}
                id={'toggle-map'}
                onClick={() => setIsMapVisible((prev) => !prev)}
            >
                <FontAwesomeIcon
                    icon={isMapVisible ? faCaretUp : faCaretDown}
                />
            </HoverPopup>

            {/* Conditionally Render GoogleMap */}
            {isMapVisible && (
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '400px',
                        marginTop: '15px',
                        marginBottom: '15px',
                    }}
                    center={mapCenter}
                    onLoad={onLoad}
                    mapTypeId="hybrid"
                    zoom={18}
                    options={{
                        mapTypeId: 'hybrid', // Explicitly set the map type here
                        disableDefaultUI: false,
                        draggable: true,
                        keyboardShortcuts: false,
                        scaleControl: false,
                        scrollwheel: false,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        styles: [
                            {
                                featureType: 'all',
                                elementType: 'labels',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'landscape',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'poi',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'road',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'simplified' }],
                            },
                            {
                                featureType: 'road',
                                elementType: 'labels.text',
                                stylers: [{ visibility: 'on' }],
                            },
                            {
                                featureType: 'transit',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'water',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'administrative',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'off' }],
                            },
                            {
                                featureType: 'landscape.man_made',
                                elementType: 'geometry',
                                stylers: [{ visibility: 'on' }],
                            },
                        ],
                    }}
                >
                    <MarkerF position={mapCenter} onClick={handleMarkerClick}>
                        {infoWindowOpen && (
                            <InfoWindowF
                                position={mapCenter}
                                onCloseClick={() => setInfoWindowOpen(!infoWindowOpen)}
                            >
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        autocompleteValue
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: colors.blue }}
                                >
                                    {autocompleteValue}
                                </a>
                            </InfoWindowF>
                        )}
                    </MarkerF>
                </GoogleMap>
            )}
        </StyledDiv>
    );
};

export default ShowJustMap;
