export const createPrivateConversation = (
    userIds,
    companyId,
    currentUserId,
    clientIds,
    callback,
    ws
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    // Emit the event to create a private conversation
    ws.current.emit('createPrivateConversation', { userIds, companyId, currentUserId, clientIds }, (response) => {
        if (response.success) {
            callback && callback(response.conversation);
        } else {
            console.error('Failed to create private conversation:', response.message);
            callback && callback(null, response.message);
        }
    });
};