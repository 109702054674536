import base64ToBlob from '../utils/base64ToBlob';

export const uploadImagesAndSendMessage = async (
    ws,
    conversationId,
    messageId,
    imagePreviews,
    filesPreviews,
    callback
) => {
    const images = imagePreviews?.map((image) => ({
        buffer: base64ToBlob(image?.dataUrl),
        mimetype: image?.type,
        originalName: image?.originalName,
    }));
    const files = filesPreviews?.map((file) => {
        if (!file) return null;

        let buffer;

        if (typeof file?.dataUrl === 'string' && file?.dataUrl.startsWith('data:')) {
            buffer = base64ToBlob(file?.dataUrl);
        }
        else if (file instanceof File || file instanceof Blob) {
            buffer = file;
        }
        else {
            return null;
        }

        return {
            buffer,
            mimetype: file?.type || buffer.type, // Ensure MIME type is correct
            originalName: file?.originalName || file?.name, // Use filename if available
        };
    }).filter(Boolean); // ✅ Remove any null/invalid entries


    ws.current.emit(
        'uploadMessageImage',
        { messageId, conversationId, images, files }, // Send all images and files in one go
        (response) => {
            if (response.success) {
                callback?.(response.message);
            } else {
                console.error('Failed to finalize message:', response.message);
                callback?.(null, response.message);
            }
        }
    );
};

