import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getPremiumProducts } from './premiumProducts';
import CallToAction from '../Core/CallToAction';
import PaymentForm from './PaymentForm';
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames'

const StyledDiv = styled.div`
margin: 0 auto;
.list {
    ul {

        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        li {
            margin-bottom: 0.5em;
            word-break: break-word;
            list-style: none;
            width: ${(props) => props.isPhone ? '100%' : '32.3%'};
            margin-right: 1%;
            padding: 1em;
            background-color: ${(props) => props.theme === 'light' ? colors.darkGray : colors.backgroundSecondary};
            background-color: ${(props) => !props.isLoggedIn && colors.backgroundSecondary};
        }
    }
}
    .SubscriptionOptions {
        display: ${({ isPhone }) => !isPhone ? 'flex' : 'block'};
        justify-content: center;
        width: 100%;
        .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: space-evenly;
        margin: 15px;
        flex: 1;
        h2 {
            font-size: 2rem;
            font-family: $openSans;
            font-weight: bold;
        }
        h3 {
            font-size: 1.5rem;
            font-family: $openSans;
            font-weight: bold;
        }
        }
    }
    .PaymentForm__wrapper {
        width: 100%;
        display: flex;
        height: 100%;
      }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 2px solid ${props => props.theme === 'light' ? colors.blue : colors.secondary};
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  flex: 1;
  border-radius: 5px;
  margin-bottom: 1em;
  &:first-of-type {
    margin-right: ${({ isPhone }) => !isPhone ? '1em' : '0'};
  }
  &:hover {
    box-shadow: 0 0 10px 0 ${props => props.theme === 'light' ? colors.blue : colors.secondary};
    }
  .header {
    text-align: left;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  .body {
    text-align: left;
    .price {
        font-size: 2em;
        font-weight: bold;
        color: ${props => props.theme === 'light' ? colors.blue : colors.secondary};

    }
  }
`;


const SubscriptionOptions = ({
    isLoggedIn,
    fetchUserDetails,
    trialHasBeenUsed,
    email,
    isPremium,
    fetchCompanyDetails,
    company,
    isCompanyPremium
}) => {
    const { theme } = useTheme();
    const { text } = useLanguage();
    const { isPhone, isDesktop } = useScreenSize();


    const [selectedPlan, setSelectedPlan] = useState({});
    const [showPayment, setShowPayment] = useState(false);
    const [startTrial, setStartTrial] = useState(false)

    const navigate = useNavigate();

    const handleTrial = () => {
        if (!isLoggedIn) {
            navigate('/register');
            setShowPayment(false);
            setStartTrial(false)
        } else {
            setShowPayment(true);
            setStartTrial(true)
            // TODO: DECIDE IF THE TRIAL WILL SET PREMIUM BY MONTH OR YEAR
            setSelectedPlan({
                type: 'month',
                label: text?.payment?.montly,
                price: 35,
                productName: 'MonthlyPremium',
                value: '$35 USD',
            });
        }
    }

    const handlePlanChange = ({ type, price, productName, value }) => {
        if (isLoggedIn) {
            setSelectedPlan({ type, price, productName, value });
            setShowPayment(true);
            setStartTrial(false)
        } else {
            navigate('/register')
        }
    };

    return (
        <StyledDiv
            isLoggedIn={isLoggedIn}
            isPhone={isPhone}
            className='scroll-container'
            theme={theme}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                padding: '1em'
            }}
        >
            <div
                className={classnames({
                    'h-100': true,
                    'w-100': !isCompanyPremium && isLoggedIn,
                    'm-center w-80': !isCompanyPremium && isDesktop && !isLoggedIn
                })}
            >
                {
                    showPayment ? (
                        <PaymentForm
                            selectedPlan={selectedPlan}
                            setShowPayment={setShowPayment}
                            fetchUserDetails={fetchUserDetails}
                            startTrial={startTrial}
                            email={email}
                            isPremium={isPremium}
                            fetchCompanyDetails={fetchCompanyDetails}
                            company={company}
                        />
                    ) : (
                        <>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>

                                <div className='flex flex-column'>
                                    <div className={'list'}>
                                        <h1 className='mb-md tx-left' style={{
                                            fontSize: '1.5em',
                                        }}>
                                            {text?.payment?.title}
                                        </h1>
                                        <ul>
                                            <li>
                                                {text?.payment?.list?.one}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.two}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.three}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.five}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.six}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.seven}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.eight}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.four}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.nine}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.ten}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.eleven}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.twelve}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.thirteen}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.fourteen}
                                            </li>
                                            <li>
                                                {text?.payment?.list?.fifteen}
                                            </li>
                                        </ul>
                                    </div>

                                    <p className='flex mb-md mt-md'>
                                        {text?.payment?.moneyBack}
                                    </p>
                                    <div className='SubscriptionOptions'>
                                        {
                                            getPremiumProducts(text)?.map((x) => {
                                                return (
                                                    <Card isPhone={isPhone} theme={theme} onClick={() => handlePlanChange(x)}>
                                                        <h3 className='header'>
                                                            {x.label}
                                                        </h3>
                                                        <div className='body'>
                                                            <p className='price'>
                                                                {x.value}
                                                            </p>
                                                            <hr />
                                                            <small>
                                                                {text?.payment?.tag}
                                                            </small>
                                                        </div>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </div>
                                    <div
                                        className='mb-md'
                                    >
                                        {
                                            !trialHasBeenUsed && (
                                                <CallToAction
                                                    text={text?.payment?.freeButton}
                                                    onClick={handleTrial}

                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </StyledDiv>
    );
};

export default SubscriptionOptions;
