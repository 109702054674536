import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({
    url,
    shouldPlay = true,
    muted = true,
    controls = true,
    loop = true
}) => {
    return (
        <ReactPlayer
            url={url}
            controls={controls}
            width="100%"
            height="auto"
            playing={shouldPlay} // Only play when shouldPlay is true
            muted={muted}
            loop={loop}
            preload="auto"
            playsinline
        />
    );
};

export default VideoPlayer;
