import React, { useState, useEffect } from 'react'
import SearchInput from '../Core/Inputs/SearchInput';
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import ConfirmModal from './ConfirmModal';
import PdfViewer from './PdfViewer';
import useScreenSize from '../context/useScreenSize';
import SelectableBox from './SelectableBox';
import ConditionalRender from './ConditionalRender';
import { isEmpty } from 'lodash';

const StyledDiv = styled.div`
    .uploaded-files {
        .file-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1rem 0;
            background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
            padding: 1rem;
                .file-name {
                    margin-right: 1rem;
                }
                .remove {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: ${colors.red};
                    svg {
                        color: ${colors.white};
                        font-size: 1rem;
                    }
                }

        }
    }
`

const RenderFiles = ({
    files,
    removeFile,
    projectIsComplete,
    handleDownloadFile,
    readOnly,
    noDataText,
    isDark = false,
    isChat = false,
    noOverlay = false
}) => {
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;
    const { text } = useLanguage();

    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const { isDesktop } = useScreenSize();

    useEffect(() => {
        if (files === 0) {
            setTableData([]);
        } else {
            setTableData(files);
        }
    }, [files]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });
    };

    const filterTableData = () => {
        let filteredData = files;
        if (filters.query) {
            filteredData = filteredData.filter((file) => {
                return file.originalName.toLowerCase().includes(filters.query.toLowerCase());
            });
        }
        setTableData(filteredData);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, files]);

    const openPdfViewer = (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedFile(file);
    };

    const closePdfViewer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedFile(null);
    };

    const FileActionButton = ({ file }) => {
        const extension = file.originalName.split('.').pop();

        if (extension === 'txt') {
            return (
                <button onClick={(e) => handleDownloadFile(e, file)} className="file-name-button tx-left">
                    <p>{file.originalName}</p>
                </button>
            );
        } else if (extension === 'pdf') {
            return (
                <button onClick={(e) => openPdfViewer(e, file)} className="file-name-button tx-left">
                    <p>{file.originalName}</p>
                </button>
            );
        }
        return null;
    };

    return (
        <StyledDiv theme={theme}>
            <ConditionalRender renderIf={!isChat}>
                <div className='flex flex-column'>
                    <SearchInput
                        isDark={isDark}
                        className='w-100 mb-md'
                        value={filters.query}
                        onChange={handleSearchChange}
                        placeHolder={text?.tasks?.home?.filters?.search}
                    />
                </div>
            </ConditionalRender>
            <ConditionalRender renderIf={true}>
                {tableData?.map((file, index) => (
                    <SelectableBox
                        key={index}
                        className='w-100 flex'
                        removeButton={!readOnly}
                        isDark={isDark}
                        onClickRemove={() => {
                            removeFile && removeFile(index);
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (file.originalName.split('.').pop() === 'pdf') {
                                openPdfViewer(e, file);
                            } else {
                                handleDownloadFile(e, file);
                            }
                        }}
                    >
                        <p>
                            {file.originalName}
                        </p>
                    </SelectableBox>
                ))}
            </ConditionalRender>
            {
                selectedFile && (
                    <ConfirmModal
                        isOpen={!!selectedFile}
                        toggle={closePdfViewer}
                        width={isDesktop ? '80%' : '100%'}
                        height={isDesktop ? '80%' : '100%'}
                        noOverlay={noOverlay}
                    >
                        <PdfViewer pdfFile={selectedFile.url} />
                    </ConfirmModal>
                )
            }
        </StyledDiv >
    );
}

export default RenderFiles