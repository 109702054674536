import React, { useState } from 'react';
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ConditionalRender from './ConditionalRender';
import CallToAction from './CallToAction';
import { useTheme } from '../context/ThemeContext'
import colors from '../globalStyles.scss'

const StyledFormGroup = styled.div`
    .form-group {
        .toggle-password-visibility {
            position: absolute;
            right: -15px;
            top: 0;
            cursor: pointer;
        }

        input {
            border: none;
            border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid black'};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
            border-bottom-color: ${props => props.showLightColors && colors.black};
            background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightGray) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
            background: ${props => props.showLightColors && colors.white};
            padding: 0.2rem;
            border-radius: 0;
            color: ${props => props.theme === 'dark' ? colors.white : colors.black};
    }
    }
    input:focus,
    textarea:focus {
      outline: 0;
      border-bottom: 1px solid ${colors.blue};
      border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
      border-bottom-color: ${props => props.showLightColors && colors.black};
    }
    label {
        display: flex;
        align-items: center;
    }
`


const FieldWithNoValidation = ({
    label,
    name,
    type,
    width,
    showPassword,
    togglePasswordVisibility,
    style,
    block,
    wrapperStyles,
    info,
    onClick,
    btnText,
    showLightColors,
    ...rest
}) => {
    const [value, setValue] = useState('')
    const { theme } = useTheme();


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <StyledFormGroup
            theme={theme}
            value={value}
            className='FieldWithValidation'
            showLightColors={showLightColors}
            style={{
                width: width ? `${width}%` : '100%',
                display: !block ? `flex` : 'block',
                ...style
            }}>
            <div className="form-group wide" style={wrapperStyles}>
                <span className='input-wrapper'>
                    <div className='flex items-center justify-start'>
                        <label className='b mb-sm' htmlFor={type}>
                            {label}
                            <ConditionalRender renderIf={info}>
                                <div className="info">{info}</div>
                            </ConditionalRender>
                        </label>
                    </div>
                    <div>
                        <input
                            name={name}
                            type={type}
                            id={type}
                            onChange={handleChange}
                            {...rest}
                        />
                        <ConditionalRender renderIf={onClick}>
                            <CallToAction
                                className='mt-md'
                                text={btnText}
                                onClick={onClick}
                            />
                        </ConditionalRender>
                    </div>

                    {
                        name === 'password' && (
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="toggle-password-visibility"
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        )
                    }
                </span>
            </div>
        </StyledFormGroup>
    )
}

export default FieldWithNoValidation;
