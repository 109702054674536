import { useState, useEffect } from 'react';

const getScreenSize = () => {
    const width = window.innerWidth;
    return {
        isPhone: width <= 480,
        isTablet: width > 480 && width <= 1024,
        isDesktop: width > 1024,
    };
};

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize); // ✅ Initialize immediately
    const [loading, setLoading] = useState(true); // ✅ Keep loading until useEffect runs

    useEffect(() => {
        const updateScreenSize = () => {
            setScreenSize(getScreenSize());
            setLoading(false); // ✅ Set loading to false only after first update
        };

        updateScreenSize(); // ✅ Run once when the effect mounts
        window.addEventListener('resize', updateScreenSize);

        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    return { ...screenSize, loading };
};

export default useScreenSize;
