
import moment from 'moment-timezone';
import { renderLabel } from '../Dashboard/Charts/helperFunctions';
import { capitalize, map } from "lodash";
import colors from '../globalStyles.scss'

export const toCamelCase = (str) => {
    // Split the string into words
    let words = str && str?.split(' ');
    if (!words) return false

    // Convert the first word to lowercase
    words[0] = words[0]?.toLowerCase();

    // Convert the rest of the words to Title Case (first letter uppercase)
    for (let i = 1; i < words?.length; i++) {
        words[i] = words[i]?.charAt(0).toUpperCase() + words[i]?.slice(1).toLowerCase();
    }

    // Join the words back together
    let camelCaseStr = words?.join('');

    return camelCaseStr;
}

export const compareStrings = (str1, str2) => {
    if (typeof str1 === 'string' && typeof str2 === 'string') {
        const cleanStr1 = str1.toLowerCase().replace(/\s/g, "");
        const cleanStr2 = str2.toLowerCase().replace(/\s/g, "");
        return cleanStr1 === cleanStr2;
    }
    return false;
};

export const formatDateTime = (time, startField) => {
    if (startField) {
        return new Date(moment.utc(time).format('MM-DD-YYYY hh:mm:ss A'))
    } else {
        return new Date(moment.utc(time).add(1, 'hours').format('MM-DD-YYYY hh:mm:ss A'))
    }
}

export const transformArray = (inputArr, inventoryColumns) => {
    return inputArr.flatMap(item =>
        Object.entries(item.advanceFields)
            .filter(([key]) => key.startsWith('date-'))
            .map(([key, dateValue]) => {
                // filter out date- keys from advanceFields
                const filteredAdvanceFields = Object.fromEntries(
                    Object.entries(item.advanceFields).filter(
                        ([innerKey]) => !innerKey.startsWith('date-')
                    )
                );

                return {
                    title: `${renderLabel(inventoryColumns, key)} - ${item.inventoryName}`,
                    productId: item.productId,
                    start: formatDateTime(dateValue, true),
                    end: formatDateTime(dateValue),
                    product: {
                        productId: item.productId,
                        inventoryName: item.inventoryName || '',
                        inventoryCategory: item.inventoryCategory || '',
                        inventoryQuantity: item.inventoryQuantity.value || '0',
                        images: item.images || [],
                        ...filteredAdvanceFields
                    }
                };
            })
    );
}

export const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
]

export const lengthUnits = (text) => {
    return [
        { value: 'in', label: text?.inventory?.length?.inches },
        { value: 'ft', label: text?.inventory?.length?.feet },
        { value: 'm', label: text?.inventory?.length?.meters },
        { value: 'cm', label: text?.inventory?.length?.centimeters },
        { value: 'mm', label: text?.inventory?.length?.millimeters },
        { value: 'yd', label: text?.inventory?.length?.yards },
    ]
}

export const weightUnits = (text) => {
    return [
        { value: 'lb', label: text?.inventory?.weigth?.pounds },
        { value: 'kg', label: text?.inventory?.weigth?.kilograms },
        { value: 'g', label: text?.inventory?.weigth?.grams },
        { value: 'mg', label: text?.inventory?.weigth?.milligrams },
        { value: 'oz', label: text?.inventory?.weigth?.ounces },
    ]
};

export const volumeUnits = (text) => {
    return [
        { value: 'l', label: text?.inventory?.volume?.liters },
        { value: 'ml', label: text?.inventory?.volume?.milliliters },
        { value: 'gal', label: text?.inventory?.volume?.gallons },
        { value: 'qt', label: text?.inventory?.volume?.quarts },
        { value: 'b', label: text?.inventory?.volume?.barrels },
        { value: 'm3', label: text?.inventory?.volume?.cubicMeters },
    ]
};

export const proposalStatus = (text) => {
    return [
        { value: 'Pending', label: text?.proposals?.status?.pending },
        { value: 'Approved', label: text?.proposals?.status?.approved },
        { value: 'Rejected', label: text?.proposals?.status?.rejected }
    ]
}

export const status = (text) => {
    return [
        { value: 'pending', label: text?.tasks?.status?.pending },
        { value: 'in-progress', label: text?.tasks?.status?.inProgress, },
        { value: 'paused', label: text?.tasks?.status?.paused, },
        { value: 'completed', label: text?.tasks?.status?.completed },
    ]
}
export const projectStatus = (text) => {
    return [
        { value: 'pending', label: text?.tasks?.status?.pending },
        { value: 'in-progress', label: text?.tasks?.status?.inProgress },
        { value: 'paused', label: text?.tasks?.status?.paused },
        { value: 'completed', label: text?.tasks?.status?.completed },
    ]
}

export const priority = (text) => {
    return [
        { value: 'low', label: text?.tasks?.priority?.low },
        { value: 'medium', label: text?.tasks?.priority?.medium },
        { value: 'high', label: text?.tasks?.priority?.high },
        { value: 'critical', label: text?.tasks?.priority?.critical }

    ]
}

export const resourcesCategories = (text) => {
    return [
        { value: 'Article', label: text?.resources?.articles },
        { value: 'Announcement', label: text?.resources?.announcement },
        { value: 'Tutorial', label: text?.resources?.tutorial },
        { value: 'NewsLetter', label: text?.resources?.newsLetter },
        { value: 'Job', label: text?.resources?.job },
        { value: 'Event', label: text?.resources?.event },
    ]
}

export const titleCase = (str) => map(str.split(" "), capitalize).join(" ");


export const renderLengthLabel = (value, text) => {
    const foundUnit = lengthUnits(text).find(unit => unit.value === value);
    return foundUnit ? foundUnit.label : null;
}

export const renderWeightLabel = (value, text) => {
    const foundUnit = weightUnits(text).find(unit => unit.value === value);
    return foundUnit ? foundUnit.label : null;
}

export const renderVolumeLabel = (value, text) => {
    const foundUnit = volumeUnits(text).find(unit => unit.value === value);
    return foundUnit ? foundUnit.label : null;
}

export const getStartAndEndOfDay = () => {
    const now = new Date();

    const localStartOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const localEndOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const startOfToday = new Date(localStartOfDay.getTime() - (localStartOfDay.getTimezoneOffset() * 60000)).toISOString();
    const endOfToday = new Date(localEndOfDay.getTime() - (localEndOfDay.getTimezoneOffset() * 60000)).toISOString();

    return { startOfToday, endOfToday };
}

export const getTitleFromMilliseconds = (text, milliseconds) => {
    const totalMinutes = Math.floor(milliseconds / 60000);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const remainingMinutes = totalMinutes % 60;

    let daysText = days === 1 ? text?.analytics?.day : text?.analytics?.days;
    let hoursText = hours === 1 ? text?.analytics?.hour : text?.analytics?.hours;
    let minutesText = remainingMinutes === 1 ? text?.analytics?.minute : text?.analytics?.minutes;

    let result = [];

    if (days > 0) {
        result.push(`${days} ${daysText}`);
    }
    if (hours > 0) {
        result.push(`${hours} ${hoursText}`);
    }
    if (remainingMinutes > 0 || result.length === 0) { // Ensure that at least minutes are displayed if all are zero
        result.push(`${remainingMinutes} ${minutesText}`);
    }

    return result.join(` ${text?.analytics?.and} `);
}

export const getDeadlineStatus = (text, date) => {
    const deadlineDate = moment(date);
    const currentDate = moment();
    const diffInDays = deadlineDate.diff(currentDate, 'days');
    let color;
    let title;

    if (diffInDays >= 5) {
        color = 'green';
        title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
    } else if (diffInDays >= 3) {
        color = 'yellow';
        title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
    } else if (diffInDays >= 0) {
        color = 'red';
        title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
    } else {
        color = 'red';
        title = text?.tasks?.details?.pastDue;
    }

    return { color, title };
};

export const findFieldByName = (name, fieldNames, text) => {
    if (name === 'inventoryQuantity') return { label: text?.inventory?.create?.form?.quantity };
    return fieldNames.find(attribute => attribute.name?.toLowerCase() === name?.toLowerCase());
}


export const formatDateToLocal = (dateString, timezone = 'UTC') => {
    // Use moment-timezone to parse the date and convert to the specified timezone
    return moment.tz(dateString, timezone).format('YYYY-MM-DD hh:mm:ss A');
};

export const getQueryParams = (url) => {
    const queryParams = new URLSearchParams(new URL(url).search);
    const params = {};

    // Iterate through all the query parameters and add them to the params object
    for (const [key, value] of queryParams.entries()) {
        params[key] = value;
    }

    return params;
};

export const removeQueryParam = (url, paramToRemove) => {
    const parsedUrl = new URL(url);
    const queryParams = new URLSearchParams(parsedUrl.search);

    // Delete the specified parameter
    queryParams.delete(paramToRemove);

    // Update the URL's search string
    parsedUrl.search = queryParams.toString();

    return parsedUrl.toString();
};

export const formatToCurrency = (amount) => {
    if (typeof amount !== "number") {
        throw new Error("Input must be a number.");
    }

    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export const formatMillisecondsToHours = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const parts = [];

    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds}s`);

    return parts.join(' ');
};



export const formatMessageContent = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Regex for detecting URLs
    const urlRegex = /\b((?:https?|ftp|file|mailto|tel|sms):\/\/[^\s<]+|www\.[^\s<]+|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?![^<]*>)/gi;

    // Regex for detecting task UUIDs (e.g., TSK-11267626)
    const taskUuidRegex = /\b(TSK-\d+)\b/g;

    // Function to find and replace URLs and task UUIDs in text nodes
    const processNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            let text = node.nodeValue;
            let matches = [...text.matchAll(new RegExp(`${urlRegex.source}|${taskUuidRegex.source}`, 'gi'))];

            if (matches.length > 0) {
                const fragment = document.createDocumentFragment();
                let lastIndex = 0;

                matches.forEach((match) => {
                    const matchedText = match[0];
                    const matchIndex = match.index;

                    // Append text before match
                    if (lastIndex < matchIndex) {
                        fragment.appendChild(document.createTextNode(text.slice(lastIndex, matchIndex)));
                    }

                    let link = document.createElement("a");
                    link.textContent = matchedText;

                    if (matchedText.startsWith("TSK-")) {
                        // Handle task UUID
                        link.href = `${process.env.REACT_APP_URL}/tasks/details?uuid=${matchedText}`;
                        link.style.color = colors.white;
                        link.style.textDecoration = "none";

                        // Check if already wrapped in .taskmention
                        let parent = node.parentElement;
                        if (!parent || !parent.classList.contains("taskmention")) {
                            // Wrap ONLY the <a> tag inside a <span class="taskmention"> if not already wrapped
                            const wrapper = document.createElement("span");
                            wrapper.classList.add("taskmention");
                            wrapper.appendChild(link);
                            fragment.appendChild(wrapper);
                        } else {
                            fragment.appendChild(link); // Already wrapped, no need to add span
                        }
                    } else {
                        // Handle URLs
                        let formattedUrl = matchedText.startsWith("www.") ? "https://" + matchedText : matchedText;
                        link.href = formattedUrl;
                        link.target = "_blank";
                        link.rel = "noopener noreferrer";

                        fragment.appendChild(link);
                    }

                    lastIndex = matchIndex + matchedText.length;
                });

                // Append remaining text after last match
                if (lastIndex < text.length) {
                    fragment.appendChild(document.createTextNode(text.slice(lastIndex)));
                }

                node.replaceWith(fragment);
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            Array.from(node.childNodes).forEach(processNode);
        }
    };

    // Process all child nodes of the body
    Array.from(doc.body.childNodes).forEach(processNode);

    return doc.body.innerHTML;
};



