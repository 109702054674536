export const updateMessage = async (
    messageId,
    updatedContent,
    senderId,
    callback,
    ws,
    setMessages,
    selectedConversationId
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    try {
        // Prepare the data to send
        const updates = {
            messageContent: updatedContent,
        };

        // Emit the updateMessage event
        ws.current.emit('updateMessage', { messageId, conversationId: selectedConversationId, updates }, (response) => {
            if (response.success) {
                const updatedMessage = response.message;

                // Update the local messages state with the updated message
                setMessages((prevMessages) => {
                    return prevMessages.map((msg) =>
                        msg._id === messageId ? { ...msg, ...updatedMessage } : msg
                    );
                });

                // Notify all recipients about the updated message
                ws.current.emit('notifyMessageUpdated', {
                    conversationId: selectedConversationId,
                    senderId,
                    updatedMessage,
                });

                // Notify success via callback
                callback?.(updatedMessage, null);
            } else {
                console.error('Failed to update message:', response.message);
                callback?.(null, response.message || 'Failed to update message');
            }
        });
    } catch (error) {
        console.error('Error updating message:', error);
        callback?.(null, 'Failed to update message due to an error.');
    }
};