import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import ConditionalRender from './ConditionalRender';
import styled from 'styled-components';
import colors from '../globalStyles.scss';
import { useTheme } from '../context/ThemeContext';
import ControlledTooltip from './ControlledTooltip';
import { isEmpty } from 'lodash';

const CustomTooltip = styled.div`
  .tooltip-inner {
    background-color: ${colors.red};
    color: #fff;
    border-radius: 6px;
  }
  .tooltip-arrow {
    border-top-color: #555 !important;
  }
  .hover-popup {
    border-radius: 0;
    border: none;
    min-width: 30px;
    width: ${(props) => props?.style?.width || 'auto'};
    display: ${(props) => props?.style?.display || 'inline-block'};
    padding: ${(props) => props?.style?.padding || '5px 10px'};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')} !important;
    background: ${(props) => {
        if (props.noBG) return 'transparent';
        if (props?.style?.background) {
            return props?.style?.background;
        } else if (props.theme === 'dark') {
            return colors.backgroundSecondary; // Adjust to your dark theme color
        } else {
            return colors.blue;
        }
    }};
    svg {
      color: ${(props) => props?.style?.color || colors.white};
    }
    ${(props) => props?.wrapperStyle}
  }
  .secondary-button button {
    background: ${colors.darkGray};
    color: ${colors.black};
  }
  .primary-button button {
    background: ${colors.darkGray};
    color: ${colors.blue};
  }
  ${(props) => props.style}
`;

const HoverPopup = ({
    children,
    id,
    text,
    placement = 'top',
    disabled,
    onClick,
    style,
    btnClassName = '',
    wrapperStyle,
    className,
    isDark,
    noBG = false,
    tooltipProps = {},
    controlledTooltipProps = {},
    ...rest
}) => {
    const { theme } = useTheme();

    const CustomTooltipContent = (
        <CustomTooltip
            theme={isDark ? 'dark' : theme}
            className={`UncontrolledTooltip ${className}`}
            style={style}
            wrapperStyle={wrapperStyle}
            disabled={disabled}
            noBG={noBG}
            {...rest}
        >
            <span
                className={`hover-popup ${btnClassName}`}
                id={id}
                onClick={onClick && onClick}
            >
                {children}
            </span>
            <ConditionalRender renderIf={text}>
                <UncontrolledTooltip
                    {...tooltipProps}
                    style={{
                        width: '100%',
                    }}
                    placement={placement}
                    target={id}
                >
                    {text}
                </UncontrolledTooltip>
            </ConditionalRender>
        </CustomTooltip>
    );

    return !isEmpty(controlledTooltipProps) ? (
        <ControlledTooltip {...controlledTooltipProps}>
            {CustomTooltipContent}
        </ControlledTooltip>
    ) : (
        CustomTooltipContent
    );
};

export default HoverPopup;
