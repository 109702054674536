export const updateReaction = (
    messageId,
    reactionType,
    userId,
    ws,
    setMessages
) => {
    if (!messageId || !reactionType || !userId) {
        console.error('Invalid parameters for updating reaction');
        return;
    }

    ws.current.emit(
        'updateReaction',
        {
            messageId,
            reactionType,
            userId,
        },
        (response) => {
            if (response.success) {
                const { reactions, parentId } = response;

                setMessages((prevMessages) =>
                    prevMessages.map((msg) => {
                        if (msg._id === messageId) {
                            // Update reactions for a main message
                            return { ...msg, reactions };
                        }

                        if (msg._id === parentId) {
                            return {
                                ...msg,
                                replies: msg.replies?.map((reply) =>
                                    reply._id === messageId
                                        ? { ...reply, reactions }
                                        : reply
                                ),
                            };
                        }

                        return msg; // Return other messages as-is
                    })
                );
            } else {
                console.error('Error updating reaction:', response.message);
            }
        }
    );
};