import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Fab } from '@mui/material';
import TasksContainer from './TasksContainer';
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { isRequired, composeValidators } from '../utils/validators';
import styled from 'styled-components'
import { Form, FormSpy } from 'react-final-form';
import { isEmpty } from 'lodash';
import ConfirmModal from '../Core/ConfirmModal';
import SubmitModal from '../Core/SubmitModal';
import FormActions from '../Core/FormActions';
import MultiFields from '../Core/MultiFields';
import NotFound from '../Home/NotFound';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWithNoValidation from '../Core/FieldWithNoValidation';
import HoverPopup from '../Core/HoverPopup';
import CheckBox from '../Core/Inputs/CheckBox';
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader';
import SelectInput from '../Core/SelectInput';
import FieldWrapper from '../Core/FieldWrapper';
import { status, priority, formatMillisecondsToHours } from '../utils/helperFunctions';
import { useLanguage } from '../context/LanguageContext'
import { useTheme } from '../context/ThemeContext'
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import TimeEditFields from '../Core/Inputs/TimeEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faHistory, faAdd, faPlus } from '@fortawesome/free-solid-svg-icons';
import MessageWrapper from './MessageWrapper';

import moment from 'moment';
import {
    getTask,
    addCommentToTask,
    createCommentImages,
    createCommentFiles,
    updateTask,
    deleteComment,
    deleteTask,
    createTask,
    updateComment,
    updateHistory,
    updateTaskHistory,
    updateCommentImages,
    updateCommentFiles
} from '../utils/calls'
import base64ToBlob from '../utils/base64ToBlob';
import { btnUnderline } from '../mixins';
import colors from '../globalStyles.scss'
import classNames from 'classnames';

const StyledDiv = styled.div`

.delete {
    padding: 15px 0 5px;
    position: relative;
    display: flex;
    cursor: pointer;
    color: ${colors.red};
    font-family: ${colors.openSans};
    ${btnUnderline(colors.red)};
}
.clone {
    padding: 15px 0 5px;
    position: relative;
    display: flex;
    cursor: pointer;
    font-family: ${colors.openSans};
}
    .image-uploader {
        .body {
            margin: 0;
        }
    }
`
const StyledTodos = styled.div`
    .wrapper {
        padding: 0.5em;
        background-color: ${props => props.theme === 'dark' ? colors.backgroundSecondary : colors.lighterGray};
    }
`

const TaskDetailsContainer = ({
    user,
    projects,
    employees,
    fetchProjectTasks,
    tasksIsLoading
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isDesktop } = useScreenSize()
    const { text, formatDate } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast()
    const inputRefs = useRef([]);


    const [task, setTask] = useState({});
    const [taskIsLoading, setTaskIsLoading] = useState(false)
    const [disabledTaskEdit, setDisabledTaskEdit] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [totalTimeEdit, setTotalTimeEdit] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(false);
    const queryId = queryString.parse(location.search).id;
    const queryUUID = queryString.parse(location.search).uuid;
    const [id, setId] = useState(queryId)
    const [uuid, setUuid] = useState(queryUUID)

    const [todos, setTodos] = useState([]);
    const [isArchived, setIsArchived] = useState(false);

    useEffect(() => {
        setId(queryString.parse(location.search).id)

        // eslint-disable-next-line
    }, [queryId])
    useEffect(() => {
        setUuid(queryString.parse(location.search).uuid)

        // eslint-disable-next-line
    }, [queryUUID])

    useEffect(() => {
        setIsArchived(task?.isArchived)
    }, [task.isArchived])

    const handleArchivedChange = () => {
        setIsArchived(!isArchived)
        setIsEditing(true)
    }

    const [taskIsComplete, setTaskIsComplete] = useState(false);
    const [taskIsDisabled, setTaskIsDisabled] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (task?.taskStatus === 'completed') {
            setTaskIsComplete(true)
        } else {
            setTaskIsComplete(false)
        }

        if (task?.taskProject?.projectStatus === 'completed' ||
            task?.taskProject?.projectStatus === 'pending' ||
            task?.taskProject?.projectStatus === 'paused' ||
            (task?.taskAssignedTo?._id !== user?.id && !isAdmin)
        ) {
            setTaskIsDisabled(true)
        } else {
            setTaskIsDisabled(false)
        }

        // eslint-disable-next-line
    }, [task.taskStatus])

    const required = composeValidators(
        isRequired(text?.tasks?.details?.validations?.required),
    )

    const [subtasks, setSubtasks] = useState([]);

    const handleGetTask = async () => {
        setTaskIsLoading(true)
        console.log(uuid)
        try {
            const res = await getTask(id, uuid)
            if (res.status === 200) {
                setTaskIsLoading(false)
                setTask(res.data)
                setSubtasks(res.data?.subtasks?.filter(subtask => !subtask.isArchived))
                setTodos(res.data?.taskTodos)
                const isAdmin = res.data?.taskProject?.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
                setIsAdmin(isAdmin)
                setDisabledTaskEdit(res.data?.isAdmin)
                if (!id) {
                    navigate(`/tasks/details?id=${res.data?._id}&uuid=${res.data.uuid}`)
                }
            }
        } catch (error) {
            setTaskIsLoading(false)
            console.error(error)
        }
    }

    const [toggleDeleteTaskModal, setToggleDeleteTaskModal] = useState(false)

    const handleDeleteTask = async () => {
        try {
            const res = await deleteTask(id)
            const projectId = res?.data?.projectId;

            if (res.status === 200) {
                fetchProjectTasks()
                if (projectId) {
                    navigate(`/project/details?id=${projectId}`)
                } else {
                    navigate(`/`)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetTask()

        // eslint-disable-next-line
    }, [id, uuid, tasksIsLoading])


    const getDeadlineStatus = (date) => {
        const deadlineDate = moment(date);
        const currentDate = moment();
        const diffInDays = deadlineDate.diff(currentDate, 'days');
        let color;
        let title;

        if (diffInDays >= 5) {
            color = 'green';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 3) {
            color = 'yellow';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else if (diffInDays >= 0) {
            color = 'red';
            title = `${text?.tasks?.details?.dueIn} ${diffInDays} ${text?.tasks?.details?.days}`;
        } else {
            color = 'red';
            title = text?.tasks?.details?.pastDue;
        }

        return { color, title };
    };

    const processImages = async (data) => {
        const { images, message, projectId } = data;
        const formData = new FormData();
        images?.forEach((image) => {
            if (image.new) {
                const imageBlob = base64ToBlob(image?.url);
                formData.append('newImages', imageBlob, image?.originalName);
            } else {
                formData.append('oldImages', JSON.stringify(image));
            }
        });

        formData.append('commentId', message?._id);
        formData.append('taskId', id);
        formData.append('projectId', projectId);
        formData.append('taskUUID', uuid);

        // Send to the API
        const res = await updateCommentImages(formData)
        if (res.status === 200) {
            console.log("Images updated");
        } else {
            throw new Error("Images update failed");
        }

        return res;
    };

    const processFiles = async (data) => {
        const formData = new FormData();

        const projectId = task?.taskProject?._id;
        const commentId = data?.message?._id;

        data?.files?.forEach((file) => {
            if (file.new) {
                // 1) Convert the base64 dataUrl into a real Blob
                const fileBlob = base64ToBlob(file.dataUrl);

                // 2) Append that Blob to FormData as a file
                //    The 3rd argument sets the “filename” for Multer
                formData.append('newFiles', fileBlob, file.originalName);
            } else {
                // 3) For old files, pass them as JSON strings
                formData.append('oldFiles', JSON.stringify(file));
            }
        });

        // 4) Include your other fields
        formData.append('projectId', projectId);
        formData.append('taskId', id);
        formData.append('commentId', commentId);

        try {
            // Make your Axios/fetch call
            const res = await updateCommentFiles(formData);
            if (res.status === 200) {
                console.log('Files updated');
            }
        } catch (error) {
            console.error('Files update failed:', error);
            throw new Error('Files update failed');
        }
    }

    const saveTotalTime = async (values) => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        let body = {};
        setTaskIsLoading(true);

        // Iterate over the keys in the values object
        for (const key in values) {
            if (values[key] && typeof values[key] === 'object' && 'value' in values[key]) {
                body[key] = values[key].value;
            } else {
                body[key] = values[key];
            }
        }

        // Transform the values into the desired format
        const logs = Object.keys(values).reduce((acc, key) => {
            const [type, id] = key.split('_');
            if (!acc[id]) {
                acc[id] = { days: 0, hours: 0, minutes: 0 };
            }
            if (type === 'days' && values[key]) {
                acc[id].days += parseInt(values[key], 10);
            } else if (type === 'hours' && values[key]) {
                acc[id].hours += parseInt(values[key], 10);
            } else if (type === 'minutes' && values[key]) {
                acc[id].minutes += parseInt(values[key], 10);
            }
            return acc;
        }, {});

        // Calculate totalTime in milliseconds
        let totalTime = 0;
        for (const id in logs) {
            const { days, hours, minutes } = logs[id];
            if (days > 0 || hours > 0 || minutes > 0) { // Only add if there are days, hours, or minutes provided
                totalTime += (days * 24 * 60 * 60 * 1000) + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000); // Convert to milliseconds
            }
        }

        try {
            body.localTime = nowLocal;
            body.totalTime = totalTime;
            const res = await updateTask(id, body);
            if (res.status === 200) {
                fetchProjectTasks();
                setIsEditing(false);
                handleGetTask();
                setTaskIsLoading(false);
                setTotalTimeEdit(false);
                notify(text?.notificationsUI?.task?.updated, 'success');
            }
        } catch (error) {
            setTaskIsLoading(false);
            notify(`Error: ${error.message}`, 'error');
            console.error(error);
        }
    }


    const onSubmit = async (values) => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().split('.')[0] + 'Z';

        let body = {};
        setTaskIsLoading(true);

        // Iterate over the keys in the values object
        for (const key in values) {
            if (values[key] && typeof values[key] === 'object' && 'value' in values[key]) {
                body[key] = values[key].value;
            } else {
                body[key] = values[key];
            }
        }

        try {
            body.localTime = nowLocal;
            body.taskTodos = todos.filter(todo => todo.text.trim() !== '') || [];
            body.isArchived = isArchived;
            const res = await updateTask(id, body);
            if (res.status === 200) {
                fetchProjectTasks();
                setIsEditing(false);
                handleGetTask();
                setTaskIsLoading(false);
                notify(text?.notificationsUI?.task?.updated, 'success');
                if (body.taskStatus !== task.taskStatus) {
                    await updateTaskHistory(id, `${text?.projects?.history?.statusChangedTo} ${status(text)?.find(s => s.value === body.taskStatus)?.label}`);
                    await updateHistory(task?.taskProject?._id, `${text?.projects?.history?.taskTitle} ${task?.taskTitle} ${text?.projects?.history?.statusChangedTo} ${status(text)?.find(s => s.value === body.taskStatus)?.label}`);
                } else {
                    await updateTaskHistory(id, `${text?.projects?.history?.taskUpdated}`);
                    await updateHistory(task?.taskProject?._id, `${text?.projects?.history?.taskTitle} ${task?.taskTitle} ${text?.projects?.history?.taskUpdated}`);
                }
            }
        } catch (error) {
            setTaskIsLoading(false);
            notify(`Error: ${error.message}`, 'error');
            console.error(error);
        }
    }

    const [imagePreviews, setImagePreviews] = useState([]);
    const [filesPreviews, setFilesPreviews] = useState([]);

    const handleImageChange = (imageData) => {
        setImagePreviews(imageData);
    };

    const handleFileChange = (fileData) => {
        setFilesPreviews(fileData);
    }


    const handleCommentSubmit = async (values, form) => {
        try {
            const res = await addCommentToTask({ id, comment: values?.comment, uuid })
            if (res.status === 200) {
                const commentId = res.data?.comment?._id;
                const projectId = res.data?.projectId;

                const imageUploadPromises = imagePreviews?.map(async (image) => {
                    const imageBlob = base64ToBlob(image?.dataUrl);
                    const formData = new FormData();
                    formData.append("image", imageBlob, image?.originalName);
                    formData.append("taskId", id);
                    formData.append("commentId", commentId);
                    formData.append('projectId', projectId);
                    // Send the image to a separate endpoint
                    return createCommentImages(formData)
                        .then((imageRes) => {
                            if (imageRes?.status === 200) {
                                console.log("Image uploaded");
                            } else {
                                throw new Error("Image upload failed");
                            }
                        });

                }) || []

                // Create file upload promises
                const fileUploadPromises = filesPreviews?.map(async (file) => {
                    const formData = new FormData();
                    formData.append('file', file); // Add the file to FormData
                    formData.append("taskId", id);
                    formData.append("commentId", commentId);
                    formData.append('projectId', projectId);
                    // Send the file to the createProjectFiles endpoint
                    return createCommentFiles(formData)
                        .then((fileRes) => {
                            if (fileRes?.status === 200) {
                                console.log("File uploaded");
                            } else {
                                throw new Error("File upload failed");
                            }
                        });
                }) || [];

                const allUploadPromises = [
                    ...imageUploadPromises,
                    ...fileUploadPromises
                ];

                await Promise.all(allUploadPromises)
                form.reset()
                handleGetTask()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState(null);

    const handleToggleDelete = (commentId) => {
        setSelectedCommentId(commentId);
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleDeleteComment = async () => {
        try {
            const res = await deleteComment({ id, commentId: selectedCommentId })
            if (res.status === 200) {
                handleGetTask()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [toggleCloneModal, setToggleCloneModal] = useState(false)

    const handleCloneTask = async () => {
        const {
            _id,
            companyId,
            __v,
            createdAt,
            updatedAt,
            taskCreatedBy,
            taskComments,
            taskProject,
            taskAssignedTo,
            ...clonedTask
        } = task
        clonedTask.taskTitle = `(${text?.inventory?.create?.form?.cloned}) ${task.taskTitle}`
        clonedTask.taskStatus = { value: 'pending' }
        clonedTask.taskPriority = { value: task.taskPriority }
        clonedTask.taskAssignedTo = { value: '000000000000000000000000' }
        clonedTask.taskProject = { value: task.taskProject?._id }
        try {
            const res = await createTask(clonedTask)
            if (res.status === 200) {
                // Update state and navigate to the new task's details page
                setToggleCloneModal(!toggleCloneModal);
                setImagePreviews([]);
                setFilesPreviews([]);
                navigate(`/tasks/details?id=${res?.data?._id}&uuid=${res.data.uuid}`);
                setId(res.data._id);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [toggleSubTaskModal, setToggleSubTaskModal] = useState(false);

    const handleSubTask = async () => {
        const {
            _id,
            companyId,
            __v,
            createdAt,
            updatedAt,
            taskCreatedBy,
            taskComments,
            taskProject,
            taskAssignedTo,
            ...subTask
        } = task
        subTask.taskTitle = `(${text?.tasks?.details?.subtask}) ${task.taskTitle}`
        subTask.taskStatus = { value: 'pending' }
        subTask.taskPriority = { value: task.taskPriority }
        subTask.taskAssignedTo = { value: '000000000000000000000000' }
        subTask.taskProject = { value: task.taskProject?._id }
        subTask.taskTodos = [];
        subTask.isSubtask = true;
        subTask.parentTask = task._id;
        try {
            const res = await createTask(subTask)
            if (res.status === 200) {
                // Update state and navigate to the new task's details page
                setToggleSubTaskModal(!toggleSubTaskModal);
                setImagePreviews([]);
                setFilesPreviews([]);
                navigate(`/tasks/details?id=${res?.data?._id}&uuid=${res.data.uuid}`);
                setId(res.data._id);
            }
        } catch (error) {
            console.error(error)

        }
    }

    const [initialProjectValue, setInitialProjectValue] = useState({
        label: task?.taskProject?.projectName,
        value: task?.taskProject?._id,
    });
    const [initialStatusValue, setInitialStatusValue] = useState(status(text)?.find(s => s.value === task?.taskStatus));
    const [initialPriorityValue, setInitialPriorityValue] = useState(priority(text)?.find(s => s.value === task?.taskStatus)
    );
    const assignedTo = !task?.taskAssignedTo ? {
        label: text?.tasks?.details?.unassigned,
        value: '000000000000000000000000'
    } : {
        label: `${task?.taskAssignedTo?.firstName} ${task?.taskAssignedTo?.lastName}`,
        value: task?.taskAssignedTo?._id
    }

    const [initialIAssignedToValue, setInitialAssignedToValue] = useState(assignedTo);
    useEffect(() => {
        setInitialProjectValue({
            label: task?.taskProject?.projectName,
            value: task?.taskProject?._id,
        });
        setInitialAssignedToValue(assignedTo);
        setInitialStatusValue(status(text)?.find(s => s.value === task?.taskStatus))
        setInitialPriorityValue(priority(text)?.find(s => s.value === task?.taskPriority))

        // eslint-disable-next-line
    }, [task]);

    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });


    const handleSave = (newValue, commentId) => {
        try {
            const res = updateComment({ id, commentId, commentText: newValue, uuid })
            if (res.status === 200) {
                handleGetTask()
            }
        } catch (error) {
            console.error(error)
        }
    };

    const [error, setError] = useState(null);


    const addTodo = () => {
        // Check if the last todo is not empty
        if (todos.length > 0 && todos[todos.length - 1]?.text.trim() === '') {
            setError(text?.tasks?.create?.error);
            return;
        }

        setError(''); // Reset error
        setTodos((prevTodos) => [...prevTodos, { id: uuidv4(), text: '', completed: false }]);
        setIsEditing(true);

        // Focus on the new input field after adding a new todo
        setTimeout(() => {
            const nextField = inputRefs.current[todos.length];
            if (nextField) {
                nextField.focus();
            }
        }, 0); // Delay to ensure the new input field is rendered
    };

    const updateTodo = (id, key, value) => {
        const updatedTodos = todos.map((todo) =>
            todo.id === id ? { ...todo, [key]: value } : todo
        );
        setTodos(updatedTodos);
        setIsEditing(true);

    };

    const removeTodo = (id) => {
        const updatedTodos = todos.filter((todo) => todo.id !== id);
        setTodos(updatedTodos);
        setIsEditing(true);

        // Update the inputRefs array by removing the reference of the deleted todo
        inputRefs.current = inputRefs.current.filter((_, i) => todos[i]?.id !== id);
    };

    const renderSubtasks = (subtasks) => {
        if (!subtasks || subtasks.length === 0) return null;

        return (
            <ul className='ml-md'>
                {subtasks.map((subtask) => (
                    <li key={subtask._id} style={{ fontSize: '0.9rem' }}>
                        - {subtask.taskTitle}
                        {renderSubtasks(subtask.subtasks)}
                    </li>
                ))}
            </ul>
        );
    };

    return !taskIsLoading && isEmpty(task) ? <NotFound isCompanyPremium /> :
        (
            <StyledDiv>
                <ConditionalRender
                    count={2}
                    customStyled={{
                        height: '100vh',
                        display: 'flex'
                    }}
                    renderIf={true}
                    isLoading={taskIsLoading}
                >
                    <div className='mb-lg tx-left'>
                        <h4>
                            {text?.projects?.details?.details}
                        </h4>
                        <hr />
                        <div className='flex flex-column'>
                            <FieldWrapper
                                name={''}
                                label={`${task?.uuid}`}
                            />
                        </div>
                        {task?.taskStatus === 'completed' && (
                            <div className='flex items-center'>
                                <FontAwesomeIcon
                                    style={{
                                        color: theme === 'dark' ? colors.secondary : colors.green
                                    }} icon={faCheck}
                                />
                                <p className='ml-md'>
                                    {moment(task?.dateCompleted).format('MM-DD-YYYY')}
                                </p>
                            </div>
                        )}
                    </div>

                    {/* admin */}
                    <ConditionalRender renderIf={disabledTaskEdit}>
                        <Form
                            onSubmit={(values) => onSubmit(values)}
                            render={({ handleSubmit, form }) => (
                                <form onSubmit={handleSubmit}>
                                    <p className='flex mb-md tx-left'>
                                        {text?.tasks?.details?.timeInfo}
                                    </p>
                                    <div className='flex items-center'>
                                        <FieldWrapper
                                            name={text?.logs?.totalTimeLogged}
                                            label={formatMillisecondsToHours(task?.totalTime)}
                                        />

                                    </div>
                                    <p className='flex mb-sm'>
                                        {text?.tasks?.info}
                                    </p>
                                    <CheckBox
                                        name={`isArchived`}
                                        component="input"
                                        type="checkbox"
                                        label={text?.tasks?.archive}
                                        checked={isArchived}
                                        onChange={handleArchivedChange}
                                        className='mr-md'
                                    />
                                    <SelectInput
                                        isSearchable={false}
                                        className='field-style'
                                        isValidNewOption={() => false}
                                        name='taskStatus'
                                        label={text?.tasks?.details?.status}
                                        initialValue={initialStatusValue}
                                        isClearable={false}
                                        options={status(text)}
                                        setIsEditing={setIsEditing}
                                        disabled={task?.isRunning || taskIsDisabled}
                                        callToAction={<FontAwesomeIcon
                                            onClick={() => setHistoryStatus(!historyStatus)}
                                            style={{
                                                color: theme === 'dark' ? colors.secondary : colors.blue,
                                                cursor: 'pointer'
                                            }}
                                            icon={faHistory}
                                        />}
                                    />
                                    <FormSpy subscription={{ values: true }}>
                                        {({ form }) => (
                                            <>
                                                <SelectInput
                                                    validate={required}
                                                    name={'taskProject'}
                                                    label={text?.tasks?.details?.project}
                                                    isValidNewOption={() => false}
                                                    disabled={taskIsComplete || task?.isRunning || task?.isSubtask}
                                                    isClearable={false}
                                                    setIsEditing={setIsEditing}
                                                    isSearchable
                                                    controlWidth='100%'
                                                    options={
                                                        adminProjects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                                            return {
                                                                label: x.projectName,
                                                                value: x._id
                                                            };
                                                        })}
                                                    initialValue={initialProjectValue}
                                                    style={{ marginBottom: '1em' }}
                                                    onChange={(selectedOption) => {
                                                        form.change('taskAssignedTo', null);
                                                    }}
                                                />
                                                <FormSpy subscription={{ values: true }}>
                                                    {({ values }) => {
                                                        const selectedProject = projects?.find((x) => x?._id === values?.taskProject?.value);
                                                        const assignedEmployees = employees?.filter((employee) =>
                                                            selectedProject?.assignedEmployees?.some((assignedEmployee) => assignedEmployee.id === employee.id)
                                                        );

                                                        return (
                                                            <ConditionalRender renderIf={!isEmpty(selectedProject)}>
                                                                <SelectInput
                                                                    className='field-style'
                                                                    menuPlacement='top'
                                                                    name='taskAssignedTo'
                                                                    isClearable={false}
                                                                    label={text?.tasks?.details?.assignedTo}
                                                                    disabled={taskIsComplete || task?.isRunning}
                                                                    validate={required}
                                                                    initialValue={initialIAssignedToValue}
                                                                    setIsEditing={setIsEditing}
                                                                    isSearchable
                                                                    isValidNewOption={() => false}
                                                                    controlWidth='100%'
                                                                    options={
                                                                        employees && [
                                                                            { label: text?.tasks?.details?.unassigned, value: '000000000000000000000000' },
                                                                            ...assignedEmployees?.map((employee) => ({
                                                                                label: `${employee?.firstName} ${employee?.lastName}`,
                                                                                value: employee?.id
                                                                            }))
                                                                        ]}
                                                                />
                                                            </ConditionalRender>
                                                        );
                                                    }}
                                                </FormSpy>

                                            </>
                                        )}
                                    </FormSpy>



                                    <MultiFields
                                        className='field-style'
                                        name="taskTitle"
                                        validate={required}
                                        component="input"
                                        type="text"
                                        label={text?.tasks?.details?.title}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskTitle}
                                    />
                                    <MultiFields
                                        className='field-style'
                                        name="taskDescription"
                                        validate={required}
                                        component="textarea"
                                        type="text"
                                        label={text?.tasks?.details?.description}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskDescription}
                                    />
                                    <SelectInput
                                        className='field-style'
                                        isClearable={false}
                                        disabled={taskIsComplete}
                                        name='taskPriority'
                                        label={text?.tasks?.details?.priority}
                                        validate={required}
                                        setIsEditing={setIsEditing}
                                        options={priority(text)}
                                        initialValue={initialPriorityValue}
                                    />
                                    <MultiFields
                                        className='field-style'
                                        name="taskDeadline"
                                        component="date"
                                        type="text"
                                        label={text?.tasks?.details?.deadline}
                                        block
                                        disabled={taskIsComplete}
                                        setIsEditing={setIsEditing}
                                        initialValue={task?.taskDeadline}
                                    />
                                    <div className='flex items-center mt-md mb-md'>
                                        <p
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: getDeadlineStatus(task?.taskDeadline).color,
                                                marginRight: '10px'
                                            }}
                                        />
                                        <p>
                                            {getDeadlineStatus(task?.taskDeadline).title}
                                        </p>
                                    </div>
                                    <hr />
                                    <StyledTodos theme={theme} style={{
                                        width: '100%',
                                        display: isDesktop ? 'flex' : 'block',
                                        marginBottom: '1em'
                                    }} >
                                        <div className={classNames({
                                            'wrapper': true,
                                            'mr-md': isDesktop,
                                            'mb-md': !isDesktop
                                        })} style={{
                                            width: isDesktop ? '50%' : '100%',
                                        }}>
                                            <p
                                                className='mb-md flex'
                                                style={{
                                                    fontFamily: colors.openSans
                                                }}
                                            >
                                                {text?.tasks?.create?.todos}
                                            </p>
                                            {todos.filter((x => !x.completed)).map((todo, index) => (
                                                <div key={index}
                                                    style={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        marginBottom: '10px',
                                                        padding: '10px',
                                                    }}>
                                                    <FontAwesomeIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: colors.red,
                                                            marginRight: '1em'
                                                        }}
                                                        onClick={() => removeTodo(todo.id)}
                                                        icon={faClose}
                                                    />
                                                    <ConditionalRender renderIf={todo.text.trim() !== ''}>
                                                        <CheckBox
                                                            name={`todos[${index}].completed`}
                                                            component="input"
                                                            type="checkbox"
                                                            checked={todo.completed}
                                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                                            className='mr-md'
                                                        />
                                                    </ConditionalRender>
                                                    <FieldWithNoValidation
                                                        name={`todos[${index}].text`}
                                                        component="input"
                                                        type="text"
                                                        ref={(el) => (inputRefs.current[index] = el)}
                                                        value={todo.text}
                                                        placeholder={text?.tasks?.create?.placeholder}
                                                        onChange={(e) => updateTodo(todo.id, 'text', e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                addTodo();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ))
                                            }
                                            {error && <p style={{ color: colors.red }}>{error}</p>}
                                            {
                                                (todos.length === 0 || todos.every(todo => todo.completed)) && (
                                                    <p className='mb-md'>
                                                        {text?.tasks?.create?.noTodos}
                                                    </p>
                                                )

                                            }

                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '1em',
                                                }}
                                                onClick={addTodo}
                                                icon={faAdd}
                                            />
                                        </div>
                                        {/* completed */}
                                        <div className='wrapper' style={{
                                            width: isDesktop ? '50%' : '100%',
                                        }}>
                                            <p className='mb-md flex'
                                                style={{
                                                    fontFamily: colors.openSans
                                                }}>
                                                {text?.tasks?.create?.completed}
                                            </p>
                                            <ConditionalRender renderIf={todos.filter((x => x.completed)).length === 0}>
                                                <p style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {text?.tasks?.create?.noCompleted}
                                                </p>
                                            </ConditionalRender>
                                            {
                                                todos.filter((x => x.completed)).map((todo, index) => (
                                                    <div key={index}
                                                        style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            marginBottom: '10px'
                                                        }}>
                                                        <CheckBox
                                                            className='mr-md'
                                                            name={`todos[${index}].completed`}
                                                            component="input"
                                                            type="checkbox"
                                                            checked={todo.completed}
                                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                                        />
                                                        <p style={{
                                                            textDecoration: 'line-through',
                                                        }}>
                                                            {todo.text}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </StyledTodos>
                                    {
                                        (true) && (
                                            <FormActions
                                                form={form}
                                                showSave={isEditing}
                                                showDelete={true}
                                                deleteAction={() => setToggleDeleteTaskModal(!toggleDeleteTaskModal)}
                                                deleteText={text?.employees?.details?.removeEmployee}

                                                showClone={true}
                                                cloneAction={() => setToggleCloneModal(!toggleCloneModal)}
                                                cloneText={text?.inventory?.create?.form?.clone}

                                                floating
                                                type={'submit'}
                                                submitText={text?.projects?.details?.saveButton}

                                                showCustom={true}
                                                controlledTooltipProps={{
                                                    "primary": {
                                                        isDark: true,
                                                        text: text?.actionTracking?.detailProjectButtonPrimary,
                                                        placement: 'left',
                                                        actionParams: {
                                                            feature: 'formActions',
                                                            action: 'buttonClickPrimary'
                                                        }
                                                    },
                                                    "clone": {
                                                        isDark: true,
                                                        text: text?.actionTracking?.buttonTaskClone,
                                                        placement: 'left',
                                                        actionParams: {
                                                            feature: 'formActions',
                                                            action: 'buttonClickClone'
                                                        }
                                                    },
                                                }}
                                                customButton={
                                                    <HoverPopup
                                                        btnClassName={'primary-button'}
                                                        className='popup'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            background: 'transparent',
                                                            border: 'none',
                                                            hoverBackground: 'transparent',
                                                            color: colors.blue,
                                                            marginTop: '0.5em'
                                                        }}
                                                        placement={'top'}
                                                        id={text?.tasks?.details?.subtask}
                                                        text={isDesktop && text?.tasks?.details?.subtask}
                                                        onClick={() => setToggleSubTaskModal(!toggleSubTaskModal)}
                                                    >
                                                        <Fab
                                                            variant="extended"
                                                            size={isDesktop ? 'large' : 'large'}
                                                            aria-label={text?.tasks?.details?.subtask}
                                                        >
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Fab>
                                                    </HoverPopup>}

                                            />
                                        )
                                    }
                                </form>
                            )}
                        />

                        {/* Subtask table */}
                        <TasksContainer
                            projects={projects}
                            adminProjects={projects}
                            user={user}
                            employees={employees}
                            tasks={subtasks}
                            subtasks={task?.subtasks}
                            setSubtasks={setSubtasks}
                            tasksIsLoading={tasksIsLoading}
                            handleGetTask={handleGetTask}
                            isDetails
                            projectId={task?.taskProject?._id}
                            title={text?.tasks?.details?.subtasks}
                            projectIsCompleted={task?.taskProject?.projectStatus === 'completed'}
                        />
                    </ConditionalRender>

                    {/* Non admin */}
                    <ConditionalRender renderIf={!disabledTaskEdit}>
                        <Form
                            onSubmit={(values) => onSubmit(values)}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <ConditionalRender renderIf={task?.taskStatus === 'in-progress'}>
                                        <p className='tx-left md-md'>
                                            {text?.tasks?.details?.timeInfo}
                                        </p>
                                    </ConditionalRender>
                                    <div className='flex items-center'>
                                        <FieldWrapper
                                            name={text?.logs?.totalTime}
                                            label={formatMillisecondsToHours(task?.totalTime)}
                                        />

                                    </div>
                                    <div className='flex items-center'>
                                        <SelectInput
                                            isSearchable={false}
                                            className='field-style'
                                            isValidNewOption={() => false}
                                            name='taskStatus'
                                            label={text?.tasks?.details?.status}
                                            initialValue={initialStatusValue}
                                            isClearable={false}
                                            options={status(text)}
                                            setIsEditing={setIsEditing}
                                            disabled={task?.isRunning || taskIsDisabled}
                                            callToAction={<FontAwesomeIcon
                                                onClick={() => setHistoryStatus(!historyStatus)}
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.blue,
                                                    cursor: 'pointer'
                                                }} icon={faHistory}
                                            />}
                                        />
                                        {task?.taskStatus === 'completed' && (
                                            <FontAwesomeIcon
                                                style={{
                                                    color: theme === 'dark' ? colors.secondary : colors.green,
                                                    marginLeft: '1em'
                                                }} icon={faCheck}
                                            />
                                        )}
                                    </div>
                                    {
                                        (isEditing) && (
                                            <FormActions
                                                floating
                                                type={'submit'}
                                                submitText={text?.projects?.details?.saveButton}
                                                btnStyle={{
                                                    color: colors.blue
                                                }}
                                                controlledTooltipProps={{
                                                    "primary": {
                                                        isDark: true,
                                                        text: text?.actionTracking?.detailProjectButtonPrimary,
                                                        placement: 'left',
                                                        actionParams: {
                                                            feature: 'formActions',
                                                            action: 'buttonClickPrimary'
                                                        }
                                                    }
                                                }}
                                            />
                                        )
                                    }
                                </form>
                            )}
                        />

                        <FieldWrapper
                            name={text?.tasks?.details?.project}
                            label={task?.taskProject?.projectName}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.title}
                            label={task?.taskTitle}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.description}
                            label={task?.taskDescription}
                        />

                        <FieldWrapper
                            name={text?.tasks?.details?.priority}
                            label={priority(text)?.find(p => p.value === task?.taskPriority)?.label}
                        />
                        <FieldWrapper
                            name={text?.tasks?.details?.deadline}
                            label={formatDate(task?.taskDeadline, 'MM-DD-YYYY')}
                        />
                        <div className='flex items-center mb-md'>
                            <p
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: getDeadlineStatus(task?.taskDeadline).color,
                                    marginRight: '10px'
                                }}
                            />
                            <p>
                                {getDeadlineStatus(task?.taskDeadline).title}
                            </p>
                        </div>
                        <hr />

                        <div style={{
                            width: '100%',
                            display: isDesktop ? 'flex' : 'block',
                            marginBottom: '1em'
                        }}>
                            <div style={{
                                width: isDesktop ? '50%' : '100%',
                            }}>
                                <p
                                    className='mb-md flex'
                                    style={{
                                        fontFamily: colors.openSans
                                    }}
                                >
                                    {text?.tasks?.create?.todos}
                                </p>
                                {todos.filter((x => !x.completed)).map((todo, index) => (
                                    <div key={index}
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginBottom: '10px',
                                            padding: '10px',
                                        }}>
                                        <CheckBox
                                            name={`todos[${index}].completed`}
                                            component="input"
                                            type="checkbox"
                                            checked={todo.completed}
                                            onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                            className='mr-md'
                                            disabled={taskIsDisabled}
                                        />
                                        <p>
                                            {todo.text}
                                        </p>
                                    </div>
                                ))
                                }
                                <ConditionalRender renderIf={todos.length === 0 || todos.every(todo => todo.completed)}>
                                    <p className='mb-md flex'>
                                        {text?.tasks?.create?.noTodos}
                                    </p>
                                </ConditionalRender>
                            </div>
                            {/* completed */}
                            <ConditionalRender renderIf={!isEmpty(todos.filter((x => x.completed)))}>
                                <div style={{
                                    width: isDesktop ? '50%' : '100%',
                                    padding: '0.5em',
                                    backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
                                }}>
                                    <ConditionalRender renderIf={todos.filter((x => x.completed)).length > 0}>
                                        <p className='mb-md flex'
                                            style={{
                                                fontFamily: colors.openSans
                                            }}>
                                            {text?.tasks?.create?.completed}
                                        </p>
                                    </ConditionalRender>
                                    {
                                        todos.filter((x => x.completed)).map((todo, index) => (
                                            <div key={index}
                                                style={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    marginBottom: '10px'
                                                }}>
                                                <CheckBox
                                                    className='mr-md'
                                                    name={`todos[${index}].completed`}
                                                    component="input"
                                                    type="checkbox"
                                                    checked={todo.completed}
                                                    onChange={(e) => updateTodo(todo.id, 'completed', e.target.checked)}
                                                    disabled={taskIsDisabled}
                                                />
                                                <p style={{
                                                    textDecoration: 'line-through',
                                                }}>
                                                    {todo.text}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ConditionalRender>
                        </div>
                    </ConditionalRender>

                    <RichEditorWithImageUploader
                        onImageUpload={handleImageChange}
                        onFileUpload={handleFileChange}
                        maxFiles={5}
                        customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                        title={text?.tasks?.details?.comments}
                        showForm={true}
                        onSubmit={(values, form) => handleCommentSubmit(values, form)}
                        text={text}
                        submitText={text?.tasks?.details?.button}
                        name="comment"
                        setImagePreviews={setImagePreviews}
                        setFilesPreviews={setFilesPreviews}
                        hideSave
                    />


                    <SubmitModal
                        onClick={saveTotalTime}
                        toggle={() => setTotalTimeEdit(!totalTimeEdit)}
                        text={'Save'}
                        isOpen={totalTimeEdit}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '50%' : '100%'}
                    >
                        <TimeEditFields
                            id={task?._id}
                            initialTimeInMilliseconds={task?.totalTime}
                            label={text?.logs?.totalTime}
                            showDays
                        />
                    </SubmitModal>

                    <hr />
                    <ConditionalRender renderIf={!isEmpty(task?.taskComments)}>
                        {task?.taskComments?.map((comment, index) => {
                            return (
                                <MessageWrapper
                                    index={index}
                                    message={comment}
                                    handleToggleDelete={handleToggleDelete}
                                    handleSave={handleSave}
                                    handleDeleteComment={handleDeleteComment}
                                    selectedCommentId={selectedCommentId}
                                    user={user}
                                    projectId={task?.taskProject?._id}
                                    toggleDeleteModal={toggleDeleteModal}
                                    processImages={processImages}
                                    processFiles={processFiles}
                                    name={'message'}
                                    senderFullName={`${comment?.commentedBy?.firstName} ${comment?.commentedBy?.lastName}`}
                                    createdAt={comment?.commentedAt}
                                    profilePhoto={comment?.commentedBy?.profilePhoto}
                                    value={comment?.commentText}
                                />
                            )
                        })}
                    </ConditionalRender>
                    <ConditionalRender renderIf={isAdmin}>
                        <ConfirmModal
                            onClick={handleDeleteTask}
                            text={text?.inventory?.details?.modal?.button}
                            toggle={() => setToggleDeleteTaskModal(!toggleDeleteTaskModal)}
                            isOpen={toggleDeleteTaskModal}
                            btnStyles={{
                                color: colors.red
                            }}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            <div className='w-100'>
                                <h4>
                                    {text?.inventory?.details?.modal?.title}
                                </h4>
                                {task && (
                                    <div className='mt-md'>
                                        <p className='mb-md'>
                                            {text?.tasks?.home?.modal?.info}
                                        </p>
                                        <ConditionalRender renderIf={task?.subtasks?.length > 0}>
                                            <p className='mb-md'>
                                                {text?.tasks?.details?.deleteModal?.info}
                                            </p>
                                        </ConditionalRender>
                                        {renderSubtasks(task.subtasks)}
                                    </div>
                                )}
                            </div>
                        </ConfirmModal>
                        <ConfirmModal
                            onClick={handleCloneTask}
                            text={text?.inventory?.details?.modal?.button}
                            toggle={() => setToggleCloneModal(!toggleCloneModal)}
                            isOpen={toggleCloneModal}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            <h4>
                                {text?.inventory?.details?.modal?.title2}
                            </h4>
                        </ConfirmModal>
                        <ConfirmModal
                            onClick={handleSubTask}
                            text={text?.tasks?.details?.subtaskModal?.button}
                            toggle={() => setToggleSubTaskModal(!toggleSubTaskModal)}
                            isOpen={toggleSubTaskModal}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}
                        >
                            <h4>
                                {text?.tasks?.details?.subtaskModal?.title}
                            </h4>
                        </ConfirmModal>
                    </ConditionalRender>

                    <ConfirmModal
                        isOpen={historyStatus}
                        toggle={() => setHistoryStatus(!historyStatus)}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '50%' : '100%'}
                    >
                        <div className='flex flex-column w-100'>
                            <h4 className='flex mb-md'>
                                {text?.tasks?.details?.statusHistory}
                            </h4>
                            {
                                task?.history && task?.history
                                    ?.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()) // Sort by date using moment
                                    ?.map((value, index) => {
                                        return (
                                            <>
                                                <div key={index} className='flex justify-space-between pr-md'>
                                                    <p className='b mr-md'>
                                                        {`${value?.action} by ${value?.changedBy?.firstName} ${value?.changedBy?.lastName}`}
                                                    </p>
                                                    <p>
                                                        {moment.utc(value?.timestamp).format('MM-DD-YYYY')}
                                                    </p>
                                                </div>
                                                <hr />
                                            </>
                                        )
                                    })
                            }
                        </div>
                    </ConfirmModal>
                </ConditionalRender>
            </StyledDiv >
        )
}

export default TaskDetailsContainer