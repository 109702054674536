import React, { useState, useEffect } from 'react';
import { useWebSocket } from '../context/WebSocketContext'
import SearchInput from '../Core/Inputs/SearchInput'
import { useTheme } from '../context/ThemeContext'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender'
import classnames from 'classnames'
import { isEmpty, debounce } from 'lodash'
import ConfirmModal from '../Core/ConfirmModal'
import styled from 'styled-components'
import SenderName from '../Chat/SenderName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import PaginatedData from '../Core/PaginatedData';
import { useLanguage } from '../context/LanguageContext';
import RenderFiles from '../Core/RenderFiles';
import RenderImages from '../Core/RenderImages';


const StyledMessage = styled.div`
  color: ${(props) => (props.theme === 'dark' ? colors.white : colors.black)};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  transition: background 0.3s;
`

const StyledDiv = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme === 'light' ? colors.darkGray : colors.lightBlue};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'light') && colors.lightGray)};
    background-color: ${(props) => ((props.isOwnMessage && props.theme === 'dark') && colors.backgroundSecondary)};
    min-width: ${(props) => (props.isDesktop ? '80%' : '100%')};
    justify-self: ${(props) => (props.isOwnMessage ? 'flex-start' : 'flex-end')};
    align-self: ${(props) => (props.isOwnMessage ? 'flex-start' : 'flex-end')};
    position: relative;
    padding: 1em;
    margin-top: 1em;
    .buttons {
        opacity: ${(props) => (props.isEditing ? 1 : 0)};
        transition: opacity 0.1s ease-out 0s;
    }
    &:hover {
        .buttons {
            opacity: 1;
        }
    }

    p, span {
        font-family: ${colors.openSans};
        text-align: left;
        strong {
            font-weight: bold;
        }
        em {
            font-style: italic;
        }
        s {
            text-decoration: line-through;
        }
    }

    .reaction-container {
        position: relative;
        cursor: pointer;
    }

    .reaction-count {
        position: absolute;
        top: 0;
        right: -5px;
        font-size: 0.5em;
    }

    .custom-tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: 0.75em;
        white-space: nowrap;
        display: none; /* Initially hidden */
        z-index: 1000;
    }

    .reaction-container:hover .custom-tooltip {
        display: block; /* Show the tooltip */
    }


`

const ReactionsPopup = styled.div`
    position: absolute;
    top: 0; 
    left: -4em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${(props) => ((props.theme === 'dark' && props.allowEdits) ? colors.backgroundSecondary : colors.secondary)};
    padding: 0.5em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;

    .reaction {
        cursor: pointer;
        font-size: 1.2em;
        margin: 0 0.5em;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.2);
        }
    }
`;

const Reactions = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    bottom: -5px;
    left: 0;
    cursor: pointer;
    .reaction {
        font-size: 1.2em;
        margin: 0 0.2em;
        transition: transform 0.2s ease;
        &:hover {
            transform: scale(1.2);
        }
    }
`


const ChatMentions = ({
    showMentions,
    setShowMentions
}) => {
    const { unseenMentions, unseenReplies } = useWebSocket()
    const navigate = useNavigate();
    const location = useLocation();
    const { text } = useLanguage();
    const [searchQuery, setSearchQuery] = useState('');
    const [tableData, setTableData] = useState([]);

    // Function to add multiple query parameters dynamically
    const addQueryParams = (params) => {
        const searchParams = new URLSearchParams(location.search);

        // Loop through the provided params object and set each key-value pair
        Object.entries(params).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                searchParams.set(key, value); // Update existing or add new param
            }
        });

        // Navigate to the new URL with updated query parameters
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };


    const handleMention = (mention) => {
        addQueryParams({ id: mention?.conversationId?._id, messageId: mention._id });
        setShowMentions(!showMentions)
    }

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const data = [...unseenMentions, ...unseenReplies]
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setTableData(sortedData)
    }, [unseenMentions, unseenReplies])

    useEffect(() => {
        const data = [...unseenMentions, ...unseenReplies]
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        if (searchQuery) {
            const filteredData = sortedData.filter(item => {
                const fullName = `${item.sender.firstName} ${item.sender.lastName}`
                return fullName.toLowerCase().includes(searchQuery.toLowerCase()) || item.messageContent.toLowerCase().includes(searchQuery.toLowerCase())
            })
            setTableData(filteredData)
        } else {
            setTableData(sortedData)
        }
    }, [searchQuery, unseenMentions, unseenReplies])


    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);

    const openImagesModal = (images, index) => {
        setSelectedImages(images);
        setSelectedIndex(index);
        setIsImagesModalOpen(true);
    };

    const closeImagesModal = () => {
        setIsImagesModalOpen(false);
        setSelectedImages([]);
        setSelectedIndex(0);
    };


    return (
        <ConfirmModal
            isOpen={showMentions}
            toggle={() => setShowMentions(!showMentions)}
            width='80%'
            height='80%'
            onClick={() => { }}
            text={''}
        >
            <div className='w-100'>
                <div className='flex flex-column filters mt-md'>
                    <SearchInput
                        className='w-100'
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeHolder={text?.employees?.landing?.search}
                    />

                </div>
                <PaginatedData
                    className="mt-md"
                    data={tableData}
                    noDataText={text?.table?.noDataText}
                    searching={searchQuery}
                    defaultItemsPerPage={10}
                    showLightColors
                    ItemComponent={({ item }) => (
                        <StyledDiv
                            onClick={() => handleMention(item)}
                        >
                            <SenderName
                                isDark={false}
                                isOwnMessage={false}
                                message={{
                                    senderFullName: `${item.sender.firstName} ${item.sender.lastName}`,
                                    sender: {
                                        profilePhoto: item.sender.profilePhoto,
                                    },
                                    createdAt: item.createdAt,
                                }}
                            />
                            <div
                                className="read-view flex w-100"
                                dangerouslySetInnerHTML={{ __html: item.messageContent }}
                            />
                            <ConditionalRender renderIf={!isEmpty(item.files)}>
                                <RenderFiles
                                    files={item.files}
                                    handleDownloadFile={() => { }}
                                    isChat
                                    readOnly={true}
                                    removeFile={() => { }}
                                    noOverlay
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isEmpty(item.imageUrls)}>

                                <div className="flex wrap mt-md">
                                    {item.imageUrls.map((img, idx) => (
                                        <img
                                            key={idx}
                                            src={img.url}
                                            alt={img.originalName}
                                            style={{ width: 150, height: 150, cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openImagesModal(item.imageUrls, idx);
                                            }}
                                        />
                                    ))}
                                </div>
                            </ConditionalRender>
                            <ConditionalRender renderIf={item.parentId}>
                                <div className='mt-md w-100 flex items-center justify-content-center'>
                                    <FontAwesomeIcon icon={faReply} />
                                </div>
                            </ConditionalRender>
                        </StyledDiv>
                    )}
                />
                <RenderImages
                    images={selectedImages}
                    openModal={() => { }}
                    closeModal={closeImagesModal}
                    setActiveIndex={setSelectedIndex}
                    activeIndex={selectedIndex}
                    isModalOpen={isImagesModalOpen}
                    removeImage={() => { }}
                />
            </div>
        </ConfirmModal>
    )
}

export default ChatMentions
