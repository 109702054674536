import React from 'react'
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import FieldWrapper from '../Core/FieldWrapper';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import { isEmpty } from 'lodash';

const EmployeeQuickView = ({
    isQuickViewOpen,
    setIsQuickViewOpen,
    quickViewData,
    noOverlay
}) => {
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();

    return (
        <ConfirmModal
            toggle={() => setIsQuickViewOpen(!isQuickViewOpen)}
            isOpen={isQuickViewOpen}
            noOverlay={noOverlay}
            shaddow
            width={isDesktop ? '80%' : '100%'}
            height={isDesktop ? '80%' : '100%'}
        >
            <div className='w-100'>
                <div className='mb-lg tx-left'>
                    <h4 className='mb-md'>
                        {quickViewData?.firstName} {quickViewData?.middleName || ''} {quickViewData?.lastName}
                    </h4>
                    <div className='mb-md'>
                        <img
                            style={{
                                objectFit: 'cover',
                                width: '100px',
                                height: '100px',
                                borderRadius: '10px',
                            }}
                            src={quickViewData?.profilePhoto}
                            alt='avatar'
                        />
                    </div>

                    <FieldWrapper
                        noHr
                        label={quickViewData?.email}
                        name={text?.employees?.details?.email}
                        className='mb-md'
                        isMail
                    />
                    <FieldWrapper
                        noHr
                        label={quickViewData?.userPhone}
                        name={text?.employees?.details?.phone}
                        className='mb-md'
                        isPhone
                    />
                    <FieldWrapper
                        noHr
                        label={quickViewData?.emergencyContact}
                        name={text?.employees?.details?.emergencyContact}
                        className='mb-md'
                        isPhone
                    />
                    <FieldWrapper
                        noHr
                        label={quickViewData?.jobTitle}
                        name={text?.employees?.details?.jobTitle}
                        className='mb-md'
                    />
                    <FieldWrapper
                        noHr
                        label={quickViewData?.bio}
                        name={text?.employees?.details?.bio}
                        className='mb-md'
                    />
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.skills)}>
                        <FieldWrapper
                            noHr
                            label={
                                <ul className="flex">
                                    {quickViewData?.skills?.map((skill, index) => (
                                        <li key={index} className='ml-sm'>
                                            {` ${skill}`}
                                            {index < quickViewData.skills.length - 1 && ','}
                                        </li>
                                    ))}
                                </ul>
                            }
                            name={text?.employees?.details?.skills}
                            className="mb-md"
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={!isEmpty(quickViewData?.hobbies)}>
                        <FieldWrapper
                            noHr
                            label={
                                <ul className="flex">
                                    {quickViewData?.hobbies?.map((skill, index) => (
                                        <li key={index} className='ml-sm'>
                                            {` ${skill}`}
                                            {index < quickViewData.hobbies.length - 1 && ','}
                                        </li>
                                    ))}
                                </ul>
                            }
                            name={text?.employees?.details?.hobbies}
                            className="mb-md"
                        />
                    </ConditionalRender>
                </div>
            </div>
        </ConfirmModal>
    )
}

export default EmployeeQuickView
