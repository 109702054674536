import React, { useEffect, useState, useCallback } from 'react';
import useInViewport from './useInViewport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import HoverPopup from '../Core/HoverPopup';
import { useWebSocket } from '../context/WebSocketContext';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import colors from '../globalStyles.scss';
import { useLanguage } from '../context/LanguageContext';
import ConditionalRender from '../Core/ConditionalRender';
import MessageWrapper from '../Tasks/MessageWrapper';
import { formatMessageContent } from '../utils/helperFunctions';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';


const UnreadDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.red};
    margin: 10px 0;
    text-align: center;
    color: ${colors.red};
    font-size: 12px;
    font-weight: bold;

    &::before {
        content: ${(props) => `"${props.text?.chat?.unread}"`};
    }
`;

const StyledWindow = styled.div`
.messages-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5em;
    color: #aaa;
    animation: fade-in 0.5s ease-in-out;
}

.chat-message {
    opacity: 0;
    animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

`

const ScrollDownButton = styled.button`
    position: sticky;
    bottom: 0;
    right: 0;
    background-color: ${colors.primary};
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    transition: visibility 0.2s, opacity 0.2s;
    z-index: 1;
`;

const TypingIndicator = styled.div`
    position: sticky;
    top: 0;
    right: 0;
    background: ${colors.primary};
    z-index: 1;
`

const ChatWindow = ({
    user,
    containerRef,
    firstUnreadRef,
    allRead,
    setAllRead,
    setFirstUnreadIndex,
    firstUnreadIndex,
    isProject = false,
    isClient = false,
}) => {
    const {
        selectedConversationId,
        messages,
        markAsRead,
        setMessages,
        setConversations,
        setProjectConversations,
        setChannelConversations,
        conversationIdIsLoading = true,
        newMessageReceived,
        setNewMessageReceived,
        deleteMessage,
        updateMessage,
        updateMessageImage,
        updateMessageFile,
        updateReaction,
        createReply,
        isSendingMessage,
        selectedConversation,
        typingUsers
    } = useWebSocket();

    const { isDesktop } = useScreenSize();
    const { text } = useLanguage();
    const location = useLocation(); // Get the current URL
    const navigate = useNavigate();


    const addQueryParams = (params) => {
        const searchParams = new URLSearchParams(location.search);

        // Loop through params and update/remove query parameters
        Object.entries(params).forEach(([key, value]) => {
            if (value === null || value === undefined) {
                searchParams.delete(key); // Remove if null or undefined
            } else {
                searchParams.set(key, value); // Add or update if valid
            }
        });

        // Update the URL with new query params without refreshing the page
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };


    const findMessageById = () => {
        const { messageId } = queryString.parse(window.location.search);

        // Step 1: Try to find the message directly
        const directMessage = messages.find((message) => message._id === messageId);
        if (directMessage) return directMessage;

        // Step 2: Search in the replies array
        for (const message of messages) {
            if (message.replies?.some(reply => reply._id === messageId)) {
                return message; // ✅ Return the parent message if found in replies
            }
        }

        // Step 3: Return undefined if not found
        return undefined;
    };


    // Ref to store the first unread message
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const scrollToBottom = (e) => {
        e.preventDefault();

        if (!containerRef.current) return;

        // Remove messageId from query
        addQueryParams({ messageId: null });

        // ✅ Use a delay to ensure `scrollHeight` is properly updated before scrolling
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.scrollTo({
                    top: containerRef.current.scrollHeight,
                    behavior: "smooth",
                });
            }
        }, 100);
        if (displayMessages.length === 1) {
            loadMoreMessages();
        }
    };

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

            setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 10);
            setNewMessageReceived(false);

            if (scrollTop <= 0 && hasMore) {
                loadMoreMessages();
            }
            // ✅ If user reaches bottom, reset messages to only show the latest ones
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setVisibleCount(5); // Reset to latest 5 messages
                setHasMore(true); // Enable infinite scroll up again
            }

        }
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line
    }, [hasMore]);


    useEffect(() => {
        const index = messages?.findIndex((message) => message.isUnread);
        setFirstUnreadIndex(index);
        if (isAtBottom) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }

        // eslint-disable-next-line
    }, [selectedConversationId, conversationIdIsLoading, newMessageReceived]);

    const visibleMessages = useInViewport(messages, containerRef);

    useEffect(() => {
        const index = messages?.findIndex((message) => message.isUnread);
        setFirstUnreadIndex(index);
        setAllRead(index === -1);

        // eslint-disable-next-line
    }, [messages]);

    // Mark visible messages as read
    useEffect(() => {
        if (!selectedConversationId || !visibleMessages.length) return;

        const timeoutId = setTimeout(() => {
            markAsRead(selectedConversationId, visibleMessages, user);
        }, 1000);

        // Clear timeout if effect re-runs
        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line
    }, [visibleMessages, selectedConversationId]);

    useEffect(() => {
        if (!selectedConversationId || !visibleMessages.length) return;

        const timeoutId = setTimeout(() => {
            const unreadMessageIds = messages
                .filter((message) => visibleMessages.includes(message._id) && message.isUnread)
                .map((message) => message._id);

            if (unreadMessageIds.length > 0) {
                // Mark as read via WebSocket
                markAsRead(selectedConversationId, unreadMessageIds, user);

                // Update local message state
                setMessages((prevMessages) =>
                    prevMessages.map((message) =>
                        unreadMessageIds.includes(message._id)
                            ? { ...message, isUnread: false }
                            : message
                    )
                );
            }

            // Check if all messages are now read
            const allMessagesRead = messages.every((message) => !message.isUnread);

            if (allMessagesRead) {
                setConversations((prevConversations) =>
                    prevConversations.map((conversation) =>
                        conversation._id === selectedConversationId && !conversation.projectId
                            ? { ...conversation, hasUnreadMessages: false }
                            : conversation
                    )
                );

                setProjectConversations((prevProjectConversations) =>
                    prevProjectConversations.map((conversation) =>
                        conversation._id === selectedConversationId && conversation.projectId
                            ? { ...conversation, hasUnreadMessages: false }
                            : conversation
                    )
                );

                setChannelConversations((prevChannelConversations) =>
                    prevChannelConversations.map((conversation) =>
                        conversation._id === selectedConversationId && conversation.isChannel
                            ? { ...conversation, hasUnreadMessages: false }
                            : conversation
                    )
                );
                setAllRead(true);
            }
        }, 1000);

        // Clear timeout if effect re-runs
        return () => clearTimeout(timeoutId);

        // eslint-disable-next-line
    }, [visibleMessages, selectedConversationId, setMessages, setConversations, setProjectConversations]);

    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);

    const handleToggleDelete = (commentId) => {
        setSelectedCommentId(commentId);
        setToggleDeleteModal(!toggleDeleteModal);
    };

    const handleSave = (value, id) => {
        updateMessage(id, value, user?._id, (updatedMessage, error) => {
            if (error) {
                console.error(error);
            }
        });
    };

    const handleDeleteComment = (id) => {
        deleteMessage(id);
    }

    const [replyImgs, setReplyImgs] = useState([]);
    const [replyFiles, setReplyFiles] = useState([]);

    const handleReplyImageChange = (imageData) => {
        setReplyImgs(imageData);
    }

    const handleReplyFileChange = (fileData) => {
        setReplyFiles(fileData);
    }

    const handleReply = useCallback(
        ({ message, images, files, mentions }, parentId) => {
            const replyContent = message
            const replyImages = images || replyImgs;
            const filesToSend = files || replyFiles;


            if ((!isEmpty(replyImages) || (isEmpty(replyImages) && replyContent) ||
                (!isEmpty(filesToSend) || (isEmpty(filesToSend) && replyContent)))
            ) {
                createReply(
                    selectedConversationId,
                    parentId,
                    replyContent,
                    'text',
                    user?._id,
                    replyImages,
                    filesToSend,
                    (reply, error) => {
                        if (reply) {
                            handleReplyImageChange([]);
                            handleReplyFileChange([]);

                        } else {
                            console.error('Error sending reply:', error);
                        }
                    },
                    mentions
                );
            }
        },

        // eslint-disable-next-line
        [replyImgs, replyFiles, user?._id, selectedConversationId]
    );


    const processImages = async (data) => {
        updateMessageImage(data, (updatedMessage, error) => {
            if (error) {
                console.error(error);
            }
        });

    };

    const processFiles = async (data) => {
        updateMessageFile(data, (updatedMessage, error) => {
            if (error) {
                console.error(error);
            }
        });
    }

    const [visibleCount, setVisibleCount] = useState(5);
    const [displayMessages, setDisplayMessages] = useState([]);

    useEffect(() => {
        if (!messages.length) return;

        const message = findMessageById();
        if (message) {
            const messageIndex = messages.findIndex((msg) => msg._id === message._id);

            // ✅ Dynamically calculate start index based on visibleCount
            const startIndex = Math.max(0, messageIndex - (visibleCount - 1));
            const endIndex = messageIndex + 1; // Include the target message

            setDisplayMessages(messages.slice(startIndex, endIndex));

            // ✅ Update visibleCount correctly based on the range displayed
            const newVisibleCount = endIndex - startIndex;
            setVisibleCount(newVisibleCount);

            // ✅ Track the first unread index properly
            setFirstUnreadIndex(startIndex);
        }
        else {
            if (messages.length > 0) {
                setDisplayMessages(messages.slice(-visibleCount));
            }
        }
    }, [messages, visibleCount, location.search]);

    useEffect(() => {
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.scrollTo({
                    top: containerRef.current.scrollHeight,
                    behavior: "smooth",
                });
            }
        }, 100);
    }, [location.search])


    useEffect(() => {
        if (selectedConversationId) {
            setVisibleCount(5);
            setHasMore(true);
            setIsAtBottom(true);
        }
    }, [selectedConversationId])


    const loadMoreMessages = () => {
        if (!containerRef.current) return;

        const previousScrollHeight = containerRef.current.scrollHeight;
        const previousScrollTop = containerRef.current.scrollTop;

        setVisibleCount((prevCount) => {
            if (prevCount >= messages.length) {
                setHasMore(false);
                return messages.length;
            }
            return prevCount + 5;
        });

        requestAnimationFrame(() => {
            setTimeout(() => {
                if (containerRef.current) {
                    const newScrollHeight = containerRef.current.scrollHeight;
                    const heightDifference = newScrollHeight - previousScrollHeight;

                    if (heightDifference > 0) {
                        containerRef.current.scrollTop = previousScrollTop + heightDifference;
                    }
                }
            }, 50);
        });
    };

    useEffect(() => {
        if (messages.length > 0) {
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                }
            }, 100);
        }

        // eslint-disable-next-line
    }, [selectedConversationId]);

    // if no messages reset visible messages
    useEffect(() => {
        if (isEmpty(messages)) {
            setDisplayMessages([]);
        }
    }, [messages]);


    return (
        <StyledWindow
            id={'scrollableChatContainer'}
            className="chat-window scroll-container"
            ref={containerRef}
            style={{
                height: '80%',
                overflowY: 'auto',
                padding: isDesktop ? '1em' : '0',
            }}
        >
            <div
                className="flex flex-column relative"
                style={{
                    width: '100%',
                    height: conversationIdIsLoading ? '100%' : 'auto',
                }}
            >
                <ConditionalRender renderIf={typingUsers[selectedConversationId]?.length > 0}>
                    <TypingIndicator
                    >
                        <p className="mr-sm">
                            {typingUsers[selectedConversationId]
                                ?.map((user) => `${user.firstName} ${user.lastName}`.trim())
                                ?.join(', ')}
                            {typingUsers[selectedConversationId]?.length > 1 ? ' are' : ' is'} {text?.chat?.typing}
                        </p>
                    </TypingIndicator>
                </ConditionalRender>
                <ConditionalRender renderIf={true} isLoading={conversationIdIsLoading}>
                    <InfiniteScroll
                        dataLength={displayMessages.length}
                        next={loadMoreMessages}
                        hasMore={visibleCount < messages.length}
                        inverse={true}
                        scrollableTarget="scrollableChatContainer"
                        style={{
                            overflow: 'hidden',
                            position: 'relative',
                            height: '100%',
                            paddingBottom: '1em'
                        }}
                    >
                        <ConditionalRender isDark={isClient} count={1} renderIf={true} isLoading={conversationIdIsLoading}>
                            {
                                displayMessages.map((message, index) => (
                                    <React.Fragment key={index}>
                                        <MessageWrapper
                                            index={index}
                                            message={message}
                                            handleToggleDelete={handleToggleDelete}
                                            handleSave={handleSave}
                                            handleReply={handleReply}
                                            handleDeleteComment={handleDeleteComment}
                                            selectedCommentId={selectedCommentId}
                                            user={user}
                                            projectId={message?.projectId}
                                            toggleDeleteModal={toggleDeleteModal}
                                            processImages={processImages}
                                            processFiles={processFiles}
                                            name={'message'}
                                            isChat
                                            senderFullName={`${message?.sender?.firstName} ${message?.sender?.lastName}`}
                                            createdAt={message?.createdAt}
                                            profilePhoto={message?.sender?.profilePhoto}
                                            value={formatMessageContent(message?.messageContent)}
                                            isProject={isProject}
                                            isClient={isClient}
                                            updateReaction={updateReaction}
                                            setReplyImgs={setReplyImgs}
                                            replyImgs={replyImgs}
                                            setReplyFiles={setReplyFiles}
                                            replyFiles={replyFiles}
                                            handleReplyImageChange={handleReplyImageChange}
                                            handleReplyFileChange={handleReplyFileChange}
                                            isSendingMessage={isSendingMessage}
                                        />
                                        {!allRead && index === firstUnreadIndex && (
                                            <UnreadDivider text={text} ref={firstUnreadRef} />
                                        )}
                                    </React.Fragment>
                                ))
                            }
                        </ConditionalRender>

                    </InfiniteScroll>
                </ConditionalRender>
                <ConditionalRender renderIf={isEmpty(displayMessages)}>
                    <div className="messages-loader">
                        <h4>
                            {text?.projects?.create?.notFoundMsgs?.chat}
                        </h4>
                    </div>
                </ConditionalRender>
                <ConditionalRender renderIf={selectedConversation && selectedConversation?.status === 'pending'}>
                    <div className="messages-loader mt-md">
                        <h4>
                            {text?.chat?.pendingMsg}
                        </h4>
                    </div>
                </ConditionalRender>
                <ConditionalRender renderIf={selectedConversation && selectedConversation?.status === 'closed'}>
                    <div className="messages-loader mt-md">
                        <h4>
                            {text?.chat?.closedMsg}
                        </h4>
                    </div>
                </ConditionalRender>



            </div>
            <ScrollDownButton
                isVisible={!isAtBottom || newMessageReceived || queryString.parse(location.search).messageId}
            >
                <HoverPopup
                    btnClassName={'secondary-button'}
                    className='popup'
                    placement={'top'}
                    id={`scroll-down-button`}
                    text={text?.chat?.showMore}
                    onClick={scrollToBottom}
                    wrapperStyle={{
                        backgroundColor: colors.primary,
                    }}
                >
                    <FontAwesomeIcon icon={faCaretDown} />
                </HoverPopup>
            </ScrollDownButton>
        </StyledWindow >
    );
};

export default ChatWindow;
