import React, { createContext, useEffect, useState, useContext } from 'react';
import { GlobalContext } from './GlobalContext';
import {
    registerAction,
    getPerformedActions
} from '../utils/calls';

// Create the context
export const RegisterContext = createContext();

// Provider component
export const RegisterProvider = ({ children }) => {
    const { isLoggedIn, isCompanyPremium } = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (!isLoggedIn || !isCompanyPremium) return;
        handleGetActions();
    }, [isLoggedIn, isCompanyPremium]);

    const handleGetActions = async () => {
        if (!isLoggedIn || !isCompanyPremium) return;
        setLoading(true);
        setError(null);
        try {
            const res = await getPerformedActions();
            if (res.status === 200) {
                setActions(res.data.actions);
            } else {
                setError(res.data.message)
                setLoading(false);
            }
        } catch (err) {
            console.error('Failed to fetch actions:', err);
            setError(err.response?.data?.message || 'An error occurred');
            setLoading(false);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const handleRegisterAction = async ({ feature, action }) => {
        setError(null);
        try {
            const res = await registerAction({ feature, action });
            if (res.status === 200) {
                setActions((prev) => [...prev, { feature, action, actionPerformed: true }]);
            } else {
                setError(res.data.message);
            }
        } catch (err) {
            console.error('Failed to register action:', err);
            setError(err.response?.data?.message || 'An error occurred');
            throw err;
        } finally {
            setLoading(false);
        }
    };


    return (
        <RegisterContext.Provider value={{
            handleRegisterAction,
            actions,
            loading,
            error
        }}
        >
            {children}
        </RegisterContext.Provider>
    );
};
