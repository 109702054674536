import React, { forwardRef, useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext';
import classnames from 'classnames';
import colors from '../globalStyles.scss';
import './styles.scss';

const StyledFormGroup = styled.div`
.form-group {
    div.custom-arrows {
        display: flex;
        align-self: end;
        button {
            background: ${(props) => props?.disabled ? colors.darkGray : colors.lightGray};
            margin: 0 0.2rem;
            &:hover {
                background: ${(props) => props?.disabled ? colors.lightGrey : colors.blue};
                color: ${(props) => props?.disabled ? colors.lightGrey : 'white'};
            }
            color: ${colors.blue};
        }
    }
    .toggle-password-visibility {
            position: absolute;
            right: -15px;
            top: 0;
            cursor: pointer;
        }

        input, textarea {
            border: none;
            border-bottom: ${(props) => props.value ? '1px solid' + colors.blue : '1px solid black'};
            border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
            border-bottom-color: ${props => props.showLightColors && colors.black};
            background: ${props => props.disabled ? (props.theme === 'dark' ? colors.disabledDark : colors.lightGray) : (props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray)};
            background: ${props => props.showLightColors && colors.white};
            padding: 0.2rem;
            border-radius: 0;
    }
    label {
        display: flex;
        color: ${(props) => props.showLightColors && colors.black};
    }
        input.error_message {
            border-bottom: ${(props) => props.value || (props.type === 'number' && props.value === 0) ? '1px solid' + colors.red : '1px solid' + colors.red};
        }
    }
    input:focus,
    textarea:focus {
      outline: 0;
      border-bottom: 1px solid ${colors.blue};
      border-bottom-color: ${props => props.theme === 'dark' ? colors.lightGray : colors.black};
      border-bottom-color: ${props => props.showLightColors && colors.black};

    }
    input:focus ~ label,
    textarea:focus ~ label {
    font-size: 0.75rem;
    color: #999;
    transition: all 0.225s ease;
    }

        /* For Webkit browsers like Chrome, Safari */
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* For Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }

        /* For IE 10+ */
        input[type='number']::-ms-clear,
        input[type='number']::-ms-reveal {
            display: none;
        }
`;

const SimpleFieldWithValidation = forwardRef(({
    label,
    name,
    type,
    validate,
    className,
    disabled,
    showPassword,
    togglePasswordVisibility,
    initialValue,
    setIsEditing,
    onFieldChange,
    ...rest
}, ref) => {
    const { theme } = useTheme();
    const [value, setValue] = useState(initialValue || '');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        onFieldChange && onFieldChange(name, newValue);
        setIsEditing && setIsEditing(true);
    };

    return (
        <StyledFormGroup
            theme={theme}
            className={`FieldWithValidation ${className}`}
            disabled={disabled}
        >
            <Field name={name} validate={validate ? validate : null} initialValue={initialValue} {...rest}>
                {({ input, meta }) => (
                    <div className="form-group">
                        <label className='mb-sm b' htmlFor={type}>
                            {label}
                        </label>
                        {type === 'textarea' ? (
                            <textarea
                                {...input}
                                className={classnames({
                                    'error_message': meta.error && meta.touched
                                })}
                                ref={ref}
                                disabled={disabled}
                                onChange={handleChange}
                                value={value}
                                autoComplete="off"
                            />
                        ) : (
                            <input
                                {...input}
                                className={classnames({
                                    'error_message': meta.error && meta.touched
                                })}
                                ref={ref}
                                type={type}
                                disabled={disabled}
                                onChange={handleChange}
                                value={value}
                                autoComplete="off"
                            />
                        )}
                        {name === 'password' && (
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="toggle-password-visibility"
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        )}
                        {meta.error && meta.touched && (
                            <span className='small error'>{meta.error}</span>
                        )}
                    </div>
                )}
            </Field>
        </StyledFormGroup>
    );
});

export default SimpleFieldWithValidation;
