import React from 'react'
import ConfirmModal from '../Core/ConfirmModal'
import ConditionalRender from '../Core/ConditionalRender'
import SearchInput from '../Core/Inputs/SearchInput'
import PaginatedData from '../Core/PaginatedData'
import styled from 'styled-components'
import colors from '../globalStyles.scss'
import { isEmpty } from 'lodash'
import EmployeeItem from './EmployeeItem'
import CreateNewEmployeeContainer from '../Employees/CreateNewEmployeeContainer'
import SelectableBox from '../Core/SelectableBox'
import useScreenSize from '../context/useScreenSize'
import classnames from 'classnames'
import CallToAction from '../Core/CallToAction'


const StyledEmployeeModal = styled.div`
    width: 100%;
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        a, span {
            color: ${colors.white};
        }
    }
`

const StyledClientModal = styled.div`
    width: 100%;
    .client_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        width: 30%;
        cursor: pointer;
        transition: all 0.2s ease 0s;
        &:hover {
            background: ${colors.secondaryLight};
           p {
            color: ${colors.blue};
            }
        }
        p {
            color: ${colors.white};
        }
    }

`

const CreateProjectModals = ({
    assignEmployee,
    handleAssignEmployee,
    assignClient,
    handleAssignClient,
    text,
    employees,
    employeesIsLoading,
    employeesList,
    user,
    addEmployee,
    setAddEmployee,
    addClient,
    setAddClient,
    setRoleType,
    roleType,
    createNewEmployee,
    showEmployeesFilters,
    setShowEmployeesFilters,
    searchEmployeeQuery,
    handleSearchEmployeeChange,
    selectedEmployees,
    setSelectedEmployees,
    adminEmployees,
    handleEmployeeSelection,
    handleAdminSelection,
    fetchAllClients,
    showClientsFilters,
    handleSearchClientChange,
    clientsIsLoading,
    clients,
    setShowClientsFilters,
    searchClientQuery,
    clientsList,
    selectedClients,
    setSelectedClients,
    handleClientSelection,
    setNewClient,
    isCreateProject
}) => {
    const { isDesktop, isPhone } = useScreenSize();

    return (

        <>

            {/* Employees */}
            <ConditionalRender renderIf={assignEmployee}>
                <ConfirmModal
                    isOpen={assignEmployee}
                    toggle={handleAssignEmployee}
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    btnStyles={{
                        color: colors.blue,
                    }}
                >
                    <div>
                        <CallToAction
                            className='mb-md'
                            text={text?.employees?.landing?.createNew}
                            type='button'
                            onClick={() => setAddEmployee(true)}
                        />
                        <StyledEmployeeModal>
                            <div
                                className={classnames({
                                    'flex w-100': true,
                                    'flex-column': isPhone,
                                })}
                            >
                                {/* Left Section: Available Employees */}
                                <div
                                    className={classnames({
                                        'w-50': !isPhone,
                                        'mr-md': !isPhone,
                                    })}
                                >
                                    <h4 className="mb-md">
                                        {text?.projects?.details?.assignModal?.title}
                                    </h4>
                                    <SearchInput
                                        className="pb-md w-100"
                                        value={searchEmployeeQuery}
                                        onChange={handleSearchEmployeeChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />
                                    <PaginatedData
                                        className="mt-md"
                                        data={employeesList?.filter(
                                            (employee) =>
                                                !selectedEmployees.some((e) => e._id === employee._id) &&
                                                employee._id !== user?._id // Exclude user and already selected
                                        )}
                                        dataIsLoading={employeesIsLoading}
                                        itemsPerPage={5}
                                        noDataText={text?.employees?.landing?.noData}
                                        searching={searchEmployeeQuery}
                                        showLightColors
                                        ItemComponent={({ item }) => (
                                            <EmployeeItem
                                                key={item?._id}
                                                employee={item}
                                                selectedEmployees={selectedEmployees}
                                                adminEmployees={adminEmployees}
                                                handleEmployeeSelection={handleEmployeeSelection}
                                                handleAdminSelection={handleAdminSelection}
                                                text={text}
                                                isCreateProject
                                            />
                                        )}
                                    />
                                </div>

                                {/* Right Section: Selected Employees */}
                                <div
                                    className={classnames({
                                        'w-50': !isPhone,
                                        'ml-md': !isPhone,
                                    })}
                                >
                                    <h4 className='mb-md'>{text?.paginated?.selected}</h4>
                                    <p className='mb-md'>
                                        {text?.projects?.details?.assignModal?.text}
                                    </p>
                                    <div>
                                        {selectedEmployees.map((employee) => (
                                            <EmployeeItem
                                                key={employee._id}
                                                employee={employee}
                                                selectedEmployees={selectedEmployees}
                                                adminEmployees={adminEmployees}
                                                handleEmployeeSelection={handleEmployeeSelection}
                                                handleAdminSelection={handleAdminSelection}
                                                text={text}
                                                isCreateProject
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </StyledEmployeeModal>
                    </div>
                </ConfirmModal>




                <ConditionalRender renderIf={addEmployee}>
                    <CreateNewEmployeeContainer
                        isOpen={addEmployee}
                        onClick={createNewEmployee}
                        toggle={() => setAddEmployee(false)}
                        setRoleType={setRoleType}
                        roleType={roleType}
                        employees={employees}
                        isCreateProject={isCreateProject}
                    />
                </ConditionalRender>
            </ConditionalRender>

            {/* Clients */}
            <ConditionalRender renderIf={assignClient}>
                <ConfirmModal
                    toggle={handleAssignClient}
                    isOpen={assignClient}
                    width={isDesktop ? '50%' : '100%'}
                    height={isDesktop ? '60%' : '100%'}
                    btnStyles={{
                        color: colors.blue
                    }}
                >
                    <StyledClientModal>
                        <h4 >
                            {text?.client?.assign}
                        </h4>

                        <ConditionalRender isLoading={clientsIsLoading} loadingCount={clients?.length} renderIf={!isEmpty(clients)}>
                            <div className='flex flex-column filters mt-md w-100'>
                                <SearchInput
                                    className='w-100'
                                    value={searchClientQuery}
                                    onChange={handleSearchClientChange}
                                    placeHolder={text?.employees?.landing?.search}
                                />
                            </div>

                            <PaginatedData
                                className='mt-md'
                                data={clientsList}
                                dataIsLoading={clientsIsLoading}
                                itemsPerPage={5}
                                noDataText={'No Clients Found'}
                                searching={searchClientQuery}
                                showLightColors
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className={'flex justify-space-between w-100'}
                                            showLightColors
                                            onClick={() => {
                                                setNewClient(item)
                                                handleAssignClient()
                                            }}
                                        >
                                            <p>
                                                {`${item.name} (${item.email})`}
                                            </p>
                                        </SelectableBox>
                                    )
                                }}
                            />
                        </ConditionalRender>
                    </StyledClientModal>
                </ConfirmModal>
            </ConditionalRender>
        </>
    )
}

export default CreateProjectModals