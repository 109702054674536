import { uploadImagesAndSendMessage } from './uploadImagesAndSendMessage';

export const createReply = async (
    conversationId,
    parentId,
    messageContent,
    messageType = 'text',
    userId,
    imagePreviews = [],
    filesPreviews = [],
    callback,
    setIsSendingMessage,
    setImagePreviews,
    setFilesPreviews,
    setMessages,
    selectedConversationId,
    ws,
    mentions
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        setIsSendingMessage(false);
        return;
    }

    try {
        const hasImages = imagePreviews?.length > 0;
        const hasFiles = filesPreviews?.length > 0;
        const newReply = {
            conversationId: selectedConversationId,
            parentId,
            sender: userId,
            messageContent,
            messageType,
            hasImages,
            mentions
        };

        ws.current.emit('createReply', newReply, async (response) => {
            if (response.success) {
                const createdReply = response.reply;

                setIsSendingMessage(true);
                if (hasImages || hasFiles) {
                    // Handle image uploads if necessary
                    await uploadImagesAndSendMessage(
                        ws,
                        conversationId,
                        createdReply._id,
                        imagePreviews,
                        filesPreviews,
                        (finalizedReply) => {
                            if (finalizedReply) {
                                // Add or update the reply in the parent message
                                setMessages((prevMessages) =>
                                    prevMessages.map((msg) =>
                                        msg._id === parentId
                                            ? {
                                                ...msg,
                                                replies: msg.replies?.find((r) => r._id === finalizedReply._id)
                                                    ? msg.replies.map((r) =>
                                                        r._id === finalizedReply._id ? finalizedReply : r
                                                    ) // Replace the existing reply
                                                    : [...(msg.replies || []), finalizedReply],
                                            }
                                            : msg
                                    )
                                );

                                callback?.(finalizedReply);
                                setIsSendingMessage(false);
                                setImagePreviews([]);
                                setFilesPreviews([]);
                            } else {
                                callback?.(null, 'Failed to upload images.');
                            }
                        },
                        ws
                    );
                } else {
                    // Add reply to the parent message directly
                    setMessages((prevMessages) =>
                        prevMessages.map((msg) =>
                            msg._id === parentId
                                ? {
                                    ...msg,
                                    replies: msg.replies?.find((r) => r._id === createdReply._id)
                                        ? msg.replies
                                        : [...(msg.replies || []), createdReply],
                                }
                                : msg
                        )
                    );

                    setIsSendingMessage(false);
                    callback?.(createdReply);
                }
            } else {
                console.error('Failed to create reply:', response.message);
                callback?.(null, response.message || 'Failed to create reply');
                setIsSendingMessage(false);
            }
        });
    } catch (error) {
        console.error('Error creating reply:', error);
        callback?.(null, 'Failed to send reply due to an error.');
        setIsSendingMessage(false);
    }

};