import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import ChartsContainer from './ChartsContainer'
import TimeAnalysisContainer from './TimeAnalysisContainer';
import TasksAnalysisContainer from './TasksAnalysisContainer';
import { isEmpty } from 'lodash'
import { Form, FormSpy } from 'react-final-form';
import FormActions from '../Core/FormActions'
import moment from 'moment'
import { separateData, combineData } from './utils/helperFunctions';
import { findFieldByName } from '../utils/helperFunctions';
import Wrapper from '../Core/Wrapper';
import {
    saveWidget,
    getAllAnalytics,
    getLogs
} from '../utils/calls'
import ShowJustMap from '../Core/ShowJustMap';
import ConfirmModal from '../Core/ConfirmModal'
import ConditionalRender from '../Core/ConditionalRender'
import colors from '../globalStyles.scss'
import FieldWrapper from '../Core/FieldWrapper'
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext'
import useScreenSize from '../context/useScreenSize';


const AnalyticsContainer = ({
    inventory,
    inventoryColumns,
    projects,
    fetchInventoryProducts,
    employees,
    user,
    tasks,
    tasksIsLoading,
    attributes,
    fetchAttributes,
    fieldNames
}) => {
    const formRef = useRef();
    const { text } = useLanguage()
    const { isDesktop } = useScreenSize();
    const [spyValues, setSpyValues] = useState();
    const [inventoryData, setInventoryData] = useState([]);
    const [projectData, setProjectData] = useState(projects);
    const [taskData, setTaskData] = useState([]);
    const [combineKey, setCombineKey] = useState('');
    const [combineProjectKey, setCombineProjectKey] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const [formattedProjectData, setFormattedProjectData] = useState([]);
    const [info, setInfo] = useState([]);
    const [infoIsOpen, setInfoIsOpen] = useState(false);
    const [chartIsEditing, setChartIsEditing] = useState(false);

    const [stringData, setStringData] = useState([]);
    const [numberData, setNumberData] = useState([]);
    const [stringProjectData, setStringProjectData] = useState([]);
    const [numberProjectData, setNumberProjectData] = useState([]);
    const [dataCheckedKeys, setDataCheckedKeys] = useState([]);
    const [dataCheckedKeysProject, setDataCheckedKeysProject] = useState([]);

    const [analyticsWidgets, setAnalyticsWidgets] = useState([]);
    const [analyticsWidgetsLoading, setAnalyticsWidgetsLoading] = useState(true);

    const [initialGroupBy, setInitialGroupBy] = useState({});
    const [initialGroupByProject, setInitialGroupByProject] = useState({});
    const [initialProject, setInitialProject] = useState({});
    const [initialChartType, setInitialChartType] = useState({});
    const [initialEmployee, setInitialEmployee] = useState({});

    const [initialTasksGroupBy, setInitialTasksGroupBy] = useState({});

    const [allTimeLogs, setAllTimeLogs] = useState([]);
    const [allTimeLogsLoading, setAllTimeLogsLoading] = useState(true);

    const [timeFilters, setTimeFilters] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });

    const [selectedMonth, setSelectedMonth] = useState(moment().format('MMMM'));
    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

    const renderFieldLabel = (key) => {
        if (key === 'inventoryName') return text?.inventory?.landing?.table?.columns?.name;
        if (key === 'inventoryQuantity') return text?.inventory?.create?.form?.quantity;
        if (key === 'isPriority') return text?.inventory?.details?.priority;
        if (key === 'inventoryCategory') return text?.inventory?.create?.form?.category;
        return fieldNames?.find(field => key === field?.name)?.label
    }


    const fetchAllTimeLogs = async (projectId, employeeId) => {
        setAllTimeLogsLoading(true);

        const showAllProjects = projectId === 'all';
        const showAllEmployees = employeeId === 'all';
        const projectFilterId = showAllProjects ? null : projectId;
        const employeeFilterId = showAllEmployees ? null : employeeId;

        try {
            // Fetch all logs
            const res = await getLogs('all', null, showAllProjects);
            if (res.status === 200) {
                const filteredLogs = res.data?.reduce((acc, log) => {
                    // Check if the user matches
                    const matchesEmployee = showAllEmployees || log.userId?.id === employeeFilterId;

                    // Iterate over taskTimes to filter projects
                    const matchingTaskTimes = log.taskTimes?.filter(taskTime => {
                        const matchesProject = showAllProjects || taskTime.projectId?.id === projectFilterId;
                        const projectNotCompleted = taskTime.projectId?.projectStatus !== 'completed';
                        return matchesProject && projectNotCompleted;
                    });

                    // If there are matching taskTimes and the employee matches, include this log
                    if (matchesEmployee && matchingTaskTimes?.length > 0) {
                        acc.push({
                            ...log,
                            taskTimes: matchingTaskTimes, // Include only the matching taskTimes
                        });
                    }

                    return acc;
                }, []);

                setAllTimeLogs(filteredLogs);
            }
        } catch (error) {
            console.error('Error in fetchAllTimeLogs', error);
        } finally {
            setAllTimeLogsLoading(false);
        }
    };

    const initData = () => {
        const chartInitialData = analyticsWidgets.find(widget => widget.id === 'inventory');
        const timeInitialData = analyticsWidgets.find(widget => widget.id === 'timeTracker');
        const tasksInitialData = analyticsWidgets.find(widget => widget.id === 'tasks');
        const projectInitialData = analyticsWidgets.find(widget => widget.id === 'project');
        const trueKeys = Object.keys(chartInitialData?.dataCheckedKeys || {}).filter(key => chartInitialData.dataCheckedKeys[key] === true);
        const trueKeysProject = Object.keys(projectInitialData?.dataCheckedKeys || {}).filter(key => projectInitialData.dataCheckedKeys[key] === true);
        setInitialGroupBy({
            label: chartInitialData?.groupBy?.label || text?.analytics?.name,
            value: chartInitialData?.groupBy?.value || 'inventoryName'
        })
        setInitialGroupByProject({
            label: projectInitialData?.groupBy?.label || text?.analytics?.category,
            value: projectInitialData?.groupBy?.value || 'inventoryCategory'
        })
        setInitialProject({
            label: chartInitialData?.selectProject?.label || text?.analytics?.all,
            value: chartInitialData?.selectProject?.value || 'all'
        })
        fetchAllTimeLogs(chartInitialData?.selectProject?.value)

        setInitialChartType({
            value: 'barChart',
            label: text?.analytics?.barChart
        })

        setInitialEmployee({
            label: timeInitialData?.selectEmployee?.label || text?.analytics?.all,
            value: timeInitialData?.selectEmployee?.value || 'all'
        })

        setInitialTasksGroupBy({
            label: tasksInitialData?.tasksGroupBy?.label || 'Status',
            value: tasksInitialData?.tasksGroupBy?.value || 'taskStatus'
        })

        setTimeFilters({
            startDate: moment(timeInitialData?.dateRange?.startDate) || moment().startOf('month'),
            endDate: moment(timeInitialData?.dateRange?.endDate) || moment().endOf('month')
        })

        setCombineKey(chartInitialData?.groupBy?.value || 'inventoryName')
        setCombineProjectKey(projectInitialData?.groupBy?.value || 'inventoryCategory')
        formRef?.current?.change('groupBy', chartInitialData?.groupBy)
        formRef?.current?.change('groupByProject', projectInitialData?.groupBy)
        formRef?.current?.change('selectProject', chartInitialData?.selectProject)
        formRef?.current?.change('chartType', {
            label: text?.analytics?.barChart,
            value: 'barChart'
        })
        formRef?.current?.change('selectEmployee', timeInitialData?.selectEmployee)
        formRef?.current?.change('selectYear', timeInitialData?.selectYear)
        formRef?.current?.change('selectMonth', timeInitialData?.selectMonth)
        formRef?.current?.change('tasksGroupBy', tasksInitialData?.tasksGroupBy)

        if (tasksInitialData?.dateStarted) formRef?.current?.change('dateStarted', moment(tasksInitialData?.dateStarted))
        if (tasksInitialData?.dateCompleted) formRef?.current?.change('dateCompleted', moment(tasksInitialData?.dateCompleted))
        formRef?.current?.change('dateRange.startDate', moment(timeInitialData?.dateRange?.startDate))
        formRef?.current?.change('dateRange.endDate', moment(timeInitialData?.dateRange?.endDate))
        formRef?.current?.change('dataCheckedKeys', chartInitialData?.dataCheckedKeys)
        formRef?.current?.change('dataCheckedKeysProject', projectInitialData?.dataCheckedKeys)
        setDataCheckedKeys(trueKeys)
        setDataCheckedKeysProject(trueKeysProject)
    }

    useEffect(() => {
        initData()
    }, [analyticsWidgets])

    useEffect(() => {
        initData()
    }, [])

    useEffect(() => {
        fetchInventoryProducts()
        fetchAnalyticsWidgets()
    }, [])

    const fetchAnalyticsWidgets = async () => {
        setAnalyticsWidgetsLoading(true);
        try {
            const res = await getAllAnalytics();
            if (res.status === 200) {
                setAnalyticsWidgets(res.data?.analyticsWidgets);
                setAnalyticsWidgetsLoading(false);
            }
        } catch (error) {
            setAnalyticsWidgetsLoading(false);
            console.error('Error in fetchAnalyticsWidgets', error);
        }
    };

    useEffect(() => {
        const [stringsData, numbersData] = separateData(inventoryData);
        setStringData(stringsData);
        if (!isEmpty(numbersData)) {
            setNumberData(numbersData);
        }
    }, [inventoryData])

    useEffect(() => {
        const [stringsData, numbersData] = separateData(projectData);
        setStringProjectData(stringsData);
        setNumberProjectData(numbersData);
    }, [projectData]);

    const handleClear = (form) => {
        const [stringsData, numbersData] = separateData(inventoryData);
        setStringData(stringsData);
        setNumberData(numbersData);
        setCombineKey('');
        form.change('groupBy', null);
    };

    const handleProjectClear = (form) => {
        const [stringsData, numbersData] = separateData(projectData);
        setStringProjectData(stringsData);
        setNumberProjectData(numbersData);
        setCombineProjectKey('');
        form.change('groupByProject', null);
    }

    const handleChartChange = (e, form) => {
        form.change('selectProject', { label: text?.analytics?.all, value: 'all' });
        setChartIsEditing(true);
    }

    const handleEmployeeChange = (e, form) => {
        form.change('selectEmployee', e);
        setChartIsEditing(true);
    }

    const handleSelectedDateChange = (e, form) => {
        form.change(e?.name, e?.value);
        setChartIsEditing(true);
    }

    const handleSave = async (values) => {
        // Filter out false values from dataCheckedKeys
        const filteredDataCheckedKeys = Object.fromEntries(
            Object.entries(values.dataCheckedKeys).filter(([key, value]) => value === true)
        );

        const filteredDataCheckedKeysProject = Object.fromEntries(
            Object.entries(values.dataCheckedKeysProject).filter(([key, value]) => value === true)
        )

        const inventory = {
            id: 'inventory',
            chartType: values.chartType,
            selectProject: values.selectProject,
            dataCheckedKeys: filteredDataCheckedKeys,
            groupBy: values.groupBy
        };

        const project = {
            id: 'project',
            chartType: values.chartType,
            selectProject: values.selectProject,
            dataCheckedKeys: filteredDataCheckedKeysProject,
            groupBy: values.groupByProject
        }

        const timeTracker = {
            id: 'timeTracker',
            selectProject: values.selectProject,
            selectEmployee: values.selectEmployee,
            selectYear: values.selectYear,
            selectMonth: values.selectMonth,
            dateRange: values.dateRange,
        }

        const tasks = {
            id: 'tasks',
            tasksGroupBy: values.tasksGroupBy,
            selectProject: values.selectProject,
            selectEmployee: values.selectEmployee,
            dateStarted: values.dateStarted && moment(values.dateStarted).toISOString(),
            dateCompleted: values.dateCompleted && moment(values.dateCompleted).toISOString()
        };

        try {
            const res = await saveWidget({ inventory, timeTracker, tasks, project });
            if (res.status === 200) {
                setChartIsEditing(false);
                fetchAnalyticsWidgets();
            }
        } catch (error) {
            console.error('Error in saveCharts', error);
        }
    };

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        setInventoryData(
            inventory.filter(x => {
                if (selectedProject === '000000000000000000000000') {
                    return x.projectId === null;
                } else if (selectedProject === 'all') {
                    return true;
                } else {
                    return x.projectId?.id === selectedProject;
                }
            })
        );
    }, [spyValues?.selectProject, inventory]);

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        setProjectData(
            projects?.filter(x => {
                if (selectedProject === 'all') {
                    return true;
                } else {
                    return x.id === selectedProject;
                }
            })
        )
    }, [spyValues?.selectProject, projects]);

    const adjustToLocalTime = (dateString) => {
        if (!dateString) {
            return null;
        }

        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            return null;
        }

        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString().split('T')[0];
    };

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        const selectedEmployee = spyValues?.selectEmployee?.value;
        const selectedDateStarted = spyValues?.dateStarted;
        const selectedDateCompleted = spyValues?.dateCompleted;

        setTaskData(
            tasks
                .filter(x => x?.taskProject?.projectStatus !== 'completed')
                .filter(x => {
                    if (selectedProject === 'all') {
                        return true;
                    } else {
                        return x.taskProject?.id === selectedProject;
                    }
                })
                .filter(x => {
                    if (selectedEmployee === '000000000000000000000000') {
                        return x.taskAssignedTo === null; // Return only items with projectId as null
                    } else if (selectedEmployee === 'all') {
                        return true;
                    } else {
                        return x.taskAssignedTo?.id === selectedEmployee;
                    }
                })
                .filter(x => {
                    // No date filters selected, include all tasks
                    if (!selectedDateStarted && !selectedDateCompleted) {
                        return true;
                    }

                    const taskDateStarted = adjustToLocalTime(x.dateStarted);
                    const taskDateCompleted = adjustToLocalTime(x.dateCompleted);
                    const selectedDateStartedString = selectedDateStarted?.format('YYYY-MM-DD');
                    const selectedDateCompletedString = selectedDateCompleted?.format('YYYY-MM-DD');

                    // Include tasks that match either dateStarted or dateCompleted or both
                    const matchesDateStarted = selectedDateStarted && taskDateStarted === selectedDateStartedString;
                    const matchesDateCompleted = selectedDateCompleted && taskDateCompleted === selectedDateCompletedString;

                    // Task is included if it matches either or both date filters
                    return matchesDateStarted || matchesDateCompleted;
                })
        );
    }, [spyValues?.selectProject, spyValues?.selectEmployee, spyValues?.dateStarted, spyValues?.dateCompleted, tasks]);

    useEffect(() => {
        const selectedProject = spyValues?.selectProject?.value;
        const selectedEmployee = spyValues?.selectEmployee?.value;
        if (selectedProject && selectedEmployee) {
            fetchAllTimeLogs(selectedProject, selectedEmployee);
        }
    }, [spyValues?.selectProject, spyValues?.selectEmployee]);

    useMemo(() => {
        const formattedData = inventory.map(item => {
            // Destructure to remove unwanted keys
            const { id, _id, images, __v, companyId, ...rest } = item;

            const obj = { ...rest };

            if (item.advanceFields) {
                let newAdvanceFields = {};
                Object.keys(item.advanceFields).forEach(key => {
                    if (key.startsWith('number-') && typeof item.advanceFields[key]?.value === 'number') {
                        newAdvanceFields[key] = { value: item.advanceFields[key].value };
                    } else {
                        newAdvanceFields[key] = item.advanceFields[key];
                    }
                });
                obj.advanceFields = newAdvanceFields;
            }

            // Handle other fields that may have the `.value` structure
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key]?.value !== undefined) {
                    obj[key] = { value: obj[key].value };
                }
            });

            return obj;
        });

        setInventoryData(formattedData);
    }, [inventory]);


    useMemo(() => {
        const formattedData = projects?.map(item => {
            // Destructure to remove unwanted keys
            const { id, _id, images, __v, companyId, pin, ...rest } = item;

            const obj = { ...rest };

            if (item.advanceFields) {
                let newAdvanceFields = {};
                Object.keys(item.advanceFields).forEach(key => {
                    if (key.startsWith('number-') && typeof item.advanceFields[key]?.value === 'number') {
                        newAdvanceFields[key] = { value: item.advanceFields[key].value };
                    } else {
                        newAdvanceFields[key] = item.advanceFields[key];
                    }
                });
                obj.advanceFields = newAdvanceFields;
            }

            // Handle other fields that may have the `.value` structure
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key]?.value !== undefined) {
                    obj[key] = { value: obj[key].value };
                }
            });

            return obj;
        });

        setProjectData(formattedData);
    }, [projects]);


    const handleCombineChange = useCallback((value, arr) => {
        if (value && arr) {
            const newData = combineData(value, arr);
            setFormattedData(newData);
            setCombineKey(value);
        }
    }, []);

    useEffect(() => {
        if (!numberData || numberData.length === 0) {
            return;
        }

        if (!combineKey) {
            setFormattedData(numberData);
            return;
        }

        handleCombineChange(combineKey, numberData);
    }, [numberData, combineKey, handleCombineChange]);



    const handleCombineProjectChange = useCallback((value, arr) => {
        if (value && arr) {
            const newData = combineData(value, arr);
            setFormattedProjectData(newData);
            setCombineProjectKey(value)
        }

    }, [])

    useEffect(() => {
        if (!numberProjectData || numberProjectData.length === 0) {
            return;
        }

        if (!combineProjectKey) {
            setFormattedProjectData(numberProjectData);
            return;
        }

        handleCombineProjectChange(combineProjectKey, numberProjectData);
    }, [numberProjectData, combineProjectKey, handleCombineProjectChange])

    useEffect(() => {
        const month = moment(selectedMonth, 'MMMM').month();
        const year = parseInt(selectedYear, 10);
        const startDate = moment({ year, month, day: 1 });
        const endDate = startDate.clone().endOf('month');
        setTimeFilters({ startDate, endDate });
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        const startDate = timeFilters.startDate;
        if (startDate) {
            setSelectedMonth(startDate.format('MMMM'));
            setSelectedYear(startDate.format('YYYY'));
            formRef?.current?.change('selectMonth', startDate.format('MMMM'));
            formRef?.current?.change('selectYear', startDate.format('YYYY'));
        }
    }, [timeFilters.startDate]);

    const handleDatesChange = ({ startDate, endDate }) => {
        setTimeFilters({ startDate, endDate });
        if (startDate) {
            setSelectedMonth(startDate.format('MMMM'));
            setSelectedYear(startDate.format('YYYY'));
        } else if (endDate) {
            setSelectedMonth(endDate.format('MMMM'));
            setSelectedYear(endDate.format('YYYY'));
        }
        formRef?.current?.change('selectMonth', startDate ? startDate.format('MMMM') : endDate.format('MMMM'));
        formRef?.current?.change('selectYear', startDate ? startDate.format('YYYY') : endDate.format('YYYY'));
        setChartIsEditing(true);
    };

    const handleMonthChange = (event) => {
        const month = event?.value;
        if (month) {
            setSelectedMonth(month);

            // Calculate the new start and end dates based on the selected month and current year
            const newStartDate = moment().month(month).year(selectedYear).startOf('month');
            const newEndDate = moment(newStartDate).endOf('month');

            // Update filters
            setTimeFilters({ startDate: newStartDate, endDate: newEndDate });

            // Update the form fields
            formRef?.current?.change('dateRange.startDate', newStartDate);
            formRef?.current?.change('dateRange.endDate', newEndDate);
            formRef?.current?.change('selectMonth', newStartDate.format('MMMM'));

            setChartIsEditing(true);
        }
    };

    const handleYearChange = (event) => {
        const year = event?.value;
        if (year) {
            setSelectedYear(year);

            // Calculate the new start and end dates based on the selected year and current month
            const newStartDate = moment().month(selectedMonth).year(year).startOf('month');
            const newEndDate = moment(newStartDate).endOf('month');

            // Update filters
            setTimeFilters({ startDate: newStartDate, endDate: newEndDate });

            // Update the form fields
            formRef?.current?.change('dateRange.startDate', newStartDate);
            formRef?.current?.change('dateRange.endDate', newEndDate);
            formRef?.current?.change('selectYear', year);

            setChartIsEditing(true);
        }
    };

    return (
        <ConditionalRender renderIf={true} isLoading={analyticsWidgetsLoading}>
            <Form
                onSubmit={handleSave}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy subscription={{ values: true }}>
                            {({ values, form }) => {
                                formRef.current = form;

                                setSpyValues(values);
                            }}
                        </FormSpy>
                        <p className='mb-md flex'>
                            {text?.analytics?.info}
                        </p>
                        <SelectInput
                            className='mb-md'
                            name='selectProject'
                            isClearable={false}
                            isValidNewOption={() => false}
                            isSearchable
                            label={text?.tasks?.home?.filters?.project}
                            placeholder={text?.projects?.details?.selectProject}
                            initialValue={initialProject}
                            options={
                                !isEmpty(projects) &&
                                [
                                    { label: text?.analytics?.all, value: 'all' },
                                    { label: text?.analytics?.unassigned, value: "000000000000000000000000" },
                                    ...projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                        return {
                                            label: x?.projectName,
                                            value: x?._id
                                        }

                                    })
                                ]

                            }
                            onChange={(e) => {
                                form.change('selectProject', e)
                                fetchAllTimeLogs(e?.value)
                                setChartIsEditing(true)

                            }}
                        />

                        {/* Time Tracker */}
                        <Wrapper
                            title={text?.homeLandingContainer?.services?.timeTracker?.title}
                            isLoading={analyticsWidgetsLoading && allTimeLogsLoading}
                        >
                            <SelectInput
                                className='mb-md'
                                name={'selectEmployee'}
                                isValidNewOption={() => false}
                                isClearable={false}
                                label={text?.dashboard?.employees}
                                options={
                                    !isEmpty(employees) &&
                                    [
                                        { label: text?.analytics?.all, value: 'all' },
                                        { label: text?.analytics?.unassigned, value: "000000000000000000000000" },
                                        ...employees?.filter(((x) => x?.id !== user?.id))?.map(employee => ({
                                            value: employee.id,
                                            label: `${employee.firstName} ${employee.lastName}`
                                        }))]
                                }
                                initialValue={initialEmployee}
                                onChange={(e) => handleEmployeeChange(e, form)}

                            />
                            <TimeAnalysisContainer
                                analyticsWidgetsLoading={analyticsWidgetsLoading}
                                allTimeLogsLoading={allTimeLogsLoading}
                                timeLogs={allTimeLogs}
                                employees={employees}
                                user={user}
                                initialEmployee={initialEmployee}
                                handleEmployeeChange={handleEmployeeChange}
                                form={form}
                                setChartIsEditing={setChartIsEditing}
                                setTimeFilters={setTimeFilters}
                                filters={timeFilters}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                setSelectedMonth={setSelectedMonth}
                                setSelectedYear={setSelectedYear}
                                handleDatesChange={handleDatesChange}
                                handleMonthChange={handleMonthChange}
                                handleYearChange={handleYearChange}
                                spyValues={spyValues}
                            />

                            <hr />

                            <TasksAnalysisContainer
                                spyValues={spyValues}
                                tasks={taskData}
                                tasksGroupBy={initialTasksGroupBy}
                                setTasksGroupBy={setInitialTasksGroupBy}
                                setChartIsEditing={setChartIsEditing}
                                analyticsWidgetsLoading={analyticsWidgetsLoading}
                                tasksIsLoading={tasksIsLoading}
                                handleSelectedDateChange={handleSelectedDateChange}
                                form={form}
                            />
                        </Wrapper>

                        <hr />

                        {/* Project Advance Fields */}
                        <Wrapper
                            title={text?.projects?.details?.inventoryModal?.projects}
                            isLoading={analyticsWidgetsLoading}
                        >
                            <h4 className='flex mb-md'>
                                {text?.category?.advanceFields}
                            </h4>

                            {/* TODO: PROJECT ADVANCE FIELDS LOGIC */}
                            <ChartsContainer
                                data={projectData}
                                formattedData={formattedProjectData}
                                setFormattedData={setFormattedProjectData}
                                handleCombineChange={handleCombineProjectChange}
                                setStringData={setStringProjectData}
                                setNumberData={setNumberProjectData}
                                setCombineKey={setCombineProjectKey}
                                setDataCheckedKeys={setDataCheckedKeysProject}
                                dataCheckedKeys={dataCheckedKeysProject}
                                combineKey={combineProjectKey}
                                stringData={stringProjectData}
                                numberData={numberProjectData}
                                setInitialGroupBy={setInitialGroupByProject}
                                initialGroupBy={initialGroupByProject}
                                handleClear={handleProjectClear}
                                isProject
                                handleChartChange={handleChartChange}
                                spyValues={spyValues}
                                form={form}
                                projects={projects}
                                setInfo={setInfo}
                                setChartIsEditing={setChartIsEditing}
                                initialProject={initialProject}
                                initialChartType={initialChartType}
                                analyticsWidgetsLoading={analyticsWidgetsLoading}
                                fieldNames={fieldNames}
                                formRef={formRef}
                                analyticsWidgets={analyticsWidgets}
                            />

                            <hr />
                            {/* Inventory */}
                            <h4 className='flex mb-md'>
                                {text?.projects?.create?.inventory}
                            </h4>
                            <ChartsContainer
                                handleChartChange={handleChartChange}
                                spyValues={spyValues}
                                form={form}
                                projects={projects}
                                data={inventoryData}
                                setFormattedData={setFormattedData}
                                formattedData={formattedData}
                                handleCombineChange={handleCombineChange}
                                setCombineKey={setCombineKey}
                                combineKey={combineKey}
                                setStringData={setStringData}
                                setNumberData={setNumberData}
                                stringData={stringData}
                                numberData={numberData}
                                setDataCheckedKeys={setDataCheckedKeys}
                                dataCheckedKeys={dataCheckedKeys}
                                handleClear={handleClear}
                                setInfo={setInfo}
                                setInitialGroupBy={setInitialGroupBy}
                                initialGroupBy={initialGroupBy}
                                setChartIsEditing={setChartIsEditing}
                                initialProject={initialProject}
                                initialChartType={initialChartType}
                                analyticsWidgetsLoading={analyticsWidgetsLoading}
                                fieldNames={fieldNames}
                                formRef={formRef}
                            />
                        </Wrapper>

                        <ConditionalRender renderIf={

                            chartIsEditing
                        }>
                            <FormActions
                                type="submit"
                                floating
                                submitText={text?.timeTracker?.modal?.button}
                                btnStyle={{
                                    color: colors.blue
                                }}
                            />
                        </ConditionalRender>

                        <ConfirmModal
                            isOpen={!isEmpty(info)}
                            toggle={() => {
                                setInfoIsOpen(!infoIsOpen);
                                setInfo([])
                            }}
                            width={isDesktop ? '80%' : '100%'}
                            height={isDesktop ? '80%' : '100%'}
                        >
                            <div className='w-100'>
                                <div>
                                    <p className='mb-md'>
                                        {text?.analytics?.dontForgetToSave}
                                    </p>
                                    <FieldWrapper
                                        noHr
                                        name={text?.analytics?.project}
                                        label={spyValues?.selectProject?.label}
                                        className='mb-md'
                                    />
                                    {
                                        info?.map((item, index) => {
                                            return (
                                                <div className='w-100' key={index}>
                                                    {
                                                        // Render inventoryName first if it exists
                                                        item.inventoryName && (
                                                            <FieldWrapper
                                                                noHr
                                                                goto={`/inventory/details?id=${item['productId']}`}
                                                                name={renderFieldLabel('inventoryName')}
                                                                label={item.inventoryName}
                                                                key="inventoryName" // Ensure to use a unique key
                                                                className='b'
                                                            />
                                                        )
                                                    }
                                                    {
                                                        // Render the rest of the fields, excluding inventoryName
                                                        Object.entries(item)
                                                            .filter(([key, value]) => !['__v', '_id', 'id', 'projectName', 'inventoryName', 'projectId', 'projectStatus'].includes(key)) // Filter out the keys you want to exclude and also inventoryName since it's already handled
                                                            .map(([key, value]) => {
                                                                const field = findFieldByName(key, fieldNames);
                                                                if (key === 'isPriority') {
                                                                    return (
                                                                        <FieldWrapper
                                                                            noHr
                                                                            name={renderFieldLabel(key)}
                                                                            label={value === true ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                                                                            key={key} // Ensure to use a unique key for each FieldWrapper
                                                                        />
                                                                    )
                                                                }
                                                                if (key === 'advanceFields') {
                                                                    return (
                                                                        <div className='flex flex-column'>
                                                                            {
                                                                                Object.entries(value).map(([key, value]) => {
                                                                                    const field = findFieldByName(key, fieldNames);
                                                                                    if (field?.inputType === 'number') {
                                                                                        return (
                                                                                            <FieldWrapper
                                                                                                noHr
                                                                                                name={renderFieldLabel(key)}
                                                                                                label={String(value?.value)}
                                                                                                key={key} // Ensure to use a unique key for each FieldWrapper
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                    if (field?.inputType === 'amount') {
                                                                                        return (
                                                                                            <FieldWrapper
                                                                                                noHr
                                                                                                name={renderFieldLabel(key)}
                                                                                                label={`$${String(value)}`}
                                                                                                key={key} // Ensure to use a unique key for each FieldWrapper
                                                                                            />
                                                                                        )
                                                                                    }

                                                                                    return (
                                                                                        <FieldWrapper
                                                                                            noHr
                                                                                            name={renderFieldLabel(key)}
                                                                                            label={String(value)}
                                                                                            key={key} // Ensure to use a unique key for each FieldWrapper
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                if (key === 'inventoryQuantity') {
                                                                    return (
                                                                        <FieldWrapper
                                                                            noHr
                                                                            name={renderFieldLabel(key)}
                                                                            label={String(value?.value)}
                                                                            key={key} // Ensure to use a unique key for each FieldWrapper
                                                                        />
                                                                    )
                                                                }
                                                                if (key === 'productId') return '';
                                                                if (!isEmpty(field)) {
                                                                    if (field?.inputType === 'date') {
                                                                        return (
                                                                            <FieldWrapper
                                                                                noHr
                                                                                name={renderFieldLabel(key)}
                                                                                label={moment(value).format('YYYY-MM-DD hh:mm A')}
                                                                                key={key} // Ensure to use a unique key for each FieldWrapper
                                                                            />
                                                                        )
                                                                    }
                                                                    if (field?.inputType === 'link') {
                                                                        return (
                                                                            <FieldWrapper
                                                                                noHr
                                                                                name={renderFieldLabel(key)}
                                                                                label={value}
                                                                                key={key}
                                                                                isLink

                                                                            />
                                                                        )
                                                                    }
                                                                    if (field?.inputType === 'location') {
                                                                        return (
                                                                            <div>
                                                                                <FieldWrapper
                                                                                    noHr
                                                                                    key={index}
                                                                                    name={renderFieldLabel(key)}
                                                                                />
                                                                                <ConditionalRender renderIf={!isEmpty(value)}>
                                                                                    <ShowJustMap
                                                                                        location={value}
                                                                                    />
                                                                                </ConditionalRender>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }

                                                                return (
                                                                    <FieldWrapper
                                                                        noHr
                                                                        name={renderFieldLabel(key)}
                                                                        label={String(value)}
                                                                        key={key}
                                                                    />
                                                                );
                                                            })
                                                    }
                                                    <hr />
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </ConfirmModal>
                    </form >
                )}
            />

        </ConditionalRender>
    )
}

export default AnalyticsContainer
