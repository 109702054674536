import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ConditionalRender from './ConditionalRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../context/ThemeContext';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';
import colors from '../globalStyles.scss';
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'query-string';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height:  ${props => props.height || 'auto'};
    width: ${props => props.width || '100%'};
        &.desktop {
            .SelectableBox {
                    width: 50%;
                }
        }
            &.tablet {
                .SelectableBox {
                    width: 50%;
                }
            }
            &.phone {
                .SelectableBox {
                    width: 100%;
                }
            }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto; /* Push to the bottom */
`;

const Button = styled.button`
    margin: 0 0.5rem;
    padding: 0.5rem 1em;
background-color: ${props =>
        props.disabled
            ? '#cccccc'
            : props.showLightColors
                ? colors.primary
                : props.theme === 'dark'
                    ? colors.secondary
                    : colors.blue
    };
    svg {    
        color: ${colors.blue};
        color: ${props => (props.showLightColors && colors.white)} !important;
    }
    border: none;
    border-radius: 4px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  &:hover {
    background-color: ${props => (props.disabled ? '#cccccc' : (props.theme === 'dark') ? colors.primary : colors.blueLight)};
    svg {
        color: ${colors.blueLight};
        color: ${props => (props.showLightColors && colors.white)};
    }
  }
`;

const PageIndicator = styled.p`
  margin: 0 1em;
  font-size: 1em;
  font-weight: bold;
  font-family: ${colors.openSans};
`;

const Wrapper = styled.div`
  flex: 1;
  display: ${(props) => (props.noData ? 'flex' : 'block')} ;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0.5em;
`;

const PaginatedData = ({
    data,
    className,
    dataIsLoading = false,
    defaultItemsPerPage = 5,
    ItemComponent,
    height,
    width,
    noDataText = 'No data available',
    searching,
    paginationLocation = 'top',
    showLightColors = false,
    wrapperStyle,
    useUrlPagination = false,
}) => {
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const location = useLocation();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    // Sync state with URL query on mount & when URL changes (only if useUrlPagination is true)
    useEffect(() => {
        if (useUrlPagination) {
            const parsedQuery = queryString.parse(location.search);
            const page = parsedQuery.page ? parseInt(parsedQuery.page, 10) : 1;
            if (!isNaN(page) && page > 0 && page <= totalPages) {
                setCurrentPage(page);
            } else {
                setCurrentPage(1); // Default to page 1 if invalid query
            }
        }
    }, [location.search, totalPages, useUrlPagination]); // Watch for URL or total pages change

    useEffect(() => {
        setCurrentPage(1); // Reset to first page when search input changes
    }, [searching]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            if (useUrlPagination) {
                navigate(`?page=${newPage}`); // Update URL only if enabled
            }
        }
    };

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data?.slice(startIndex, endIndex);
    };

    return (
        <Container
            height={height}
            theme={theme}
            width={width}
            className={classnames({
                [className]: className,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
        >
            <ConditionalRender renderIf={paginationLocation === 'top'}>
                <PaginationContainer theme={theme}>
                    <div className="flex items-center mb-md">
                        <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(currentPage - 1);
                        }}
                            disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </Button>
                        <PageIndicator>{currentPage} / {totalPages}</PageIndicator>
                        <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(currentPage + 1);
                        }}
                            disabled={currentPage === totalPages || isEmpty(data)}
                        >
                            <FontAwesomeIcon icon={faCaretRight} />
                        </Button>
                    </div>
                </PaginationContainer>
            </ConditionalRender>
            <Wrapper style={wrapperStyle} className='scroll-container' noData={isEmpty(data)}>
                <ConditionalRender renderIf={!isEmpty(data)}>
                    {getCurrentPageData()?.map(item => (
                        <ItemComponent key={item?._id || ''} item={item} />
                    ))}
                </ConditionalRender>
                <ConditionalRender isLoading={dataIsLoading} renderIf={isEmpty(data)}>
                    <p className='no-data mt-md'>{noDataText}</p>
                </ConditionalRender>
            </Wrapper>
            <ConditionalRender renderIf={paginationLocation === 'bottom'}>
                <PaginationContainer theme={theme}>
                    <div className="flex items-center mt-md">
                        <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(currentPage - 1);
                        }}
                            disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </Button>
                        <PageIndicator>{currentPage} / {totalPages}</PageIndicator>
                        <Button showLightColors={showLightColors} theme={theme} onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(currentPage + 1);
                        }}
                            disabled={currentPage === totalPages || isEmpty(data)}
                        >
                            <FontAwesomeIcon icon={faCaretRight} />
                        </Button>
                    </div>
                </PaginationContainer>
            </ConditionalRender>
        </Container>
    );
};

export default PaginatedData;