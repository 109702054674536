import request from '../request';

/**
 * Get a single task request from the API.
 * @param {string} [id] - (Optional) The task ID.
 * @param {string} [uuid] - (Optional) The task UUID.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const getTask = async (id, uuid) => {
    try {
        let url = "/tasks/details"; // Base endpoint for querying task details

        // Build query parameters
        const queryParams = [];

        if (id) {
            queryParams.push(`id=${encodeURIComponent(id)}`);
        }

        if (uuid) {
            queryParams.push(`uuid=${encodeURIComponent(uuid)}`);
        }

        // If both id and uuid are present, concatenate them in the query string
        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        } else {
            throw new Error("Either id or uuid must be provided");
        }

        console.log("Fetching task from URL:", url);

        const { data, status } = await request('GET', url);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get task: ${error.message}`);
    }
};

export default getTask;
