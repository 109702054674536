import React, { useEffect, useState, useCallback } from 'react'
import { useLocation } from "react-router-dom";

import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarLogs from '../Core/CalendarLogs'
import CalendarLogsSmall from '../Core/CalendarLogsSmall'
import useScreenSize from '../context/useScreenSize'
import ConditionalRender from '../Core/ConditionalRender'
import queryString from "query-string";
import moment from 'moment';
import {
    getEmployee,
    getWeeklyTimeLog
} from '../utils/calls';
import { useLanguage } from '../context/LanguageContext';
import { projectStatus } from '../utils/helperFunctions';
import { formatMillisecondsToHours } from '../utils/helperFunctions';


const LogDetails = ({
    projects,
    fetchTimeLogs,
    timeLogs,
    logsIsLoading,
    user,
    tasks,
    fetchProjectTasks,
    employees
}) => {
    const location = useLocation();
    const [employee, setEmployee] = useState({});
    const [project, setProject] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { text } = useLanguage();

    const { id } = queryString.parse(location.search);

    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const assignedProjects = projects?.filter(project => {
        // Check if the user is an admin
        const isAdmin = project.assignedEmployees?.some(employee => employee?.id === user?.id);
        const isProjectCompleted = project?.projectStatus === 'completed';

        return (isAdmin || user?.roleName === 'Admin') && !isProjectCompleted;
    });

    const assignedOrAdminProjects = projects?.filter(project =>
        project.adminEmployees?.some(admin => admin?.id === user?.id) ||
        project.assignedEmployees?.some(assigned => assigned?.id === user?.id)
    );

    const selectedProject = projects?.find(p => p.id === project?.value);

    const filteredEmployees = employees?.filter(employee => {
        // If no project is selected, return all employees
        if (!selectedProject) {
            return true;
        }

        if (user?.roleName === 'Admin') {
            // Admin can see all employees in the selected project
            return selectedProject?.assignedEmployees?.some(assigned => assigned?.id === employee?.id);
        }

        // Non-admin users see only employees if they're part of adminEmployees
        return (
            selectedProject?.assignedEmployees?.some(assigned => assigned?.id === employee?.id) &&
            selectedProject?.adminEmployees?.some(admin => admin?.id === user?.id)
        );
    });


    const transformTimesToEvents = (times) => {
        // Helper function to get the start of the day in UTC
        const getStartOfDayInUTC = (dateStr) => {
            const date = new Date(dateStr);
            return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();
        };

        // Create an object to hold the groups
        const groupedByDay = times?.reduce((acc, current) => {
            if (current && current?.taskTimes?.length > 0) {
                // Extract the day part of the clockIn timestamp as the key
                const date = new Date(current.clockIn);
                const dayKey = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0)).toISOString();

                // If the key doesn't exist in the accumulator, initialize it
                if (!acc[dayKey]) {
                    acc[dayKey] = {
                        totalTime: 0,
                        isRunning: false,
                        dailyLog: current.dailyLog,
                        tasks: [],
                        totalTimeText: '',
                        clockIn: getStartOfDayInUTC(dayKey),
                        imageUrls: current.imageUrls || [],
                        _id: current._id,
                    };
                }

                // Iterate over taskTimes
                current.taskTimes.forEach((task) => {
                    acc[dayKey].totalTime += task.timeSpent; // Sum total time for the day
                    acc[dayKey].totalTimeText = formatMillisecondsToHours(acc[dayKey].totalTime); // Update totalTimeText
                    if (task.isRunning) {
                        acc[dayKey].isRunning = true; // Update isRunning if any task is running
                    }

                    // Add task details to the tasks array
                    acc[dayKey].tasks.push({
                        id: task.taskId?._id,
                        taskId: task.taskId?._id,
                        taskTitle: task.taskId?.taskTitle || 'Untitled Task',
                        isArchived: task.taskId?.isArchived || false,
                        uuid: task.taskId?.uuid || '',
                        projectId: task.projectId?._id,
                        projectName: task.projectId?.projectName || 'Untitled Project',
                        projectStatus: task.projectId?.projectStatus,
                        adminEmployees: task.projectId?.adminEmployees || [], // Include adminEmployees array
                        assignedEmployees: task.projectId?.assignedEmployees || [], // Include assignedEmployees array
                        timeSpent: task.timeSpent,
                        taskTotalTimeText: formatMillisecondsToHours(task.timeSpent),
                        isRunning: task.isRunning,
                    });
                });
            }

            return acc;
        }, {});


        return groupedByDay;
    };


    useEffect(() => {
        fetchProjectTasks(false, false, id)
    }, [id])

    const fetchEmployeeData = useCallback(
        async (employeeId = false, projectId = false, showAll = false) => {
            try {
                setIsLoading(true);

                // Only fetch employee data if employeeId is provided
                if (employeeId) {
                    const res = await getEmployee(employeeId);
                    if (res.status === 200) {
                        setEmployee(res.data); // Update employee state
                    }
                }
                if (projectId) {
                    const project = projects.find(project => project._id === projectId);
                    setProject({
                        value: project._id,
                        label: project.projectName
                    });
                }

                // Always fetch project tasks and time logs
                fetchProjectTasks(projectId, false, employeeId || null); // Pass null if no employeeId
                fetchTimeLogs(employeeId || null, projectId, showAll); // Pass null if no employeeId
            } catch (error) {
                setError(text?.logs?.validations?.notAvailable);
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchProjectTasks, fetchTimeLogs, setEmployee, setProject, text?.logs?.validations?.notAvailable]
    );


    useEffect(() => {
        if (id) {
            fetchEmployeeData(id);
        }
    }, [location.search]);

    const [weeklyTotalTime, setWeeklyTotalTime] = useState(0)
    const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));


    const fetchWeeklyTime = async (employeeId, projectId) => {
        try {
            const res = await getWeeklyTimeLog({
                currentWeekStart,
                id: employeeId,
                projectId,
            });
            if (res.status === 200) {
                setWeeklyTotalTime(res.data?.totalTime);
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchWeeklyTime(employee?.id, project?._id)
    }, [currentWeekStart])


    return (
        <>
            <ConditionalRender renderIf={isDesktop}>
                <CalendarLogs
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={formatMillisecondsToHours}
                    projects={user?.roleName === 'Admin' ? assignedProjects : assignedOrAdminProjects}
                    user={user}
                    tasks={tasks}
                    employees={filteredEmployees}
                    employeeId={employee?.id}
                    fetchProjectTasks={fetchProjectTasks}
                    setEmployee={setEmployee}
                    setProject={setProject}
                    project={project}
                    employee={employee}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    fetchEmployeeData={fetchEmployeeData}
                    fetchWeeklyTime={fetchWeeklyTime}
                    weeklyTotalTime={weeklyTotalTime}
                    setCurrentWeekStart={setCurrentWeekStart}
                    currentWeekStart={currentWeekStart}
                />
            </ConditionalRender>
            <ConditionalRender renderIf={isTablet || isPhone}>
                <CalendarLogsSmall
                    fetchTimeLogs={fetchTimeLogs}
                    logsIsLoading={logsIsLoading}
                    timeLogs={timeLogs}
                    transformTimesToEvents={transformTimesToEvents}
                    getTitleFromMilliseconds={formatMillisecondsToHours}
                    user={user}
                    tasks={tasks}
                    employees={filteredEmployees}
                    employeeId={id}
                    setEmployee={setEmployee}
                    setProject={setProject}
                    project={assignedProjects}
                    projects={user?.roleName === 'Admin' ? assignedProjects : assignedOrAdminProjects}
                    employee={employee}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    fetchEmployeeData={fetchEmployeeData}
                    fetchWeeklyTime={fetchWeeklyTime}
                    weeklyTotalTime={weeklyTotalTime}
                    setCurrentWeekStart={setCurrentWeekStart}
                    currentWeekStart={currentWeekStart}
                />
            </ConditionalRender>
        </>
    )
}

export default LogDetails