import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classNames from 'classnames';
import classnames from 'classnames';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    h1 {
        text-align: left;
        margin-bottom: 1em;
        font-size: 1.5em;
    }
    h2 {
        text-align: left;
        margin-bottom: 1em;
    }
    p {
        text-align: left;
        margin-bottom: 1em;
    }
    a {
        text-decoration: underline !important;
    }
    &.tablet, &.phone {
    }
    &.desktop {
    }
`;

const englishQnA = [
    {
        question: 'What is Ardidflow?',
        answer: 'Ardidflow is a comprehensive project management toolkit designed specifically for small businesses, freelancers, and teams. It offers a range of features and services—including unlimited projects, time tracking, employee management, invoicing and proposals, AI assistance, integrated chat and calendar, and more—to help you streamline your workflow and keep your projects on track. Plus, Ardidflow is easier to use than the competition and available for less money, making it the ideal choice for budget-conscious professionals.'
    },
    {
        question: 'How do I sign up for an account?',
        answer: 'Signing up is easy! Simply click the [register](/register) button, fill in the required information (name, email, password, etc.), and follow the instructions to create your account. You’ll receive an email confirmation with next steps to start using our services.'
    },
    {
        question: 'Is there a free trial available?',
        answer: 'Yes! We offer a 30 days free trial period so you can explore all the features of Ardidflow without any upfront costs. During the trial, you’ll have access to our full suite of tools to determine if our service meets your project management needs.'
    },
    {
        question: 'What pricing plans do you offer?',
        answer: 'Our pricing is designed to be affordable and flexible for businesses of all sizes. After the free trial, you can choose from various subscription plans that best fit your team’s needs. [Visit our Pricing page](/subscriptions) for detailed information on each plan and any available discounts for long-term commitments.'
    },
    {
        question: 'Can I cancel my subscription at any time?',
        answer: 'Absolutely. Our service is month-to-month or yearly, and you can cancel your subscription at any time through your account settings. We strive to keep our process transparent and hassle-free. Your account will remain active until the end of the current billing cycle, and you won’t be charged for the following month. If you have any questions or need assistance, please contact our support team.'
    },
    {
        question: 'Do you offer customer support?',
        answer: 'Yes, we’re here to help! Our support team is available via email and live chat during business hours. Additionally, our AI Help Center contains guides, tutorials, and troubleshooting tips to assist you in getting the most out of our platform.'
    },
    {
        question: 'What types of projects can I manage with Ardidflow?',
        answer: 'Whether you’re managing a small freelance assignment, coordinating team projects, or overseeing complex business workflows, Ardidflow is designed to accommodate a wide range of projects. Our tools are versatile enough to manage tasks, track time, and handle invoicing for virtually any project type.'
    },
    {
        question: 'How secure is my data?',
        answer: 'We take data security very seriously. Ardidflow uses industry-standard encryption and security protocols to protect your information. We store your data on secure servers and databases and adhere to strict privacy policies to ensure that your data is safe and confidential. We continuously monitor and update our security measures to ensure that your data remains safe and private.'
    },
];

const spanishQnA = [
    {
        question: '¿Qué es Ardidflow?',
        answer: 'Ardidflow es un completo conjunto de herramientas de gestión de proyectos diseñado específicamente para pequeñas empresas, freelancers y equipos. Ofrece una variedad de características y servicios, incluyendo proyectos ilimitados, seguimiento de tiempo, gestión de empleados, facturación y propuestas, asistencia de IA, chat e integración de calendario, y más, para ayudarte a optimizar tu flujo de trabajo y mantener tus proyectos en marcha. Además, Ardidflow es más fácil de usar que la competencia y está disponible por menos dinero, lo que lo convierte en la elección ideal para profesionales conscientes del presupuesto.'
    },
    {
        question: '¿Cómo me registro para una cuenta?',
        answer: '¡Registrarse es fácil! Simplemente haz clic en el botón [registrarse](/register), completa la información requerida (nombre, correo electrónico, contraseña, etc.) y sigue las instrucciones para crear tu cuenta. Recibirás un correo de confirmación con los siguientes pasos para comenzar a usar nuestros servicios.'
    },
    {
        question: '¿Hay una prueba gratuita disponible?',
        answer: '¡Sí! Ofrecemos un período de prueba gratuito de 30 días para que puedas explorar todas las características de Ardidflow sin ningún costo inicial. Durante la prueba, tendrás acceso a nuestra suite completa de herramientas para determinar si nuestro servicio satisface tus necesidades de gestión de proyectos.'
    },
    {
        question: '¿Qué planes de precios ofrecen?',
        answer: 'Nuestros precios están diseñados para ser asequibles y flexibles para empresas de todos los tamaños. Después de la prueba gratuita, puedes elegir entre varios planes de suscripción que se adapten mejor a las necesidades de tu equipo. [Visita nuestra página de precios](/subscriptions) para obtener información detallada sobre cada plan y cualquier descuento disponible por compromisos a largo plazo.'
    },
    {
        question: '¿Puedo cancelar mi suscripción en cualquier momento?',
        answer: 'Absolutamente. Nuestro servicio es mensual o anual, y puedes cancelar tu suscripción en cualquier momento a través de la configuración de tu cuenta. Nos esforzamos por mantener nuestro proceso transparente y sin complicaciones. Tu cuenta permanecerá activa hasta el final del ciclo de facturación actual, y no se te cobrará el mes siguiente. Si tienes alguna pregunta o necesitas ayuda, por favor contacta a nuestro equipo de soporte.'
    },
    {
        question: '¿Ofrecen soporte al cliente?',
        answer: '¡Sí, estamos aquí para ayudar! Nuestro equipo de soporte está disponible por correo electrónico y chat en vivo durante el horario laboral. Además, nuestro Centro de Ayuda con IA contiene guías, tutoriales y consejos de solución de problemas para ayudarte a sacar el máximo provecho de nuestra plataforma.'
    },
    {
        question: '¿Qué tipos de proyectos puedo gestionar con Ardidflow?',
        answer: 'Ya sea que estés gestionando una pequeña asignación freelance, coordinando proyectos en equipo o supervisando flujos de trabajo empresariales complejos, Ardidflow está diseñado para adaptarse a una amplia variedad de proyectos. Nuestras herramientas son lo suficientemente versátiles como para gestionar tareas, hacer seguimiento del tiempo y manejar la facturación para prácticamente cualquier tipo de proyecto.'
    },
    {
        question: '¿Qué tan seguros están mis datos?',
        answer: 'Tomamos la seguridad de los datos muy en serio. Ardidflow utiliza cifrado y protocolos de seguridad estándar de la industria para proteger tu información. Almacenamos tus datos en servidores y bases de datos seguros y cumplimos con políticas de privacidad estrictas para garantizar que tus datos estén seguros y sean confidenciales. Monitorizamos y actualizamos continuamente nuestras medidas de seguridad para asegurarnos de que tus datos sigan siendo seguros y privados.'
    },
];

const FAQ = ({
    isLoggedIn,
    isCompanyPremium
}) => {
    const { text, language } = useLanguage();
    const { isDesktop, isTablet } = useScreenSize();

    // Choose the appropriate QnA based on the language value
    const qnaContent = language === 'es' ? spanishQnA : englishQnA;

    return (
        <div className={
            classnames('h-100', {
                'scroll-container': !isLoggedIn,
            })
        }>
            <div
                className={classnames({
                    'm-center w-80': isDesktop && !isCompanyPremium,
                })}
            >
                <StyledDiv
                    className={classNames({
                        'desktop': isDesktop,
                        'tablet h-100': isTablet,
                        'phone h-100': !isDesktop && !isTablet,
                        'pa-md': !isLoggedIn || !isDesktop,
                        'mt-md': isLoggedIn && isDesktop,
                    })}
                >
                    <div className={classNames('w-100', {
                        'flex flex-column': isDesktop,
                        'flex-column': !isDesktop,
                    })}>
                        <h1>
                            {text?.faq?.title}
                        </h1>
                        {qnaContent.map((qa, index) => (
                            <div key={index} className={classNames('w-100', {
                                'flex flex-column': isDesktop,
                                'flex-column': !isDesktop,
                            })}>
                                <h2>{qa.question}</h2>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {qa.answer}
                                </ReactMarkdown>
                                <hr />
                            </div>
                        ))}
                    </div>
                </StyledDiv>
            </div>
        </div>
    );
};

export default FAQ;
