import React, { useState, useEffect, useRef } from 'react'
import SubmitModal from '../Core/SubmitModal'
import ConfirmModal from '../Core/ConfirmModal';
import useScreenSize from '../context/useScreenSize'
import { useLanguage } from '../context/LanguageContext';
import ConditionalRender from '../Core/ConditionalRender';
import MultiFields from '../Core/MultiFields';
import { isRequired, composeValidators } from '../utils/validators';
import SelectInput from '../Core/SelectInput';
import CheckBox from '../Core/Inputs/CheckBox';
import CallToAction from '../Core/CallToAction';
import FieldWrapper from '../Core/FieldWrapper';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import colors from '../globalStyles.scss';
import styled from 'styled-components';
import PaginatedData from '../Core/PaginatedData';
import SelectableBox from '../Core/SelectableBox';
import SearchInput from '../Core/Inputs/SearchInput';
import EmployeeItem from '../Project/EmployeeItem';
import { useToast } from '../context/ToastContext';
import { useTheme } from '../context/ThemeContext'

import { updateEvent, deleteEvent } from '../utils/calls'

const StyledEmployeeModal = styled.div`
    width: 100%;
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        width: 30%;
        a, span {
            color: ${colors.white};
        }
    }
`

const CalendarDetailsContainer = ({
    setEventIsOpen,
    eventIsOpen,
    projects,
    setSelectedEvent,
    selectedEvent,
    user,
    isProject,
    isClient = false,
    employees,
    employeesIsLoading,
    fetchEvents,
    projectId,
    eventCategories,
    eventCategoriesIsLoading,
    fetchEventCategories
}) => {
    const { theme } = useTheme();
    const { isDesktop, isPhone } = useScreenSize();
    const { notify } = useToast();
    const { text } = useLanguage();
    const formRef = useRef();

    const [errors, setErrors] = useState({
        startDate: '',
        endDate: ''
    });
    const [selectedSlot, setSelectedSlot] = useState({});

    const [initialProject, setInitialProject] = useState(selectedEvent?.project ? {
        label: selectedEvent?.project?.projectName,
        value: selectedEvent?.project?._id
    } : null);
    const [isForEveryone, setIsForEveryone] = useState(selectedEvent?.forEveryone);
    const [showClient, setShowClient] = useState(selectedEvent?.showClient);
    const [isAllDay, setIsAllDay] = useState(selectedEvent?.allDay);
    const [selectedEmployees, setSelectedEmployees] = useState(selectedEvent?.selectedEmployees || []);
    const [selectedClient, setSelectedClient] = useState({});


    const [selectedProjectId, setSelectedProjectId] = useState({});
    const [searchEmployeeQuery, setSearchEmployeeQuery] = useState('');
    const [employeesList, setEmployeesList] = useState(employees);
    const [selectedColor, setSelectedColor] = useState(selectedEvent?.color || '#1181C1');
    const [categoryInitial, setCategoryInitial] = useState({})
    const [categoryOptions, setCategoryOptions] = useState([]);


    useEffect(() => {
        if (selectedEvent && selectedEvent.startDate) {
            const startDateLocal = new Date(selectedEvent.startDate);
            let endDateLocal;

            // If no endDate is provided, set it to 30 minutes after the startDate
            if (selectedEvent.endDate) {
                endDateLocal = new Date(selectedEvent.endDate);
            } else {
                endDateLocal = new Date(startDateLocal.getTime() + (30 * 60 * 1000)); // Add 30 minutes to startDate
            }

            // Check if event is all day and set time to 5 AM if so
            if (selectedEvent.allDay) {
                startDateLocal.setHours(5, 0, 0, 0); // Set to 5:00 AM
                endDateLocal.setHours(5, 0, 0, 0);   // Set to 5:00 AM
            }

            if (!isNaN(startDateLocal.getTime()) && !isNaN(endDateLocal.getTime())) {
                const formattedStartDate = new Date(startDateLocal.getTime() - (startDateLocal.getTimezoneOffset() * 60000))
                    .toISOString()
                    .split('.')[0] + 'Z';
                const formattedEndDate = new Date(endDateLocal.getTime() - (endDateLocal.getTimezoneOffset() * 60000))
                    .toISOString()
                    .split('.')[0] + 'Z';

                setSelectedSlot({
                    startDate: formattedStartDate,
                    endDate: formattedEndDate
                });
            }
        }
        setIsForEveryone(selectedEvent.forEveryone);
        setShowClient(selectedEvent.showClient);
        setIsAllDay(selectedEvent.allDay);
        setInitialProject(selectedEvent?.project ? {
            label: selectedEvent?.project?.projectName,
            value: selectedEvent?.project?._id
        } : null);
        setSelectedEmployees(selectedEvent?.selectedEmployees || []);
        setSelectedClient(selectedEvent?.project?.client || {});
        setCategoryInitial(selectedEvent?.category ? {
            label: selectedEvent?.category,
            value: selectedEvent?.category
        } : null);
        setCategoryOptions(
            eventCategories?.map((category) => {
                return {
                    label: category,
                    value: category
                }
            })
                ?.filter((x) => x?.value !== 'event')
                ?.filter((x) => x?.value !== 'task')
                ?.filter((x) => x)
        )
    }, [selectedEvent]);

    useEffect(() => {
        if (searchEmployeeQuery) {
            const query = searchEmployeeQuery.toLowerCase();
            const filteredEmployees = employees.filter((employee) => {
                const fullName = `${employee?.firstName} ${employee?.lastName} (${employee.email})`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
        } else {
            setEmployeesList(employees);
        }
    }, [searchEmployeeQuery, employees]);

    useEffect(() => {
        if (selectedEvent?.color) {
            setSelectedColor(selectedEvent.color);
        }
    }, [selectedEvent]);

    const handleChangeColor = (e) => {
        setSelectedColor(e.target.value);
    }



    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    // Custom validation to check if the end date is before the start date
    const validateDates = (startDate, endDate, isAllDay) => {
        let tempErrors = { ...errors };

        // If isAllDay is true, skip end date validation
        if (!isAllDay) {
            const start = moment(startDate);
            const end = moment(endDate);

            // Ensure end date is not before start date
            if (end.isBefore(start)) {
                tempErrors.endDate = text?.calendar?.create?.errors?.endDate;
            } else {
                tempErrors.endDate = ''; // Clear error if validation passes
            }
        } else {
            tempErrors.endDate = '';
        }

        setErrors(tempErrors);
    };

    const handleAddEmployees = (value) => {
        const projectId = value?.value;
        setSelectedProjectId(value);

        const selectedProject = projects.find(project => project.id === projectId);
        if (selectedProject) {
            const assignedEmployeeIds = selectedProject.assignedEmployees.map(emp => emp.id);
            const assignedEmployees = employees
                .filter(emp => assignedEmployeeIds.includes(emp.id))
                .filter(emp => emp.id !== user?.id);
            setSelectedEmployees(assignedEmployees);
        }
    };

    const handleClear = () => {
        // Find the project being cleared
        const clearedProject = projects.find(project => project.id === selectedProjectId?.value);

        if (clearedProject) {
            // Get the list of employee IDs assigned to the cleared project
            const assignedEmployeeIds = clearedProject.assignedEmployees.map(emp => emp.id);

            // Filter out the employees that were assigned to the cleared project
            setSelectedEmployees(prevEmployees =>
                prevEmployees.filter(emp => !assignedEmployeeIds.includes(emp.id))
            );
            setSelectedProjectId({});
            formRef.current?.change('selectProject', null);
        }
    }

    const handleSearchEmployeeChange = (e) => {
        setSearchEmployeeQuery(e.target.value);
    };


    const [assignEmployee, setAssignEmployee] = useState(false);
    const [showEmployeesFilters, setShowEmployeesFilters] = useState(false);

    const handleAssignEmployee = () => {
        setAssignEmployee(!assignEmployee)
    };

    const handleDeleteEvent = async () => {
        try {
            const res = await deleteEvent(selectedEvent.id);
            if (res.status === 200) {
                setEventIsOpen(false);
                notify(text?.calendar?.create?.deleteSuccessMessage, 'success');
                setSelectedSlot({});
                setErrors({
                    startDate: '',
                    endDate: ''
                });
                setIsAllDay(false);
                setIsForEveryone(false);
                setShowClient(false);
                setSelectedProjectId({});
                if (isProject) {
                    fetchEvents(projectId);
                } else {
                    fetchEvents();
                }
            }
        } catch (error) {
            console.error(`Failed to delete event: ${error.message}`);
        }
    }

    const handleEmployeeSelection = (employee) => {
        if (selectedEmployees.some((e) => e._id === employee._id)) {
            setSelectedEmployees((prev) => prev.filter((e) => e._id !== employee._id));
        } else {
            setSelectedEmployees((prev) => [...prev, employee]);
        }
    };

    const handleRemoveEmployee = (employeeId) => {
        // Filter out the employee by id from the selectedEmployees array
        setSelectedEmployees(prevEmployees => prevEmployees.filter(emp => emp.id !== employeeId));
    };

    const handleSubmit = async (values) => {

        if (errors.startDate || errors.endDate) {
            return;
        }

        if (!isAllDay) {
            values.startDate = selectedSlot.startDate;
            values.endDate = selectedSlot.endDate;
        } else {
            values.startDate = selectedSlot.startDate;
            values.endDate = selectedSlot.startDate;
        }
        values.allDay = isAllDay;
        values.forEveryone = isForEveryone;
        values.selectedEmployees = selectedEmployees.map(employee => employee.id);
        values.projectId = values.selectProject?.value || selectedProjectId?.value;
        values.color = selectedColor || '#1181C1';
        values.showClient = showClient || false;

        try {
            const res = await updateEvent(values, selectedEvent.id);
            if (res.status === 200) {
                setEventIsOpen(false);
                notify(text?.calendar?.create?.updateSuccessMessage, 'success');
                setSelectedSlot({});
                setErrors({
                    startDate: '',
                    endDate: ''
                });
                setIsAllDay(false);
                setIsForEveryone(false);
                setShowClient(false);
                setSelectedProjectId({});
                fetchEventCategories();
                setSelectedEmployees([]);
                if (isProject) {
                    fetchEvents(projectId);
                } else {
                    fetchEvents();
                }
            }
        } catch (error) {
            console.error(`Failed to create event: ${error.message}`);
        }
    };

    const colors = {
        "light": [
            '#0F70A9',
            '#A33E49',
            '#008000',
            '#7A6200'
        ],
        "dark": [
            '#0F70A9',
            '#A33E49',
            '#008000',
            '#7A6200'
        ]
    }

    return (
        <>
            <ConditionalRender renderIf={
                user?.roleName === 'Admin' || user?.id === selectedEvent?.createdBy?.id ||
                !isClient
            }>
                <SubmitModal
                    isOpen={eventIsOpen}
                    toggle={() => {
                        setEventIsOpen(!eventIsOpen);
                        setSelectedSlot({});
                        setErrors({
                            startDate: '',
                            endDate: ''
                        })
                        setIsAllDay(false);
                        setIsForEveryone(false);
                        setShowClient(false);
                        setSelectedProjectId({});
                        setSelectedEmployees([]);
                        setSelectedClient({});
                        setSelectedEvent({});
                    }}
                    onClick={handleSubmit}
                    text={text?.calendar?.create?.updateBtn}
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    formEvents={formRef}
                    deleteText={text?.calendar?.create?.deleteEvent}
                    onDelete={handleDeleteEvent}
                >
                    <h4>
                        {text?.calendar?.create?.updateEvent}
                    </h4>
                    <ConditionalRender renderIf={selectedEvent?.createdBy?.id !== user?.id}>
                        <FieldWrapper className='mt-md'
                            name={text?.calendar?.create?.createdBy}
                            label={`${selectedEvent?.createdBy?.firstName} ${selectedEvent?.createdBy?.lastName}`}
                        />
                    </ConditionalRender>
                    <MultiFields
                        className='field-style'
                        name="title"
                        component="input"
                        type="text"
                        label={text?.calendar?.create?.title}
                        block
                        validate={required}
                        initialValue={selectedEvent?.title}
                    />
                    <MultiFields
                        className='field-style'
                        name="description"
                        component="textarea"
                        type="textarea"
                        label={text?.calendar?.create?.description}
                        block
                        validate={required}
                        initialValue={selectedEvent?.description}
                    />
                    <SelectInput
                        className='field-style'
                        name='category'
                        label={text?.inventory?.details?.form?.category}
                        options={categoryOptions}
                        initialValue={categoryInitial}
                        placeholder={text?.inventory?.create?.form?.categoryPlaceholder}
                    />
                    <div className={classnames({
                        'flex': true,
                        'flex-column': isPhone,
                    })}>
                        <div className={classnames({
                            'mr-md w-50': !isPhone
                        })}>
                            <CheckBox
                                checked={isAllDay}
                                label={text?.calendar?.create?.allDayEvent}
                                onChange={() => {
                                    const newIsAllDay = !isAllDay;
                                    setIsAllDay(newIsAllDay);
                                    validateDates(selectedSlot.startDate, selectedSlot.endDate, newIsAllDay);
                                }}
                            />
                            <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                                <CheckBox
                                    className='mt-md'
                                    checked={isForEveryone}
                                    disabled={isProject}
                                    label={text?.calendar?.create?.forEveryone}
                                    onChange={(value) => {
                                        setIsForEveryone(!isForEveryone);
                                        if (value) {
                                            setSelectedEmployees([]);
                                            setSelectedProjectId({});
                                            formRef.current?.change('selectProject', null);
                                        }
                                    }}
                                />
                            </ConditionalRender>
                            <MultiFields
                                className="field-style"
                                name="startDate"
                                component="date"
                                type="date"
                                label={text?.calendar?.create?.startDate}
                                block
                                onChange={({ value }) => {
                                    const formattedStartDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
                                    setSelectedSlot((prev) => ({
                                        ...prev,
                                        startDate: formattedStartDate,
                                    }));
                                    validateDates(formattedStartDate, selectedSlot.endDate, isAllDay);
                                }}
                                initialValue={selectedSlot.startDate}
                                withTimePicker={!isAllDay}
                            />
                            {errors.startDate && <span className="error mb-md">{errors.startDate}</span>}
                            <ConditionalRender renderIf={!isAllDay}>
                                <MultiFields
                                    className="field-style"
                                    name="endDate"
                                    component="date"
                                    type="date"
                                    label={text?.calendar?.create?.endDate}
                                    block
                                    onChange={({ value }) => {
                                        const formattedEndDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
                                        setSelectedSlot((prev) => ({
                                            ...prev,
                                            endDate: formattedEndDate,
                                        }));

                                        // Pass updated start and end date for validation
                                        validateDates(selectedSlot.startDate, formattedEndDate, isAllDay);
                                    }}
                                    initialValue={selectedSlot.endDate}
                                    withTimePicker
                                />
                                {errors.endDate && <span className="error mb-md">{errors.endDate}</span>}
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isForEveryone}>

                                <SelectInput
                                    menuPlacement='top'
                                    name='selectProject'
                                    isSearchable
                                    isValidNewOption={() => false}
                                    onChange={handleAddEmployees}
                                    initialValue={initialProject}
                                    placeholder={text?.tasks?.create?.project}
                                    handleClear={handleClear}
                                    disabled={isProject}
                                    width={100}
                                    options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                        return {
                                            label: x?.projectName,
                                            value: x?._id
                                        }
                                    })}
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isEmpty(selectedClient)}>
                                <CheckBox
                                    className='mt-md'
                                    checked={showClient}
                                    label={text?.calendar?.create?.showClient}
                                    onChange={() => {
                                        setShowClient(!showClient);
                                    }}
                                />
                            </ConditionalRender>
                            <ConditionalRender renderIf={!isForEveryone}>
                                <p className='mt-md mb-sm'>
                                    {text?.calendar?.create?.additionalEmails}
                                </p>
                                <CallToAction
                                    wrapperWidth={'100%'}
                                    type="button"
                                    onClick={handleAssignEmployee}
                                    text={text?.calendar?.create?.addEmails}
                                />

                                {/* Modal */}
                                <ConditionalRender renderIf={assignEmployee}>
                                    <ConfirmModal
                                        width={isDesktop ? '80%' : '100%'}
                                        height={isDesktop ? '80%' : '100%'}
                                        toggle={handleAssignEmployee}
                                        isOpen={assignEmployee}

                                        btnStyles={{
                                            color: colors.blue
                                        }}
                                    >
                                        <StyledEmployeeModal>
                                            <div
                                                className={classnames({
                                                    'flex w-100': true,
                                                    'flex-column': isPhone,
                                                })}
                                            >
                                                {/* Left Section: Available Employees */}
                                                <div
                                                    className={classnames({
                                                        'w-50': !isPhone,
                                                        'mr-md': !isPhone,
                                                    })}
                                                >
                                                    <h4 className='flex'>
                                                        {text?.calendar?.create?.employeesLookup}
                                                    </h4>
                                                    {/* Filter controls & search input */}
                                                    <ConditionalRender
                                                        isLoading={employeesIsLoading}
                                                        loadingCount={employees?.length}
                                                        renderIf={!isEmpty(employees)}
                                                    >
                                                        <div className="flex flex-column filters mt-md">
                                                            <SearchInput
                                                                value={searchEmployeeQuery}
                                                                onChange={handleSearchEmployeeChange}
                                                                placeHolder={text?.employees?.landing?.search}
                                                            />
                                                        </div>
                                                    </ConditionalRender>

                                                    {/* Paginated available employees */}
                                                    <PaginatedData
                                                        className="mt-md"
                                                        data={employeesList?.filter(
                                                            (employee) =>
                                                                employee?._id !== user?._id &&
                                                                !selectedEmployees.some((sel) => sel._id === employee._id)
                                                        )}
                                                        dataIsLoading={employeesIsLoading}
                                                        itemsPerPage={5}
                                                        noDataText={text?.employees?.landing?.noData}
                                                        searching={searchEmployeeQuery}
                                                        showLightColors
                                                        ItemComponent={({ item }) => (
                                                            <EmployeeItem
                                                                key={item?._id}
                                                                employee={item}
                                                                selectedEmployees={selectedEmployees}
                                                                handleEmployeeSelection={handleEmployeeSelection}
                                                                // handleAdminSelection={handleAdminSelection}
                                                                text={text}
                                                            // You can include any additional props needed by EmployeeItem
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                {/* Right Section: Selected Employees */}
                                                <div
                                                    className={classnames({
                                                        'w-50': !isPhone,
                                                        'ml-md': !isPhone,
                                                    })}
                                                >
                                                    <h4 className="mb-md">{text?.paginated?.selected}</h4>
                                                    <div>
                                                        {selectedEmployees.map((employee) => (
                                                            <EmployeeItem
                                                                key={employee._id}
                                                                employee={employee}
                                                                selectedEmployees={selectedEmployees}
                                                                handleEmployeeSelection={handleEmployeeSelection}
                                                                //   handleAdminSelection={handleAdminSelection}
                                                                text={text}
                                                            // Include additional props as needed
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </StyledEmployeeModal>
                                    </ConfirmModal>
                                </ConditionalRender>
                            </ConditionalRender>

                            <div className="mt-md">
                                <label className='mb-sm' htmlFor={'color'}>
                                    <b>
                                        {'Color'}
                                    </b>
                                </label>
                                <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                                    {colors[theme]?.map((color, index) => (
                                        <label key={index} style={{ cursor: 'pointer' }}>
                                            <input
                                                type="radio"
                                                name="color"
                                                value={color}
                                                style={{ display: 'none' }}
                                                onChange={handleChangeColor}
                                                checked={selectedColor === color}
                                            />
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: '24px',
                                                    height: '24px',
                                                    backgroundColor: color,
                                                    borderRadius: '50%',
                                                    border: selectedColor === color ? `2px solid` : `1px solid`,
                                                    borderColor: selectedColor === color ? colors.white : colors.darkGray,
                                                    transform: selectedColor === color ? 'scale(1.5)' : 'none',
                                                }}
                                            ></span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className={classnames({
                            'mr-md w-50': !isPhone
                        })}>
                            <ConditionalRender renderIf={!isForEveryone}>
                                <ConditionalRender renderIf={!isEmpty(selectedEmployees)}>
                                    <h4 className='mt-md'>{text?.calendar?.create?.assignedEmployees}</h4>
                                    <div className='flex flex-column mt-md'>
                                        {
                                            selectedEmployees?.map((employee) => (
                                                <SelectableBox
                                                    block
                                                    key={employee._id}
                                                    className='flex items-center'
                                                    onClickRemove={() => handleRemoveEmployee(employee.id)}
                                                    removeButton
                                                >
                                                    <p className='ml-md'>
                                                        {`${employee.firstName} ${employee.lastName} (${employee.email})`}
                                                    </p>
                                                </SelectableBox>
                                            ))
                                        }
                                    </div>
                                </ConditionalRender>
                            </ConditionalRender>
                        </div>
                    </div>
                </SubmitModal>
            </ConditionalRender>

            {/* Read only */}
            <ConditionalRender renderIf={
                user?.roleName !== 'Admin' ||
                (user?.roleName !== 'Admin' && user?.id !== selectedEvent?.createdBy?.id) ||
                isClient
            }>
                <ConfirmModal
                    width={isDesktop ? '80%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                    toggle={() => {
                        setEventIsOpen(!eventIsOpen);
                        setSelectedSlot({});
                        setErrors({
                            startDate: '',
                            endDate: ''
                        })
                        setIsAllDay(false);
                        setIsForEveryone(false);
                        setSelectedProjectId({});
                        setSelectedEmployees([]);
                        setSelectedClient({});
                        setSelectedEvent({});
                    }}
                    isOpen={eventIsOpen}
                >
                    <div className='w-100'>
                        <h4>
                            {selectedEvent?.title}
                        </h4>
                        <FieldWrapper
                            className='mt-md'
                            name={text?.calendar?.create?.description}
                            label={selectedEvent?.description}
                        />
                        <FieldWrapper
                            className='mt-md'
                            name={text?.calendar?.create?.allDayEvent}
                            label={selectedEvent?.allDay ? text?.proposals?.landing?.yes : text?.proposals?.landing?.no}
                        />
                        <FieldWrapper
                            className='mt-md'
                            name={text?.calendar?.create?.startDate}
                            label={moment(selectedEvent?.startDate).format('MM/DD/YYYY hh:mm A')}
                        />
                        <ConditionalRender renderIf={!selectedEvent?.allDay}>
                            <FieldWrapper
                                className='mt-md'
                                name={text?.calendar?.create?.endDate}
                                label={moment(selectedEvent?.endDate).format('MM/DD/YYYY hh:mm A')}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isProject}>
                            <ConditionalRender renderIf={!isEmpty(selectedEvent?.project)}>
                                <FieldWrapper
                                    className='mt-md'
                                    name={text?.tasks?.home?.filters?.project}
                                    label={selectedEvent?.project?.projectName}
                                    goto={`/project/details?id=${selectedEvent?.project?._id}`}
                                />
                            </ConditionalRender>
                        </ConditionalRender>
                        <FieldWrapper
                            className='mt-md'
                            name={text?.calendar?.create?.createdBy}
                            label={`${selectedEvent?.createdBy?.firstName} ${selectedEvent?.createdBy?.lastName}`}
                        />
                    </div>
                </ConfirmModal>
            </ConditionalRender>
        </>
    )
}

export default CalendarDetailsContainer
