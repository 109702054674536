import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from 'react'
import { NumericFormat } from 'react-number-format';
import SubmitModal from '../Core/SubmitModal'
import { isRequired, composeValidators } from '../utils/validators';
import CreateNewClientContainer from '../Clients/CreateNewClientContainer';
import { GlobalContext } from '../context/GlobalContext';
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import MultiFields from '../Core/MultiFields';
import arrayMutators from 'final-form-arrays';
import Wrapper from '../Core/Wrapper';
import { useDropzone } from 'react-dropzone';
import RenderImages from '../Core/RenderImages';
import base64ToBlob from '../utils/base64ToBlob';
import { FormSpy, Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import SelectInput from '../Core/SelectInput';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';
import CreatableMultiInput from '../Core/Inputs/CreatableMultiInput';
import FieldsWrapper from './FieldsWrapper';
import { useTheme } from '../context/ThemeContext';
import classnames from 'classnames';

import {
    saveProposalAsDraft,
    createProposalImages,
    createProposal,
    sendProposalEmails,
    updateUpdatedDate,
    updateHistory
} from '../utils/calls';
import colors from '../globalStyles.scss';

import styled from 'styled-components';


const StyledDiv = styled.div`

    .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dropzone {
            display: flex;
            flex-direction: column;
            margin: 0.5em 0;
            padding: 1em;
            background: ${props => props.theme === 'dark' ? colors.secondary : colors.darkGray};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${colors.lightGray};
            }
            span {
                font-family: ${colors.openSans};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }

`;


const CreateNewProposalContainer = ({
    isOpen,
    toggle,
    projects,
    adminProjects,
    project,
    clients,
    isProject,
    projectId,
    fetchAllProposals,
    user,
    handleGetProject
}) => {
    const { fetchAllClients, MAX_IMAGES, UPLOAD_LIMIT } = useContext(GlobalContext);
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const { theme } = useTheme();
    const { text } = useLanguage();
    const { notify } = useToast();
    const [spyValues, setSpyValues] = useState();
    const [total, setTotal] = useState(0);
    const [newClient, setNewClient] = useState({});

    const formRef = useRef();

    const [isFormReady, setIsFormReady] = useState(false);



    useEffect(() => {
        if (formRef.current && !isFormReady) {
            setIsFormReady(true);
        }
    }, [formRef?.current]);

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    const [initialProjectValue, setInitialProjectValue] = useState(() => {
        if (!isProject) {
            if (user?.roleName === 'Admin') {
                return { label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' };
            }
            const firstProject = projects?.find((x) => x?._id === projectId) || projects?.[0];
            return { label: firstProject?.projectName || text?.inventory?.details?.form?.unassigned, value: firstProject?._id || '000000000000000000000000' };
        }
        const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
        return { label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' };
    });


    useEffect(() => {
        if (!isProject) {
            if (user?.roleName === 'Admin') {
                setInitialProjectValue({ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' });
            } else {
                const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
                setInitialProjectValue({ label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' });
            }
        } else {
            const project = projects?.find((x) => x?._id === projectId) || projects?.[0];
            setInitialProjectValue({ label: project?.projectName || text?.inventory?.details?.form?.unassigned, value: project?._id || '000000000000000000000000' });
        }
    }, [projectId, isProject, projects, text, user]);

    const [initialClientValue, setInitialClientValue] = useState(() => {
        const selectedProject = spyValues?.values?.projectId && projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
        return selectedProject?.client && {
            label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
            value: selectedProject?.client?._id
        };
    })

    useMemo(() => {
        if (initialProjectValue) {
            const selectedProject = projects?.find((x) => x?._id === spyValues?.values?.projectId?.value);
            setInitialClientValue(selectedProject?.client && {
                label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                value: selectedProject?.client?._id
            });
        }
    }, [initialProjectValue])

    useEffect(() => {
        if (newClient) {
            formRef?.current?.change('clientId', {
                label: `${newClient?.name} (${newClient?.email})`,
                value: newClient?._id
            }
            )
        }
    }, [newClient])

    useEffect(() => {
        if (isProject && isFormReady && !isEmpty(project?.client)) {
            formRef.current.change('clientId', {
                label: `${project?.client?.name} (${project?.client?.email})`,
                value: project?.client?._id
            });
        }
    }, [isProject, isFormReady, project]);

    const [selectedImages, setSelectedImages] = useState([]);
    const [imageError, setImageError] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const removeImage = (imageIndex) => {
        setSelectedImages((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            return updatedImages;
        });
    };

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file => file.type.startsWith('image/'));

        if (imageFiles?.length !== acceptedFiles?.length) {
            setImageError(text?.projects?.details?.validations?.onlyImages);
            return;
        }

        setSelectedImages((prevImages) => {
            if (prevImages?.length >= MAX_IMAGES) {
                setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${MAX_IMAGES} ${text?.projects?.create?.uploader?.images}`);
                return prevImages;
            }

            // If we already have x new images, block any further uploads
            const newImagesCount = prevImages?.filter(img => img.new)?.length;
            if (newImagesCount >= UPLOAD_LIMIT) {
                setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
                return prevImages;
            }

            // Ensure that only x new images are added at a time
            const remainingSlots = Math.min(UPLOAD_LIMIT - newImagesCount, MAX_IMAGES - prevImages?.length);
            const filesToUpload = imageFiles.slice(0, remainingSlots);

            if (filesToUpload?.length === 0) return prevImages;

            Promise.all(
                filesToUpload.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onloadend = () => {
                            resolve({ file, url: reader.result, originalName: file.name });
                        };

                        reader.readAsDataURL(file);
                    });
                })
            ).then((newUploadedImages) => {
                setSelectedImages([
                    ...newUploadedImages.map((img) => ({ url: img.url, originalName: img.originalName, new: true })),
                    ...prevImages,
                ]);
                setImageError('');
            });

            return prevImages;
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg']
        },
        multiple: true
    });

    const uploadImages = async (proposalId) => {
        try {
            // Convert and upload product images
            const imageUploadPromises = selectedImages?.map(async (image) => {
                const imageBlob = base64ToBlob(image?.url); // Convert base64 data to a Blob
                const formData = new FormData();
                formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                formData.append("proposalId", proposalId);

                console.log("Uploading image...");

                // Send the image to a separate endpoint
                const res = await createProposalImages(formData);

                if (res?.status === 200) {
                    return res;
                } else {
                    throw new Error("Image upload failed");
                }
            }) || [];

            // Wait for all images to be uploaded
            await Promise.all(imageUploadPromises);
        } catch (error) {
            console.error("Error uploading images:", error);
        }
    };

    const onSubmit = async (values) => {
        let body = {}
        body.projectId = values?.projectId?.value;
        body.clientId = values?.clientId?.value;
        body.clientEmail = values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        body.yourEmail = user?.email
        if (!isEmpty(values?.additionalEmails)) {
            body.additionalEmails = values?.additionalEmails.map(x => x.value)
        }
        if (values?.emailSubject) {
            body.emailSubject = values?.emailSubject
        }
        body.title = values?.title
        body.emailMessage = values?.emailMessage
        body.fields = values?.fields?.map(x => {
            return {
                description: x?.description || '',
                amount: x?.amount || 0,
                notes: x?.notes || ''
            }
        })
        body.totalCost = total
        try {
            toggle()
            const res = await createProposal(body)
            if (res.status === 200) {
                const proposalId = res.data?._id
                uploadImages(proposalId).then(() => {
                    return sendProposalEmails(proposalId)
                })
                if (isProject && projectId) {
                    updateUpdatedDate(projectId)
                    handleGetProject(`${text?.projects?.history?.proposalCreated} ${values?.title}`)
                }
                if (!isProject && values?.projectId?.value !== '000000000000000000000000') {
                    updateUpdatedDate(values?.projectId?.value)
                    updateHistory(values?.projectId?.value, `${text?.projects?.history?.proposalCreated} ${values?.title}`)
                }
                fetchAllProposals()
                notify(text?.notificationsUI?.proposal?.submitted, 'success')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const saveAsDraft = async () => {
        let values = {};
        if (spyValues?.values?.projectId) {
            values.projectId = spyValues?.values?.projectId?.value;
        }
        if (spyValues?.values?.clientId) {
            values.clientId = spyValues?.values?.clientId?.value;
            values.clientEmail = spyValues?.values?.clientId?.label.match(/\(([^)]+)\)/)?.[1] || '';
        }
        if (!isEmpty(spyValues?.values?.additionalEmails)) {
            values.additionalEmails = spyValues?.values?.additionalEmails.map(x => x.value)
        }
        if (spyValues?.values?.emailSubject) {
            values.emailSubject = spyValues?.values?.emailSubject
        }
        if (spyValues?.values?.emailMessage) {
            values.emailMessage = spyValues?.values?.emailMessage
        } else {
            values.emailMessage = ''
        }
        if (spyValues?.values?.title) {
            values.title = spyValues?.values?.title
        }
        if (total > 0) {
            values.totalCost = total
        }
        if (!isEmpty(spyValues?.values?.fields)) {
            values.fields = spyValues?.values?.fields.map(x => {
                return {
                    description: x?.description || '',
                    amount: x?.amount || 0,
                    notes: x?.notes || ''
                }
            })
        } else {
            values.fields = []
        }

        try {
            toggle()
            const res = await saveProposalAsDraft(values)
            if (res.status === 200) {
                const proposalId = res.data?._id
                if (isProject) {
                    updateUpdatedDate(projectId)
                    handleGetProject(`${text?.projects?.history?.proposalSavedAsDraft} ${spyValues?.values?.title}`)
                }
                if (!isProject && spyValues?.values?.projectId?.value !== '000000000000000000000000') {
                    updateUpdatedDate(spyValues?.values?.projectId?.value)
                    updateHistory(spyValues?.values?.projectId?.value, `${text?.projects?.history?.proposalSavedAsDraft} ${spyValues?.values?.title}`)
                }
                await uploadImages(proposalId)
                if (isProject) {
                    fetchAllProposals(projectId)
                } else {
                    fetchAllProposals()
                }
                notify(text?.notificationsUI?.proposal?.saved, 'success')
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!isEmpty(spyValues?.values?.fields)) {
            const total = spyValues?.values?.fields?.reduce((acc, curr) => {
                if (curr.amount === undefined) return acc
                return acc + curr.amount
            }, 0)
            setTotal(total)
        }
    }, [spyValues?.values?.fields]);

    const [addClient, setAddClient] = useState(false)

    return (
        <>
            <SubmitModal
                onClick={onSubmit}
                text={text?.proposals?.create?.send}
                toggle={toggle}
                isOpen={isOpen}
                saveAsDraft={spyValues?.values?.title ? saveAsDraft : null}
                saveAsDraftText={text?.proposals?.create?.save}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                mutators={{
                    ...arrayMutators,
                }}
            >
                <FormSpy subscription={{ values: true }}>
                    {({ form, values }) => {
                        formRef.current = form;
                        if (!values.fields || values.fields.length === 0) {
                            form.change('fields', [
                                {
                                    description: '',
                                    amount: null,
                                    notes: ''
                                }
                            ]);
                        }
                        return null;
                    }}
                </FormSpy>
                <FormSpy subscription={{ values: true }} onChange={(values) => setSpyValues(values)} />
                <StyledDiv
                    theme={theme}
                    className={isDesktop ? 'desktop' : isTablet ? 'tablet' : 'phone'}
                >
                    <h4>{text?.proposals?.landing?.createButton}</h4>
                    <FormSpy subscription={{ values: true }}>
                        {({ form, values }) => {

                            return (
                                <>
                                    <SelectInput
                                        className='field-style'
                                        width={isDesktop ? 30 : isTablet ? 50 : 80}
                                        menuPlacement='bottom'
                                        name='projectId'
                                        label={text?.proposals?.create?.project}
                                        isClearable={false}
                                        validate={required}
                                        disabled={isProject}
                                        initialValue={initialProjectValue}
                                        placeholder={text?.tasks?.create?.project}
                                        isSearchable
                                        isValidNewOption={() => false}
                                        controlWidth='100%'
                                        options={[
                                            ...(user?.roleName === 'Admin' ? [{ label: text?.inventory?.details?.form?.unassigned, value: '000000000000000000000000' }] : []),
                                            ...(adminProjects ? adminProjects?.filter((x) => x.projectStatus !== 'completed')?.map((x) => ({
                                                label: x?.projectName,
                                                value: x?.id
                                            })) : [])
                                        ]}
                                        onChange={(selectedOption) => {
                                            const selectedProject = projects?.find((x) => x?._id === selectedOption.value);
                                            const newClientValue = selectedProject?.client && {
                                                label: `${selectedProject?.client?.name} (${selectedProject?.client?.email})`,
                                                value: selectedProject?.client?._id
                                            };
                                            form.change('clientId', newClientValue);
                                        }}
                                    />

                                    <MultiFields
                                        className='field-style'
                                        name="title"
                                        component="text"
                                        type="text"
                                        label={text?.proposals?.create?.title}
                                        validate={required}
                                        block
                                    // showLightColors
                                    />

                                    <div className='email_wrapper mb-md' style={{
                                        backgroundColor: theme === 'dark' ? colors.black : colors.lightGray,
                                        padding: '1em',
                                    }}>
                                        <FormSpy subscription={{ values: true }}>
                                            {({ values }) => {
                                                const selectedProject = projects?.find((x) => x?._id === values?.projectId?.value);

                                                return (
                                                    <ConditionalRender renderIf={true}>
                                                        <p className='small'>
                                                            {text?.proposals?.create?.emailDescription}
                                                        </p>
                                                        <p className='small flex'>
                                                            {text?.proposals?.details?.textFour}
                                                        </p>
                                                        <div className={classnames({
                                                            'flex items-center mt-md': isDesktop || isTablet,
                                                        })} >
                                                            <SelectInput
                                                                width={isDesktop ? 30 : isTablet ? 50 : 100}
                                                                // showLightColors
                                                                menuPlacement='bottom'
                                                                initialValue={initialClientValue}
                                                                name='clientId'
                                                                label={text?.projects?.create?.client}
                                                                isClearable={false}
                                                                validate={required}
                                                                placeholder={text?.proposals?.create?.selectClient}
                                                                isSearchable
                                                                isValidNewOption={() => false}
                                                                controlWidth='100%'
                                                                options={clients?.map((client) => ({
                                                                    label: `${client.name} (${client.email})`,
                                                                    value: client?._id
                                                                }))}
                                                            />
                                                            <CallToAction
                                                                text={text?.client?.create?.title}
                                                                type='button'
                                                                onClick={() => setAddClient(!addClient)}
                                                                style={isDesktop || isTablet ? {
                                                                    alignSelf: 'flex-end',
                                                                    marginLeft: '1em'
                                                                } : {
                                                                    marginTop: '1em'
                                                                }}
                                                            />
                                                        </div>
                                                    </ConditionalRender>
                                                );
                                            }}
                                        </FormSpy>
                                        <FormSpy subscription={{ values: true }}>
                                            {({ values }) => {
                                                return (
                                                    <ConditionalRender renderIf={!isEmpty(values?.clientId)}>
                                                        <Field name="additionalEmails">
                                                            {({ input, meta }) => (
                                                                <CreatableMultiInput
                                                                    className='field-style'
                                                                    input={input}
                                                                    meta={meta}
                                                                    isClearable={true}
                                                                    // showLightColors
                                                                    placeHolder={text?.proposals?.create?.additionalEmails}
                                                                    width={'30%'}
                                                                />
                                                            )}
                                                        </Field>
                                                    </ConditionalRender>
                                                );
                                            }}
                                        </FormSpy>
                                        <MultiFields
                                            className='field-style'
                                            name="emailSubject"
                                            component="text"
                                            type="text"
                                            label={text?.proposals?.create?.emailSubject}
                                            block
                                        // showLightColors
                                        />
                                        <MultiFields
                                            className='field-style'
                                            name="emailMessage"
                                            component="textarea"
                                            type="text"
                                            label={text?.proposals?.create?.emailMessage}
                                            block
                                        // showLightColors
                                        />
                                    </div>
                                </>
                            );
                        }}
                    </FormSpy>
                    <FieldsWrapper
                        name={'fields'}
                        // showLightColors
                        validate={required}
                    />

                    <ConditionalRender renderIf={!isEmpty(spyValues?.values?.fields)}>
                        <div>
                            <div className='field-style'>
                                <label className='mb-sm b' style={{
                                    color: theme === 'dark' ? colors.white : colors.black
                                }}>
                                    {text?.proposals?.create?.total}
                                </label>
                                <div className='custom-field'>
                                    <NumericFormat
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        isNumericString={true}
                                        name={'total'}
                                        value={total}
                                        placeholder={'$'}
                                        disabled
                                        style={{
                                            color: theme === 'dark' ? colors.white : colors.black
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ConditionalRender>

                    <Wrapper
                        title={text?.projects?.details?.designs}
                        button={
                            <div className='image-uploader'>
                                <div
                                    className='dropzone'
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <span>
                                        {text?.inventory?.details?.uploadImages}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        {
                            !isEmpty(imageError) && (
                                <span style={{
                                    marginTop: '1em',
                                    color: colors.red
                                }}>
                                    {imageError}
                                </span>
                            )
                        }
                        <ConditionalRender renderIf={!isEmpty(selectedImages)}>
                            <RenderImages
                                images={selectedImages}
                                removeImage={removeImage}
                                openModal={openCarousel}
                                closeModal={closeModal}
                                setActiveIndex={setActiveIndex}
                                activeIndex={activeIndex}
                                isModalOpen={isModalOpen}
                            />
                        </ConditionalRender>

                        <ConditionalRender renderIf={isEmpty(selectedImages)}>
                            <p className='mb-md'>
                                {text?.projects.create?.notFoundMsgs?.images}
                            </p>
                        </ConditionalRender>
                    </Wrapper>
                </StyledDiv>
            </SubmitModal>
            <ConditionalRender renderIf={addClient}>
                <CreateNewClientContainer
                    isOpen={addClient}
                    toggle={() => setAddClient(false)}
                    user={user}
                    fetchAllClients={fetchAllClients}
                    setNewClient={setNewClient}
                    clients={clients}
                />
            </ConditionalRender>
        </>
    )
}

export default CreateNewProposalContainer