import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { GlobalContext } from '../context/GlobalContext';

import FormActions from '../Core/FormActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import ConfirmModal from '../Core/ConfirmModal';
import { v4 as uuidv4 } from 'uuid';
import SelectInput from '../Core/SelectInput';
import { isRequired } from '../utils/validators';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import ProductForm from './ProductForm';
import HoverPopup from '../Core/HoverPopup';
import {
    createProduct,
    createProductImages,
    updateFields,
    updateColumns,
    updateUpdatedDate,
    updateHistory
} from '../utils/calls';
import colors from '../globalStyles.scss'
import base64ToBlob from '../utils/base64ToBlob';
import { capitalize } from 'lodash';
import { toCamelCase } from '../utils/helperFunctions';
import { isEmpty } from 'lodash';
import ConditionalRender from '../Core/ConditionalRender';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';

import Wrapper from '../Core/Wrapper';

const InventoryCreateContainer = ({
    fetchInventoryProducts,
    inventory,
    categories,
    fetchInventoryCategories,
    fieldNames,
    fetchAttributes,
    fetchFieldNames,
    inventoryColumns,
    projects,
    fetchCompanyProjects,
    isCreateProject,
    user,
    newProject,
    fetchTemplates,
    templates,
    templatesIsLoading,
    company,
    setInventoryIsCreating,

    title,
    info,
    toggle,
    toggleState = true,
    projectIsLoading = false
}) => {
    const { UPLOAD_LIMIT, MAX_IMAGES } = useContext(GlobalContext);
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [products, setProducts] = useState(
        isCreateProject ? [] :
            [{ id: 0, productId: uuidv4() }]
    );
    const [spyValues, setSpyValues] = useState();

    const [duplicatedFields, setDuplicatedFields] = useState({});

    const [uploadedImages, setUploadedImages] = useState({});
    const [currentImagesLength, setCurrentImagesLength] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [resetChild, setResetChild] = useState(false);
    const [formattedFieldNames, setFormattedFieldNames] = useState(fieldNames);
    const [fieldNameAndValues, setFieldNameAndValues] = useState([]);
    const [columnNameAndValues, setColumnNameAndValues] = useState(inventoryColumns);
    const [toggleModal, setToggleModal] = useState(false);

    const [imageError, setImageError] = useState({});


    const [hasSelectedProjectId, setHasSelectedProjectId] = useState(null)
    const [projectIdValue, setProjectIdValue] = useState(null);
    const [projectInitialValue, setProjectInitialValue] = useState();


    const navigate = useNavigate();
    const location = useLocation();
    const { id } = queryString.parse(location.search);

    useEffect(() => {
        const findProject = projects && projects?.find((x) => x?.id === id);
        setHasSelectedProjectId(!isEmpty(findProject) ? findProject : null)
    }, [id])

    // Update projectIdValue when hasSelectedProjectId changes
    useEffect(() => {
        if (!isEmpty(hasSelectedProjectId)) {
            setProjectIdValue({
                label: hasSelectedProjectId.projectName,
                value: hasSelectedProjectId.id,
            });
        }

    }, [hasSelectedProjectId]);

    useMemo(() => {
        if (!id && user?.roleName === 'Admin') {
            setProjectInitialValue({
                label: text?.inventory?.landing?.table?.columns?.unassigned,
                value: '000000000000000000000000'
            })
        }
    }, [id])


    const onSubmit = async (values) => {
        if (!isEmpty(products)) {
            if (isCreateProject) {
                values.projectId = {
                    value: newProject?.id,
                    label: newProject?.projectName
                }
            }
            try {
                setIsLoading(true); // Set loading state to true
                // Filter out products without an id
                setInventoryIsCreating && setInventoryIsCreating(true);
                const filteredProducts = values?.products?.filter((product) => product?.id);

                const processedProducts = filteredProducts?.map((product) => {
                    const { images, advanceFields, ...productData } = product;

                    if (product?.inventoryCategory) {
                        productData.inventoryCategory = productData?.inventoryCategory?.value?.toLowerCase();
                    } else {
                        productData.inventoryCategory = 'Uncategorized';
                    }

                    if (!product?.inventoryQuantity?.value) {
                        productData.inventoryQuantity = {
                            value: 0,
                        }
                    }

                    if (advanceFields) {
                        const processedAdvanceFields = {};

                        Object.keys(advanceFields).forEach((key) => {
                            const parts = key.split('-');
                            const prefix = parts[0];
                            const attribute = parts[parts.length - 1];

                            if (prefix === 'length' && attribute !== 'unit') {
                                const sizeValue = advanceFields[key];
                                const unitKey = `${parts[0]}-${parts[1]}-unit`;
                                const unitValue = advanceFields[unitKey]?.value || 'ft';

                                processedAdvanceFields[key] = `${sizeValue}-${unitValue}`;
                            } else if ((prefix === 'weight' && attribute !== 'unit')) {
                                const sizeValue = advanceFields[key];
                                const unitKey = `${parts[0]}-${parts[1]}-unit`;
                                const unitValue = advanceFields[unitKey]?.value || 'lb';

                                processedAdvanceFields[key] = `${sizeValue}-${unitValue}`;
                            } else if ((prefix === 'volume' && attribute !== 'unit')) {
                                const sizeValue = advanceFields[key];
                                const unitKey = `${parts[0]}-${parts[1]}-unit`;
                                const unitValue = advanceFields[unitKey]?.value || 'l';

                                processedAdvanceFields[key] = `${sizeValue}-${unitValue}`;
                            } else if (prefix === 'number' || prefix === 'amount') {
                                const value = advanceFields[key];
                                processedAdvanceFields[key] = isNaN(value) ? value : Number(value);
                            } else if (attribute !== 'unit') {
                                // Copy other fields that are not unit fields
                                processedAdvanceFields[key] = advanceFields[key];
                            }
                            // Unit fields are not copied as they are already processed with their respective length fields
                        });

                        productData.advanceFields = processedAdvanceFields;
                    }

                    return productData;
                });

                let processedData = {
                    ...values,
                    products: processedProducts,
                };

                // Create the product
                const createProductResponse = await createProduct(processedData);

                if (createProductResponse?.status === 200) {
                    // Process and upload product images
                    const imageUploadPromises = products.map(async (product, index) => {
                        const productImages = uploadedImages[index] || [];

                        const imageUploadPromises = productImages?.map((image) => {
                            const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
                            const formData = new FormData();
                            formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
                            formData.append("productId", product?.productId);

                            console.log("Uploading image...");

                            // Send the image to a separate endpoint
                            return createProductImages(formData)
                                .then((imageRes) => {
                                    if (imageRes?.status === 200) {
                                        console.log("Image uploaded");
                                    } else {
                                        throw new Error("Image upload failed");
                                    }
                                });
                        });

                        return Promise.all(imageUploadPromises);
                    });

                    // Wait for all image upload promises to resolve
                    await Promise.all(imageUploadPromises);
                    const projectId = values.projectId;

                    // Update fields and columns
                    const fieldRes = await updateFields(fieldNameAndValues, projectId);
                    const columnsRes = await updateColumns(columnNameAndValues, projectId);

                    if (fieldRes.status !== 200) {
                        console.error('console: fieldRes', fieldRes?.data?.message);
                    }
                    if (columnsRes.status !== 200) {
                        console.error('console: columnsRes', columnsRes?.data?.message);
                    }

                    setIsLoading(false); // Set loading state to false after the form submission
                    setInventoryIsCreating && setInventoryIsCreating(false);

                    // Fetch inventory products, categories, and attributes
                    const fetchPromises = [];

                    if (fetchInventoryProducts) {
                        fetchPromises.push(fetchInventoryProducts());
                    }
                    if (fetchInventoryCategories) {
                        fetchPromises.push(fetchInventoryCategories());
                    }
                    if (fetchAttributes) {
                        fetchPromises.push(fetchAttributes());
                    }
                    if (fetchFieldNames) {
                        fetchPromises.push(fetchFieldNames());
                    }
                    if (projectId?.value !== '000000000000000000000000') {
                        fetchPromises.push(updateUpdatedDate(projectId?.value));
                        fetchPromises.push(updateHistory(projectId?.value, text?.projects?.history?.inventoryCreated));
                    }

                    await Promise.all(fetchPromises);
                    notify(text?.notificationsUI?.inventory?.created, 'success');
                    if (isCreateProject) {
                        if (newProject?.id === '000000000000000000000000') {
                            navigate('/')
                        } else {
                            navigate(`/project/details?id=${newProject?.id}`);
                        }
                    } else {
                        if (projectId?.value === '000000000000000000000000') {
                            navigate('/')
                        } else {
                            navigate(`/project/details?id=${projectId?.value}`);
                        }
                    }
                } else {
                    if (!isCreateProject) notify(text?.notificationsUI?.inventory?.error, 'error');
                    setIsLoading(false);
                    setInventoryIsCreating && setInventoryIsCreating(false);
                }
            } catch (error) {
                if (!isCreateProject) notify(`Error: ${error.message}`, 'error')
                setIsLoading(false);
                setInventoryIsCreating && setInventoryIsCreating(false);
                console.error("Error:", error.message);
            }
        } else {
            return null
        }
    };
    useEffect(() => {
        // Check if the conditions for automatic submission are met
        if (isCreateProject && !isEmpty(newProject) && newProject?.id) {
            // Call onSubmit with the form values
            onSubmit(spyValues).catch(console.error);
        }
    }, [isCreateProject, newProject]);

    const removeImage = (id, imageIndex) => {
        setUploadedImages((prevImages) => {
            const updatedImages = prevImages[id]?.filter((_, idx) => idx !== imageIndex);
            return {
                ...prevImages,
                [id]: updatedImages,
            };
        });
    };

    const addProduct = (e) => {
        e.preventDefault();

        const newId = products && products.length > 0
            ? Math.max(...products.map(product => product.id)) + 1
            : 0;
        const newProductId = uuidv4(); // Generate a new UUID for productId

        const newProduct = {
            id: newId,
            productId: newProductId // Assign the newly generated unique productId
        };

        setProducts([...products, newProduct]);
    };

    const removeProduct = (form, id, productId, index) => {

        // Remove the product from the products state array
        const updatedProducts = products.filter(product => product.productId !== productId);

        // Clone the uploadedImages object to avoid mutating the original state directly
        const tempImages = { ...uploadedImages };

        // Delete the image at the provided index
        delete tempImages[index];

        // Re-index the subsequent entries
        Object.keys(tempImages).forEach(key => {
            if (parseInt(key) > index) {
                tempImages[key - 1] = tempImages[key];
                delete tempImages[key];
            }
        });

        // Update the uploadedImages state
        setUploadedImages(tempImages);
        // Remove the corresponding fields from the duplicatedFields state
        setDuplicatedFields(prevFields => {
            const updatedFields = { ...prevFields };
            delete updatedFields[id];
            return updatedFields;
        });

        // Set the updated products in state
        setProducts(updatedProducts);

        // Remove the product from the form's state
        const currentFormProducts = form.getState().values.products || [];
        const updatedFormProducts = currentFormProducts.filter(product => product && product.productId !== productId);

        // Update the form state
        form.change('products', updatedFormProducts);
    };

    const duplicateProduct = (e, initialId, form, productIndex) => {
        e.preventDefault();

        const currentFormValues = form.getState().values;

        if (!currentFormValues || !currentFormValues.products) {
            return;
        }

        const productToDuplicate = currentFormValues.products.find(product => product?.id == initialId);
        if (!productToDuplicate) {
            return;
        }

        const newId = Math.max(...products.map((product) => product.id), 0) + 1; // Added a fallback of 0 in case products is empty
        const newProductId = uuidv4();

        // Duplicate the product values
        const duplicatedProduct = {
            ...productToDuplicate,
            id: newId,
            productId: newProductId,
            inputType: null,
            fieldName: null
        };

        // Add the duplicated product to the form's current values
        form.change('products', [...currentFormValues.products, duplicatedProduct]);

        // Update the products array and set the duplicated product to productValues
        setProducts(prevProducts => [...prevProducts, { id: newId, productId: newProductId }]);

        let newFields = [];
        const advanceFields = productToDuplicate.advanceFields;

        const searchDuplicatedFields = (fullName) => {
            for (let key in duplicatedFields) {
                for (let field of duplicatedFields[key]) {
                    if (field.fullName === fullName) {
                        return field.label;
                    }
                }
            }
            return null;
        }

        if (advanceFields && typeof advanceFields === 'object') {
            Object.keys(advanceFields).forEach(key => {
                const fieldKey = key.split('-');
                const prefix = fieldKey[0];
                const attribute = fieldKey[fieldKey.length - 1]; // Get the last part of the key
                // Skip if the attribute is 'unit'
                if (attribute === 'unit') {
                    return; // Skip the rest of this iteration
                }
                const id = uuidv4();
                const labelFromDuplicatedFields = searchDuplicatedFields(key) || capitalize(fieldKey[1]);

                const newField = {
                    id,
                    fullName: fieldKey.join('-'),
                    inputType: fieldKey[0],
                    label: labelFromDuplicatedFields,
                    name: toCamelCase(fieldKey[1]),
                };
                newFields.push(newField);
            });
        }

        setDuplicatedFields(prev => ({ ...prev, [newId]: newFields }));
        form.change(`products[${productIndex}].inputType`, null)
        form.change(`products[${productIndex}].fieldName`, null);
    };


    const handleFieldRemoval = (id, field) => {
        setDuplicatedFields(prevFields => {
            if (prevFields[id]) {
                // Filter out the field with the given fieldId from the array of fields for the given id
                const updatedFieldArray = prevFields[id].filter(x => x.id !== field?.id);

                // Update the product's fields in the state
                return {
                    ...prevFields,
                    [id]: updatedFieldArray
                };
            }
            return prevFields;
        });
    }

    useEffect(() => {
        // Check the upload limit per index
        Object.keys(uploadedImages).forEach((id) => {
            if (uploadedImages[id]?.length >= UPLOAD_LIMIT) {
                setImageError((prevErrors) => ({
                    ...prevErrors,
                    [id]: `${text?.projects?.create?.uploader?.validations?.upto} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`,
                }));
            }

            // Count only 'new' images per index
            const newImages = uploadedImages[id]?.filter((image) => image.new) || [];
            setCurrentImagesLength((prevLengths) => ({
                ...prevLengths,
                [id]: newImages.length,
            }));
        });
    }, [uploadedImages]);

    useEffect(() => {
        // Notify and update error state if any index exceeds upload limit
        Object.keys(currentImagesLength).forEach((id) => {
            if (currentImagesLength[id] >= UPLOAD_LIMIT) {
                notify(
                    `${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`,
                    "warning"
                );
                setImageError((prevErrors) => ({
                    ...prevErrors,
                    [id]: `${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`,
                }));
            }
        });
    }, [currentImagesLength]);


    const onDrop = useCallback(async (acceptedFiles, id) => {
        const imageFiles = acceptedFiles?.filter(file => file.type.startsWith("image/"));

        if (imageFiles?.length !== acceptedFiles?.length) {
            setImageError((prevErrors) => ({
                ...prevErrors,
                [id]: text?.projects?.details?.validations?.onlyImages,
            }));
            return;
        }

        // Get the current images list for this ID
        const currentImages = uploadedImages[id] || [];

        // Count new images
        const newImagesCount = currentImages.filter(img => img.new).length;

        // Validation: If total uploaded images for this ID have reached MAX_IMAGES, block further uploads
        if (currentImages.length >= MAX_IMAGES) {
            setImageError((prevErrors) => ({
                ...prevErrors,
                [id]: `${text?.projects?.create?.uploader?.validations?.upto} ${MAX_IMAGES} ${text?.projects?.create?.uploader?.images}`,
            }));
            return;
        }

        // Validation: If we already have UPLOAD_LIMIT new images for this ID, prevent further uploads
        if (newImagesCount >= UPLOAD_LIMIT) {
            setImageError((prevErrors) => ({
                ...prevErrors,
                [id]: `${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`,
            }));
            return;
        }

        // Determine how many new images can be uploaded while staying within limits
        const remainingSlots = Math.min(UPLOAD_LIMIT - newImagesCount, MAX_IMAGES - currentImages.length);
        const filesToUpload = imageFiles.slice(0, remainingSlots);

        if (filesToUpload.length === 0) return;

        // Process images asynchronously to ensure UI updates immediately
        const newUploadedImages = await Promise.all(
            filesToUpload.map((file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        resolve({ file, dataUrl: reader.result, originalName: file.name, new: true });
                    };

                    reader.readAsDataURL(file);
                })
            )
        );

        // Ensure images appear immediately
        setUploadedImages((prevImages) => ({
            ...prevImages,
            [id]: [
                ...newUploadedImages, // Add new images at the top
                ...(prevImages[id] || []), // Keep existing images
            ],
        }));

        setImageError((prevErrors) => ({
            ...prevErrors,
            [id]: "",
        }));

    }, [MAX_IMAGES, UPLOAD_LIMIT, text?.projects?.create?.uploader?.validations, uploadedImages]);




    const handleImageUpload = (images) => {
        console.log("Images uploaded");
        setUploadedImages(images); // Update the state with the new images
        // You can also initiate an API request here to upload the images to a server
    };


    const handleToggle = (e) => {
        e?.preventDefault()
        setToggleModal(prevToggleModal => !prevToggleModal);
    }

    const resetForm = (form) => {
        setResetChild(!resetChild);
        form.reset();
        setProducts([{ id: 0 }])
        handleToggle();
        navigate('/')

    };
    const resetChildDone = () => {
        setResetChild(false);
    };

    useEffect(() => {
        const extracLabel = fieldNames?.map((x) => {
            return x.label
        })
        setFormattedFieldNames([...new Set(extracLabel)])
    }, [fieldNames])

    useEffect(() => {
        setColumnNameAndValues(inventoryColumns)
    }, [inventoryColumns])

    const showProjectProps = isCreateProject ? {
        title,
        info,
        toggle,
        toggleState,
        isLoading: projectIsLoading
    } : {
        isLoading
    }

    return (
        <Wrapper
            {...showProjectProps}
        >
            <ConditionalRender renderIf={toggleState}>
                <Form
                    // TODO: THIS IS NOT WORKING EITHER
                    initialValues={{
                        projectId: projectIdValue,
                        ...spyValues
                    }}

                    onSubmit={onSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (e.target.tagName !== 'TEXTAREA') {
                                    e.preventDefault();
                                }
                            }
                        }} >
                            <FormSpy subscription={{ values: true }}>
                                {({ values, form }) => {
                                    setSpyValues(values);
                                    // TODO: FIND A BETTER SOLUTION
                                    // Check if hasSelectedProjectId is not empty and projectId is not already set
                                    if (!isEmpty(hasSelectedProjectId) && !values.projectId) {
                                        form.change('projectId', {
                                            label: hasSelectedProjectId?.projectName,
                                            value: hasSelectedProjectId?.id,
                                        });
                                    } else if (isEmpty(hasSelectedProjectId) && values.projectId !== null) {
                                        form.change('projectId', values.projectId); // Set projectId to null when hasSelectedProjectId is empty
                                    }

                                    return null; // Render nothing
                                }}
                            </FormSpy>
                            <ConditionalRender renderIf={!isCreateProject}>
                                <SelectInput
                                    className='mb-md'
                                    isClearable={false}
                                    width={isDesktop ? 30 : isTablet ? 50 : 80}
                                    name='projectId'
                                    label={text?.proposals?.create?.project}
                                    initialValue={projectInitialValue}
                                    placeholder={text?.inventory?.create?.selectProject}
                                    isValidNewOption={() => false}
                                    validate={isRequired('Required')}
                                    options={[
                                        ...(user?.roleName === 'Admin' ? [{ label: text?.inventory?.landing?.table?.columns?.unassigned, value: '000000000000000000000000' }] : []),
                                        ...(projects ? projects?.filter((x) => x?.projectStatus !== 'completed' && x?.features?.inventory)?.map((x) => ({
                                            label: x?.projectName,
                                            value: x?.id
                                        })) : [])
                                    ]}
                                />
                            </ConditionalRender>
                            {products?.map((product, index) => {
                                const fieldsToRender = duplicatedFields[Number(product?.id)]

                                return <ProductForm
                                    key={product.id}
                                    index={index}
                                    id={product.id}
                                    productId={product.productId}
                                    products={products}
                                    productCount={products.length}
                                    removeProduct={removeProduct}
                                    change={form.change}
                                    onDrop={onDrop}
                                    uploadedImages={uploadedImages}
                                    handleImageUpload={handleImageUpload}
                                    removeImage={removeImage}
                                    form={form}
                                    inventory={inventory}
                                    categories={categories}
                                    resetChild={resetChild}
                                    resetChildDone={resetChildDone}
                                    setUploadedImages={setUploadedImages}
                                    fieldNames={formattedFieldNames}
                                    setFieldNameAndValues={setFieldNameAndValues}
                                    setColumnNameAndValues={setColumnNameAndValues}
                                    fieldNameAndValues={fieldNameAndValues}
                                    columnNameAndValues={columnNameAndValues}
                                    duplicateProduct={duplicateProduct}
                                    newFields={fieldsToRender}
                                    onFieldRemove={handleFieldRemoval}
                                    setDuplicatedFields={setDuplicatedFields}
                                    imageError={imageError}
                                    projects={projects}
                                    fetchCompanyProjects={fetchCompanyProjects}
                                    isCreateProject={isCreateProject}
                                    inventoryColumns={inventoryColumns}
                                    fetchTemplates={fetchTemplates}
                                    templates={templates}
                                    templatesIsLoading={templatesIsLoading}
                                    companyIndustry={company?.companyIndustry}
                                />
                            })}
                            <div style={{
                                marginTop: "15px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <button type="button" onClick={addProduct}>
                                    <HoverPopup style={{
                                        background: colors.blue
                                    }}
                                        placement={'bottom'}
                                        id={'addProduct'}
                                        text={isDesktop && text?.inventory?.create?.add}
                                    >
                                        <FontAwesomeIcon icon={faAdd} />
                                    </HoverPopup>
                                </button>
                            </div>
                            <ConditionalRender renderIf={!isCreateProject}>
                                <FormActions
                                    type="submit"
                                    floating
                                    onClick={handleToggle}
                                    navigate={navigate}
                                    submitText={text?.inventory?.create?.submit}
                                    secondaryText={text?.inventory?.create?.cancel}
                                    form={form}
                                    btnStyle={{
                                        color: colors.blue
                                    }}
                                />
                            </ConditionalRender>
                            <ConfirmModal
                                toggle={handleToggle}
                                isOpen={toggleModal}
                                text={text?.inventory?.create?.modal?.button}
                                onClick={() => resetForm(form)}
                                width={isDesktop ? '50%' : '100%'}
                                height={isDesktop ? '50%' : '100%'}
                            >
                                <div>
                                    <h4 className='mb-md'>
                                        {text?.inventory?.create?.modal?.title}
                                    </h4>
                                    <p>
                                        {text?.inventory?.create?.modal?.subtitle}
                                    </p>
                                </div>
                            </ConfirmModal>
                        </form>
                    )}
                />
            </ConditionalRender>
        </Wrapper>
    );
};

export default InventoryCreateContainer;
