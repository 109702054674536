import colors from '../globalStyles.scss'
import {
    one,
    three_one,
    three_two,
    three_three,
    four_one,
    four_two,
    four_three,
    four_four,
    five_one,
    five_two,
    six_two,
    six_three,
    six_four,
    six_five,
    six_six,
    seven_one,
    seven_two,
} from './flow_en';
import RegistrationContainer from '../Registration/RegistrationContainer';
import VideoPlayer from '../Core/VideoPlayer';
import logo from '../images/ardidflow_logo_animation.svg'

export const pages_es_sm = [
    {
        id: "page0",
        url: 'bienvenido',
        index: 0,
        content: (
            <div style={{
                width: '85%',
                margin: '0 auto',
            }}>
                <img style={{
                    width: '100%',
                    maxWidth: '300px',
                    display: 'block',
                    margin: '1em auto',
                }} src={logo} alt="Logo de Ardidflow" />
                <h1 style={{
                    textAlign: 'center',
                    color: colors.white,
                    fontSize: '2.5em',
                }}>
                    {'Bienvenido a Ardidflow'}
                </h1>
                <h2
                    style={{ textAlign: "center", fontSize: '1.2em', }}
                    className="mt-md"
                >
                    {
                        "Donde comienza tu viaje de gestión de proyectos y crece el rendimiento de tu negocio."
                    }
                </h2>
                <h3
                    style={{
                        textAlign: "center",
                        fontSize: '1em',
                        fontWeight: 'normal',
                    }}
                    className="mt-md"
                >
                    {
                        "No se necesita integración - Ardidflow es una solución completa de gestión de proyectos que te ayuda a gestionar tus proyectos, tareas, seguimiento de tiempo, propuestas, clientes, eventos de calendario, comunicación por chat, gestión de empleados y más, todo en un solo lugar."
                    }
                </h3>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page1" },
    },
    {
        id: "page1",
        url: 'projects',
        index: 1,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right'
                        }}
                    >
                        {"Proyectos"}
                    </h1>
                    <p style={{ textAlign: 'right' }}>
                        {'Gestiona proyectos de manera eficiente con características para el seguimiento de tareas, gestión de propuestas, control de inventario, programación de calendarios, colaboración en equipo, gestión de clientes, atributos personalizados, almacenamiento de archivos, monitoreo del historial del proyecto e interfaces dedicadas para empleados y administradores.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/7",
                    }}
                >
                    <p
                        className="mt-sm"
                    >
                        {
                            "Con proyectos ilimitados, comenzar tu proyecto en Ardidflow es simple e intuitivo, asegurando que puedas enfocarte rápidamente en lo que más importa: entregar resultados y alcanzar tus objetivos."
                        }
                    </p>
                    <p>
                        {'Después de crear tu proyecto, puedes actualizar los detalles del proyecto en cualquier momento y actualizar el estado para realizar un seguimiento del progreso hasta la finalización.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/6",
                        gridColumn: "2/7",
                    }}
                >
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/two.mp4'} controls={false} />                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: 'page0', right: "page2" },
    },
    {
        id: "page2",
        url: 'tasks',
        index: 2,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Tareas'}
                    </h1>
                    <p>
                        {'Las tareas serán el motor que impulsa tu proyecto, manteniendo todo funcionando sin problemas.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_two}
                            alt="Asignar empleados"
                        />
                        <h2 className='ml-md' style={{ textAlign: 'left' }}>
                            {'Asigna empleados para que todos conozcan su rol en el proyecto'}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "3/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <h2 className='mt-md mr-md' style={{ textAlign: 'right' }}>
                            {'Establece prioridades y plazos para mantenerte en el camino'}
                        </h2>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_three}
                            alt="Establecer prioridades y plazos"
                        />
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "5/6",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='mt-md'>
                        <h2 className='mb-md' style={{ textAlign: 'right' }}>
                            {'& Más...'}
                        </h2>
                        <ul style={{ textAlign: 'right' }}>
                            <li style={{ textAlign: 'right' }}>Crea subtareas para dividir el trabajo en partes manejables.</li>
                            <li style={{ textAlign: 'right' }}>Cada tarea puede incluir listas de pendientes para detallar los pasos o detalles necesarios.</li>
                            <li style={{ textAlign: 'right' }}>Agrega comentarios/imágenes para una mejor comunicación.</li>
                            <li style={{ textAlign: 'right' }}>Clona tareas para una creación más rápida de trabajos similares.</li>
                            <li style={{ textAlign: 'right' }}>Recibe notificaciones/calendario/correos electrónicos como recordatorios cuando se acerque el plazo de una tarea o se haya realizado un cambio.</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page1", right: "page3" },
    },
    {
        id: "page3",
        url: 'time-tracking',
        index: 3,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right',
                        }}
                    >
                        {'Seguimiento de Tiempo, Registros y Gestión'}
                    </h1>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <p
                        className='mt-md'
                    >
                        {'El seguimiento de tiempo ayudará a monitorear las horas de trabajo de los empleados, asegurando una mejor gestión del tiempo y eficiencia en las tareas.'}
                    </p>
                    <p className='mt-md mb-md'>
                        {'Los registros diarios pueden actualizarse para reflejar el progreso, ayudando al equipo a mantenerse alineado y mejorando la colaboración general.'}
                    </p>
                </div>
                <div

                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/7",
                    }}
                >
                    <img width={'80%'} src={four_four} alt="Seguimiento de tiempo" />

                    <div className='flex justify-between mt-md'>
                        <p
                            style={{ textAlign: 'left' }}
                        >
                            {'Automáticamente hará un seguimiento del estado de las tareas al registrar entradas y salidas, haciendo que el seguimiento de tiempo sea más preciso y confiable.'}
                        </p>
                        <p
                            style={{ textAlign: 'right' }}
                        >
                            {'Tendrás la posibilidad de actualizar las entradas de tiempo en cualquier momento a través de la función de gestión de registros para ti y tu equipo.'}
                        </p>
                    </div>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page2", right: "page4" },
    },
    {
        id: "page4",
        url: 'clients-proposals',
        index: 4,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    className='mt-md'
                    style={{
                        gridRow: "4/7",
                        gridColumn: "2/8",
                        color: colors.white,
                    }}
                >
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {'Crea, actualiza o elimina y gestiona clientes fácilmente, manteniéndolos informados a través del portal del cliente. Los clientes pueden ver detalles de proyectos, seguir el progreso, aprobar/rechazar y firmar propuestas con solo unos clics.'}
                    </p>
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {
                            'Comunícate con los clientes a través del chat y obtén retroalimentación instantánea sobre las actualizaciones del proyecto, tareas y más.'
                        }
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Guarda borradores de propuestas, envíalos directamente a los clientes por correo electrónico con un PDF adjunto, y recibe notificaciones instantáneas cuando cambie el estado de una propuesta.'}
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Realiza ajustes rápidos a las propuestas y reenvíalas sin esfuerzo para una mayor rapidez y mejor colaboración.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/8",
                    }}
                >
                    <img width={'100%'} src={five_one} alt="Seguimiento de tiempo" />
                    <img width={'100%'} src={five_two} alt="Seguimiento de tiempo" />
                    <h1
                        className="mt-md mb-md"
                        style={{
                            fontSize: "2em",
                            textAlign: 'center',
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Clientes y Propuestas'}
                    </h1>
                </div>
                <div

                    style={{
                        gridRow: "3/6",
                        gridColumn: "2/8"
                    }}
                >
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/five_three.mp4'} controls={false} />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page3", right: "page5" },
    },
    {
        id: "page5",
        url: 'chat-calendar-notifications',
        index: 5,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Chat, Calendario y Notificaciones'}
                    </h1>
                    <img width={'100%'} src={six_four} alt="Analíticas" />
                    <p className="mt-md">
                        {
                            'El calendario te permite programar reuniones, establecer recordatorios y realizar un seguimiento de cuándo se deben completar las tareas, asegurando que cumplas con todos los plazos y compromisos del proyecto.'
                        }
                    </p>
                    <p className="mt-md">
                        {
                            'Usa el calendario para coordinar horarios, gestionar eventos y asegurarte de que se cumplan las fechas límite de las tareas, todo en una interfaz intuitiva.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_five} alt="Notificaciones" />
                    <p>
                        {
                            'Recibe notificaciones instantáneas sobre actualizaciones de proyectos, fechas límite de tareas, mensajes y más para mantenerte informado y mejorar la colaboración.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "5/7",
                    }}
                >
                    <p>
                        {
                            'La función de chat está diseñada para una comunicación fluida entre los miembros del equipo y con los clientes. Úsala para discutir proyectos, compartir actualizaciones y colaborar de manera efectiva. Ya sea sobre el progreso del proyecto, la coordinación de tareas o consultas generales, este chat asegura que todos estén conectados e informados.'
                        }
                    </p>
                    <img className='mt-md' width={'100%'} src={six_six} alt="Chat" />
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page4", right: "page6" },
    },
    {
        id: "page6",
        url: 'analytics-ai-help-employee-management',
        index: 6,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 50px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 50px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Analíticas, Ayuda de IA y Gestión de Empleados'}
                    </h1>
                    <p>
                        {
                            'La función de Gestión de Empleados simplifica la supervisión del personal con una plataforma centralizada para gestionar detalles como títulos de trabajo, salarios y habilidades. Los empleados pueden ver información clave en sus perfiles, mientras que los gerentes pueden rastrear certificaciones, rendimiento y más. Una base de datos robusta permite búsquedas rápidas de empleados para una mejor toma de decisiones, y cualquier persona en la empresa puede encontrar fácilmente información de contacto o colaborar con colegas.'
                        }
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_three} alt="Ayuda de IA" />
                    <p className="mt-md mb-md" style={{ textAlign: 'right' }}>
                        {
                            'Obtén una visión clara del rendimiento de tu proyecto con analíticas avanzadas y toma decisiones basadas en datos para mejorar la eficiencia empresarial.'
                        }
                    </p>
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/six_one.mp4'} controls={false} />
                </div>
                <div
                    style={{
                        gridRow: "3/6",
                        gridColumn: "5/7",
                    }}
                >
                    <img width={'100%'} src={seven_two} alt="empleado" />
                    <p className="mt-md">
                        {
                            'La Ayuda de IA es tu asistente para responder preguntas sobre tareas, inventario, proyectos y cómo usar la aplicación. Obtén rápidamente la ayuda que necesitas para navegar por las funciones y resolver problemas.'
                        }
                    </p>
                </div>
            </div>
        ),
        background: colors.black,
        neighbors: { left: "page5", right: "page7" },
    },

    {
        id: "page7",
        url: 'get-started',
        index: 7,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "30px 1fr 15px 1fr 1fr 1fr 30px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <RegistrationContainer
                        route={'register'}
                    />
                </div>
            </div >
        ),
        background: colors.black,
        neighbors: { left: "page6" },
    }

];

export default pages_es_sm;
