import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import AutocompleteDropdown from '../Core/Inputs/AutocompleteDropdown';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb'; // English locale (use 'en-gb' for 12-hour format)
import CalendarDetailsContainer from './CalendarDetailsContainer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ConditionalRender from '../Core/ConditionalRender';
import MultiFields from '../Core/MultiFields';
import CheckBox from '../Core/Inputs/CheckBox';
import { useToast } from '../context/ToastContext';
import SelectInput from '../Core/SelectInput';
import ConfirmModal from '../Core/ConfirmModal';
import CallToAction from '../Core/CallToAction';
import PaginatedData from '../Core/PaginatedData';
import { isRequired, composeValidators } from '../utils/validators';

import EmployeeItem from '../Project/EmployeeItem';
import SelectableBox from '../Core/SelectableBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { useTheme } from '../context/ThemeContext';
import { useLanguage } from '../context/LanguageContext';
import SubmitModal from '../Core/SubmitModal';
import SearchInput from '../Core/Inputs/SearchInput';
import classnames from 'classnames';
import HoverPopup from '../Core/HoverPopup';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { createEvent } from '../utils/calls';
import colors from '../globalStyles.scss';
import { isEmpty } from 'lodash';
import { titleCase } from '../utils/helperFunctions';
import useScreenSize from '../context/useScreenSize';

const StyledEmployeeModal = styled.div`
    width: 100%;
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        width: 30%;
        a, span {
            color: ${colors.white};
        }
    }
`

const StyledCalendar = styled.div`
  .fc-list-event-graphic {
    display: none;
  }
    &.phone, &.isProject {
        .fc-toolbar {
            flex-direction: column;
        }
        .fc-toolbar-title {
            margin-bottom: 0.5em;
            margin-top: 0.5em;
        }
    }
    .fc-event-main {
        padding: 0;
        p, b {
            color: ${colors.white};  
        }
    }
  .fc-view {
    .fc-day-today {
        background: ${(props) => props.theme === 'dark' ? colors.mutedBlue : colors.darkGray};
        color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
    }
    .fc-list-day-cushion {
        background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
    }
        .fc-list-empty-cushion {
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        }
    .fc-event {
        cursor: pointer;
        border-radius: 0;
        padding: 0;
        margin: 0;
        border: 0;
        p, b {
            color: ${colors.white};  
        }
    }
  }

  .fc-next-button, .fc-prev-button {
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
    border: none;
    &:hover {
        background: ${(props) => props.theme === 'dark' ? colors.mutedBlue : colors.mutedBlue};
    }
  }


  .fc-timegrid-slots {
        table {
            color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};

            thead {
                tr th {
                    font-family: ${colors.openSans};
                    padding: 0.5em;
                }
            }

            .rbc-agenda-time-cell {
                padding: 0.5em;
                span {    
                    font-family: ${colors.openSans};
                }
            }
            .rbc-agenda-date-cell {
                padding: 0.5em;
                font-family: ${colors.openSans};
            }
            .rbc-agenda-event-cell {
                padding: 0.5em;
                text-align: left;
                font-family: ${colors.openSans};
                cursor: pointer;
            }
        }
    }
    // .task-event {
    //     background-color: ${colors.task};
    // }
    // .calendar-event {
    //     background-color: ${colors.calendar};
    // } 
}

.calendar-event, .task-event, .fc-list-event {
  color: ${(props) => props.theme === 'dark' ? colors.white : colors.black};
        a {
            cursor: pointer;
        }
        &:hover {
            td {
                background: ${(props) => props.theme === 'dark' ? colors.mutedBlue : colors.darkGray};
            }
        }
}

// .default-event {
//   background-color: ${colors.secondary};
// }
  .fc-scroller {
        scroll-behavior: smooth;
        ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: ${colors.white};
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #888;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }
  }

  .fc-popover {
    background: ${(props) => props.theme === 'dark' ? colors.backgroundSecondary : colors.darkGray};
  }
    .fc-daygrid-event-harness {
        cursor: pointer;
        margin: 0;
        margin-bottom: 0.2em;
    }
.fc-timegrid-event {
    box-shadow: none;
  }
`;

const FullCalendarContainer = ({
    events,
    eventsIsLoading,
    user,
    projects,
    employees,
    employeesIsLoading,
    fetchEvents,
    isProject,
    isClient = false,
    isDark = false,
    projectId,
    projectIsLoading,
    eventCategories,
    eventCategoriesIsLoading,
    fetchEventCategories,
    isFull = false,
}) => {
    const { text, language } = useLanguage();
    const { isDesktop, isPhone, loading, isTablet } = useScreenSize();
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;
    const navigate = useNavigate();
    const { notify } = useToast();
    const [currentView, setCurrentView] = useState('timeGridWeek');
    const [selectedSlot, setSelectedSlot] = useState({});
    const [errors, setErrors] = useState({
        startDate: '',
        endDate: ''
    });
    const [isAllDay, setIsAllDay] = useState(false);
    const [isForEveryone, setIsForEveryone] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState({});
    const [assignEmployee, setAssignEmployee] = useState(false);
    const [employeesList, setEmployeesList] = useState(employees);
    const [searchEmployeeQuery, setSearchEmployeeQuery] = useState('');
    const formRef = useRef();
    const [formattedEvents, setFormattedEvents] = useState([]);
    const [selectedColor, setSelectedColor] = useState('#1181C1');

    const handleChangeColor = (e) => {
        setSelectedColor(e.target.value);
    }

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    // Custom validation to check if the end date is before the start date
    const validateDates = (startDate, endDate, isAllDay) => {
        let tempErrors = { ...errors };

        // If isAllDay is true, skip end date validation
        if (!isAllDay) {
            const start = moment(startDate);
            const end = moment(endDate);

            // Ensure end date is not before start date
            if (end.isBefore(start)) {
                tempErrors.endDate = text?.calendar?.create?.errors?.endDate;
            } else {
                tempErrors.endDate = ''; // Clear error if validation passes
            }
        } else {
            tempErrors.endDate = '';
        }

        setErrors(tempErrors);
    };


    useEffect(() => {
        if (events && events.length > 0) {
            const transformedEvents = events.map(event => {
                // Parse the start and end strings manually, keeping the exact time
                const start = event.start || event.startDate;
                const end = event.end || event.endDate;
                const localStart = new Date(start?.split('Z')[0]);
                const localEnd = new Date(end?.split('Z')[0]);

                return {
                    ...event,
                    title: event.type === 'task' ? `${text?.calendar?.create?.task}: ${event?.title}` : event?.title,
                    start: localStart, // Use the manually parsed local date
                    end: localEnd,
                    allDay: event.allDay || false,
                };
            });
            setFormattedEvents(transformedEvents);
        } else {
            setFormattedEvents([]); // Clear if no valid events
        }
    }, [events]);

    useEffect(() => {
        if (isProject || isClient) {
            fetchEvents && fetchEvents(projectId);
        } else {
            fetchEvents();
        }
    }, [projectId]);


    useEffect(() => {
        if (!isPhone && !isFull) {
            setCurrentView('timeGridWeek');
        } else if (isPhone) {
            setCurrentView('timeGridDay');
        } else if (isProject && !isFull) {
            setCurrentView('timeGridDay');
        } else if (isProject && isFull) {
            setCurrentView('dayGridMonth');
        } else if (isClient) {
            setCurrentView('listWeek');
        }
    }, [isPhone, isProject, isClient, isFull]);

    const handleSearchEmployeeChange = (e) => {
        setSearchEmployeeQuery(e.target.value);
    };

    useEffect(() => {
        if (searchEmployeeQuery) {
            const query = searchEmployeeQuery.toLowerCase();
            const filteredEmployees = employees.filter((employee) => {
                const fullName = `${employee?.firstName} ${employee?.lastName} (${employee.email})`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
        } else {
            setEmployeesList(employees);
        }
    }, [searchEmployeeQuery, employees]);

    const [eventIsOpen, setEventIsOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});

    const handleEventClick = (eventClickInfo) => {
        const event = eventClickInfo.event;
        if (event.extendedProps.type === 'task') {
            navigate(`/tasks/details?id=${event.id}`);
        }
        if (event.extendedProps.type === 'calendar') {
            setEventIsOpen(!eventIsOpen);
            setSelectedEvent({
                id: event.id,
                title: event.title,
                startDate: event.start,
                endDate: event.end,
                description: event.extendedProps.description,
                allDay: event.allDay,
                type: event.extendedProps.type,
                color: event.backgroundColor,
                category: titleCase(event.extendedProps.category),
                forEveryone: event.extendedProps.forEveryone,
                showClient: event.extendedProps.showClient,
                selectedEmployees: event.extendedProps.selectedEmployees,
                project: event.extendedProps.project,
                createdBy: event.extendedProps.createdBy,
            })
        }
    };

    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});


    const handleAddEmployees = (value) => {
        const projectId = value?.value;
        setSelectedProjectId(value);

        const selectedProject = projects.find(project => project.id === projectId);
        if (selectedProject) {
            const assignedEmployeeIds = selectedProject.assignedEmployees.map(emp => emp.id);
            const assignedEmployees = employees
                .filter(emp => assignedEmployeeIds.includes(emp.id))
                .filter(emp => emp.id !== user?.id);
            setSelectedEmployees(assignedEmployees);
            setSelectedClient(selectedProject.client);
        }
    };

    const handleSubmit = async (values) => {

        if (errors.startDate || errors.endDate) {
            return;
        }

        if (!isAllDay) {
            values.startDate = selectedSlot.startDate;
            values.endDate = selectedSlot.endDate;
        } else {
            values.startDate = selectedSlot.startDate;
            values.endDate = selectedSlot.startDate;
        }
        values.allDay = isAllDay;
        values.forEveryone = isForEveryone;
        values.selectedEmployees = selectedEmployees.map(employee => employee.id);
        values.projectId = selectedProjectId?.value;
        values.color = selectedColor;
        values.showClient = showClient || false;

        try {
            const res = await createEvent(values);
            if (res.status === 200) {
                setIsOpen(false);
                notify(text?.calendar?.create?.createSuccessMessage, 'success');
                setSelectedSlot({});
                setErrors({
                    startDate: '',
                    endDate: ''
                });
                setIsAllDay(false);
                setIsForEveryone(false);
                setShowClient(false);
                setSelectedProjectId({});
                fetchEventCategories();
                setSelectedEmployees([]);
                if (isProject) {
                    fetchEvents(projectId);
                } else {
                    fetchEvents();
                }
            }
        } catch (error) {
            console.error(`Failed to create event: ${error.message}`);
        }
    };

    const eventClassNames = (event) => {
        if (event.event.extendedProps.type === 'task') {
            return ['task-event'];
        } else if (event.event.extendedProps.type === 'calendar') {
            return ['calendar-event'];
        }
        return ['default-event'];
    };

    const handleClear = () => {
        // Find the project being cleared
        const clearedProject = projects.find(project => project.id === selectedProjectId?.value);

        if (clearedProject) {
            // Get the list of employee IDs assigned to the cleared project
            const assignedEmployeeIds = clearedProject.assignedEmployees.map(emp => emp.id);

            // Filter out the employees that were assigned to the cleared project
            setSelectedEmployees(prevEmployees =>
                prevEmployees.filter(emp => !assignedEmployeeIds.includes(emp.id))
            );
            setSelectedProjectId({});
            setSelectedClient({});
            setSelectedColor('#1181C1');
            formRef.current?.change('selectProject', null);
        }
    }

    const handleAssignEmployee = () => {
        setAssignEmployee(!assignEmployee)
    };

    const [showEmployeesFilters, setShowEmployeesFilters] = useState(false);

    const handleEmployeeSelection = (employee) => {
        if (selectedEmployees.some((e) => e._id === employee._id)) {
            setSelectedEmployees((prev) => prev.filter((e) => e._id !== employee._id));
        } else {
            setSelectedEmployees((prev) => [...prev, employee]);
        }
    };


    const handleRemoveEmployee = (employeeId) => {
        // Filter out the employee by id from the selectedEmployees array
        setSelectedEmployees(prevEmployees => prevEmployees.filter(emp => emp.id !== employeeId));
    };

    // Function to open the event creation modal
    const handleCreateEventClick = () => {
        const now = new Date();
        const nowLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString()?.split('.')[0] + 'Z';

        // Set end time 30 minutes after nowLocal
        const endLocal = new Date(now.getTime() - (now.getTimezoneOffset() * 60000) + (30 * 60 * 1000)).toISOString()?.split('.')[0] + 'Z';

        // Set the selectedSlot state with start and end times
        setSelectedSlot({
            startDate: nowLocal,
            endDate: endLocal,
        });

        setIsOpen(true); // Open the modal
    };

    const colors = {
        "light": [
            '#0F70A9',
            '#A33E49',
            '#008000',
            '#7A6200'
        ],
        "dark": [
            '#0F70A9',
            '#A33E49',
            '#008000',
            '#7A6200'
        ]
    }

    return (
        <ConditionalRender renderIf={true} isLoading={eventsIsLoading || loading}>
            <StyledCalendar
                theme={theme}
                className={classnames({
                    'desktop': isDesktop,
                    'phone': isPhone,
                    'tablet': !isDesktop && !isPhone,
                    'isProject': ((isProject && !isTablet) || (isClient && !isTablet)),
                })}
            >
                <FullCalendar
                    locale={language === 'es' ? esLocale : enLocale}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]} // Add listPlugin for agenda view
                    initialView={(isPhone || (isProject && !isFull)) ? 'timeGridDay' : isClient ? 'listWeek' : currentView}
                    selectable={true}
                    events={formattedEvents}
                    eventClick={handleEventClick}
                    eventContent={(eventInfo) => {
                        return (
                            <div className='w-100 flex flex-column'
                                style={{
                                    backgroundColor: eventInfo?.backgroundColor, padding: '2px 4px'
                                }}>
                                <b style={{
                                    fontSize: '0.8em',
                                }}>
                                    {eventInfo.timeText}
                                </b>
                                <p style={{ textWrap: 'initial', color: colors.white }}>
                                    {eventInfo.event.title}
                                </p>
                            </div>
                        )
                    }}
                    customButtons={{
                        createEvent: {
                            text: text?.calendar?.createEvent,
                            click: handleCreateEventClick,
                        },
                    }}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: (isProject && isFull) ? 'dayGridMonth,timeGridDay,listWeek' : (isProject) ? 'timeGridDay,listWeek' : isPhone ? 'timeGridDay,listWeek createEvent' : isClient ? 'listWeek' : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek createEvent'
                    }}
                    buttonText={{
                        today: text?.calendar?.today,
                        month: text?.calendar?.month,
                        week: text?.calendar?.week,
                        day: text?.calendar?.day,
                        listWeek: 'Agenda', // Label for the agenda view
                    }}
                    views={{
                        listWeek: {
                            type: 'listWeek',
                            buttonText: 'Agenda', // Button label for the weekly agenda view
                        },
                    }}
                    slotLabelFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short',
                        hour12: true,
                    }}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short',
                        hour12: true,
                    }}
                    height="85vh"
                    dayMaxEvents={2}
                    eventClassNames={eventClassNames}
                />

            </StyledCalendar>

            <SubmitModal
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen);
                    setSelectedSlot({});
                    setErrors({
                        startDate: '',
                        endDate: ''
                    })
                    setIsAllDay(false);
                    setIsForEveryone(false);
                    setShowClient(false);
                    setSelectedProjectId({});
                    setSelectedEmployees([]);
                    setSelectedColor('#1181C1');
                }}
                onClick={handleSubmit}
                text={text?.calendar?.create?.createBtn}
                width={isDesktop ? '80%' : '100%'}
                height={isDesktop ? '80%' : '100%'}
                formEvents={formRef}
            >
                <h4>
                    {text?.calendar?.create?.createEvent}
                </h4>
                <MultiFields
                    className='field-style'
                    name="title"
                    component="input"
                    type="text"
                    label={text?.calendar?.create?.title}
                    block
                    validate={required}
                />
                <MultiFields
                    className='field-style'
                    name="description"
                    component="textarea"
                    type="textarea"
                    label={text?.calendar?.create?.description}
                    block
                    validate={required}
                />
                <AutocompleteDropdown
                    name={'category'}
                    label={text?.inventory?.create?.form?.category}
                    options={
                        eventCategories
                            ?.filter((x) => x)
                            ?.filter((x) => x?.toLowerCase() !== 'event')
                            ?.filter((x) => x?.toLowerCase() !== 'task')
                            ?.reduce((unique, item) => {
                                const lowercaseItem = item.toLowerCase();
                                if (!unique.some(existingItem => existingItem.toLowerCase() === lowercaseItem)) {
                                    unique.push(item)
                                }
                                return unique;
                            }, [])
                    }
                    placeholder={text?.inventory?.create?.form?.categoryPlaceholder}
                    width={isDesktop ? 30 : isTablet ? 50 : 100}
                    style={{
                        alignSelf: 'flex-start',
                        marginBottom: '1em',
                    }}
                />

                <div className={classnames({
                    'flex': true,
                    'flex-column': isPhone,
                })}>
                    <div className={classnames({
                        'mr-md w-50': !isPhone
                    })}
                    >
                        <CheckBox
                            checked={isAllDay}
                            label={text?.calendar?.create?.allDayEvent}
                            onChange={() => {
                                const newIsAllDay = !isAllDay;
                                setIsAllDay(newIsAllDay);

                                // Trigger validation when toggling All Day
                                validateDates(selectedSlot.startDate, selectedSlot.endDate, newIsAllDay);
                            }}
                        />
                        <ConditionalRender renderIf={user?.roleName === 'Admin'}>
                            <CheckBox
                                className='mt-md'
                                checked={isForEveryone}
                                label={text?.calendar?.create?.forEveryone}
                                onChange={(value) => {
                                    setIsForEveryone(!isForEveryone);
                                    if (value) {
                                        setSelectedEmployees([]);
                                        setSelectedProjectId({});
                                        formRef.current?.change('selectProject', null);
                                    }
                                }}
                            />
                        </ConditionalRender>
                        <MultiFields
                            className="field-style"
                            name="startDate"
                            component="date"
                            type="date"
                            label={text?.calendar?.create?.startDate}
                            block
                            onChange={({ value }) => {
                                const formattedStartDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
                                setSelectedSlot((prev) => ({
                                    ...prev,
                                    startDate: formattedStartDate,
                                }));
                                validateDates(formattedStartDate, selectedSlot.endDate, isAllDay);
                            }}
                            initialValue={selectedSlot.startDate}
                            withTimePicker={!isAllDay}
                        />
                        {errors.startDate && <span className="error mb-md">{errors.startDate}</span>}
                        <ConditionalRender renderIf={!isAllDay}>
                            <MultiFields
                                className="field-style"
                                name="endDate"
                                component="date"
                                type="date"
                                label={text?.calendar?.create?.endDate}
                                block
                                onChange={({ value }) => {
                                    const formattedEndDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
                                    setSelectedSlot((prev) => ({
                                        ...prev,
                                        endDate: formattedEndDate,
                                    }));

                                    // Pass updated start and end date for validation
                                    validateDates(selectedSlot.startDate, formattedEndDate, isAllDay);
                                }}
                                initialValue={selectedSlot.endDate}
                                withTimePicker
                            />
                            {errors.endDate && <span className="error mb-md">{errors.endDate}</span>}
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isForEveryone}>
                            <SelectInput
                                menuPlacement='top'
                                name='selectProject'
                                isSearchable
                                isValidNewOption={() => false}
                                onChange={handleAddEmployees}
                                placeholder={text?.tasks?.create?.project}
                                handleClear={handleClear}
                                width={100}
                                options={projects?.filter((x) => x?.projectStatus !== 'completed')?.map((x) => {
                                    return {
                                        label: x?.projectName,
                                        value: x?._id
                                    }
                                })}
                            />
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isEmpty(selectedClient)}>
                            <CheckBox
                                className='mt-md'
                                checked={showClient}
                                label={text?.calendar?.create?.showClient}
                                onChange={() => {
                                    setShowClient(!showClient);
                                }}
                            />
                        </ConditionalRender>

                        <ConditionalRender renderIf={!isForEveryone}>
                            <p className='mt-md mb-sm'>
                                {text?.calendar?.create?.additionalEmails}
                            </p>
                            <CallToAction
                                wrapperWidth={'100%'}
                                type="button"
                                onClick={handleAssignEmployee}
                                text={text?.calendar?.create?.addEmails}
                            />



                            {/* Modal */}
                            <ConditionalRender renderIf={assignEmployee}>
                                <ConfirmModal
                                    width={isDesktop ? '80%' : '100%'}
                                    height={isDesktop ? '80%' : '100%'}
                                    toggle={handleAssignEmployee}
                                    isOpen={assignEmployee}

                                    btnStyles={{
                                        color: colors.blue
                                    }}
                                >
                                    <StyledEmployeeModal>

                                        <div
                                            className={classnames({
                                                'flex w-100': true,
                                                'flex-column': isPhone,
                                            })}
                                        >
                                            {/* Left Section: Available Employees */}
                                            <div
                                                className={classnames({
                                                    'w-50': !isPhone,
                                                    'mr-md': !isPhone,
                                                })}
                                            >
                                                <h4 className='flex'>
                                                    {text?.calendar?.create?.employeesLookup}
                                                </h4>
                                                {/* Filter controls & search input */}
                                                <ConditionalRender
                                                    isLoading={employeesIsLoading}
                                                    loadingCount={employees?.length}
                                                    renderIf={!isEmpty(employees)}
                                                >
                                                    <div className="flex flex-column filters mt-md">
                                                        <SearchInput
                                                            value={searchEmployeeQuery}
                                                            onChange={handleSearchEmployeeChange}
                                                            placeHolder={text?.employees?.landing?.search}
                                                        />
                                                    </div>
                                                </ConditionalRender>

                                                {/* Paginated available employees */}
                                                <PaginatedData
                                                    className="mt-md"
                                                    data={employeesList?.filter(
                                                        (employee) =>
                                                            employee?._id !== user?._id &&
                                                            !selectedEmployees.some((sel) => sel._id === employee._id)
                                                    )}
                                                    dataIsLoading={employeesIsLoading}
                                                    itemsPerPage={5}
                                                    noDataText={text?.employees?.landing?.noData}
                                                    searching={searchEmployeeQuery}
                                                    showLightColors
                                                    ItemComponent={({ item }) => (
                                                        <EmployeeItem
                                                            key={item?._id}
                                                            employee={item}
                                                            selectedEmployees={selectedEmployees}
                                                            handleEmployeeSelection={handleEmployeeSelection}
                                                            // handleAdminSelection={handleAdminSelection}
                                                            text={text}
                                                        // You can include any additional props needed by EmployeeItem
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {/* Right Section: Selected Employees */}
                                            <div
                                                className={classnames({
                                                    'w-50': !isPhone,
                                                    'ml-md': !isPhone,
                                                })}
                                            >
                                                <h4 className="mb-md">{text?.paginated?.selected}</h4>
                                                <div>
                                                    {selectedEmployees.map((employee) => (
                                                        <EmployeeItem
                                                            key={employee._id}
                                                            employee={employee}
                                                            selectedEmployees={selectedEmployees}
                                                            handleEmployeeSelection={handleEmployeeSelection}
                                                            //   handleAdminSelection={handleAdminSelection}
                                                            text={text}
                                                        // Include additional props as needed
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </StyledEmployeeModal>
                                </ConfirmModal>
                            </ConditionalRender>
                        </ConditionalRender>



                        <div className="mt-md">
                            <label className='mb-sm' htmlFor={'color'}>
                                <b>
                                    {'Color'}
                                </b>
                            </label>
                            <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
                                {colors[theme]?.map((color, index) => (
                                    <label key={index} style={{ cursor: 'pointer' }}>
                                        <input
                                            type="radio"
                                            name="color"
                                            value={color}
                                            style={{ display: 'none' }}
                                            onChange={handleChangeColor}
                                            checked={selectedColor === color}
                                        />
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: color,
                                                borderRadius: '50%',
                                                border: selectedColor === color ? `2px solid` : `1px solid`,
                                                borderColor: selectedColor === color ? colors.white : colors.darkGray,
                                                transform: selectedColor === color ? 'scale(1.5)' : 'none',
                                            }}
                                        ></span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={classnames({
                        'mr-md w-50': !isPhone
                    })}>
                        <ConditionalRender renderIf={!isForEveryone}>
                            <ConditionalRender renderIf={!isEmpty(selectedEmployees)}>
                                <h4 className='mt-md'>{text?.calendar?.create?.assignedEmployees}</h4>
                                <div className='flex flex-column mt-md'>
                                    {
                                        selectedEmployees?.map((employee) => (
                                            <SelectableBox
                                                block
                                                key={employee._id}
                                                className='flex items-center'
                                                onClickRemove={() => handleRemoveEmployee(employee.id)}
                                                removeButton
                                            >
                                                <p className='ml-md'>
                                                    {`${employee.firstName} ${employee.lastName} (${employee.email})`}
                                                </p>
                                            </SelectableBox>
                                        ))
                                    }
                                </div>
                            </ConditionalRender>
                        </ConditionalRender>
                    </div>

                </div>
            </SubmitModal >

            <CalendarDetailsContainer
                eventIsOpen={eventIsOpen}
                setEventIsOpen={setEventIsOpen}
                setErrors={setErrors}
                setSelectedSlot={setSelectedSlot}
                setIsAllDay={setIsAllDay}
                setIsForEveryone={setIsForEveryone}
                setShowClient={setShowClient}
                setSelectedProjectId={setSelectedProjectId}
                setSelectedEmployees={setSelectedEmployees}
                setSelectedEvent={setSelectedEvent}
                selectedEvent={selectedEvent}
                user={user}
                isProject={isProject}
                projects={projects}
                employees={employees}
                employeesIsLoading={employeesIsLoading}
                fetchEvents={fetchEvents}
                projectId={projectId}
                eventCategories={eventCategories}
                eventCategoriesIsLoading={eventCategoriesIsLoading}
                fetchEventCategories={fetchEventCategories}
                isClient={isClient}
            />
        </ConditionalRender>
    );
};

export default FullCalendarContainer;
