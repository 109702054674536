import request from '../request';

/**
 * search user from the API.
 * @param {string} query - The search query for inventoryName.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const findCompanies = async ({
    query,
    pendingAccess = false,
    premium = false,
    notPremium = false
}) => {
    try {
        const { data, status } = await request('POST', '/admin/companies', {
            query,
            pendingAccess,
            premium,
            notPremium
        });
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to get comopanies: ${error.message}`);
    }
};

export default findCompanies;
