import React from 'react';
import colors from '../globalStyles.scss';

const ConversationHeader = ({ selectedConversation, onlineUsers, currentUserId }) => {
    // Combine participants and clientParticipants arrays
    const allParticipants = [
        ...(selectedConversation?.participants || []),
        ...(selectedConversation?.clientParticipants || []),
    ];

    // Filter out the current user
    const filteredParticipants = allParticipants.filter(
        (participant) => participant._id !== currentUserId
    );

    return (
        <div>
            <ul className='flex flex-wrap gap-2 itesm-center justify-content-center'>
                {filteredParticipants.map((participant) => {
                    const isOnline = onlineUsers.includes(participant._id); // Check if the participant is online
                    return (
                        <li key={participant._id} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            {/* Display the dot (green for online, red for offline) */}
                            <span
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: isOnline ? colors.green : colors.red,
                                }}
                            ></span>
                            {/* Display the participant's name */}
                            {participant.firstName && participant.lastName
                                ? `${participant.firstName} ${participant.lastName}`
                                : participant.name}                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ConversationHeader;
