export const createChannel = (
    channelName,
    isChannelPrivate,
    channelAdmins,
    participants,
    companyId,
    currentUserId,
    callback,
    ws
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    // Emit the event to create a private conversation
    ws.current.emit('createChannel', { channelName, isChannelPrivate, channelAdmins, participants, companyId, currentUserId }, (response) => {
        if (response.success) {
            callback && callback(response.conversation);
        } else {
            console.error('Failed to create channel conversation:', response.message);
            callback && callback(null, response.message);
        }
    });
};