import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import ConditionalRender from '../Core/ConditionalRender';
import Wrapper from '../Core/Wrapper';
import CallToAction from '../Core/CallToAction';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../Core/HoverPopup';
import RadioButton from '../Core/Inputs/RadioButton';
import colors from '../globalStyles.scss'
import { isEmpty } from 'lodash';
import PaginatedData from '../Core/PaginatedData';
import SelectableBox from '../Core/SelectableBox';
import SearchInput from '../Core/Inputs/SearchInput';
import ConfirmModal from '../Core/ConfirmModal';
import {
    removeClients
} from '../utils/calls';
import { useToast } from '../context/ToastContext';
import CustomTable from '../Core/Tables/CustomTable';
import { clientsColumns } from '../Invetory/columns';
import CreateNewClientContainer from './CreateNewClientContainer';
import useScreenSize from '../context/useScreenSize';


const ClientsContainer = ({
    user,
    fetchAllClients,
    clients,
    clientsIsLoading,
    isProject,
    showLightColors,
    title,
    btnWidth,
    setSelectedClient,
    selectedClient,
    fetchCompanyProjects,
    project,
    setExpanded
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { notify } = useToast();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const { isDesktop, isTablet } = useScreenSize();


    useEffect(() => {
        const clientId = project?.client?._id;

        const sortedClients = clients && [...clients].sort((a, b) => {
            if (a._id === clientId) return -1;
            if (b._id === clientId) return 1;
            return 0;
        });


        if (clients?.length === 0) {
            setTableData([]);
        } else {
            setTableData(sortedClients);
        }
    }, [clients, project, clientsIsLoading]);

    const handleSearchChange = (e) => {
        setFilters({ ...filters, query: e?.target?.value });

    };

    const filterTableData = async () => {
        const filterOptions = {};
        const clientId = project?.client?._id;

        const sortedClients = clients && [...clients].sort((a, b) => {
            if (a._id === clientId) return -1;
            if (b._id === clientId) return 1;
            return 0;
        });

        if (filters.query) {
            filterOptions.query = filters.query.toLowerCase();
        }

        let filteredClients = sortedClients;

        if (filterOptions.query) {
            filteredClients = filteredClients.filter(client =>
                client?.name.toLowerCase().includes(filterOptions.query)
            );
        }
        setTableData(filteredClients);
    };

    useEffect(() => {
        filterTableData();
    }, [filters, clients, project, clientsIsLoading]);

    const onModalClose = () => {
        setFilters({});
        setFilters({ query: '' });
    }

    const [createIsOpen, setCreateIsOpen] = useState(false)

    const handleCreateToggle = () => {
        setCreateIsOpen(!createIsOpen)
    }

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const handleDeleteSelection = async () => {
        try {
            const res = await removeClients(selectedIds);
            if (res.status === 200) {
                fetchAllClients();
                fetchCompanyProjects()
                setToggleModal(!toggleModal)
                setSelectedIds([]);
                setSelectedItems([]);
                notify(text?.notificationsUI?.clients?.deleted, 'success')
            }
        } catch (error) {
            notify(`Error:: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const handleClientSelection = (e) => {
        e.preventDefault();
        setSelectedClient(e.target?.value);

    }

    return (
        <Wrapper
            showLightColors={showLightColors}
            isLoading={clientsIsLoading}
            title={title}
            btnWidth={btnWidth}
            setExpanded={setExpanded}
            expandable
            section={'clients'}
            onModalClose={onModalClose}
            onClickBtn={handleCreateToggle}
            button={
                <ConditionalRender renderIf={!isProject}>
                    <CallToAction
                        className='flex-one'
                        text={text?.client?.create?.title}
                        type='button'
                        onClick={() => handleCreateToggle()}
                    />
                </ConditionalRender>
            }
        >
            <div className='flex flex-column'>
                <SearchInput
                    className='mt-md w-100'
                    value={filters.query}
                    onChange={handleSearchChange}
                    placeHolder={text?.tasks?.home?.filters?.search}
                />
            </div>

            <ConditionalRender renderIf={isProject}>
                <div className='flex flex-column align-start mt-md'>
                    <PaginatedData
                        className='mt-md'
                        data={tableData}
                        dataIsLoading={clientsIsLoading}
                        noDataText={'No Clients Found'}
                        searching={filters?.query}
                        showLightColors
                        ItemComponent={({ item }) => {
                            return (
                                <SelectableBox
                                    key={item?._id}
                                    className='flex justify-space-between w-100'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedClient(item._id);
                                    }}
                                    isSelected={selectedClient === item._id}
                                >
                                    <p>
                                        {item.name}({item.email})
                                    </p>
                                </SelectableBox>
                            )
                        }}
                    />
                </div>
            </ConditionalRender>

            <ConditionalRender renderIf={!isProject}>
                <div className='flex flex-column align-start mt-md'>
                    <CustomTable
                        keyField="_id"
                        columns={clientsColumns({ theme, text, navigate })}
                        rows={tableData}
                        onRowSelectionChange={handleRowSelectionChange}
                        theme={theme}
                        noDataText={'No Clients Found'}
                    />
                    <ConditionalRender renderIf={!isEmpty(selectedIds)}>
                        <div style={{
                            color: colors.red,
                            fontFamily: colors.openSans,
                            cursor: 'pointer',
                            display: 'flex',
                            marginBottom: '5px'
                        }} onClick={() => setToggleModal(true)} >
                            {'Delete Selected Clients'}
                        </div>
                        <ConfirmModal
                            onClick={handleDeleteSelection}
                            text={text?.inventory?.landing?.table?.modal?.button}
                            toggle={() => setToggleModal(!toggleModal)}
                            isOpen={toggleModal}
                            btnStyles={{
                                color: colors.red
                            }}
                            width={isDesktop ? '50%' : '100%'}
                            height={isDesktop ? '50%' : '100%'}

                        >
                            <div>
                                <h4 style={{ marginBottom: '15px' }}>
                                    {text?.inventory?.landing?.table?.modal?.title}
                                </h4>
                                <ul>
                                    {selectedItems?.map((x) => {
                                        return (
                                            <li style={{ fontSize: '0.9rem' }}>
                                                - {x?.name}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </ConfirmModal>
                    </ConditionalRender>
                </div>
            </ConditionalRender>

            <ConditionalRender renderIf={createIsOpen}>
                <CreateNewClientContainer
                    isOpen={createIsOpen}
                    toggle={handleCreateToggle}
                    user={user}
                    fetchAllClients={fetchAllClients}
                    clients={clients}
                />
            </ConditionalRender>
        </Wrapper>
    )
}

export default ClientsContainer