import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useWebSocket } from '../context/WebSocketContext';
import { Form } from 'react-final-form';
import MultiFields from '../Core/MultiFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import ControlledTooltip from '../Core/ControlledTooltip';
import styled from 'styled-components'
import { composeValidators, isRequired, isValidEmail } from '../utils/validators';
import { contactUs } from '../utils/calls';
import SelectInput from '../Core/SelectInput';
import FormActions from '../Core/FormActions';
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';
import classNames from 'classnames';
import instaLogo from '../images/instaLogo.webp'
import ConfirmModal from '../Core/ConfirmModal';
import RichEditorWithImageUploader from '../Core/Inputs/RichEditorWithImageUploader';
import ChatWindow from '../Chat/ChatWindow';
import { isEmpty } from 'lodash';
const Styledform = styled.div`
    display: flex;
    justify-content: center;
    h1 {
        text-align: left;
        margin-bottom: 1em;
        font-size: 1.5em;
    }
    &.tablet, &.phone {
        .form-wrapper {
            width: 100%;
        }
    }
     &.desktop {
            .form-wrapper {
                width: 80%;
            }
        }
    .form-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 auto;

    }
        .error {
            color: ${colors.red};
        }
`

const ContactForm = ({
    isLoggedIn,
    user
}) => {
    const {
        selectedConversationId,
        setSelectedConversationId,
        setSelectedConversation,
        setMessages,
        selectedConversation,
        findSuperAdminConversation,
        isSendingMessage,
        setIsSendingMessage,
        createSuperAdminMessage,
        conversationIdIsLoading
    } = useWebSocket();
    const conversationIdRef = useRef(selectedConversationId);

    const [emailSent, setEmailSent] = useState(false);
    const [successMessage, setSuccesMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { text } = useLanguage();
    const { isDesktop, isTablet } = useScreenSize();
    const [showModal, setShowModal] = useState(false);
    const containerRef = useRef(null);
    const firstUnreadRef = useRef(null);
    const [firstUnreadIndex, setFirstUnreadIndex] = useState(null);

    // Sync the ref with the latest selected conversation ID
    useEffect(() => {
        conversationIdRef.current = selectedConversationId;
    }, [selectedConversationId]);

    const onSubmit = async values => {
        try {
            const res = await contactUs(values)
            if (res.status === 200) {
                setEmailSent(true)
                setSuccesMessage(text?.contact?.successMessage)
            } else {
                setEmailSent(false);
                setSuccesMessage('');
                setErrorMessage(text?.contact?.errorMessage)
            }
        } catch (error) {
            setEmailSent(false);
            setSuccesMessage('');
            setErrorMessage(text?.contact?.errorMessage)

        }
    }

    const nameValidator = composeValidators(
        isRequired(text?.contact?.validation?.required)
    )

    const emailValidator = composeValidators(
        isRequired(text?.contact?.validation?.required),
        isValidEmail('Invalid email address')
    )

    const handleSendMessage = useCallback(({ message, images }) => {
        const text = message;
        const contentToSend = text
        const imgs = images
        if (!isEmpty(imgs) || (isEmpty(imgs) && contentToSend)) {
            createSuperAdminMessage(
                conversationIdRef.current,
                user?.companyId,
                contentToSend,
                'text',
                user?._id,
                imgs,
                (message, conversation) => {
                    if (message) {
                        setTimeout(() => {
                            if (containerRef.current) {
                                containerRef.current.scrollTop = containerRef.current.scrollHeight;
                            }
                        }, 100);
                        setSelectedConversation(conversation)
                        setSelectedConversationId(conversation._id)
                    }
                }
            )
        }
    }, [user?._id])

    const handleFindSuperAdminConversation = () => {
        if (showModal) {
            setSelectedConversation(null);
            setSelectedConversationId(null);
        } else {
            findSuperAdminConversation(user?._id, user?.companyId, (conversation) => {
                if (conversation) {
                    // setSelectedConversation(conversation);
                    // setSelectedConversationId(conversation._id);
                }
            });
        }
        setShowModal(!showModal);
    };

    return (
        <ConditionalRender renderIf={true} isLoading={conversationIdIsLoading}>
            <Styledform
                className={classNames({
                    'desktop': isDesktop,
                    'tablet h-100': isTablet,
                    'phone h-100': !isDesktop && !isTablet,
                    'pa-md': !isLoggedIn || !isDesktop,
                    'mt-md': isLoggedIn && isDesktop,

                })}
            >
                {
                    emailSent ? (
                        <p style={{
                            fontFamily: colors.openSans,
                            marginTop: '1em'
                        }}>
                            {successMessage}
                        </p>
                    ) : (
                        <div className='w-100 h-100'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <div className='form-wrapper scroll-container h-100'>
                                <h1 className='flex'>
                                    {text?.footer?.contactUs}
                                </h1>
                                <div>
                                    <Form
                                        onSubmit={onSubmit}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit}>
                                                <ConditionalRender renderIf={!isLoggedIn}>
                                                    <MultiFields
                                                        className='field-style'
                                                        name="name"
                                                        component="input"
                                                        type="text"
                                                        label={text?.contact?.fields?.name}
                                                        block
                                                        validate={nameValidator}
                                                        style={{
                                                            margin: '15px 0'
                                                        }}
                                                        isDark={!isLoggedIn}
                                                    />
                                                    <MultiFields
                                                        className='field-style'
                                                        name="email"
                                                        component="input"
                                                        type="email"
                                                        block
                                                        label={text?.contact?.fields?.email}
                                                        validate={emailValidator}
                                                        style={{
                                                            margin: '15px 0'
                                                        }}
                                                        isDark={!isLoggedIn}
                                                    />
                                                </ConditionalRender>
                                                <SelectInput
                                                    className='field-style'
                                                    name='subject'
                                                    options={[
                                                        { value: 'general', label: text?.contact?.fields?.subject?.general },
                                                        { value: 'support', label: text?.contact?.fields?.subject?.support },
                                                        { value: 'feedback', label: text?.contact?.fields?.subject?.feedback },
                                                        { value: 'feedback', label: text?.contact?.fields?.subject?.billing },
                                                        { value: 'technical', label: text?.contact?.fields?.subject?.technical },
                                                        { value: 'other', label: text?.contact?.fields?.subject?.other }
                                                    ]}
                                                    placeholder={text?.contact?.fields?.subjectText}
                                                    isDark={!isLoggedIn}
                                                    validate={nameValidator}
                                                />
                                                <MultiFields
                                                    className='field-style'
                                                    name="message"
                                                    component="textarea"
                                                    label={text?.contact?.fields?.message}
                                                    block
                                                    validate={nameValidator}
                                                    isDark={!isLoggedIn}
                                                />
                                                <FormActions
                                                    type="submit"
                                                    onClick={form.reset}
                                                    submitText={text?.contact?.button}
                                                    form={form}
                                                    isDark={!isLoggedIn}
                                                />
                                            </form>
                                        )}
                                    />
                                    <span className='error small'>
                                        {errorMessage}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        margin: '1em',
                    }}
                    className='flex items-center'>
                    <ConditionalRender renderIf={isLoggedIn}>
                        <ControlledTooltip
                            text={text?.actionTracking?.contactChat}
                            placement='top'
                            style={{
                                width: '100%'
                            }}
                            actionParams={{
                                feature: 'chat',
                                action: 'contact',
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    margin: '0 1em',
                                    fontSize: '25px'
                                }}
                                icon={faMessage}
                                onClick={() => {
                                    handleFindSuperAdminConversation()

                                }}
                            />
                        </ControlledTooltip>
                    </ConditionalRender>
                    <a

                        href='https://www.instagram.com/ardidflow'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img
                            src={instaLogo} alt='instagram logo' style={{
                                width: '25px',
                                height: '25px',
                                margin: '1em auto'
                            }}
                        />
                    </a>
                </div>
                <ConfirmModal
                    isOpen={showModal}
                    toggle={() => {
                        setShowModal(!showModal)
                        setSelectedConversation(null)
                        setSelectedConversationId(null)
                        setMessages([])
                    }}
                    width={isDesktop ? '50%' : '100%'}
                    height={isDesktop ? '80%' : '100%'}
                >
                    <div
                        className='w-100 h-100 flex flex-column'
                    >
                        <ChatWindow
                            user={user}
                            containerRef={containerRef}
                            firstUnreadRef={firstUnreadRef}
                            setAllRead={() => console.log('')}
                            allRead={true}
                            setFirstUnreadIndex={setFirstUnreadIndex}
                            firstUnreadIndex={firstUnreadIndex}
                            isProject
                        />
                        <RichEditorWithImageUploader
                            // onImageUpload={handleImageChange}
                            maxFiles={5}
                            noButton
                            customMessage={text?.inventory?.create?.form?.extraFields?.uploader?.title}
                            title={text?.chat?.message}
                            showForm={false}
                            onSubmit={(values) => handleSendMessage(values)}
                            text={text}
                            noGif
                            submitText={text?.tasks?.details?.button}
                            name="message"
                            isSendingMessage={isSendingMessage}
                            setIsSendingMessage={setIsSendingMessage}
                            // imagePreviews={imagePreviews}
                            // setImagePreviews={setImagePreviews}
                            // isDark={isClient}
                            hideSave
                        />
                    </div>
                </ConfirmModal>
            </Styledform>
        </ConditionalRender >
    );
};

export default ContactForm;
