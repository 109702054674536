import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import logo from './images/ardidflow_logo_small.png';
import { useLanguage } from './context/LanguageContext';
import queryString from 'query-string';

const HelmetWrapper = () => {
    const location = useLocation(); // Access the current location
    const { language } = useLanguage(); // Access the language context
    const [title, setTitle] = useState('Ardidflow'); // State to hold the dynamic title

    // Titles and metadata for dynamic routes
    const titles = {
        en: {
            '/': 'Ardidflow | Home',
            '/flow': 'Ardidflow | Flow',
            '/contact-us': 'Ardidflow | Contact Us',
            '/subscriptions': 'Ardidflow | Subscriptions',
            '/register': 'Ardidflow | Register',
            '/login': 'Ardidflow | Login',
            '/faq': 'Ardidflow | FAQ',
            '/resources': 'Ardidflow | Resources',
            '/analytics': 'Ardidflow | Analytics',
            '/settings': 'Ardidflow | Settings',
            '/chat': 'Ardidflow | Chat',
            '/calendar': 'Ardidflow | Calendar',
        },
        es: {
            '/': 'Ardidflow | Inicio',
            '/flow': 'Ardidflow | Flujo',
            '/contact-us': 'Ardidflow | Contáctanos',
            '/subscriptions': 'Ardidflow | Suscripciones',
            '/register': 'Ardidflow | Registrarse',
            '/login': 'Ardidflow | Iniciar sesión',
            '/faq': 'Ardidflow | Preguntas frecuentes',
            '/resources': 'Ardidflow | Recursos',
            '/analytics': 'Ardidflow | Analítica',
            '/settings': 'Ardidflow | Configuración',
            '/chat': 'Ardidflow | Chat',
            '/calendar': 'Ardidflow | Calendario',
        },
    };

    const descriptions = {
        en: 'Project management toolkit for your business',
        es: 'Herramientas de gestión de proyectos para tu negocio',
    };

    const keywords = {
        en: 'Project Management Toolkit',
        es: 'Herramientas de Gestión de Proyectos',
    };

    const currentLanguage = titles[language] || titles.en;

    // Listen for changes to the query string
    useEffect(() => {
        const query = queryString.parse(location.search); // Parse the query string
        const pageQuery = query.page; // Extract the `page` parameter
        const queryResourceTitle = query.title; // Extract the `title` parameter

        let dynamicTitle = currentLanguage[location.pathname] || 'Ardidflow';

        // Handle the query string for /flow?page=...
        if (location.pathname.startsWith('/flow') && pageQuery) {
            const formattedPageQuery = pageQuery
                .replace(/-/g, ' ') // Replace hyphens with spaces
                .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
            dynamicTitle = `${currentLanguage['/flow']} | ${formattedPageQuery}`;
        }

        // Append query title for /resources and its sub-paths
        if (location.pathname.startsWith('/resources') && queryResourceTitle) {
            const decodedTitle = decodeURIComponent(queryResourceTitle); // Decode the title from the query string
            dynamicTitle = `${currentLanguage['/resources']} | ${decodedTitle}`;
        }

        // Update the dynamic title
        setTitle(dynamicTitle);
    }, [location.search, location.pathname, currentLanguage]); // Re-run when the query string or path changes

    const description = descriptions[language] || descriptions.en;
    const keyword = keywords[language] || keywords.en;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <meta property="og:title" content={title} />
            <meta property='og:url' content={`https://www.ardidflow.com${location.pathname}`} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta name="robots" content="index, follow" />
            <meta property="og:image" content={logo} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Ardidflow.com" />
            <meta name="twitter:description" content="Project management toolkit for your business" />
            <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.jpg" />
            <link rel="canonical" href="https://www.ardidflow.com/" />

        </Helmet>
    );
};

export default HelmetWrapper;
