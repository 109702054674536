import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import styled from 'styled-components';
import colors from '../globalStyles.scss';
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext';
import useScreenSize from '../context/useScreenSize';
import ConditionalRender from './ConditionalRender';
import Wrapper from './Wrapper';
import SelectableBox from './SelectableBox';


const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1em;
    min-height: 40px;
    background: ${(props) => (props.theme === 'light' ? colors.blue : colors.secondary)};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    p {
        font-family: ${colors.openSans};
        color: ${(props) => (props.theme === 'light' ? colors.white : colors.black)};
    }
    &:hover {
      background: ${colors.lightGray};
      p {
        color: ${colors.blue};
      }
    }
  }
  .file-wrapper {
    position: relative;
    padding: 20px;
    background: ${(props) => (props.theme === 'light' ? colors.darkGray : colors.backgroundSecondary)};
    margin-bottom: 1em;
    p {
        display: flex;
    }
    .remove {
      position: absolute;
      top: 0;
      right: 0;
      background: ${colors.red};
      svg {
        color: ${colors.white};
        font-size: 1rem;
      }
    }
  }
`;

const FileUploader = ({
    onFileUpload,
    maxFiles,
    customMessage,
    files,
    title = '',
    isLoading = false,
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const [fileError, setFileError] = useState('');

    const maxSize = 5 * 1024 * 1024; // 5 MB limit
    const allowedFileTypes = ['text/plain', 'application/pdf'];


    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = [];
        let error = '';

        acceptedFiles.forEach((file) => {
            if (!allowedFileTypes.includes(file.type)) {
                error = `${text?.uploader?.fileType} ${file.type} ${text?.uploader?.isNot}`;
            } else if (file.size > maxSize) {
                error = `${text?.uploader?.file} ${file.name} ${text?.uploader?.toLarge}`;
            } else {
                newFiles.push(file);
            }
        });

        if (files.length + newFiles.length > maxFiles) {
            error = `${text?.projects?.create?.uploader?.validations?.upto} ${maxFiles} ${maxFiles > 1 ? text?.projects?.create?.uploader?.files : text?.projects?.create?.uploader?.file}`;
        }

        if (error) {
            setFileError(error);
        } else {
            setFileError('');
            onFileUpload((prevFiles) => [...prevFiles, ...newFiles]);
        }
    }, [maxFiles, onFileUpload, files]);

    const removeFile = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        onFileUpload(updatedFiles);
        if (updatedFiles.length < maxFiles) {
            setFileError('');
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf']
        },
        multiple: true,
    });

    return (
        <StyledDiv
            className={classnames({
                'file-uploader': true,
                desktop: isDesktop,
                tablet: isTablet,
                phone: isPhone
            })}
            theme={theme}
        >
            <Wrapper
                isLoading={isLoading}
                title={title}
                width={'100%'}
                button={
                    <div {...getRootProps()} className='dropzone'>
                        <input {...getInputProps()} />
                        <ConditionalRender renderIf={!customMessage}>
                            <p>
                                {`${text?.projects?.create?.uploader?.title} ${maxFiles} ${maxFiles > 1 ? text?.projects?.create?.uploader?.files : text?.projects?.create?.uploader?.file} ${text?.projects?.create?.uploader?.here}`}
                            </p>
                            <p>
                                {text?.projects?.create?.uploader?.or}
                            </p>
                        </ConditionalRender>
                        <ConditionalRender renderIf={customMessage}>
                            <p>
                                {customMessage}
                            </p>
                        </ConditionalRender>
                    </div>
                }
            >
                <div className='flex flex-column items-center mb-md'>
                    <span className='small mt-md'>
                        {text?.projects?.details?.filesModal?.text2}
                    </span>
                    <span className='small'>
                        {text?.projects?.details?.filesModal?.text3}
                    </span>
                </div>
                <div className='uploaded-files'
                    style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        gap: '5px',
                    }}>
                    {files?.map((file, index) => (
                        <SelectableBox
                            block
                            key={file._id}
                            className="flex items-center relative"
                            style={{
                                marginRight: (index + 1) % 3 === 0 ? '0' : '5px',
                                marginBottom: '5px',
                            }}
                            removeButton
                            onClickRemove={() => {
                                removeFile(index);
                            }}
                        >
                            <p>{file.name}</p>
                        </SelectableBox>
                    ))}
                </div>

                {fileError && <p className='error small mt-sm'>{fileError}</p>}
            </Wrapper>
        </StyledDiv>
    );
};

export default FileUploader;
