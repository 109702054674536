export const deleteMessage = async (
    messageId,
    ws,
    setMessages,
    selectedConversationId
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    try {
        ws.current.emit(
            'deleteMessage',
            { messageId, conversationId: selectedConversationId },
            (response) => {
                if (response.success) {
                    setMessages((prevMessages) =>
                        prevMessages
                            .filter((msg) => msg._id !== messageId)
                            .map((msg) => {
                                if (msg.replies) {
                                    return {
                                        ...msg,
                                        replies: msg.replies.filter((reply) => reply._id !== messageId),
                                    };
                                }
                                return msg;
                            })
                    );
                } else {
                    console.error('Failed to delete message:', response.message);
                }
            }
        );
    } catch (error) {
        console.error('Error deleting message:', error);
    }
};