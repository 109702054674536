import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import colors from '../globalStyles.scss';
import classnames from 'classnames';
import { useTheme } from '../context/ThemeContext'
import ConditionalRender from './ConditionalRender';
import { isEmpty } from 'lodash';
import ControlledTooltip from './ControlledTooltip';


const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${(props) => props.wrapperWidth || 'auto'};
    margin-top: ${(props) => props.marginTop};
    button, a {
        cursor: pointer;
        min-height: ${(props) => props.btnHeight || '40px'};
        width: ${(props) => props.btnWidth || props.width || '100%'};
        background-color: ${props => props.disabled ? colors.lightGray : (props.theme === 'dark' ? (props?.style?.backgroundColor || colors.secondary) : (props.style?.backgroundColor || colors.blue))};
        border: none;
        color: ${props =>
        props.disabled ? colors.black :
            props.theme === 'dark' ? colors.black :
                (props.style?.color || colors.white)};
        font-size: ${(props) => props.btnStyle?.fontSize || '1em'};
        cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
        transition: background-color 0.3s;
        letter-spacing: 2px;
        font-family: ${colors.openSans};
        padding: ${(props) => props.btnStyle?.padding || '0 1em'};
        ...${(props) => props.btnStyle}
    }
    button:hover, a:hover {
        background-color: ${(props) => props.disabled ? colors.lightGray : (props.btnStyle?.hoverBackgroundColor || colors.darkGray)};
        box-shadow: 1px 2px 8px ${colors.lightBlue};
        color: ${(props) => props.btnStyle?.hoverColor || colors.black};
        color: ${(props) => props.disabled ? colors.white : (props.btnStyle?.hoverColor || colors.black)};
        color: ${props => props.theme === 'dark' ? colors.black : props.btnStyle?.hoverColor || colors.black};
        box-shadow: ${(props) => props.disabled ? 'none' : '1px 2px 8px ' + colors.lightBlue};
    }
    }

    ...${(props) => props.style}
`;

const CallToAction = ({
    disabled,
    text,
    type = 'button',
    onClick,
    style,
    btnStyle,
    btnHeight,
    btnWidth,
    className,
    wrapperClassName = 'callToAction__wrapper',
    linkTo,
    wrapperWidth,
    hasIcon,
    googleAcionRoute,
    controlledTooltipProps = {},
    width = '',
    marginTop = '0',
}) => {
    const { theme } = useTheme();

    const handleClick = (e) => {
        if (googleAcionRoute) {
            try {
                // Evaluate the string as a function
                const actionFunction = new Function(googleAcionRoute);
                actionFunction();
            } catch (error) {
                console.error("Error executing Google Action Route:", error);
            }
        }

        if (onClick) {
            onClick(e);
        }
    };


    return (
        <StyledButton
            className={classnames(wrapperClassName, {
                disabled: disabled
            })}
            btnWidth={btnWidth}
            btnStyle={btnStyle}
            style={style}
            disabled={disabled}
            btnHeight={btnHeight}
            theme={theme}
            wrapperWidth={wrapperWidth}
            width={isEmpty(controlledTooltipProps) && width}
        >
            <ConditionalRender renderIf={!hasIcon}>
                {linkTo ? (
                    <Link to={linkTo} className={className} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {text}
                    </Link>
                ) : (
                    <>
                        <ConditionalRender renderIf={isEmpty(controlledTooltipProps)}>
                            <button
                                className={className}
                                onClick={onClick && handleClick}
                                type={type}
                                disabled={disabled ? disabled : null}
                            >
                                {text}
                            </button>
                        </ConditionalRender>
                        <ConditionalRender renderIf={!isEmpty(controlledTooltipProps)}>
                            <ControlledTooltip
                                {...controlledTooltipProps}
                            >
                                <button
                                    className={className}
                                    onClick={onClick && handleClick}
                                    type={type}
                                    disabled={disabled ? disabled : null}
                                >
                                    {text}
                                </button>
                            </ControlledTooltip>
                        </ConditionalRender>
                    </>
                )}
            </ConditionalRender>
            <ConditionalRender renderIf={hasIcon}>
                <ConditionalRender renderIf={isEmpty(controlledTooltipProps)}>
                    <div
                        className={`icon-button flex flex-column ${className}`}
                        type={type}
                        onClick={onClick && handleClick}
                    >
                        {hasIcon}
                        <p className='mt-sm' style={{
                            textAlign: 'center',
                            fontSize: '0.8em',
                            fontFamily: colors.openSans,
                            color: style?.color || theme === 'dark' ? colors.white : colors.black,
                        }}>
                            {text}
                        </p>
                    </div>
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(controlledTooltipProps)}>
                    <ControlledTooltip
                        {...controlledTooltipProps}
                    >
                        <div
                            className={`icon-button flex flex-column ${className}`}
                            type={type}
                            onClick={onClick && handleClick}
                        >
                            {hasIcon}
                            <p className='mt-sm' style={{
                                textAlign: 'center',
                                fontSize: '0.8em',
                                fontFamily: colors.openSans,
                            }}>
                                {text}
                            </p>
                        </div>
                    </ControlledTooltip>
                </ConditionalRender>
            </ConditionalRender>
        </StyledButton>
    )
}

export default CallToAction;
