import React, { useState, useEffect } from 'react'
import CallToAction from '../Core/CallToAction';
import ConditionalRender from '../Core/ConditionalRender';
import CreateNewEmployeeContainer from './CreateNewEmployeeContainer';
import { Field } from 'react-final-form';
import PaginatedData from '../Core/PaginatedData';
import Checkbox from '../Core/Inputs/CheckBox';
import Wrapper from '../Core/Wrapper';
import colors from '../globalStyles.scss';
import SelectableBox from '../Core/SelectableBox';
import CustomTable from '../Core/Tables/CustomTable';
import { useTheme } from '../context/ThemeContext'
import { employeesColumns, assignedEmployeesColumns } from '../Invetory/columns';
import ConfirmModal from '../Core/ConfirmModal';
import { useNavigate } from 'react-router';
import SubmitModal from '../Core/SubmitModal';
import styled from 'styled-components';
import SearchInput from '../Core/Inputs/SearchInput';
import { isEmpty } from 'lodash';
import useScreenSize from '../context/useScreenSize';
import {
    createUser,
    setRole,
    removeEmployees,
    updateAssignedEmployees,
    updateUpdatedDate
} from '../utils/calls'
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import classnames from 'classnames';
import EmployeeQuickView from '../QuickView/EmployeeQuickView';


const StyledDiv = styled.div`
.EmployeesContainer {
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        a, span {
            color: ${colors.white};
        }
    }
}
`

const StyledEmployeeModal = styled.div`
    width: 100%;
    .employee_wrapper {
        background: ${colors.backgroundSecondary};
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;
        margin-bottom: 1em;
        a, span {
            color: ${colors.white};
        }
    }
`

const EmployeesContainer = ({
    employees,
    filteredEmployees,
    user,
    company,
    fetchCompanyEmployees,
    isProject,
    project,
    title = null,
    showLightColors,
    employeesIsLoading,
    btnWidth = 'auto',

    form = null,
    checkboxSelection = true,
    isDetailsProject,
    EmployeeIsAdmin,
    projectIsComplete,
    openEmployeeModal,
    userRole,
    employeeModalIsOpen,
    id,
    handleGetProject,
    projects,
    isDashboard,
    setExpanded
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop, isPhone } = useScreenSize();
    const { notify } = useToast();
    const navigate = useNavigate();


    const [addEmployee, setAddEmployee] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [employeesList, setEmployeesList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [tableData, setTableData] = useState([]);

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [quickViewData, setQuickViewData] = useState(null);
    const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

    const handleQuickView = (rowData) => {
        setQuickViewData(rowData);
        setIsQuickViewOpen(!isQuickViewOpen);
    };

    useEffect(() => {
        // Get assigned employee IDs from the form
        const assignedEmployeeIds = form?.getState()?.values?.assignedEmployees || [];

        // Map IDs to full employee objects using tableData
        const fullEmployees = assignedEmployeeIds.map((id) =>
            tableData.find((employee) => employee.id === id.id)
        ).filter(Boolean); // Filter out any unmatched IDs

        setSelectedEmployees(fullEmployees);
    }, [form, tableData]);


    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;

        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (sortedEmployees?.length === 0) {
            setTableData([]);
        } else {
            setTableData(sortedEmployees);
        }
    }, [employees, project, employeesIsLoading]);



    const handleAddEmployee = () => {
        setAddEmployee(!addEmployee)
    };
    const [roleType, setRoleType] = useState('Employee');
    const [error, setError] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const assignedIds = new Set(project?.assignedEmployees.map(emp => emp.id));
        const formattedEmployees = isDetailsProject ? filteredEmployees : employees;


        const sortedEmployees = formattedEmployees && [...formattedEmployees].sort((a, b) => {
            if (assignedIds.has(a.id) && !assignedIds.has(b.id)) {
                return -1;
            }
            if (!assignedIds.has(a.id) && assignedIds.has(b.id)) {
                return 1;
            }
            return 0;
        });

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            const filteredEmployees = sortedEmployees.filter((employee) => {
                const fullName = `${employee?.firstName} ${employee?.lastName}`.toLowerCase();
                return fullName.includes(query);
            });
            setEmployeesList(filteredEmployees);
            setTableData(filteredEmployees);
        } else {
            setEmployeesList(sortedEmployees);
            setTableData(sortedEmployees);
        }
    }, [searchQuery, employees, project, employeesIsLoading]);

    const handleEmployeeSelection = (employee) => {
        if (selectedEmployees.some((e) => e._id === employee._id)) {
            setSelectedEmployees((prev) => prev.filter((e) => e._id !== employee._id));
            form.change('assignedEmployees', selectedEmployees.filter((e) => e._id !== employee._id).map((e) => ({ id: e._id })))
        } else {
            setSelectedEmployees((prev) => [...prev, employee]);
            form.change('assignedEmployees', [...selectedEmployees, employee].map((e) => ({ id: e._id })))
        }
    }

    const createNewEmployee = async (values) => {
        values.companyId = company?.id
        values.companyName = company?.companyName
        values.companyIndustry = {
            value: company?.companyIndustry
        }
        const permissions = ['read', 'write']

        setRole({
            companyId: company?.id,
            roleName: roleType,
            permissions
        })
            .then((roleRes) => {
                const roleId = roleRes?.data?._id; // Use _id instead of id
                const roleName = roleRes?.data?.roleName;
                const companyId = roleRes?.data?.companyId?._id;

                return createUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    companyName: roleRes?.data?.companyId?.companyName,
                    companyIndustry: roleRes?.data?.companyId?.companyIndustry,
                    roleId,
                    roleName,
                    companyId
                })
            }).then((res) => {
                if (res.status === 200) {
                    const newEmployee = res.data?.user
                    fetchCompanyEmployees(company?.id)
                    if (isProject) {
                        handleEmployeeSelection(newEmployee);
                    }
                    setAddEmployee(false)
                }
            })

            .catch((error) => {
                notify(`Error: ${error?.message}`, 'error')
                setError(error);
            });
    };

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleRowSelectionChange = (rows) => {
        setSelectedItems(rows);
        setSelectedIds(rows.map(x => x._id));
    };

    const [toggleModal, setToggleModal] = useState(false);

    const handleDeleteSelection = async () => {
        try {
            const res = await removeEmployees(selectedIds);
            if (res.status === 200) {
                setToggleModal(!toggleModal)
                fetchCompanyEmployees(company?.id)
                setSelectedIds([])
                setSelectedItems([])
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleEmployeeSubmit = async (values, e) => {
        try {
            openEmployeeModal()
            const res = await updateAssignedEmployees({ projectId: id, assignedEmployees: values.employee });
            if (res.status === 200) {
                handleGetProject(text?.projects?.history?.updateAssignedEmployee)
                updateUpdatedDate(id)
                notify(text?.notificationsUI?.project?.updated, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }


    return (
        <StyledDiv>
            <Wrapper
                title={title}
                btnWidth={btnWidth}
                isFlex={isProject ? false : true}
                setExpanded={setExpanded}
                section='employees'
                isTransparent={!isDashboard && !isDetailsProject && userRole === 'Admin'}
                expandable
                buttonStyle={{
                    width: '100%',
                }}
                button={
                    <>
                        {/* Dashboard button */}
                        <ConditionalRender renderIf={isDashboard && !isDetailsProject && userRole === 'Admin'}>
                            <CallToAction
                                text={text?.employees?.landing?.createNew}
                                type='button'
                                onClick={() => handleAddEmployee()}
                            />
                        </ConditionalRender>

                        {/* Project Details button */}
                        <ConditionalRender renderIf={!isDashboard && !isDetailsProject && userRole === 'Admin'}>
                            <CallToAction
                                text={text?.employees?.landing?.createNew}
                                type='button'
                                onClick={() => handleAddEmployee()}
                            />
                        </ConditionalRender>

                        {/* Assign button */}
                        <ConditionalRender renderIf={isDetailsProject && !projectIsComplete && userRole === 'Admin'}>
                            <CallToAction
                                text={text?.projects?.details?.employeebutton}
                                type='button'
                                onClick={() => openEmployeeModal()}

                            />
                        </ConditionalRender>
                    </>
                }
            >
                <div
                    className='EmployeesContainer mb-md'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    <StyledEmployeeModal>
                        <div
                            className={classnames({
                                'flex w-100': true,
                                'flex-column': isPhone,
                            })}
                        >
                            {/* Left Section: Available Employees */}
                            <div
                                className={classnames({
                                    'w-50': !isDetailsProject && !isPhone,
                                    'w-100': (!isDetailsProject && isPhone) || isDetailsProject || isDashboard,
                                })}
                            >
                                <div className='flex flex-column filters mt-md'>
                                    <SearchInput
                                        className='w-100'
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        placeHolder={text?.employees?.landing?.search}
                                    />

                                </div>
                                <ConditionalRender renderIf={isProject}>
                                    <p className='flex mt-md'>
                                        {text?.projects?.details?.assignModal?.text}
                                    </p>
                                    <PaginatedData
                                        className="mt-md"
                                        data={tableData?.filter(
                                            (x) =>
                                                x?._id !== user?._id && // Exclude current user
                                                !selectedEmployees.some((e) => e.id === x.id) // Exclude already selected employees
                                        )}
                                        dataIsLoading={employeesIsLoading}
                                        noDataText={text?.employees?.landing?.noData}
                                        searching={searchQuery}
                                        showLightColors
                                        ItemComponent={({ item }) => {
                                            const isChecked = selectedEmployees.some((employee) => item.id === employee.id);

                                            return (
                                                <Field
                                                    name={`employee.${item.id}`}
                                                    type="checkbox"
                                                    component="input"
                                                    initialValue={isChecked}
                                                >
                                                    {({ input }) => (
                                                        <SelectableBox
                                                            key={item.id}
                                                            className="flex justify-space-between w-100"
                                                            showLightColors={showLightColors}
                                                        >
                                                            {/* Main Employee Checkbox */}
                                                            <Checkbox
                                                                id={`employee_${item.id}`}
                                                                disabledPopUp
                                                                checked={input.checked}
                                                                label={`${item?.firstName} ${item?.lastName} (${item?.email})`}
                                                                showLightColors={showLightColors}
                                                                onChange={(e) => {
                                                                    input.onChange(e);

                                                                    const updatedAssignedEmployees = e.target.checked
                                                                        ? [...selectedEmployees, item] // Add to selected
                                                                        : selectedEmployees.filter((employee) => employee.id !== item.id); // Remove from selected

                                                                    // Sync in-memory state and form state
                                                                    setSelectedEmployees(updatedAssignedEmployees);
                                                                    form.change(
                                                                        'assignedEmployees',
                                                                        updatedAssignedEmployees.map((e) => ({ id: e.id }))
                                                                    );
                                                                }}
                                                            />
                                                        </SelectableBox>
                                                    )}
                                                </Field>
                                            );
                                        }}
                                    />
                                </ConditionalRender>
                            </div>
                            <ConditionalRender renderIf={isProject}>

                                {/* Right Section: Selected Employees */}
                                <div
                                    className={classnames({
                                        'w-50': !isPhone,
                                        'ml-md': !isPhone,
                                    })}
                                >
                                    <h4 className="mb-md">{text?.paginated?.selected}</h4>
                                    <p className="mb-md">{text?.projects?.details?.assignModal?.text}</p>
                                    <div>
                                        {selectedEmployees.map((employee) => (
                                            <Field
                                                name={`employee.${employee.id}`}
                                                type="checkbox"
                                                component="input"
                                                initialValue={true}
                                                key={employee.id}
                                            >
                                                {({ input }) => (
                                                    <SelectableBox
                                                        key={employee.id}
                                                        className='flex flex-column w-100'
                                                        showLightColors={showLightColors}
                                                    >
                                                        {/* Main Checkbox */}
                                                        <Checkbox
                                                            id={`selected_employee_${employee.id}`}
                                                            disabledPopUp
                                                            checked={input.checked}
                                                            label={`${employee?.firstName} ${employee?.lastName} (${employee?.email})`}
                                                            showLightColors={showLightColors}
                                                            onChange={(e) => {
                                                                input.onChange(e);

                                                                // Move to the left section when unchecked
                                                                if (!e.target.checked) {
                                                                    setSelectedEmployees((prev) =>
                                                                        prev.filter((e) => e.id !== employee.id)
                                                                    );

                                                                    // Update form state for assignedEmployees
                                                                    form.change(
                                                                        'assignedEmployees',
                                                                        selectedEmployees
                                                                            .filter((e) => e.id !== employee.id)
                                                                            .map((e) => ({ id: e.id }))
                                                                    );
                                                                }
                                                            }}
                                                        />

                                                        {/* Admin Checkbox */}
                                                        <Field
                                                            name={`employee.isAdmin.${employee.id}`}
                                                            type="checkbox"
                                                            component="input"
                                                            initialValue={project?.adminEmployees?.some(
                                                                (admin) => admin.id === employee.id
                                                            )}
                                                        >
                                                            {({ input: adminInput }) => (
                                                                <Checkbox
                                                                    className='mt-sm'
                                                                    id={`admin_${employee.id}`}
                                                                    disabledPopUp
                                                                    checked={adminInput.checked}
                                                                    showLightColors={showLightColors}
                                                                    label={text?.employees?.details?.admin}
                                                                    onChange={(e) => {
                                                                        adminInput.onChange(e);

                                                                        // Update admin state only
                                                                        const updatedAdminEmployees = e.target.checked
                                                                            ? [...(project?.adminEmployees || []), employee]
                                                                            : project?.adminEmployees?.filter(
                                                                                (admin) => admin.id !== employee.id
                                                                            );

                                                                        form.change(
                                                                            'adminEmployees',
                                                                            updatedAdminEmployees.map((admin) => ({
                                                                                id: admin.id,
                                                                            }))
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SelectableBox>
                                                )}
                                            </Field>
                                        ))}
                                    </div>
                                </div>
                            </ConditionalRender>

                        </div>
                    </StyledEmployeeModal>
                    <ConditionalRender renderIf={!isProject}>
                        <div className='flex flex-column align-start mt-md'>
                            <CustomTable
                                keyField="_id"
                                columns={isDetailsProject ? assignedEmployeesColumns({
                                    theme,
                                    text,
                                    userRole,
                                    project,
                                    navigate,
                                    handleQuickView,
                                    isDesktop
                                }) : employeesColumns({
                                    theme,
                                    text,
                                    navigate,
                                    userRole,
                                    handleQuickView,
                                    isDesktop
                                })}
                                rows={tableData?.filter((x) => x?._id !== user?._id)}
                                theme={theme}
                                noDataText={text?.employees?.landing?.noData}
                                onRowSelectionChange={handleRowSelectionChange}
                                checkboxSelection={userRole === 'Admin' ? checkboxSelection : false}
                            />
                            <ConditionalRender renderIf={!isProject && !isEmpty(selectedIds)}>
                                <div style={{
                                    color: colors.red,
                                    fontFamily: colors.openSans,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    marginBottom: '5px'
                                }} onClick={() => setToggleModal(true)} >
                                    {text?.employees?.landing?.deleteSelected}
                                </div>

                                <ConfirmModal
                                    onClick={handleDeleteSelection}
                                    text={text?.inventory?.landing?.table?.modal?.button}
                                    toggle={() => setToggleModal(!toggleModal)}
                                    isOpen={toggleModal}

                                    btnStyles={{
                                        color: colors.red
                                    }}
                                    width={isDesktop ? '50%' : '100%'}
                                    height={isDesktop ? '50%' : '100%'}

                                >
                                    <div>
                                        <h4 style={{ marginBottom: '15px' }}>
                                            {text?.inventory?.landing?.table?.modal?.title}
                                        </h4>
                                        <ul>
                                            {selectedItems?.map((x) => {
                                                return (
                                                    <li style={{ fontSize: '0.9rem' }}>
                                                        - {`${x?.firstName} ${x?.lastName}`}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </ConfirmModal>
                            </ConditionalRender>

                        </div>
                    </ConditionalRender>

                    <ConditionalRender renderIf={!isEmpty(error)}>
                        <span className='error'>
                            {error}
                        </span>
                    </ConditionalRender>

                    {/* Modals */}
                    {/* Quick View */}
                    <EmployeeQuickView
                        isQuickViewOpen={isQuickViewOpen}
                        setIsQuickViewOpen={setIsQuickViewOpen}
                        quickViewData={quickViewData}
                        noOverlay={false}
                    />
                    <ConditionalRender renderIf={addEmployee}>
                        <CreateNewEmployeeContainer
                            isOpen={addEmployee}
                            onClick={createNewEmployee}
                            toggle={() => setAddEmployee(false)}
                            setRoleType={setRoleType}
                            roleType={roleType}
                            employees={employees}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={isDetailsProject && employeeModalIsOpen}>
                        <SubmitModal
                            onClick={(values, e) => handleEmployeeSubmit(values, e)}
                            text={text?.projects?.details?.assignModal?.button}
                            toggle={() => {
                                openEmployeeModal()
                                setSelectedEmployees([])
                                form.change('assignedEmployees', project?.assignedEmployees.map((e) => ({ id: e.id })))

                            }}
                            disabled={isEmpty(employees?.filter((x) => x?._id !== user?._id))}
                            isOpen={employeeModalIsOpen}
                            width={isDesktop ? '80%' : '100%'}
                            height={isDesktop ? '80%' : '100%'}
                            btnStyles={{
                                color: colors.blue
                            }}
                        >
                            <div className='w-100'>
                                <h4 className='flex justify-center'>
                                    {text?.projects?.details?.assignModal?.title}
                                </h4>
                                <div className='w-100 mt-md'>
                                    <EmployeesContainer
                                        employees={employees}
                                        userRole={userRole}
                                        user={user}
                                        projects={projects}
                                        company={company}
                                        fetchCompanyEmployees={fetchCompanyEmployees}
                                        isProject
                                        project={project}
                                        showLightColors
                                        employeesIsLoading={employeesIsLoading}
                                        btnWidth='100%'
                                        form={form}
                                    />
                                </div>
                            </div>

                        </SubmitModal>
                    </ConditionalRender>
                </div>
            </Wrapper>
        </StyledDiv>
    )
}

export default EmployeesContainer