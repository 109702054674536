import request from '../request';

/**
 * get single product inventory request to the API.
 * @returns {Promise<Object>} - A Promise that resolves to the API response data.
 * @throws {Error} - If the API request fails, an Error is thrown.
 */
export const deleteChatHistoryFromS3 = async () => {
    try {
        const { data, status } = await request('DELETE', `/search/delete-chat-history`);
        return { data, status };
    } catch (error) {
        throw new Error(`Failed to delete history: ${error.message}`);
    }
};

export default deleteChatHistoryFromS3;
