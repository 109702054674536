import React, { useState } from 'react';
import { Field } from 'react-final-form';
import ConditionalRender from '../ConditionalRender'
import { FieldStyles } from './FieldStyles';
import { useTheme } from '../../context/ThemeContext'
import classnames from 'classnames';

const TextAreaField = ({
    className,
    name,
    label,
    setIsEditing,
    info,
    showLightColors,
    disabled,
    isDark = false,
    ...rest
}) => {
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;

    const [value, setValue] = useState('')
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FieldStyles
            className={className}
            value={value}
            theme={theme}
            showLightColors={showLightColors}
            disabled={disabled}
        >
            <label className='mb-sm b flex'>
                {label}
                <ConditionalRender renderIf={info}>
                    <div className="info">{info}</div>
                </ConditionalRender>
            </label>
            <div className={`custom-field`}>
                <Field name={name} {...rest}>
                    {({ input, meta }) => {

                        setValue(input.value)
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                                <textarea
                                    {...input}
                                    {...meta}
                                    className={classnames({
                                        'error_message': meta.error && meta.touched
                                    })}
                                    disabled={disabled}
                                    type={'textarea'}
                                    id={name}
                                    value={input.value}
                                    onChange={(event) => {
                                        input.onChange(event)
                                        handleChange(event)
                                        if (setIsEditing) {
                                            setIsEditing(true)
                                        }
                                    }}
                                    {...rest}
                                />
                                {meta.error && meta.touched && (
                                    <span className='small error' style={{ alignSelf: 'end' }}>{meta.error}</span>
                                )}
                            </div>
                        )
                    }}
                </Field>
            </div>
        </FieldStyles >
    );
};

export default TextAreaField;
