import React, { useState } from 'react'
import styled from 'styled-components'
import HoverPopup from './HoverPopup'
import ConditionalRender from './ConditionalRender'
import colors from '../globalStyles.scss'
import Switch from '@mui/material/Switch';
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext'
import useScreenSize from '../context/useScreenSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames'

const StyledDiv = styled.div`
    position: relative;
    transition: all 0.3s;

    .header-wrapper {
        background: ${props =>
        props.isTransparent ? 'transparent' :
            ((props.theme === 'dark' && !props.showLightColors) ? colors.backgroundSecondary : colors.darkGray) ||
            (props.theme === 'dark' && props.showLightColors ? colors.lightGray : colors.backgroundSecondary)
    };
        transition: all 0.3s;
    }

    &:hover {
        .header-wrapper {
            background: ${props =>
        (!props.noHover && props.theme === 'dark') ? colors.primary :
            (!props.noHover && props.theme === 'light') ? colors.lightGray :
                'transparent'
    } !important;
        }
    }

    &.desktop {
        .SelectableBox {
            width: 30%;
        }
    }

    &.tablet {
        .SelectableBox {
            width: 50%;
        }
    }

    &.phone {
        .SelectableBox {
            width: 100%;
        }
        
        .header-wrapper {
            padding: 0.5em;

            .small {
                button, .dropzone {
                    padding: 0.5em;
                    font-size: 0.8em;
                    min-height: 20px;
                    margin-left: 0;
                    margin: 0.5em;
                }
            }
        }
    }

    h4 {
        font-size: 1em;  
    }
`;


const Wrapper = ({
    title,
    children,
    flex,
    margin,
    padding,
    width,
    btnWidth,
    borderRight,
    button,
    secondaryButton,
    buttonStyle = {},
    showLightColors,
    disableButton,
    noDataText,
    isLoading,
    className,
    toggle,
    toggleState,
    isFlex = true,
    expandable,
    setExpanded,
    section,
    h1 = false,
    bodyClassName = 'mt-md mb-md',
    isDark = false,
    showWrapper = true,
    isTransparent = false,
    noHover = false
}) => {
    let { theme } = useTheme();
    theme = isDark ? 'dark' : theme;
    const { text } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();

    const handleToggleModal = () => {
        setExpanded(section);
    };

    return (
        <ConditionalRender renderIf={showWrapper}>
            <StyledDiv
                isTransparent={isTransparent}
                showLightColors={showLightColors}
                noHover={noHover}
                theme={theme}
                className={classnames({
                    [className]: className,
                    desktop: isDesktop,
                    tablet: isTablet,
                    phone: isPhone
                })}
                style={{
                    flex: flex,
                    margin: margin,
                    padding: padding,
                    width: width,
                    borderRight: borderRight
                }}
            >
                <ConditionalRender
                    count={1}
                    customStyled={{
                        height: '50vh',
                        display: 'flex'
                    }}
                    isLoading={isLoading} renderIf={true}>
                    <div
                        className={classnames({
                            'flex': isFlex,
                            'justify-space-between items-center flex-wrap header-wrapper': true,
                        })}
                        style={{
                            padding: '0em 0.5em'
                        }}
                    >
                        <ConditionalRender renderIf={title}>
                            <ConditionalRender renderIf={toggle}>
                                <div className='flex align-center'>
                                    <h4 className='mb-md mt-md mr-md flex items-center'>
                                        <ConditionalRender renderIf={expandable}>
                                            <HoverPopup
                                                className='mr-sm'
                                                placement={'top'}
                                                id={`expand_${section}_popup_${title}`}
                                                text={isDesktop && text?.editToggle?.expand}
                                                onClick={handleToggleModal}
                                                style={{
                                                    background: 'transparent'
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExpand}
                                                />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        {title}
                                    </h4>
                                    <div className='ml-md flex items-center'>
                                        <Switch
                                            checked={toggleState}
                                            onChange={toggle}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <span
                                            style={{
                                                fontFamily: colors.openSans,
                                                color: toggleState ? (theme === 'dark' ? colors.white : colors.blue) : (theme === 'dark' ? colors.white : colors.blue)
                                            }}
                                        >{!toggleState ? text?.editToggle?.off : text?.editToggle?.on}
                                        </span>
                                    </div>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender renderIf={!toggle}>
                                <ConditionalRender renderIf={h1}>
                                    <h1 className={classnames({
                                        'mb-sm mt-sm': !isDesktop,
                                        'mb-md mt-md': isDesktop,
                                        'mr-md flex items-center': true,
                                    })}>
                                        <ConditionalRender renderIf={expandable}>
                                            <HoverPopup
                                                className='mr-sm'
                                                placement={'top'}
                                                id={`expand_${section}_popup`}
                                                text={isDesktop && text?.editToggle?.expand}
                                                onClick={handleToggleModal}
                                                style={{
                                                    background: 'transparent',
                                                    color: theme === 'dark' ? colors.white : colors.blue
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExpand}
                                                />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        {title}
                                    </h1>
                                </ConditionalRender>
                                <ConditionalRender renderIf={!h1}>
                                    <h4
                                        style={{
                                            color: theme === 'dark' ? colors.white : colors.dark
                                        }}
                                        className={classnames({
                                            'mb-sm mt-sm': !isDesktop,
                                            'mb-md mt-md': isDesktop,
                                            'mr-md flex items-center': true,
                                        })}>
                                        <ConditionalRender renderIf={expandable}>
                                            <HoverPopup
                                                className='mr-sm'
                                                placement={'top'}
                                                id={`expand_${section}_popup`}
                                                text={isDesktop && text?.editToggle?.expand}
                                                onClick={handleToggleModal}
                                                style={{
                                                    background: 'transparent',
                                                    color: theme === 'dark' ? colors.white : colors.blue
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExpand}
                                                />
                                            </HoverPopup>
                                        </ConditionalRender>
                                        {title}
                                    </h4>
                                </ConditionalRender>
                            </ConditionalRender>
                        </ConditionalRender>
                        <div className='flex items-center'>
                            <ConditionalRender renderIf={!isPhone}>
                                <ConditionalRender renderIf={button && !disableButton}>
                                    <div style={buttonStyle} className='flex'>
                                        <div
                                            className={classnames({
                                                'mb-md': isPhone
                                            })}
                                            style={{
                                                width: btnWidth || 'auto',
                                            }}
                                        >
                                            {button}
                                        </div>
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender renderIf={secondaryButton}>
                                    <div className='flex mt-sm mb-sm'>

                                        <div
                                            className={classnames({
                                                'mb-md': isPhone
                                            })}
                                            style={{
                                                width: btnWidth || 'auto'
                                            }}
                                        >
                                            {secondaryButton}
                                        </div>
                                    </div>
                                </ConditionalRender>
                            </ConditionalRender>
                            <div>
                                <ConditionalRender renderIf={isPhone && button && !disableButton}>
                                    <div
                                        className={classnames({
                                            'small': isPhone
                                        })}
                                        style={{
                                            width: btnWidth || 'auto',
                                        }}
                                    >
                                        {button}
                                    </div>
                                </ConditionalRender>
                                <ConditionalRender renderIf={isPhone && secondaryButton}>
                                    <div
                                        className={classnames({
                                            'small': isPhone
                                        })}
                                        style={{
                                            width: btnWidth || 'auto'
                                        }}
                                    >
                                        {secondaryButton}
                                    </div>
                                </ConditionalRender>
                            </div>
                        </div>
                    </div>
                    <div className={`body ${bodyClassName}`}>
                        <ConditionalRender renderIf={noDataText}>
                            <p>
                                {noDataText}
                            </p>
                        </ConditionalRender>
                        {children}
                    </div>
                </ConditionalRender>
            </StyledDiv>
        </ConditionalRender>
    );
};

export default Wrapper;