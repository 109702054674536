import React, { useRef, useState, useCallback, useEffect, useContext, useMemo } from 'react';
import ImageUploader from '../ImageUploader';// Adjust the import path
import { Editor } from '@tinymce/tinymce-react';
import { Form } from 'react-final-form';
import ConditionalRender from '../ConditionalRender';
import colors from '../../globalStyles.scss'; // Adjust the import path
import { useLanguage } from '../../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import HoverPopup from '../HoverPopup';
import axios from 'axios';
import ConfirmModal from '../ConfirmModal';
import useScreenSize from '../../context/useScreenSize';
import styled from 'styled-components';
import { isEmpty, debounce } from 'lodash';
import FieldWithNoValidation from '../FieldWithNoValidation';
import CallToAction from '../CallToAction';
import { useWebSocket } from '../../context/WebSocketContext';
import { GlobalContext } from '../../context/GlobalContext';
import SelectableBox from '../SelectableBox';
import PaginatedData from '../PaginatedData';
import SearchInput from './SearchInput';

const StyledDiv = styled.div`
    .emoji-picker {
       button {
        cursor: pointer;
        transition: transform 0.2s ease;
         &:hover {
            transform: scale(1.2);
        }
       }
    }
       
`

const RichEditorWithImageUploader = ({
    onImageUpload,
    onFileUpload,
    maxFiles,
    customMessage,
    title,
    showForm = true,
    onSubmit,
    submitText = 'Submit',
    name = 'message',
    isEditing,
    initialValue,
    onCancel,
    customButton,
    isSendingMessage,
    imagePreviews,
    setImagePreviews,
    setFilesPreviews,
    filesPreviews,
    isDark = false,
    noGif = false,
    onChange,
    height = 150,
    noKeyDown = false,
    noButton = false,
    hideSave = false,
}) => {
    const {
        tasks, projects
    } = useContext(GlobalContext);
    const {
        chatData,
        participants,
        clientParticipants,
        channelAdmins,
        selectedConversationId,
        selectedProjectId,
        handleUpdateParticipants,
        user,
        setIsTyping,
        isTyping,
        handleTyping,
        selectedConversation
    } = useWebSocket();
    const { isDesktop } = useScreenSize();
    const formRef = useRef(null);
    const selectedProjectIdRef = useRef(selectedProjectId);
    const conversationIdRef = useRef(selectedConversationId);
    const selectedConversationRef = useRef(selectedConversation);
    const participantsRef = useRef(participants);
    const clientParticipantsRef = useRef(clientParticipants);
    const channelAdminsRef = useRef(channelAdmins);
    const { text } = useLanguage();

    const editorRef = useRef(null);
    const [editorValue, setEditorValue] = useState(initialValue || '');

    const handleKeyDownRef = useRef();
    const imagePreviewsRef = useRef(imagePreviews);
    const filePreviewRef = useRef(filesPreviews);

    const [showGifModal, setShowGifModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showMentionModal, setShowMentionModal] = useState(false);
    const [gifSearchResults, setGifSearchResults] = useState([]);
    const [gifSearchQuery, setGifSearchQuery] = useState('');
    const [selectedMentions, setSelectedMentions] = useState([]);
    const [mentionActive, setMentionActive] = useState(false);
    const [filters, setFilters] = useState({});
    const [taskData, setTaskData] = useState([]);
    const [mentionData, setMentionData] = useState([]);

    useEffect(() => {
        if (isTyping) {
            handleTyping(true, conversationIdRef.current, user);
        } else {
            handleTyping(false, conversationIdRef.current, user);
        }
    }, [isTyping])


    const [selectedFilters, setSelectedFilters] = useState({
        query: '',
        mentionQuery: '',
    });

    const debouncedSetFilters = useMemo(
        () =>
            debounce((updatedFilters) => {
                setFilters((prev) => ({
                    ...prev,
                    ...updatedFilters,
                }));
            }, 500),
        []
    );

    const handleSearchChange = (e) => {
        const queryValue = e?.target?.value ?? '';
        setSelectedFilters((prev) => ({ ...prev, query: queryValue }));
        debouncedSetFilters({ query: queryValue });
    };

    const handleMentionSearchChange = (e) => {
        const queryValue = e?.target?.value ?? '';
        setSelectedFilters((prev) => ({ ...prev, mentionQuery: queryValue }));
        debouncedSetFilters({ mentionQuery: queryValue });
    }

    const checkConversationConditions = () => {
        // Condition A: The user is an Admin
        const isUserAdmin = user?.roleName === 'Admin';

        const adminProjects = projects?.filter(project => {
            // Check if the user is an admin
            return project.adminEmployees?.some(employee => employee?.id === user?.id)
                || user?.roleName === 'Admin';
        });

        // Condition B: The conversation has a project AND the user is an admin of that project
        const isUserProjectAdmin = adminProjects?.some(
            (project) =>
                project?._id === (selectedConversation?.projectId?._id || selectedConversation?.projectId)
        );

        // Condition C: It’s a channel (no project) AND the user is a channel admin
        const isChannelAdmin =
            !selectedConversation?.projectId &&
            selectedConversation?.isChannel &&
            selectedConversation?.channelAdmins?.includes(user?._id);

        // Condition D: It’s neither a project nor a channel
        const isNeitherProjectNorChannel =
            !selectedConversation?.projectId &&
            !selectedConversation?.isChannel;

        // Return true if ANY condition is true:
        return (
            isUserAdmin ||
            isUserProjectAdmin ||
            isChannelAdmin ||
            isNeitherProjectNorChannel
        );
    };



    const filterData = useCallback(() => {
        const filterOptions = {};

        const query = filters?.query ? String(filters.query).toLowerCase() : '';
        const mentionQuery = filters?.mentionQuery ? String(filters.mentionQuery).toLowerCase() : '';

        if (query) {
            filterOptions.query = query;
        }

        if (mentionQuery) {
            filterOptions.mentionQuery = mentionQuery;
        }

        let filteredData = tasks || [];
        let filteredMentions = chatData
            ?.filter((x) => x?.type !== 'project')
            ?.filter((x) => x?.type !== 'channel') || [];

        if (filterOptions.query) {
            filteredData = filteredData.filter(
                (item) =>
                    item?.taskTitle?.toLowerCase().includes(filterOptions.query) ||
                    item?.uuid?.toLowerCase().includes(filterOptions.query)
            );
        }

        if (filterOptions.mentionQuery) {
            filteredMentions = filteredMentions.filter((item) => {
                return (
                    item?.name?.toLowerCase().includes(filterOptions.mentionQuery) ||
                    item?.email?.toLowerCase().includes(filterOptions.mentionQuery) ||
                    item?.type?.toLowerCase().includes(filterOptions.mentionQuery)
                );
            });
        }

        if (query) {
            setTaskData(filteredData);
        }

        if (mentionQuery) {
            setMentionData(filteredMentions);
        }
    }, [filters, tasks, chatData]);


    useEffect(() => {
        if (filters.query || filters.mentionQuery) {
            filterData();
        } else {
            setTaskData(tasks);
            const filteredMentions = chatData
                ?.filter((x) => x?.type !== 'project')
                ?.filter((x) => x?.type !== 'channel')
            setMentionData(filteredMentions);
        }

        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        selectedProjectIdRef.current = selectedProjectId;
    }, [selectedProjectId]);
    useEffect(() => {
        conversationIdRef.current = selectedConversationId;
    }, [selectedConversationId]);
    useEffect(() => {
        participantsRef.current = participants;
    }, [participants]);

    useEffect(() => {
        clientParticipantsRef.current = clientParticipants;
    }, [clientParticipants]);

    useEffect(() => {
        selectedConversationRef.current = selectedConversation;
    }, [selectedConversation]);

    useEffect(() => {
        if (isEmpty(channelAdmins)) return;
        channelAdminsRef.current = channelAdmins;
    }, [channelAdmins]);


    useEffect(() => {
        const observer = new MutationObserver(() => {
            const mentionDropdown = document.querySelector('.tox-autocompleter');
            const isMentionActive = mentionDropdown && mentionDropdown.offsetParent !== null;

            if (isMentionActive) {
                setMentionActive(true);
            } else {
                setMentionActive(false);
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect(); // Cleanup observer
    }, []);


    useEffect(() => {
        imagePreviewsRef.current = imagePreviews;
    }, [imagePreviews]);

    useEffect(() => {
        filePreviewRef.current = filesPreviews;
    }, [filesPreviews]);

    const handleKeyDown = useCallback(
        async (e) => {
            if (noKeyDown) return;

            const content = editorRef.current?.getContent({ format: 'html' })?.trim();

            if (e.key === 'Enter' && !e.shiftKey) {
                if (mentionActive) {
                    setTimeout(() => {
                        setMentionActive(false);
                    }, 200);
                    return;
                }

                e.preventDefault();

                const currentImagePreviews = imagePreviewsRef.current;
                const currentFiles = filePreviewRef.current;

                if (content) {
                    if (showForm) {
                        const form = formRef.current;
                        if (form) {
                            form.change(name, content);
                            await form.submit();
                        }
                    } else {
                        if (onSubmit) {
                            const mentionedUsers = extractMentionsFromEditor(content);
                            const mentionedClientIds = mentionedUsers
                                .filter(user => user.type === 'client')
                                .map(user => user.id);

                            const mentionedEmployeeIds = mentionedUsers
                                .filter(user => user.type === 'employee')
                                .map(user => user.id);

                            onSubmit({
                                [name]: content,
                                images: currentImagePreviews,
                                files: currentFiles,
                                mentions: mentionedUsers,
                            });
                            setIsTyping(false);
                            handleTyping(false, conversationIdRef.current, user);
                            // if (!isEmpty(mentionedUsers)) {
                            //     const isChannel = selectedConversationRef.current?.isChannel;
                            //     const canUpdate = checkConversationConditions();
                            //     if (canUpdate) {
                            //         handleUpdateParticipants(
                            //             conversationIdRef.current,
                            //             [...new Set([...participantsRef.current, ...mentionedEmployeeIds])],
                            //             [...new Set([...clientParticipantsRef.current, ...mentionedClientIds])],
                            //             [...new Set([...channelAdminsRef.current])],
                            //             user,
                            //             selectedProjectIdRef.current,
                            //             isChannel
                            //         );
                            //     }

                            // }

                        }
                    }

                    editorRef.current?.setContent('');
                    setEditorValue('');

                    setTimeout(() => {
                        setSelectedMentions([]);
                        setImagePreviews && setImagePreviews([]);
                        setFilesPreviews && setFilesPreviews([]);
                    }, 500);
                }
            } else if (e.key === 'Escape') {
                e.preventDefault();
                if (onCancel) {
                    onCancel();
                }
            }
        },

        // eslint-disable-next-line
        [name, onSubmit, onCancel, showForm]
    );

    const selectedMentionsRef = useRef([]);

    const addMention = (userInfo) => {
        setSelectedMentions((prevUsers) => {
            if (!prevUsers.some(user => user.id === userInfo.id)) {
                const updatedMentions = [...prevUsers, {
                    id: userInfo.id,
                    name: userInfo.name,
                    type: userInfo.type,
                    full_name: userInfo.full_name,
                    email: userInfo.email
                }];

                selectedMentionsRef.current = updatedMentions; // ✅ Update ref
                return updatedMentions;
            }
            return prevUsers;
        });

    };

    // Update the ref with the latest handleKeyDown function
    useEffect(() => {
        handleKeyDownRef.current = handleKeyDown;
    }, [handleKeyDown]);

    // Ensure proper listener setup
    useEffect(() => {
        const editor = editorRef.current;
        if (!editor) return;

        const keyDownHandler = (e) => {
            handleKeyDownRef.current?.(e); // Use the latest handler
        };
        editor.on('keydown', keyDownHandler);

        return () => {
            editor.off('keydown', keyDownHandler); // Cleanup listener
        };
    }, []); // Attach listener only once

    const removeCommandFromEditor = (editor, command) => {
        const content = editor.getContent({ format: 'text' });
        const newContent = content.replace(command, '').trim(); // Remove only the command
        editor.setContent(newContent); // Set updated content
    };


    const handleEmojiPicker = (editor) => {
        const pickerId = `emoji-picker-${editor.id}`;
        let emojiPicker = document.getElementById(pickerId);

        const closeEmojiPicker = () => {
            if (emojiPicker) {
                emojiPicker.remove();
                document.removeEventListener('click', handleOutsideClick);
                editor.iframeElement?.contentWindow?.removeEventListener('click', closeEmojiPicker);
            }
        };

        const handleOutsideClick = (e) => {
            const editorContainer = editor.getContainer(); // Get TinyMCE editor container
            if (
                emojiPicker &&
                !emojiPicker?.contains(e.target) &&
                !editorContainer?.contains(e.target) // Closes when clicking inside the editor
            ) {
                closeEmojiPicker();
            }
        };

        const parentDiv = editor.getContainer();
        if (!parentDiv) {
            console.error('Editor container not found.');
            return;
        }

        if (emojiPicker) {
            closeEmojiPicker();
        } else {
            emojiPicker = document.createElement('div');
            emojiPicker.id = pickerId;
            emojiPicker.classList.add('emoji-picker');
            emojiPicker.classList.add('scroll-container');
            emojiPicker.style.position = 'absolute';
            emojiPicker.style.height = '250px'; // Limit height with scroll
            emojiPicker.style.overflowY = 'auto';
            emojiPicker.style.top = '-60px';
            emojiPicker.style.left = '50%';
            emojiPicker.style.transform = 'translate(-50%, 0)';
            emojiPicker.style.zIndex = '9999';
            emojiPicker.style.background = '#fff';
            emojiPicker.style.padding = '10px';
            emojiPicker.style.borderRadius = '5px';
            emojiPicker.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
            emojiPicker.style.display = 'grid';
            emojiPicker.style.gridTemplateColumns = 'repeat(8, 1fr)';
            emojiPicker.style.gap = '5px';

            // List of emojis
            const emojis = [
                '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '🥰', '😗', '😙', '😚',
                '🙂', '🤗', '🤩', '🤔', '🤨', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '🥱',
                '😴', '😌', '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '☹️', '🙁', '😖', '😞', '😟',
                '😤', '😢', '😭', '😦', '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '🥵', '🥶', '😳', '🤪', '😵', '🥴', '😠',
                '😡', '🤬', '🤡', '👹', '👺', '💀', '👻', '👽', '🤖', '💩', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿',
                '😾', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕',
                '👇', '☝️', '👍', '👎', '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '💅', '🤳', '💪', '🦾'
            ];

            emojis.forEach((emoji) => {
                const button = document.createElement('button');
                button.textContent = emoji;
                button.style.border = 'none';
                button.style.background = 'transparent';
                button.style.fontSize = '20px';
                button.style.cursor = 'pointer';
                button.style.padding = '5px';

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    closeEmojiPicker();
                    editor.insertContent(emoji);
                });

                emojiPicker.appendChild(button);
            });

            parentDiv.appendChild(emojiPicker);
            document.addEventListener('click', handleOutsideClick);

            // Add event listener inside the TinyMCE editor's iframe to close picker when clicking inside the editor
            setTimeout(() => {
                const iframe = editor.iframeElement?.contentWindow;
                if (iframe) {
                    iframe.addEventListener('click', closeEmojiPicker);
                }
            }, 500); // Small delay to ensure TinyMCE is ready
        }
    };

    const handleEditorInit = (editor) => {
        editor.ui.registry.addButton('customInsertEmoji', {
            text: '😀',
            onAction: () => handleEmojiPicker(editor),
        });
    };

    const fetchGifs = async (query, isShuffle = false) => {
        const apiKey = process.env.REACT_APP_GIPHY_API_KEY;
        try {
            const randomOffset = isShuffle ? Math.floor(Math.random() * 100) : 0; // Use random offset for shuffle
            const response = await axios.get(`https://api.giphy.com/v1/gifs/search`, {
                params: {
                    api_key: apiKey,
                    q: query,
                    limit: 5,
                    offset: randomOffset,
                },
            });
            setGifSearchResults(response.data.data);
        } catch (error) {
            console.error('Error fetching GIFs:', error);
        }
    };

    const handleInsertGif = (gifUrl) => {
        const editor = editorRef.current;
        if (editor) {
            editor.insertContent(`<img src="${gifUrl}" alt="GIF" style="max-width: 100%;" />`);
            setShowGifModal(false);
        }
        setGifSearchQuery('');
        setGifSearchResults([]);
    };

    const insertTaskMention = (task) => {
        const editor = editorRef.current;
        if (editor) {
            editor.insertContent(`<span 
            style="color: ${colors.white};  background-color: ${colors.task}; padding: 2px 5px; border-radius: 5px; margin: 0 2px;"
                class="taskmention" data-task-id="${task._id}">${task.uuid}</span>`);
        }

        setShowTaskModal(false);
        setFilters({ query: '' });
        setSelectedFilters({ query: '' });

    };

    const insertMention = (user) => {
        console.log(user)
        const editor = editorRef.current;
        if (editor) {
            editor.insertContent(`<span 
            style="color: ${colors.white};  background-color: ${colors.primary}; padding: 2px 5px; border-radius: 5px; margin: 0 2px;"
                class="mymention" data-mention-id="${user.id}">@${user.name}</span>`);
        }

        setShowMentionModal(false);
        setSelectedFilters({ mentionQuery: '' });
        setFilters({ mentionQuery: '' });
    }

    const fetchUsers = (query, process) => {
        // Filter users based on query
        const filteredUsers = chatData
            ?.filter((x) => x?.type !== 'project')
            ?.filter((x) => x?.type !== 'channel')
            ?.filter(user =>
                user.name?.toLowerCase().includes(query.term?.toLowerCase()) ||
                user.email?.toLowerCase().includes(query.term?.toLowerCase()) ||
                user.type?.toLowerCase().includes(query.term?.toLowerCase())
            );

        const limitedResults = filteredUsers.slice(0, Math.max(5, query.term.length));

        const mentionOptions = limitedResults.map(user => ({
            id: user._id,
            name: `${user.name} (${user.type})`,
            type: user.type,
            value: `@${user.name}`,
            full_name: `${user.name}`,
            email: user.email
        }));

        process(mentionOptions);
    };


    const extractMentionsFromEditor = (content) => {

        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const mentionElements = doc.querySelectorAll('.mymention');
        const mentionedUsers = new Set();

        mentionElements.forEach(span => {
            const mentionId = span.getAttribute('data-mention-id');
            const mentionName = span.textContent.replace('@', '').trim();
            // ✅ Use ref to avoid empty `selectedMentions` during extraction
            const user = selectedMentionsRef.current.find(user => user.id === mentionId || user.full_name === mentionName);

            if (user) {
                mentionedUsers.add(JSON.stringify(user));
            }
        });

        const uniqueMentionedUsers = Array.from(mentionedUsers).map(user => JSON.parse(user));
        return uniqueMentionedUsers;
    };

    const handleEditorChange = (content) => {
        setEditorValue(content);

        if (onChange) {
            onChange(content);
        }
        handleTypingEvent();
    };
    let typingTimeout = null;
    const handleTypingEvent = () => {
        if (!isTyping) {
            setIsTyping(true);
            handleTyping(true, conversationIdRef.current, user);
        }
        if (typingTimeout) clearTimeout(typingTimeout);
        typingTimeout = setTimeout(() => {
            setIsTyping(false);
            handleTyping(false, conversationIdRef.current, user);
        }, 2000);
    }

    return (
        <ImageUploader
            onImageUpload={onImageUpload}
            onFileUpload={onFileUpload}
            maxFiles={maxFiles}
            title={title}
            isEditing={isEditing}
            imageSize={'100px'}
            customButton={customButton}
            isSendingMessage={isSendingMessage}
            isDark={isDark}
            noButton={noButton}
            hideSave={hideSave}
            icon
        >
            <StyledDiv className="chat-text relative" style={{
                zIndex: 0,
            }}>
                <ConditionalRender renderIf={showForm}>
                    <Form
                        onSubmit={(values, form) => {
                            onSubmit(values, form)
                            form.reset();
                            setEditorValue('');
                            setIsTyping(false);
                            handleTyping(false, conversationIdRef.current, user);
                        }}
                        render={({ handleSubmit, form, values }) => {
                            formRef.current = form;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Editor
                                        apiKey="ufnzdbkgsq9gt4qexsl47ib3zlt5dr49kfnxd00tp8vxa5m0"
                                        value={editorValue}
                                        onInit={(evt, editor) => (editorRef.current = editor)}

                                        init={{
                                            height,
                                            resize: false,
                                            menubar: false,
                                            statusbar: false,
                                            toolbar: !noGif
                                                ? 'bold italic strikethrough blockquote bullist numlist outdent indent | customInsertEmoji | customInsertGif'
                                                : 'bold italic strikethrough blockquote bullist numlist outdent indent',
                                            setup: (editor) => {
                                                handleEditorInit(editor);

                                                // ✅ Custom GIF Button
                                                editor.ui.registry.addButton('customInsertGif', {
                                                    text: 'GIF',
                                                    onAction: () => setShowGifModal(true),
                                                });

                                                // ✅ Detect Space key to select mention
                                                editor.on('keydown', (e) => {
                                                    handleKeyDown(e);
                                                });
                                            },
                                            plugins: 'lists advlist',

                                        }}

                                        onEditorChange={(content) => {
                                            setEditorValue(content);
                                            form.change(name, content);
                                        }}
                                    />
                                    <div className='flex mt-sm items-center w-100 justify-content-end'>
                                        <HoverPopup
                                            btnClassName={'secondary-button'}
                                            className='popup'
                                            placement={'bottom'}
                                            id={`send-${name}`}
                                            text={submitText}
                                            onClick={handleSubmit}
                                            wrapperStyle={{
                                                background: isDark ? colors.backgroundSecondary : colors.primary,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                style={{ color: colors.white }}
                                            />
                                        </HoverPopup>
                                        <ConditionalRender renderIf={isEditing && onCancel}>
                                            <HoverPopup
                                                btnClassName={'secondary-button'}
                                                className='popup mr-md'
                                                placement={'bottom'}
                                                id={`close-${name}`}
                                                text={text?.inventory?.create?.cancel} onClick={onCancel}
                                                wrapperStyle={{
                                                    background: isDark ? colors.backgroundSecondary : colors.primary,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    style={{ color: colors.red }}
                                                />
                                            </HoverPopup>
                                        </ConditionalRender>
                                    </div>
                                </form>
                            )
                        }}
                    />

                </ConditionalRender>

                <ConditionalRender renderIf={!showForm}>
                    <Editor
                        apiKey="ufnzdbkgsq9gt4qexsl47ib3zlt5dr49kfnxd00tp8vxa5m0"
                        value={editorValue}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                            height,
                            resize: false,
                            menubar: false,
                            statusbar: false,
                            plugins: 'lists advlist mentions',
                            toolbar: !noGif
                                ? 'bold italic strikethrough blockquote bullist numlist outdent indent | customInsertEmoji | customInsertGif | customInsertTask | customInsertMention'
                                : 'bold italic strikethrough blockquote bullist numlist outdent indent',

                            setup: (editor) => {
                                handleEditorInit(editor);
                                // ✅ Add Custom Task Picker Button
                                editor.ui.registry.addButton('customInsertTask', {
                                    text: text?.dashboard?.tasks,
                                    tooltip: `\\t`,
                                    onAction: () => setShowTaskModal(true),
                                });

                                editor.ui.registry.addButton('customInsertGif', {
                                    text: 'GIF',
                                    tooltip: `\\g`,
                                    onAction: () => setShowGifModal(true),
                                });
                                editor.ui.registry.addButton('customInsertMention', {
                                    text: '@',
                                    tooltip: `\\m`,
                                    onAction: () => setShowMentionModal(true),
                                });
                                editor.on('keydown', (e) => {
                                    const mentionDropdown = document.querySelector('.tox-autocompleter');

                                    if (e.key === 'Enter' && !mentionDropdown && !e.shiftKey) {
                                        if (noKeyDown) return;
                                        e.preventDefault();
                                        editor.execCommand('mceInsertContent', false, '');

                                        setTimeout(() => {
                                            setMentionActive(false);
                                        }, 200);
                                        handleKeyDown(e);
                                    }

                                });
                                editor.on('input', () => {
                                    const content = editor.getContent({ format: 'text' }).trim();
                                    const words = content.split(/\s+/); // Split by spaces
                                    const lastWord = words[words.length - 1]; // Get last typed word

                                    if (lastWord === '\\g') {
                                        setShowGifModal(true);
                                        removeCommandFromEditor(editor, lastWord); // Remove "\gif" from editor
                                    }

                                    if (lastWord === '\\t') {
                                        setShowTaskModal(true);
                                        removeCommandFromEditor(editor, lastWord); // Remove "\task" from editor
                                    }

                                    if (lastWord === '\\m') {
                                        setShowMentionModal(true);
                                        removeCommandFromEditor(editor, lastWord); // Remove "\mention" from editor
                                    }
                                });
                            },
                            mentions_selector: '.mymention, .taskmention',
                            mentions_fetch: fetchUsers,

                            mentions_menu_complete: (editor, userInfo) => {
                                const doc = editor.getDoc();
                                let mentionElement;

                                if (userInfo.type === 'employee' || userInfo.type === 'client') {
                                    const route = userInfo.type === 'employee'
                                        ? `/settings/employee?id=${userInfo.id}`
                                        : `/clients/details?id=${userInfo.id}`;

                                    mentionElement = doc.createElement('a');
                                    mentionElement.setAttribute('href', route);
                                    mentionElement.style.textDecoration = 'none';
                                    mentionElement.style.color = colors.white;
                                    mentionElement.style.backgroundColor = colors.primary;
                                    mentionElement.style.padding = '2px 5px';
                                    mentionElement.style.borderRadius = '5px';
                                } else {
                                    mentionElement = doc.createElement('span');
                                }
                                mentionElement.className = 'mymention';
                                mentionElement.setAttribute('data-mention-id', userInfo.id);
                                mentionElement.appendChild(doc.createTextNode(`@${userInfo.full_name}`));

                                addMention(userInfo);

                                return mentionElement;
                            }

                        }}
                        onEditorChange={handleEditorChange}
                    />


                    <div className='flex mt-sm items-center w-100 justify-content-end'>
                        <ConditionalRender renderIf={isEditing}>
                            <HoverPopup
                                btnClassName={'secondary-button'}
                                className='popup mr-md'
                                placement={'bottom'}
                                id={`close-${name}`}
                                text={text?.inventory?.create?.cancel} onClick={onCancel}
                                wrapperStyle={{
                                    background: colors.primary,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faClose}
                                    style={{ color: colors.red }}
                                />
                            </HoverPopup>
                        </ConditionalRender>
                        <ConditionalRender renderIf={onSubmit}>
                            <HoverPopup
                                btnClassName={'secondary-button'}
                                className='popup'
                                placement={'bottom'}
                                id={`send-${name}`}
                                text={submitText}
                                wrapperStyle={{
                                    background: colors.primary,
                                }}
                                onClick={() => {
                                    const mentionedUsers = extractMentionsFromEditor(editorValue);
                                    // const mentionedClientIds = mentionedUsers
                                    //     .filter(user => user.type === 'client')
                                    //     .map(user => user.id);

                                    // const mentionedEmployeeIds = mentionedUsers
                                    //     .filter(user => user.type === 'employee')
                                    //     .map(user => user.id);

                                    onSubmit({ [name]: editorValue, mentions: mentionedUsers });
                                    setIsTyping(false);
                                    handleTyping(false, conversationIdRef.current, user);
                                    // const canUpdate = checkConversationConditions();

                                    // if (canUpdate) {
                                    //     handleUpdateParticipants(
                                    //         conversationIdRef?.current,
                                    //         [...participants, ...mentionedEmployeeIds],
                                    //         [...clientParticipants, ...mentionedClientIds],
                                    //         [...channelAdmins],
                                    //         user,
                                    //         selectedProjectIdRef.current,
                                    //         selectedConversationRef.current?.isChannel
                                    //     );
                                    // }

                                    setSelectedMentions([]);
                                    setEditorValue('');
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faPaperPlane}
                                    style={{ color: colors.white }}
                                />
                            </HoverPopup>
                        </ConditionalRender>

                    </div>
                </ConditionalRender>
                {/* Mention modal */}

                <ConditionalRender renderIf={showMentionModal}>
                    <ConfirmModal
                        isOpen={showMentionModal}
                        toggle={() => {
                            setShowMentionModal(false);
                        }}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '60%' : '100%'}
                        text={text?.chat?.searchForUser}
                    >
                        <div className="mention-modal w-100">
                            <SearchInput
                                className='pb-md w-100'
                                value={selectedFilters.mentionQuery}
                                onChange={handleMentionSearchChange}
                                label={text?.employees?.landing?.search}
                                placeHolder={'...'}
                            />
                            <PaginatedData
                                className='mt-md'
                                data={mentionData}
                                showLightColors
                                searching={filters?.mentionQuery}
                                defaultItemsPerPage={5}
                                noDataText={text?.tasks?.home?.noTasks}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className='w-100'
                                            onClick={() => insertMention(item)}
                                        >
                                            <div className='flex items-center'>
                                                <ConditionalRender renderIf={item.isOnline}>
                                                    <span
                                                        className='mr-md'
                                                        style={{
                                                            background: colors.green,
                                                            height: '10px',
                                                            width: '10px',
                                                            borderRadius: '50%'
                                                        }} />
                                                </ConditionalRender>
                                                <ConditionalRender renderIf={!item.isOnline}>
                                                    <span
                                                        className='mr-md'
                                                        style={{
                                                            background: colors.red,
                                                            height: '10px',
                                                            width: '10px',
                                                            borderRadius: '50%'
                                                        }} />
                                                </ConditionalRender>
                                                <p>
                                                    {`${item.name} (${item.type})`}
                                                </p>
                                            </div>
                                        </SelectableBox>
                                    );
                                }
                                }
                            />
                        </div>
                    </ConfirmModal>
                </ConditionalRender>

                {/* Task modal */}
                <ConditionalRender renderIf={showTaskModal}>
                    <ConfirmModal
                        isOpen={showTaskModal}
                        toggle={() => {
                            setShowTaskModal(false)
                            setFilters({ query: '' })
                            setSelectedFilters({ query: '' })

                        }}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '60%' : '100%'}
                        text={text?.inventory?.landing?.table?.search}
                    >
                        <div className="task-modal w-100">
                            <SearchInput
                                className='pb-md w-100'
                                value={selectedFilters.query}
                                onChange={handleSearchChange}
                                label={text?.employees?.landing?.search}
                                placeHolder={'...'}
                            />
                            <PaginatedData
                                className='mt-md'
                                data={taskData}
                                showLightColors
                                searching={filters?.query}
                                defaultItemsPerPage={5}
                                noDataText={text?.tasks?.home?.noTasks}
                                ItemComponent={({ item }) => {
                                    return (
                                        <SelectableBox
                                            className='w-100'
                                            onClick={() => insertTaskMention(item)}
                                        >
                                            {item.taskTitle}
                                        </SelectableBox>
                                    );
                                }
                                }
                            />
                        </div>
                    </ConfirmModal>
                </ConditionalRender>

                {/* Fig Modal */}
                <ConditionalRender renderIf={showGifModal}>
                    <ConfirmModal
                        isOpen={showGifModal}
                        toggle={() => {
                            setShowGifModal(false);
                            setGifSearchQuery('');
                            setGifSearchResults([]);
                        }}
                        width={isDesktop ? '50%' : '100%'}
                        height={isDesktop ? '60%' : '100%'}
                        onClick={!isEmpty(gifSearchQuery) ? () => fetchGifs(gifSearchQuery, false) : undefined}
                        text={text?.inventory?.landing?.table?.search}
                    >
                        <div className="gif-modal w-100">
                            <div className="gif-modal-content">
                                <FieldWithNoValidation
                                    type="text"
                                    name="gifSearchQuery"
                                    value={gifSearchQuery}
                                    onChange={(e) => setGifSearchQuery(e.target.value)}
                                    label={text?.chat?.searchForAGif}
                                />
                                <div className="gif-results mt-md">
                                    {gifSearchResults.map((gif) => (
                                        <img
                                            key={gif.id}
                                            src={gif.images.fixed_height.url}
                                            alt={gif.title}
                                            onClick={() => handleInsertGif(gif.images.fixed_height.url)}
                                            style={{ cursor: 'pointer', margin: '5px' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <ConditionalRender renderIf={!isEmpty(gifSearchResults)}>
                                <CallToAction
                                    className="shuffle-button mt-md"
                                    onClick={() => fetchGifs(gifSearchQuery, true)}
                                    text={'Shuffle'}
                                />
                            </ConditionalRender>
                        </div>
                    </ConfirmModal>
                </ConditionalRender>
            </StyledDiv>
        </ImageUploader>
    );
};

export default RichEditorWithImageUploader;
