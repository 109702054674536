import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext'
import colors from '../globalStyles.scss'


export default function CustomDialog({
    bannerText = 'We use cookies to ensure you get the best experience on our website.',
    linkText = 'Learn more',
    linkUrl = '/cookies-policy',
    agreeText = 'Accept',
    onAgree,
    isOpen,
    onToggle,
    attributesIsLoading
}) {
    const [internalOpen, setInternalOpen] = React.useState(false);
    const open = isOpen !== undefined ? isOpen : internalOpen;
    const { theme } = useTheme();


    const handleClose = () => {
        setInternalOpen(false);
        if (onToggle) onToggle(false);
    };

    const handleAgree = () => {
        if (onAgree) onAgree();
        handleClose();
    };

    if (attributesIsLoading) return null;

    return (
        <React.Fragment>
            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: theme === 'dark' ? colors.lightGray : colors.backgroundSecondary,
                        color: theme === 'dark' ? 'white' : 'black',
                        padding: '10px 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: 1300 // To ensure it stays on top
                    }}
                >
                    <Typography variant="body1 flex">
                        <p style={{
                            color: colors.black,
                            marginRight: '1em'
                        }}>
                            {bannerText}{' '}
                        </p>
                        <Link to={linkUrl} style={{ color: colors.black, textDecoration: 'underline' }}>
                            {linkText}
                        </Link>
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleAgree}>
                        {agreeText}
                    </Button>
                </Box>
            )}
        </React.Fragment>
    );
}
