import React, { useState } from 'react'
import Wrapper from '../Core/Wrapper'
import InventoryHomeContainer from '../Invetory/InventoryHomeContainer';
import TasksContainer from '../Tasks/TasksContainer';
import EmployeesContainer from '../Employees/EmployeesContainer';

import ProposalsContainer from '../Proposals/ProposalsContainer'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import ConditionalRender from '../Core/ConditionalRender';
import colors from '../globalStyles.scss'
import { useLanguage } from '../context/LanguageContext';
import { useTheme } from '../context/ThemeContext'
import useScreenSize from '../context/useScreenSize';
import ExpandedContainer from '../Expanded/ExpandedContainer';
import classnames from 'classnames';


const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    form {
        align-self: flex-end;
        .TimeTracker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            .time {
                font-family: ${colors.roboto};
            }
        }
    }
`;

const WelcomeEmployeeContainer = ({
    projects,
    projectsIsLoading,
    company,
    user,
    projectId,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    initialProject,
    handleChange,
    isRunning,
    fetchUserDetails,
    isCompanyPremium,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    setInventory,
    fetchCompanyEmployees,
    employees,
    tasks,
    tasksIsLoading,
    userRole,
    fetchProjectTasks,
    clients,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    dashboardLayout
}) => {
    const { text } = useLanguage();
    const { theme } = useTheme();
    const { isDesktop } = useScreenSize();
    const [expanded, setExpanded] = useState('');

    const adminOrAssignedProjects = projects?.filter(project => {
        // Check if the user is an admin
        const isAdmin = project.adminEmployees?.some(employee => employee?.id === user?.id);

        // Check if the user is assigned to at least one task in the separate tasks array
        const isAssigned = tasks?.some(task => task.taskAssignedTo?.id === user?.id);

        // Include the project if the user is an admin or assigned to at least one task
        return isAdmin || isAssigned;
    });

    const adminProjects = projects?.filter(project => {
        // Check if the user is an admin
        return project.adminEmployees?.some(employee => employee?.id === user?.id) || user?.roleName === 'Admin';
    });

    return (
        <StyledDiv>
            <div
                className={classnames({
                    'w-50': dashboardLayout === 'split',
                    'w-100': dashboardLayout === 'full'
                })}
                flex={'1'}
                style={{
                    padding: isDesktop && dashboardLayout === 'split' && '0 15px 0 0'
                }}
            >
                <ConditionalRender renderIf={expanded !== 'employees'}>
                    <EmployeesContainer
                        employees={employees}
                        userRole={userRole}
                        user={user}
                        projects={projects}
                        company={company}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        title={text?.dashboard?.employees}
                        isDashboard
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />
                    <hr />
                </ConditionalRender>
                <ConditionalRender renderIf={expanded !== 'inventory'}>
                    <InventoryHomeContainer
                        user={user}
                        fetchUserDetails={fetchUserDetails}
                        isCompanyPremium={isCompanyPremium}
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        setInventory={setInventory}
                        inventoryColumns={inventoryColumns}
                        setInventoryColumns={setInventoryColumns}
                        fetchAttributes={fetchAttributes}
                        projects={projects}
                        employees={employees}
                        title={text?.dashboard?.inventory}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />
                </ConditionalRender>
            </div>
            <div
                className={classnames({
                    'w-50': dashboardLayout === 'split',
                    'w-100': dashboardLayout === 'full'
                })}
                flex={'1'}
                padding={isDesktop && dashboardLayout === 'split' && '0 0 0 15px'}
            >
                <ConditionalRender renderIf={expanded !== 'tasks'}>
                    <TasksContainer
                        isProject={false}
                        projects={adminOrAssignedProjects.filter((x) => x?.projectStatus !== 'completed')}
                        adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                        user={user}
                        employees={employees}
                        tasks={
                            tasks?.filter((task) =>
                                adminOrAssignedProjects?.some((project) => project?._id === task?.taskProject?._id)
                            ).filter((x) => x?.projectStatus !== 'completed')
                        }
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        title={text?.dashboard?.tasks}
                        setExpanded={setExpanded}
                        expanded={expanded}
                    />
                    <hr />
                </ConditionalRender>
                <ConditionalRender renderIf={expanded !== 'proposals'}>
                    <ConditionalRender renderIf={!isEmpty(adminProjects)}>
                        <ProposalsContainer
                            title={'Proposals'}
                            projects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                            adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                            user={user}
                            clients={clients}
                            proposals={
                                proposals?.filter((proposal) =>
                                    adminProjects?.some((project) => project?._id === proposal?.projectId?._id)
                                ).filter((x) => x?.projectStatus !== 'completed')
                            }
                            proposalsIsLoading={proposalsIsLoading}
                            fetchAllProposals={fetchAllProposals}
                            setExpanded={setExpanded}
                            expanded={expanded}
                        />
                    </ConditionalRender>
                </ConditionalRender>
            </div>

            {/* Expanded Components */}
            <ExpandedContainer
                expanded={expanded}
                setExpanded={setExpanded}
                user={user}
                clients={clients}
                adminProjects={adminProjects.filter((x) => x?.projectStatus !== 'completed')}
                proposals={
                    proposals?.filter((proposal) =>
                        adminProjects?.some((project) => project?._id === proposal?.projectId?._id)
                    ).filter((x) => x?.projectStatus !== 'completed')
                }
                proposalsIsLoading={proposalsIsLoading}
                fetchAllProposals={fetchAllProposals}


                projects={adminOrAssignedProjects.filter((x) => x?.projectStatus !== 'completed')}
                employees={employees}
                tasks={
                    tasks?.filter((task) =>
                        adminOrAssignedProjects?.some((project) => project?._id === task?.taskProject?._id)
                    ).filter((x) => x?.projectStatus !== 'completed')
                }
                tasksIsLoading={tasksIsLoading}
                fetchProjectTasks={fetchProjectTasks}

                fetchUserDetails={fetchUserDetails}
                isCompanyPremium={isCompanyPremium}
                inventory={inventory}
                fetchInventoryProducts={fetchInventoryProducts}
                inventorysLoading={inventorysLoading}
                setInventory={setInventory}
                inventoryColumns={inventoryColumns}
                setInventoryColumns={setInventoryColumns}
                fetchAttributes={fetchAttributes}
            />

        </StyledDiv>
    )
}

export default WelcomeEmployeeContainer