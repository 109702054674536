import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useLanguage } from "../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import CallToAction from "../Core/CallToAction";
import Wave from "react-wavify";
import colors from "../globalStyles.scss";
import ConditionalRender from "../Core/ConditionalRender";
import useScreenSize from "../context/useScreenSize";

const StyledDiv = styled.div`
    margin-top: -15px;
    padding-bottom: 15px;
    background: ${colors.white};

  h4 {
    font-family: ${colors.openSans};
    color: ${colors.white};
    font-size: 3em;
  }

  .wrapper {
    display: ${props => (props.isPhone ? "block" : "flex")};
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  p {
    color: ${colors.black};
  }
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  justify-content: ${props => (props.isPhone ? "center" : "flex-start")};
  align-items:  ${props => (props.isPhone ? "center" : "flex-start")};
  width: ${props => (props.isPhone ? "100%" : "50%")};  
`;

const Item = styled.div`
  width: 150px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: ${({ color }) => color};
  transition: all 0.3s ease-in-out;
  opacity: ${({ isHovered, isActive }) => (isActive ? "1" : isHovered ? "0.3" : "1")};
  border-radius: 8px;
  box-shadow: ${({ isActive }) => (isActive ? "0 0 10px 0 rgba(0, 0, 0, 0.3)" : "0 0 5px 0 rgba(0, 0, 0, 0.1)")};

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: white;
    transition: opacity 0.3s ease;
  }
`;

const BodyContainer = styled(motion.div)`
  margin: 0 auto;
  padding: 15px;
  border-radius: 8px;
  width: ${props => (props.isPhone ? "100%" : "50%")};
  text-align: center;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: ${colors.poppins};
  font-weight: 300;
  position: relative;
  overflow: hidden;
  font-size: 1em;
`;

const Services = ({ noWave }) => {
    const { text } = useLanguage();
    const navigate = useNavigate();
    const { isPhone } = useScreenSize();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const colorsArray = [
        "#0E6FA9", "#0C5E91", "#0A4D79", "#1181C1",
        "#2290CC", "#33A0D7", "#44B0E2", "#55C0ED",
        "#66D0F8", "#77E0FF"
    ];

    const services = [
        text?.homeLandingContainer?.services?.projectManagement,
        text?.homeLandingContainer?.services?.employeeManagement,
        text?.homeLandingContainer?.services?.timeTracker,
        text?.homeLandingContainer?.services?.chat,
        text?.homeLandingContainer?.services?.help,
        text?.homeLandingContainer?.services?.analytics,
        text?.homeLandingContainer?.services?.calendar,
        text?.homeLandingContainer?.services?.dailyLogs,
        text?.homeLandingContainer?.services?.proposals,
        text?.homeLandingContainer?.services?.clients,
        text?.homeLandingContainer?.services?.tasks,
        text?.homeLandingContainer?.services?.notifications,
        text?.homeLandingContainer?.services?.inventory,
    ].filter(Boolean);

    const getContrastColor = (bgColor) => {
        if (!bgColor) return colors.black;
        const hex = bgColor.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
        return luminance > 0.5 ? colors.black : colors.white;
    };

    return (
        <StyledDiv isPhone={isPhone} id="services">
            <ConditionalRender renderIf={!noWave}>
                <Wave
                    id="wave3"
                    className="wave"
                    fill={colors.black}
                    paused={false}
                    style={{ display: "flex", transform: "rotate(180deg)" }}
                    options={{ height: 50, amplitude: 80, speed: 0.1, points: 3 }}
                />
            </ConditionalRender>

            <h4 style={{ color: colors.black, marginBottom: "1em" }}>
                {text?.homeLandingContainer?.services?.titleServices}
            </h4>

            <div className="wrapper">
                <GalleryContainer isPhone={isPhone}>
                    {services.map((service, index) => (
                        <Item
                            key={index}
                            color={colorsArray[index % colorsArray.length]}
                            isActive={selectedIndex === index}
                            isHovered={selectedIndex !== null}
                            onMouseEnter={() => setSelectedIndex(index)}
                        >
                            <h1 style={{ color: getContrastColor(colorsArray[index % colorsArray.length]) }}>
                                {service?.title}
                            </h1>
                        </Item>
                    ))}
                </GalleryContainer>

                {/* Animated Service Description */}
                <AnimatePresence mode="wait">
                    {services[selectedIndex] && (
                        <BodyContainer
                            isPhone={isPhone}
                            key={selectedIndex}
                            initial={{ x: "100vw" }}  // Start off-screen right
                            animate={{ x: "0vw" }} // Move in & slightly scale up
                            exit={{ x: "100vw" }}     // Move out to right & reset scale
                            transition={{
                                x: { duration: 0.3, ease: "easeInOut" },
                                scale: { duration: 0.3, ease: "easeInOut", delay: 0.1 }
                            }}
                            style={{
                                background: colorsArray[selectedIndex % colorsArray.length],
                                color: getContrastColor(colorsArray[selectedIndex % colorsArray.length]),
                            }}
                        >
                            {services[selectedIndex]?.body || "Hover over a service to see details"}
                        </BodyContainer>
                    )}
                </AnimatePresence>

            </div>
            <p className="mb-md mt-md">{text?.homeLandingContainer?.services?.title}</p>
            <p>{text?.homeLandingContainer?.services?.description}</p>
            <CallToAction
                className="mt-md"
                btnWidth="auto"
                btnHeight="auto"
                btnStyle={{
                    padding: "0.3em 1em",
                    border: `2px solid ${colors.blue}`,
                    color: colors.white,
                    hoverBackgroundColor: colors.lightblue,
                    fontSize: "2.5em",
                }}
                text={text?.homeLandingContainer?.services?.flowBtn}
                onClick={() => navigate("/flow")}
            />
        </StyledDiv>
    );
};

export default Services;
