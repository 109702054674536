import React from "react";
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionOptions from "./SubscriptionOptions";
import { useLanguage } from '../context/LanguageContext';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const MembershipContainer = ({
    isLoggedIn,
    fetchUserDetails,
    user,
    company,
    fetchCompanyDetails
}) => {
    const { language } = useLanguage();

    return (
        <Elements stripe={stripePromise} options={{ locale: language }} >
            <SubscriptionOptions
                trialHasBeenUsed={company?.trialHasBeenUsed}
                isLoggedIn={isLoggedIn}
                fetchUserDetails={fetchUserDetails}
                email={user?.email}
                isPremium={user?.isPremium}
                fetchCompanyDetails={fetchCompanyDetails}
                company={company}
                isCompanyPremium={company?.isPremium}
            />
        </Elements>
    );
};

export default MembershipContainer;
