export const handleUpdateParticipants = (
    conversationId,
    updatedUserParticipants,
    updatedClientParticipants,
    updatedChannelAdmins,
    user,
    ws,
    setConversations,
    setParticipants,
    setClientParticipants,
    setChannelAdmins,
    setSelectedConversation,
    setSelectedConversationId,
    setMessages,
    selectedConversationId,
    projectId,
    isChannel,
    setProjectConversations,
    setChannelConversations
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    ws.current.emit(
        'updateParticipants',
        { conversationId, updatedUserParticipants, updatedClientParticipants, updatedChannelAdmins, companyId: user?.companyId, currentUserId: user?._id, projectId, isChannel },
        (response) => {
            if (response.success) {
                if (projectId) {
                    setProjectConversations((prevConversations) =>
                        prevConversations.map((conversation) =>
                            conversation._id === response.conversation._id
                                ? response.conversation
                                : conversation
                        )
                    );
                } else {
                    setConversations((prevConversations) =>
                        prevConversations.map((conversation) =>
                            conversation._id === response.conversation._id
                                ? response.conversation
                                : conversation
                        )
                    );
                }
                setParticipants(response.conversation.participants.map((p) => p._id));
                setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));
                if (isChannel) {
                    setChannelAdmins(response.conversation.channelAdmins);
                    setChannelConversations((prevConversations) =>
                        prevConversations.map((conversation) =>
                            conversation._id === response.conversation._id
                                ? response.conversation
                                : conversation
                        )
                    );
                    setSelectedConversation(response.conversation);
                }

                if (!projectId) {
                    if (selectedConversationId === response.conversation._id) {
                        setParticipants(response.conversation.participants.map((p) => p._id));
                    } else {
                        setParticipants(response.conversation.participants.map((p) => p._id));
                        setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));
                        setSelectedConversation(response.conversation);
                    }
                } else {
                    if (selectedConversationId === response.conversation._id) {
                        setParticipants(response.conversation.participants.map((p) => p._id));
                    } else {
                        setParticipants(response.conversation.participants.map((p) => p._id));
                        setClientParticipants(response.conversation.clientParticipants.map((c) => c._id));
                    }
                }

            } else {
                console.error('Failed to update participants:', response.message);
            }
        }
    );
};