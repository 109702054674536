// ToastContext.js
import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { useTheme } from './ThemeContext';
import colors from '../globalStyles.scss';

const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast-body > div:last-child {
        font-family: ${colors.openSans};
    }
    .Toastify__close-button > svg {
        fill: ${colors.red};    
    }
    .Toastify__toast-theme--dark {
        background: ${colors.backgroundSecondary};
    }
    .Toastify__toast-theme--light {
        background: ${colors.darkGray};
    }
`

const ToastContext = createContext();

const toastOptions = {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    position: 'bottom-left',
};

let toastIds = []; // Track all active toast IDs

const notify = (message, type = "default", autoClose = 2000) => {
    // Dismiss only active toasts that still exist
    toastIds = toastIds.filter((id) => {
        if (toast.isActive(id)) {
            setTimeout(() => toast.dismiss(id), 100); // Delay dismiss slightly

        }
        return true; // Keep in array if already dismissed
    });

    if (type === "error") {
        autoClose = false;
    }

    // Store the new toast ID and keep track
    const newToastId = toast[type]
        ? toast[type](message, { ...toastOptions, autoClose })
        : toast(message, { ...toastOptions, autoClose });

    toastIds.push(newToastId); // Track new toast ID
};



export const ToastProvider = ({ children }) => {
    const { theme } = useTheme();
    return (
        <ToastContext.Provider value={{ notify }}>
            {children}
            <StyledToastContainer theme={theme} />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};
