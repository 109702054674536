import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { useTheme } from '../../context/ThemeContext';
import { useLanguage } from '../../context/LanguageContext';
import RichEditorWithImageUploader from './RichEditorWithImageUploader';
import colors from '../../globalStyles.scss';
import { useDropzone } from 'react-dropzone';
import { isEmpty } from 'lodash';
import ConditionalRender from '../ConditionalRender';
import RenderImages from '../RenderImages';
import RenderFiles from '../RenderFiles';
import MultiFields from '../MultiFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import OutsideClickHandler from '../OutsideClickHandler';
import { GlobalContext } from '../../context/GlobalContext';
import { useToast } from '../../context/ToastContext';
import {
    downloadProjectFile
} from '../../utils/calls';


const StyledDiv = styled.div`
    width: 100%;
    .read-view {
        width: 100%;
        color: ${(props) => (props.theme === 'dark' ? colors.white : colors.dark)};
        min-height: 2.5em;
        align-items: center;
        word-break: break-word;
        border-bottom: ${(props) =>
        props.isEditing ? `1px solid ${props.theme === 'dark' ? colors.white : colors.dark}` : 'none'};
        blockquote {
            border-left: 3px solid ${colors.lightGray};
            padding-left: 10px;
            margin: 0;
            color: ${colors.darkGray};
            font-style: italic;
        }
        p {
            text-align: left;
            color: ${(props) => (props.theme === 'dark' ? colors.white : colors.dark)};
            strong {
                font-weight: bold;
            }
            em {
                font-style: italic;
            }
            s {
                text-decoration: line-through;
            }
        }
    }

    .image-uploader {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .dropzone {
            align-self: flex-start;
            margin: 0.5em 0;
            padding: 1em;
            background: ${(props) => (props.theme === 'light' ? colors.blue : colors.secondary)};
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                background: ${(props) => (props.theme === 'light' ? colors.secondary : colors.backgroundSecondary)}; 
            }
            span {
                font-family: ${colors.openSans};
                color: ${props => props.theme === 'dark' ? colors.black : colors.black};
            }
        }
    }
`;

const EditableField = ({
    allowEdit = true,
    value,
    onSave,
    onCancel,
    placeholder,
    editorProps,
    name,
    message,
    projectId,
    canUpload = false,
    processImages,
    processFiles,
    isEditing,
    setIsEditing,
    isClient = false,
    disabled = false,
    isDark = false,
    notCancelOutsideClick = false,
    isBasic = false,
}) => {
    const { UPLOAD_LIMIT, MAX_IMAGES, MAX_FILES } = useContext(GlobalContext);
    const { notify } = useToast();
    const [currentImagesLength, setCurrentImagesLength] = useState(0);
    const maxFileSize = 5 * 1024 * 1024; // 5MB
    const allowedFileTypes = ['text/plain', 'application/pdf'];


    let { theme } = useTheme();
    theme = isClient ? 'dark' : theme;
    const { text } = useLanguage();
    const [confirmedValue, setConfirmedValue] = useState(value); // Last confirmed value
    const editorContainerRef = useRef(null); // Ref for editor container
    const [imageError, setImageError] = useState('')
    const [fileError, setFileError] = useState('');

    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [basicIsEditing, setBasicIsEditing] = useState(false);

    useEffect(() => {
        setConfirmedValue(value); // Sync confirmed value with prop changes
    }, [value]);

    // Trigger cancel on outside click
    useEffect(() => {
        if (notCancelOutsideClick) return;
        const handleClickOutside = (event) => {
            if (editorContainerRef.current && !editorContainerRef.current.contains(event.target)) {
                if (isEditing) {
                    cancel(); // Trigger cancel if editing
                }
            }
        };

        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup listener
        };
        // eslint-disable-next-line
    }, [isEditing]);

    const save = (newValue) => {
        setConfirmedValue(newValue);
        if (onSave) onSave(newValue);
        setIsEditing && setIsEditing(false);
        setImageError('');
        setBasicIsEditing(false);
    };

    const cancel = () => {
        if (onCancel) onCancel();
        setIsEditing && setIsEditing(false);
        setImageError('');
        setBasicIsEditing(false);
    };

    useEffect(() => {
        if (images?.length >= UPLOAD_LIMIT) {
            setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }

        // Count only 'new' images
        const newImages = images?.filter((image) => image.new);
        setCurrentImagesLength(newImages?.length);
        // eslint-disable-next-line
    }, [images]);


    useEffect(() => {
        if (currentImagesLength >= UPLOAD_LIMIT) {
            notify(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`, 'warning');
            setImageError(`${text?.projects?.create?.uploader?.validations?.uploadUpTo} ${UPLOAD_LIMIT} ${text?.projects?.create?.uploader?.validations?.atAtime}`);
        }
        // eslint-disable-next-line
    }, [currentImagesLength]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
            const otherFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

            // 🔹 **Image Upload Logic**
            if (images.length >= MAX_IMAGES) {
                setImageError(`${text?.projects?.create?.uploader?.validations?.upto} ${MAX_IMAGES} ${MAX_IMAGES > 1 ? text?.projects?.create?.uploader?.images : text?.projects?.create?.uploader?.image}`);
                return;
            }

            const newImagesCount = images.filter(img => img.new).length;
            const remainingImageSlots = Math.min(UPLOAD_LIMIT - newImagesCount, MAX_IMAGES - images.length);
            const imagesToUpload = imageFiles.slice(0, remainingImageSlots);

            if (imagesToUpload.length > 0) {
                const newImagesPromises = imagesToUpload.map(file =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve({ file, url: reader.result, originalName: file.name, new: true });
                        reader.readAsDataURL(file);
                        reader.onerror = reject;
                    })
                );

                Promise.all(newImagesPromises)
                    .then(newImages => {
                        setImages(prevImages => {
                            const updatedImages = [...newImages, ...prevImages];
                            processImages({
                                images: updatedImages,
                                message,
                                projectId
                            });
                            return updatedImages;
                        });
                        setImageError('');
                    })
                    .catch(() => setImageError(text?.projects?.create?.uploader?.validations?.error));
            }

            // 🔹 **File Upload Logic (Handled Separately via processFiles)**
            const validFiles = otherFiles.filter(file => {
                if (!allowedFileTypes.includes(file.type)) {
                    setFileError(`${text?.uploader?.fileType} ${file.type} ${text?.uploader?.isNot}`);
                    return false;
                }
                if (file.size > maxFileSize) {
                    setFileError(`${text?.uploader?.file} ${file.name} ${text?.uploader?.toLarge}`);
                    return false;
                }
                setFileError('');
                return true;
            });

            const remainingFileSlots = MAX_FILES - files.length;
            const filesToUpload = validFiles.slice(0, remainingFileSlots);

            if (filesToUpload.length > 0) {
                const newFilesPromises = filesToUpload.map((file) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () =>
                            resolve({
                                originalName: file.name,
                                dataUrl: reader.result,
                                type: file.type,
                                size: file.size,
                                new: true,
                            });
                        // Use readAsDataURL instead of readAsArrayBuffer
                        reader.readAsDataURL(file);
                        reader.onerror = reject;
                    })
                );


                Promise.all(newFilesPromises).then(uploadedFiles => {
                    setFiles(prevFiles => {
                        const allFiles = [...uploadedFiles, ...prevFiles];
                        processFiles({
                            files: allFiles,
                            message

                        });
                        return allFiles;
                    });
                }).catch(() => setFileError(text?.uploader?.error));
            }
        },
        [images, files, text, MAX_IMAGES, MAX_FILES, UPLOAD_LIMIT, processImages, processFiles, message, projectId]
    );




    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/svg+xml': ['.svg'],
            'text/plain': ['.txt'],
            'application/pdf': ['.pdf']
        },
        multiple: true,
    });

    const removeImage = (imageIndex) => {
        setImages((prevImages) => {
            const updatedImages = prevImages?.filter((image, index) => index !== imageIndex);
            // TODO: CALL UPDATE API HERE TO REMOVE IMAGE
            processImages({ images: updatedImages, message, projectId });
            return updatedImages;
        });
    };
    const removeFile = (fileIndex) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles?.filter((file, index) => index !== fileIndex);
            processFiles({ files: updatedFiles, message });
            return updatedFiles;
        });
    }
    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useMemo(() => {
        setImages(message?.imageUrls);
    }, [message?.imageUrls]);

    useMemo(() => {
        setFiles(message?.files);
    }, [message?.files]);

    const openCarousel = (index) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDownloadFile = async (e, file) => {
        e.preventDefault();
        e.stopPropagation();
        try {

        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <StyledDiv
            ref={editorContainerRef}
            theme={theme}
            isEditing={isEditing || basicIsEditing}
            allowEdit={allowEdit}>
            {(isEditing || basicIsEditing) ? (
                <div className="edit-view">
                    <ConditionalRender renderIf={!isBasic}>
                        <RichEditorWithImageUploader
                            isDark={isDark}
                            showForm={false}
                            isEditing={isEditing}
                            onSubmit={({ message }) => save(message)}
                            submitText={text?.employees?.details?.save}
                            onImageUpload={(image) => console.log('Image uploaded:', image)}
                            onFileUpload={(image) => console.log('Image uploaded:', image)}
                            maxFiles={5}
                            title={text?.logs?.edit}
                            name={name}
                            initialValue={confirmedValue}
                            onCancel={cancel}
                            customButton={
                                <ConditionalRender renderIf={canUpload}>
                                    <div className='image-uploader'>
                                        <div
                                            className='dropzone'
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <FontAwesomeIcon icon={faPlus} />

                                        </div>
                                    </div>
                                </ConditionalRender>
                            }
                            {...editorProps}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={isBasic}>
                        <OutsideClickHandler
                            onOutsideClick={cancel}
                            disabled={notCancelOutsideClick}
                        >
                            <Form
                                onSubmit={({ message }) => save(message)}
                                initialValues={{ message: confirmedValue }}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className='flex'>
                                            <MultiFields
                                                className='field-style'
                                                name="message"
                                                component="text"
                                                type="text"
                                                block
                                                initialValue={confirmedValue}
                                            />
                                            <div className='flex justify-end ml-md items-center'>
                                                <FontAwesomeIcon icon={faCheck} className='icon pointer' onClick={handleSubmit} />
                                                <FontAwesomeIcon icon={faTimes} className='icon pointer' onClick={cancel} style={{
                                                    color: colors.red,
                                                    marginLeft: '1em'
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        </OutsideClickHandler>
                    </ConditionalRender>
                </div>
            ) : (
                <>
                    <ConditionalRender renderIf={!isBasic}>
                        <div
                            className="read-view flex w-100"
                            dangerouslySetInnerHTML={{ __html: confirmedValue || placeholder }}
                        />
                    </ConditionalRender>
                    <ConditionalRender renderIf={isBasic}>
                        <div
                            onClick={() => setBasicIsEditing(true)}
                            className="read-view flex pointer">
                            {confirmedValue || placeholder}
                        </div>
                    </ConditionalRender>
                </>
            )}
            <ConditionalRender renderIf={!isBasic}>
                <ConditionalRender renderIf={!isEmpty(images)}>
                    <ConditionalRender renderIf={isEditing || basicIsEditing}>
                        {imageError && <p className="error">{imageError}</p>}
                        {fileError && <p className="error">{fileError}</p>}
                    </ConditionalRender>
                    <RenderImages
                        images={images}
                        disabled={!allowEdit || disabled}
                        removeImage={removeImage}
                        openModal={openCarousel}
                        closeModal={closeModal}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                        isModalOpen={isModalOpen}
                    />
                </ConditionalRender>
                <ConditionalRender renderIf={!isEmpty(files)}>
                    <RenderFiles
                        files={files}
                        handleDownloadFile={handleDownloadFile}
                        isChat
                        readOnly={!allowEdit}
                        removeFile={removeFile}
                    />
                </ConditionalRender>
            </ConditionalRender>
        </StyledDiv>
    );
};

export default EditableField;
