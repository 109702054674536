import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import colors from '../globalStyles.scss'
import people from '../images/people.png'
import { useNavigate } from "react-router-dom";
import ArrowDown from '../Core/ArrowDown'
import Wave from 'react-wavify'
import reading from '../images/reading.png'
import guy from '../images/guy.png'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useLanguage } from '../context/LanguageContext'
import { Carousel } from 'react-responsive-carousel';
import useScreenSize from '../context/useScreenSize';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import CallToAction from '../Core/CallToAction'
import ConditionalRender from '../Core/ConditionalRender';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { GlobalContext } from '../context/GlobalContext';
import logo from '../images/ardidflow_logo_animation.svg'
import Services from './Services'
import useTypingEffect from '../context/useTypingEffect';


const BounceDiv = styled.div`
margin-top: 1em;
opacity: 0;
transition: all 0.2s;
transform: translateY(-30%);
&.isTop {
    transform: translateY(0);
    opacity: 1;
}
// .bounce {
// 	-moz-animation: bounce 3s infinite;
// 	-webkit-animation: bounce 3s infinite;
// 	animation: bounce 3s infinite;
// }
// @-moz-keyframes bounce {
//   0%, 20%, 50%, 80%, 100% {
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//   }
//   40% {
//     -moz-transform: translateY(-30px);
//     transform: translateY(-30px);
//   }
//   60% {
//     -moz-transform: translateY(-15px);
//     transform: translateY(-15px);
//   }
// }
// @-webkit-keyframes bounce {
//   0%, 20%, 50%, 80%, 100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//   }
//   40% {
//     -webkit-transform: translateY(-30px);
//     transform: translateY(-30px);
//   }
//   60% {
//     -webkit-transform: translateY(-15px);
//     transform: translateY(-15px);
//   }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

`


const StyledDiv = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    scroll-behavior: smooth;
    #landing {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            width: 100%;
            height: 100vh;
            .left {
                flex: 1;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .heading_wrapper {
                    margin: 1em;
                    .heading {
                        color: ${colors.white};
                        font-size: 9em;
                        line-height: 1em;
                    }
                    .subheading {
                        color: ${colors.white};
                        font-size: 2.2em;
                        display: flex;
                    }
                }
                h3 {
                    color: ${colors.primary};
                    font-size: 1.5em;
                    text-align: left;
                    margin-top: 0.2em;
                }
            }
            .right {
                flex: 1;
                height: 100%;
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    #tech {
        background-color: ${colors.white};
            .text_wrapper {
                    min-height: 90px;
                    h3 {
                        color: ${colors.black};
                        font-family: ${colors.poppins};
                        font-size: 2em;
                        text-align: center;
                        margin: 1em;
                        font-weight: 600;
                    } 
            }
        .wrapper {
            margin-top: 50px;
            margin-bottom: 50px;
            display: flex;
            width: 100%;
            .left {
                width: 50%;
                height: 100%;
                    p {
                        color: ${colors.black};
                    }
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: 100%;
                    img {
                        margin-top: 50px;
                        width: 100%;
                    }
                }
            }
            .right {
                width: 50%;
                height: 100%;
            }
        }
    }

    #discover {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            justify-content: space-evenly;
            margin-top: 50px;
            margin-bottom: 50px;
            width: 100%;
            height: 100%;
            > div {
                flex: 1;
                padding: 1em;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            img {
                height: 100px;
                width: 100px;
                object-fit: cover;
            }
            h4 {
                font-size: 2em;
                margin-top: 1em;
                color: ${colors.white};
            }
            p {
                margin-top: 1em;
                font-size: 1.5em;
                color: ${colors.white};
                max-width: 50%;
            }
        }
    }

    #info {
        background-color: ${colors.black};
        .wrapper {
            display: flex;
            width: 100%;
            height: 100%;
            margin-top: 50px;
            margin-bottom: 50px;
            a {
                text-decoration: underline !important;
            }
            .left {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    height: 100%;
                    img {
                        margin-top: 50px;
                        width: 100%;
                    }
                }
                .text_wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    h3 {
                        color: ${colors.white};
                        font-size: 4em;
                        text-align: right;
                        font-weight: 600;
                    }
                }
            }
            .right {
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                > div div {
                    padding: 1em;
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    hr {
                        border-top: 2px solid ${colors.white};
                        opacity: 1;
                        margin: 15px 0;
                        z-index: 0;
                        width: 100%;
                    }
                }
                    h4 {
                        font-size: 2.5em;
                        font-weight: 600;
                        color: ${colors.white};
                    }
                    p {
                        font-size: 1.5em;
                        margin-top: 1em;
                        margin-bottom: 1em;
                        text-align: left;
                        color: ${colors.white}; 
                    }
                }

        }

    }


    #testimonial {
        background: linear-gradient(360deg, hsla(0, 0%, 0%, 1) 0%, hsla(198, 100%, 40%, 1) 100%);
        background-repeat: no-repeat;
        position: relative;
        .wrapper {
            margin-bottom: 50px;
            h4 {
                font-size: 2em;
                text-align: left;
                color: ${colors.white};
            }
            p {
                font-size: 1.2em;
                text-align: left;
                color: ${colors.white};
            }
        }
    }
    #terrain {
        background-color: ${colors.black};
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
        .wrapper {
            padding: 1em;
            display: flex;
            width: 50%;
            h1 {
                color: ${colors.white};
                font-size: 3em;
            }
            h2 {
                color: ${colors.white};
                font-size: 2em;
                font-weight: 600;
                margin-top: 1em;
            }
            h3 {
                color: ${colors.white};
                font-size: 1.5em;
                margin-top: 1em;
                font-weight: 600;
            }
        }
        .map {
            overflow: hidden;
            canvas {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
`;


const HomeLandingContainerDesktop = ({
    featuredResources,
    featuredResourcesIsLoading
}) => {
    const { setIsTop, isTop } = useContext(GlobalContext)
    const navigate = useNavigate();
    const { text, language } = useLanguage();
    const { isDesktop, isTablet, isPhone } = useScreenSize();
    const typedTitle = useTypingEffect(text?.homeLandingContainer?.tech?.title || '', 50);

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const handleHashChange = () => {
            const id = window.location.hash.substring(1);
            handleClickScroll(id);
        };

        // Listen for hash change
        window.addEventListener("hashchange", handleHashChange, false);

        // Scroll on initial load
        handleHashChange();

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    useEffect(() => {
        setIsTop(true)
    }, [])

    const handleScroll = (e) => {
        if (e.target.scrollTop <= 100) {
            setIsTop(true)
        } else {
            setIsTop(false)
        }
    }




    return (
        <StyledDiv
            onScroll={handleScroll}
            className='scroll-container'
        >
            <ParallaxProvider
                scrollAxis={'vertical'}
            >
                <div id='landing' className='w-100'>
                    <div className='wrapper'>
                        <div className='left'>
                            <div className='heading_wrapper'>
                                {/* <Parallax speed={10}> */}
                                <h1 className='heading'>{'ArdidFlow'}</h1>
                                <h2 className='subheading'>
                                    {text?.homeLandingContainer?.title}
                                </h2>
                                <h3>
                                    {text?.homeLandingContainer?.subtitle}
                                </h3>
                                {/* </Parallax> */}
                            </div>
                        </div>
                        <div className='right'>
                            <div className='img-wrapper'>
                                {/* <Parallax speed={10}>
                                    <img src={people} alt='people' />
                                </Parallax> */}
                                <BounceDiv
                                    className={classnames({
                                        'isTop': isTop,
                                    })}
                                >
                                    <img
                                        style={{
                                            display: 'flex',
                                            width: '350px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            objectFit: "contain"
                                        }}
                                        className='bounce'
                                        src={logo} alt='logo'
                                        onClick={() => handleClickScroll('wave1')}
                                    />
                                </BounceDiv>
                            </div>
                        </div>
                    </div>
                    <Wave
                        id='wave1'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex' }}
                        options={{
                            height: 50,
                            amplitude: 80,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                </div>
                <div id='tech' className='w-100'>
                    <div className='wrapper'>
                        <div className='left'>
                            <div className='flex flex-column justify-center items-center' style={{
                                marginLeft: '50px',
                            }}>
                                <p className='mt-md' style={{
                                    fontSize: '1.5em',
                                }}>
                                    {text?.homeLandingContainer?.tech?.descriptionTwo}
                                </p>
                                <p className='mt-md' style={{
                                    fontSize: '1.5em',
                                }}>
                                    {text?.homeLandingContainer?.tech?.description}
                                </p>
                                <CallToAction
                                    btnWidth='auto'
                                    btnHeight={'auto'}
                                    btnStyle={{
                                        padding: '0.3em 1em',
                                        border: `2px solid ${colors.blue}`,
                                        color: colors.black,
                                        hoverBackgroundColor: colors.white,
                                        fontSize: '2.5em',
                                    }}
                                    style={{
                                        alignSelf: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: colors.white,
                                        marginTop: '3em'
                                    }}
                                    text={text?.homeLandingContainer?.tech?.button}
                                    onClick={() => handleClickScroll('wave3')}
                                />
                            </div>
                        </div>
                        <div className='right'>
                            <div className='img-wrapper'>
                                <img
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                    height={isDesktop ? '350px' : '250px'}
                                    src={language === 'es' ? people : people}
                                    alt='home'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text_wrapper'>
                        <h3>{typedTitle}</h3>
                    </div>
                </div>

                <div id='info'>
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(180deg)' }}
                        options={{
                            height: 50,
                            amplitude: 80,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                    <div className='wrapper'>
                        <div className='left'>
                            <div>
                                <div className='text_wrapper'>
                                    <h3>
                                        {text?.homeLandingContainer?.info?.titleTop}
                                    </h3>
                                    <h3>
                                        {text?.homeLandingContainer?.info?.titleBottom}
                                    </h3>
                                </div>
                                <div className='img-wrapper'>
                                    <Parallax speed={2}>
                                        <img src={reading} alt='reading' />
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleOne}
                                    </h4>
                                    <p>
                                        {text?.homeLandingContainer?.info?.right?.descriptionOne}
                                    </p>
                                </div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleTwo}
                                    </h4>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {text?.homeLandingContainer?.info?.right?.descriptionTwo}
                                    </ReactMarkdown>
                                </div>
                                <div>
                                    <h4>
                                        {text?.homeLandingContainer?.info?.right?.titleThree}
                                    </h4>
                                    <p>
                                        {text?.homeLandingContainer?.info?.right?.descriptionThree}
                                    </p>
                                </div>
                                <ArrowDown
                                    onClick={() => handleClickScroll('services')}
                                    className='mt-lg mb-lg flex'
                                    style={{
                                        color: colors.blue,
                                        fontSize: '4em',
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <Services />
                <div id='testimonial'>
                    <Wave
                        id='wave3'
                        className='wave'
                        fill={colors.white}
                        paused={false}
                        style={{ display: 'flex', transform: 'rotate(180deg)' }}
                        options={{
                            height: 50,
                            amplitude: 50,
                            speed: 0.1,
                            points: 3
                        }}
                    />
                    <div className="wrapper flex items-center">
                        <div className='flex-one' />
                        <div style={{ flex: 1, minWidth: '50%' }}>
                            <h4>
                                {text?.homeLandingContainer?.testimonial?.title}
                            </h4>
                            <p className='mt-md' >
                                {text?.homeLandingContainer?.testimonial?.description}
                            </p>
                            <p className='mt-md' style={{ fontWeight: 'bold' }}>
                                {text?.homeLandingContainer?.testimonial?.author}
                            </p>
                        </div>
                        <img src={guy} alt="guy" style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            float: 'left',
                            shapeOutside: 'circle(50%)',
                            clipPath: 'circle(50%)',
                            width: '250px',
                            height: '250px',
                            marginLeft: '20px', // Add margin for spacing
                            marginBottom: '20px',
                            overflow: 'hidden'

                        }} />
                        <div className='flex-one' />
                    </div>

                </div>

                <div id='terrain' style={{ position: 'relative' }}>
                    <div className='wrapper flex flex-column' style={{
                        fontSize: '12px'
                    }}>
                        <div>
                            <h1 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.title}
                            </h1>
                            <h2 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.description}
                            </h2>
                            <h3 className='mt-md' style={{
                                color: colors.white,
                            }}>
                                {text?.homeLandingContainer?.terrain?.registerToday}
                            </h3>
                        </div>
                        <CallToAction
                            btnWidth='auto'
                            btnHeight={'auto'}
                            btnStyle={{
                                padding: '0.3em 1em',
                                border: `2px solid ${colors.blue}`,
                                color: colors.white,
                                hoverBackgroundColor: colors.lightblue,
                                fontSize: '2.5em',

                            }}
                            style={{
                                alignSelf: 'flex-start',
                                justifyContent: 'flex-start',
                                backgroundColor: colors.black,
                                padding: '0',
                                marginTop: '3em',
                                alignSelf: 'center',


                            }}
                            text={text?.homeLandingContainer?.terrain?.button}
                            onClick={() => navigate(`/register`)}
                        />
                    </div>
                    <ConditionalRender isLoading={featuredResourcesIsLoading} renderIf={!isEmpty(featuredResources)}>
                        <div style={{
                            width: '50%',
                            padding: '1em',
                            height: '100%',
                        }}>
                            <Carousel
                                showThumbs={false}
                                showIndicators={false}
                                autoPlay
                                infiniteLoop
                                showStatus={false}
                                interval={5000}
                                showArrows={false}
                            >
                                {featuredResources.map((resource, index) => {
                                    return (
                                        <div key={index} style={{ width: '100%', textAlign: 'center' }}>
                                            <div style={{
                                                margin: '0 auto',
                                                height: '400px',
                                                width: '400px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(14,14,20,1) 45%, rgba(11,99,128,1) 100%, rgb(212, 215, 190) 100%, rgba(21,65,82,1) 3458%)'
                                            }}>
                                                <div
                                                    style={{
                                                        height: '50%',
                                                    }}
                                                    className={classnames({
                                                        'w-100': !isPhone,
                                                        'mb-md': isPhone,
                                                        'mr-md': !isPhone,
                                                    })}
                                                >
                                                    <img style={{
                                                        width: '100%',
                                                        maxHeight: '200px',
                                                        objectFit: 'contain'
                                                    }} src={resource?.images[0]?.url || 'https://s3.us-east-2.amazonaws.com/smartflow.com/ardidflow_logo.png'} alt="resource" />
                                                </div>
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    width: '100%',
                                                    left: 0,
                                                    right: 0,
                                                    height: 'auto',
                                                    background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(14,14,20,1) 45%, rgba(11,99,128,1) 100%, rgb(212, 215, 190) 100%, rgba(21,65,82,1) 3458%)',
                                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.20)',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}>
                                                    <h4 style={{
                                                        color: colors.white,
                                                        fontSize: '1.5em',
                                                        zIndex: 1,
                                                        flex: 1,
                                                        padding: '1em',
                                                    }}>
                                                        {resource.title}
                                                    </h4>
                                                    <CallToAction
                                                        text={text?.resources?.readMore}
                                                        onClick={() => navigate(`/resources/details?id=${resource._id}`)}
                                                        style={{
                                                            fontSize: '1.5em',
                                                            padding: '1em',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            zIndex: 1,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </ConditionalRender>

                </div>
            </ParallaxProvider>

        </StyledDiv >
    )
}

export default HomeLandingContainerDesktop