import React, { useState, useEffect, useMemo, useCallback } from 'react'
import SubmitModal from '../Core/SubmitModal'
import MultiFields from '../Core/MultiFields';
import CheckBox from '../Core/Inputs/CheckBox';
import SearchInput from '../Core/Inputs/SearchInput'
import PaginatedData from '../Core/PaginatedData'
import SelectableBox from '../Core/SelectableBox'
import classnames from 'classnames'
import { isEmpty, debounce } from 'lodash'

import { useLanguage } from '../context/LanguageContext'
import { useWebSocket } from '../context/WebSocketContext'
import { isRequired, composeValidators } from '../utils/validators';
import colors from '../globalStyles.scss'
import useScreenSize from '../context/useScreenSize'



const ChannelContainer = ({
    showCreateChannel,
    setShowCreateChannel,
    user
}) => {
    const {
        chatData,
        createChannel
    } = useWebSocket()
    const { text } = useLanguage()
    const { isDesktop, isPhone } = useScreenSize();

    const [isChannelPrivate, setIsChannelPrivate] = useState(false)
    const [channelParticipants, setChannelParticipants] = useState([])
    const [channelAdmins, setChannelAdmins] = useState([])
    const [data, setData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        query: '',
    });
    const [filters, setFilters] = useState({});

    const required = composeValidators(
        isRequired(text?.tasks?.create?.validations?.required),
    )

    const handleCreateChannel = (values) => {
        createChannel(values?.channelName, isChannelPrivate, channelAdmins, channelParticipants, user.companyId, user._id, (conversation, error) => {
            if (conversation) {
                setShowCreateChannel(false)
                setIsChannelPrivate(false)
                setChannelParticipants([])
                setChannelAdmins([])
            } else {
                console.error(error)
            }
        }
        )
    }

    const debouncedSetFilters = useMemo(
        () =>
            debounce((updatedFilters) => {
                setFilters((prev) => ({
                    ...prev,
                    ...updatedFilters,
                }));
            }, 500),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSetFilters.cancel();
        };
    }, [debouncedSetFilters]);

    const handleSearchChange = (e) => {
        const queryValue = e?.target?.value ?? '';
        setSelectedFilters((prev) => ({ ...prev, query: queryValue }));
        debouncedSetFilters({ query: queryValue });
    };

    useEffect(() => {
        if (chatData?.length) {
            setData(chatData);
        }
    }, [chatData]);

    const filterData = useCallback(() => {
        const filterOptions = {};

        const query = filters?.query ? String(filters.query).toLowerCase() : '';

        if (query) {
            filterOptions.query = query;
        }

        let filteredData = chatData || [];

        if (filterOptions.query) {
            filteredData = filteredData.filter(
                (item) =>
                    item?.name?.toLowerCase().includes(filterOptions.query) ||
                    item?.type?.toLowerCase().includes(filterOptions.query)
            );
        }

        setData(filteredData);
    }, [filters, chatData]);

    useEffect(() => {
        if (filters.query) {
            filterData();
        } else {
            setData(chatData);
        }

        // eslint-disable-next-line
    }, [filters]);
    return (
        <SubmitModal
            isOpen={showCreateChannel}
            toggle={() => {
                setShowCreateChannel(!showCreateChannel)
                setIsChannelPrivate(false)
            }}
            width='80%'
            height='80%'
            onClick={handleCreateChannel}
            text={text?.projects?.create?.button}
        >
            <MultiFields
                className='field-style'
                name="channelName"
                component="input"
                type="text"
                label={text?.chat?.channelName}
                block
                validate={required}
            />
            <CheckBox
                checked={isChannelPrivate}
                label={text?.chat?.isChannelPrivate}
                onChange={() => {
                    const newIsAllDay = !isChannelPrivate;
                    setIsChannelPrivate(newIsAllDay);
                }}
            />

            <div className={classnames({
                'flex w-100 mt-md': true,
                'flex-column': isPhone,
            })}>
                <div className={classnames({
                    'w-50': !isPhone,
                    'mr-md': !isPhone,
                })}>
                    <SearchInput
                        className='pb-md w-100'
                        value={selectedFilters.query}
                        onChange={handleSearchChange}
                        label={text?.employees?.landing?.search}
                        placeHolder={'...'}
                    />

                    <PaginatedData
                        className="mt-md"
                        data={data?.filter(item =>
                            !channelParticipants.includes(item._id) &&
                            item?.type !== 'project' &&
                            item?.type !== 'client' && item?._id !== user?._id
                        )} // Exclude checked items
                        defaultItemsPerPage={5}
                        noDataText={text?.table?.noDataText}
                        searching={filters?.query}
                        showLightColors
                        ItemComponent={({ item }) => {
                            const isChecked = channelParticipants.includes(item._id)

                            return (
                                <SelectableBox
                                    style={{ width: '100%' }}
                                    showLightColors
                                    onClick={() => {
                                        if (item?.type === 'employee') {
                                            setChannelParticipants((prev) => {
                                                const updated = new Set(prev);
                                                isChecked ? updated.delete(item._id) : updated.add(item._id);
                                                return [...updated]; // ✅ Convert back to array for state consistency
                                            });
                                        }
                                    }}
                                >
                                    <CheckBox
                                        id={`participants_${item._id}`}
                                        disabledPopUp
                                        checked={isChecked}
                                        disabled={item._id === user?._id}
                                        onChange={() => { }}
                                        label={
                                            <div className="flex items-center">
                                                <span
                                                    className="mr-sm ml-sm"
                                                    style={{
                                                        background: item.isOnline ? colors.green : colors.red,
                                                        height: '10px',
                                                        width: '10px',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                                {item.name} ({item.type})
                                            </div>
                                        }
                                    />
                                </SelectableBox>
                            );
                        }}
                    />


                </div>
                <div className={classnames({
                    'w-50': !isPhone,
                    'ml-md': !isPhone,
                })}>
                    <label className='mb-sm b'>{text?.paginated?.selected}</label>
                    <div>
                        {channelParticipants.map(participantId => {
                            const participant = data.find(item => item._id === participantId);
                            if (!participant) return null;

                            const isAdmin = channelAdmins.includes(participant._id);

                            const handleToggleParticipant = () => {
                                // Remove them from channelParticipants
                                setChannelParticipants(prevParticipants =>
                                    prevParticipants.filter(id => id !== participant._id)
                                );
                                // Also remove from channelAdmins
                                setChannelAdmins(prevAdmins =>
                                    prevAdmins.filter(id => id !== participant._id)
                                );
                            };

                            const handleToggleAdmin = () => {
                                setChannelAdmins(prevAdmins =>
                                    prevAdmins.includes(participant._id)
                                        ? prevAdmins.filter(id => id !== participant._id)
                                        : [...prevAdmins, participant._id]
                                );
                            };

                            return (
                                <SelectableBox
                                    key={participant._id}
                                    showLightColors
                                >
                                    <div className='flex flex-column'>
                                        <CheckBox
                                            id={`selected_${participant._id}`}
                                            disabledPopUp
                                            checked={true}
                                            label={
                                                <div className="flex items-center">
                                                    <span
                                                        className='mr-sm ml-sm'
                                                        style={{
                                                            background: participant.isOnline ? colors.green : colors.red,
                                                            height: '10px',
                                                            width: '10px',
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                                    {participant.name} ({participant.type})
                                                </div>
                                            }
                                            onChange={handleToggleParticipant}
                                        />

                                        {/* The admin checkbox */}
                                        <CheckBox
                                            className='mt-sm'
                                            id={`admin_${participant._id}`}
                                            disabledPopUp
                                            checked={isAdmin}
                                            label={text?.projects?.create?.admin}
                                            onChange={handleToggleAdmin}
                                        />
                                    </div>
                                </SelectableBox>
                            );
                        })}
                    </div>
                </div>

            </div>
        </SubmitModal>
    )
}

export default ChannelContainer
