export const claimSuperAdminConversation = async (
    superAdminConversationId,
    adminId,
    callback,
    ws,
    setConversations
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    ws.current.emit('claimSuperAdminConversation', { adminId, superAdminConversationId }, (response) => {
        if (response.success) {
            // Update the local state immediately to reflect changes
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === superAdminConversationId
                        ? { ...conversation, superAdmin: adminId, status: 'open', isClaimed: true }
                        : conversation
                )
            );

            // Callback function if needed
            callback?.(response.conversation);
        } else {
            console.error('Failed to claim conversation:', response.error);
            callback?.(false, response.error);
        }
    });
};