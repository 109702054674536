import base64ToBlob from '../utils/base64ToBlob';

export const updateMessageImage = async (
    data,
    callback,
    ws,
    setMessages,
    selectedConversationId
) => {
    const { images, message, projectId } = data;

    const serializedData = {
        body: {
            messageId: message?._id,
            conversationId: selectedConversationId,
            projectId,
            companyId: message?.companyId,
            oldImages: [],
        },
        files: { newImages: [] },
    };

    // Serialize old and new images
    images.forEach((image) => {
        if (image.new) {
            const imageBlob = base64ToBlob(image?.url);
            serializedData.files.newImages.push({
                buffer: imageBlob,
                mimetype: image.type,
                originalName: image.originalName,
            });
        } else {
            serializedData.body.oldImages.push(image);
        }
    });

    try {
        ws.current.emit('updateMessageImage', serializedData, (response) => {
            if (response.success) {
                const updatedMessage = response.message;

                setMessages((prevMessages) => {
                    const updatedMessages = prevMessages.map((msg) => {
                        // Check if the updated message is a main message
                        if (msg._id === updatedMessage._id) {
                            return { ...msg, ...updatedMessage };
                        }

                        // Check if the updated message exists as a reply in this message's replies
                        if (msg.replies) {
                            const updatedReplies = msg.replies.map((reply) =>
                                reply._id === updatedMessage._id ? { ...reply, ...updatedMessage } : reply
                            );

                            return { ...msg, replies: updatedReplies };
                        }

                        return msg; // Return unchanged message
                    });

                    return updatedMessages;
                });

                callback?.(updatedMessage);
            } else {
                console.error('Failed to update message images:', response.message);
                callback?.(null, response.message);
            }
        });
    } catch (error) {
        console.error('Error updating message images:', error);
        callback?.(null, 'Failed to update message images due to an error.');
    }
};