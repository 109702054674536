import colors from '../globalStyles.scss'
import {
    one,
    three_one,
    three_two,
    three_three,
    four_one,
    four_two,
    four_three,
    four_four,
    five_one,
    five_two,
    six_two,
    six_three,
    six_four,
    six_five,
    six_six,
    seven_one,
    seven_two,
} from './flow_en';
import RegistrationContainer from '../Registration/RegistrationContainer';
import VideoPlayer from '../Core/VideoPlayer';
import logo from '../images/ardidflow_logo_animation.svg'

export const pages_sm = [
    {
        id: "page0",
        url: 'welcome',
        index: 0,
        content: (
            <div style={{
                width: '85%',
                margin: '0 auto',
            }}>
                <img style={{
                    width: '100%',
                    maxWidth: '300px',
                    display: 'block',
                    margin: '1em auto',
                }}
                    src={logo}
                    alt="Ardidflow Logo"
                />
                <h1 style={{
                    textAlign: 'center',
                    color: colors.white,
                    fontSize: '2.5em',
                }}>
                    {'Welcome to Ardidflow'}
                </h1>
                <h2
                    style={{ textAlign: "center", fontSize: '1em', }}
                    className="mt-md"
                >
                    {
                        "Where your project management journey begins, and your business performance grows."
                    }
                </h2>
                <h3
                    style={{
                        textAlign: "center",
                        fontSize: '1em',
                        fontWeight: 'normal',
                    }}
                    className="mt-md"
                >
                    {
                        "No Integration needed - Ardidflow is a complete project management solution that helps you manage your projects, tasks, time tracking, proposals, clients, calendar events, chat communication, employee management, and more, all in one place."
                    }
                </h3>
            </div>
        ),
        background: colors.black,
        neighbors: { right: "page1" },


    },
    {
        id: "page1",
        url: 'projects',
        index: 1,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right'
                        }}
                    >
                        {"Projects"}
                    </h1>
                    <p style={{ textAlign: 'right' }}>
                        {'Manage projects efficiently with features for task tracking, proposal management, inventory control, calendar scheduling, team collaboration, client management, custom attributes, file storage, project history monitoring, and dedicated interfaces for employees and administrators.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/7",
                    }}
                >
                    <p
                        className='mt-md mb-md'
                    >
                        {
                            "With unlimited projects, starting your project in Ardidflow is simple and intuitive, ensuring you can quickly focus on what matters most—delivering results and achieving your goals."
                        }
                    </p>
                    <p>
                        {'After creating your project, you can update the project details at any time, and update the status to keep track of the progress all the way to completion.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/6",
                        gridColumn: "2/7",
                    }}
                >
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/two.mp4'} controls={false} />
                </div>

            </div>
        ),
        background: colors.black,
        neighbors: { left: 'page0', right: "page2" },
    },
    {
        id: "page2",
        url: 'tasks',
        index: 2,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}

                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Tasks'}
                    </h1>
                    <p>
                        {'Tasks will be the engine that drives your project, keeping everything running smoothly. '}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_two}
                            alt="Assign employees"
                        />
                        <h2 className='ml-md' style={{ textAlign: 'left' }}>
                            {'Assign employees so everyone knows their role in the project'}
                        </h2>
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "3/7",
                    }}
                >
                    <div className='flex items-center mt-md '>
                        <h2 className='mt-md mr-md' style={{ textAlign: 'right' }}>
                            {'Set priorities and deadlines to stay on track'}
                        </h2>
                        <img
                            style={{
                                width: '50%',
                                display: 'flex',
                                marginTop: '10px',
                                objectFit: 'contain',
                            }}
                            src={three_three}
                            alt="Set priorities and deadlines"
                        />
                    </div>
                </div>
                <div
                    style={{
                        gridRow: "5/6",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='mt-md'>
                        <h2 className='mb-md' style={{ textAlign: 'right' }}>
                            {'& More...'}
                        </h2>
                        <ul style={{ textAlign: 'right' }}>
                            <li style={{ textAlign: 'right' }}>Create subtasks to break down work into manageable pieces.</li>
                            <li style={{ textAlign: 'right' }}>Each task can include todos to outline the steps or details of what needs to be done.</li>
                            <li style={{ textAlign: 'right' }}>Add comments/images for better communication.</li>
                            <li style={{ textAlign: 'right' }}>Clone tasks for faster creation of similar work.</li>
                            <li style={{ textAlign: 'right' }}>Get notification/calendar/email reminders when a task deadline is approaching, or a change has been made.</li>
                        </ul>
                    </div>
                </div>
            </div>
        ),

        background: colors.black,
        neighbors: { left: "page1", right: "page3" },
    },
    {
        id: "page3",
        url: 'time-tracking',
        index: 3,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                            textAlign: 'right',
                        }}
                    >
                        {'Time Tracking, Logs, and Management'}
                    </h1>
                </div>
                <div
                    style={{
                        gridRow: "3/4",
                        gridColumn: "2/7",
                    }}
                >
                    <div className='flex'>
                        <img style={{ objectFit: 'cover' }} width={'50%'} height={'50%'} src={four_two} alt="Time tracking" />
                        <img style={{ objectFit: 'cover' }} width={'50%'} src={four_one} alt="Time tracking" />
                    </div>

                    <p
                        className='mt-md'
                    >
                        {'Time tracking will help monitor employee working hours, ensuring better time management and task efficiency.'}
                    </p>
                    <p className='mt-md mb-md'>
                        {'Daily logs can be updated to reflect progress, helping the team stay aligned and improving overall collaboration.'}
                    </p>
                </div>
                <div

                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/7",
                    }}
                >
                    <img width={'80%'} src={four_four} alt="Time tracking" />
                    <div className='flex justify-between mt-md'>
                        <p

                            style={{ textAlign: 'left' }}
                        >
                            {'It will automatically track the status of tasks when clocking in and out, making time tracking more accurate and reliable.'}
                        </p>
                        <p

                            style={{ textAlign: 'right' }}
                        >
                            {'You will have the ability to update time entries anytime through the manage logs feature for you and your team'}
                        </p>
                    </div>

                </div>
            </div>

        ),
        background: colors.black,
        neighbors: { left: "page2", right: "page4" },
    },
    {
        id: "page4",
        url: 'clients-proposals',
        index: 4,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "5/7",
                        gridColumn: "2/8",
                        color: colors.white,
                    }}
                >
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {'Easily create/update/remove and manage clients and keep them updated through the client portal. Clients can view project details, track progress, and approve/reject and sign proposals with just a few clicks.'}
                    </p>
                    <p style={{ textAlign: 'center', marginTop: '10px' }}>
                        {'Communicate with clients through the chat, and get instant feedback on project updates, tasks, and more.'}
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Save proposal drafts, send them directly to clients via email with an attached PDF, and get notified instantly when a proposal status changes.'}
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        {'Make quick adjustments to proposals and resubmit effortlessly for faster turnaround and improved collaboration.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "2/4",
                        gridColumn: "2/8",
                    }}
                >
                    <img width={'100%'} src={five_one} alt="Time tracking" />
                    <img width={'100%'} src={five_two} alt="Time tracking" />
                    <h1
                        className="mt-md"
                        style={{
                            fontSize: "2em",
                            textAlign: 'center',
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Clients & Proposals'}
                    </h1>
                </div>
                <div
                    className='mt-md'
                    style={{
                        gridRow: "3/6",
                        gridColumn: "2/8",
                    }}
                >
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/five_three.mp4'} controls={false} />
                </div>
            </div>

        ),
        background: colors.black,
        neighbors: { left: "page3", right: "page5" },
    },
    {
        id: "page5",
        url: 'chat-calendar-notifications',
        index: 5,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 15px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 1fr 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Chat, Calendar & Notifications'}
                    </h1>
                    <img width={'100%'} src={six_four} alt="Analytics" />
                    <p className="mt-md">
                        {'The calendar allows you to schedule meetings, set reminders, and track when tasks are due, ensuring you stay on top of all project deadlines and commitments.'}
                    </p>
                    <p className="mt-md">
                        {'Use the calendar to coordinate schedules, manage events, and ensure task deadlines are met, all in one intuitive interface.'}
                    </p>
                </div>
                <div
                    style={{
                        gridRow: "4/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_five} alt="Notifications" />
                    <p>
                        {'Get instant notifications on project updates, task deadlines, messages and more to stay informed and improve team collaboration.'}
                    </p>

                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "5/7",
                    }}
                >
                    <p className='mb-md'>
                        {
                            'The chat feature is designed for seamless communication among team members and with clients. Use it to discuss projects, share updates, and collaborate effectively. Whether it’s about project progress, task coordination, or general inquiries, this chat ensures everyone stays connected and informed.'
                        }
                    </p>
                    <img className='mt-md' width={'100%'} src={six_six} alt="Chat" />
                </div>
            </div>

        ),
        background: colors.black,
        neighbors: { left: "page4", right: "page6" },
    },
    {
        id: "page6",
        url: 'analytics-ai-help-employee-management',
        index: 6,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "15px 1fr 50px 1fr 1fr 1fr 15px",
                    gridTemplateRows: "50px 1fr 50px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2/3",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <h1
                        className="mb-md"
                        style={{
                            fontSize: "2em",
                            borderBottom: `2px solid ${colors.white}`,
                        }}
                    >
                        {'Analytics, AI Help & Employee Management'}
                    </h1>
                    <p>
                        {
                            'The Employee Management feature simplifies workforce oversight with a centralized platform for managing details like job titles, salaries, and skills. Employees can view key information in their profiles, while managers can track certifications, performance, and more. A robust database allows quick employee lookups for better decision-making, and anyone in the company can easily find contact information or collaborate with colleagues.'
                        }
                    </p>


                </div>
                <div
                    style={{
                        gridRow: "3/5",
                        gridColumn: "2/5",
                    }}
                >
                    <img width={'100%'} src={six_three} alt="AI Help" />
                    <p className='mt-md mb-md' style={{
                        textAlign: 'right'
                    }}>
                        {'Get a clear view of your project performance with advanced analytics, and make data-driven decisions to improve business efficiency.'}
                    </p>
                    <VideoPlayer url={'https://s3.us-east-2.amazonaws.com/smartflow.com/six_one.mp4'} controls={false} />
                </div>
                <div style={{
                    gridRow: "3/6",
                    gridColumn: "5/7",
                }}>
                    <img width={'100%'} src={seven_two} alt="employee" />
                    {/* <img width={'100%'} src={seven_one} alt="employee" /> */}
                    <p className="mt-md">
                        {'The AI Help is your assistant for answering questions about tasks, inventory, projects, and how to use the app. Quickly get the help you need to navigate features and resolve issues.'}
                    </p>

                </div>

            </div>

        ),
        background: colors.black,
        neighbors: { left: "page5", right: "page7" },
    },
    {
        id: "page7",
        url: 'get-started',
        index: 7,
        content: (
            <div
                className="grid h-100 w-100"
                style={{
                    gridTemplateColumns: "30px 1fr 15px 1fr 1fr 1fr 30px",
                    gridTemplateRows: "25px 1fr 1fr 1fr 25px",
                    gridGap: "10px",
                }}
            >
                <div
                    style={{
                        gridRow: "2",
                        gridColumn: "2/7",
                        color: colors.white,
                    }}
                >
                    <RegistrationContainer
                        route={'register'}
                    />
                </div>
            </div >
        ),
        background: colors.black,
        neighbors: { left: "page6" },
    },
];

export default pages_sm;
