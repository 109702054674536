import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '../../context/ThemeContext';
import useScreenSize from '../../context/useScreenSize';
import colors from '../../globalStyles.scss';
import { useLanguage } from '../../context/LanguageContext';
import ConditionalRender from '../ConditionalRender';
import { isEmpty } from 'lodash';

const StyledDataGrid = styled(DataGrid)(({ theme, showLightColors }) => ({
    '& .MuiDataGrid-row': {
        '&:hover': {
            background: theme === 'dark' ? colors.backgroundOverlay : colors.lightGray,
        },
        'svg': {
            color: theme === 'dark' ? colors.white : colors.black
        }
    },
    '& .MuiButtonBase-root': {
        '&:hover': {
            background: theme === 'dark' ? colors.backgroundOverlay : colors.lightGray,
        },
        'svg': {
            color: theme === 'dark' ? colors.white : colors.black
        }
    },
    '& .MuiInputBase-root': {
        '&:hover': {
            background: theme === 'dark' ? colors.backgroundOverlay : colors.lightGray,
        },
        'svg': {
            color: theme === 'dark' ? colors.white : colors.black
        }
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center',
        color: theme === 'dark' ? colors.white : colors.black,
        backgroundColor: theme === 'dark' ? colors.background : colors.darkGray,
        '&:hover': {
            background: theme === 'dark' ? colors.blue : colors.lightGray,
        },
    },
    '.MuiSelect-select': {
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '& .MuiDataGrid-columnHeaderRow': {
        // background: 'none',
    },
    '& .MuiDataGrid-selectedRowCount': {
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '& .MuiTablePagination-displayedRows': {
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '& .MuiTablePagination-selectLabel': {
        color: theme === 'dark' ? colors.white : colors.black,
    },
    '.MuiDataGrid-scrollbar': {
        display: 'block',
        overFlowY: 'scroll',
        overFlowX: 'hidden',
        zIndex: '0',
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: colors.white,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#888',
        },
    },
}));

const CustomTable = ({
    columns,
    rows,
    pageSizeOptions = [5, 10, 15, 25],
    initialPageSize = 5,
    height = '100%',
    width = '100%',
    noDataText = '',
    onRowSelectionChange,
    checkboxSelection = true,
    showLightColors = false,
    noHeight,
}) => {
    const { theme } = useTheme();
    const { loading } = useScreenSize();
    const customTheme = theme === 'dark' ? 'dark' : 'light';
    const { text } = useLanguage();
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [currentHeight, setCurrentHeight] = useState(height);

    useEffect(() => {
        const rowHeight = 52; // Default row height in DataGrid
        const headerHeight = 56; // Default header height in DataGrid
        const newHeight = headerHeight + rowHeight * pageSize;
        setCurrentHeight(newHeight);
    }, [pageSize, rows]);

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const handleSelectionChange = (newSelectionModel) => {
        const selectedRowData = newSelectionModel.map(id => rows.find(row => row.id === id));
        onRowSelectionChange(selectedRowData);
    };

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 0,
            bottom: params.isLastVisible ? 0 : 0,
        };
    }, []);

    return (
        <>
            <ConditionalRender renderIf={isEmpty(rows)} isLoading={loading}>
                <p className='mt-md mb-md w-100 flex items-center justify-content-center'>
                    {noDataText || text?.table?.noDataText}
                </p>
            </ConditionalRender>
            <ConditionalRender renderIf={!isEmpty(rows)} isLoading={loading}>
                <CssBaseline />
                <div className='mb-md' style={
                    noHeight ?
                        {
                            width,
                            height: 'auto',
                        } :
                        {
                            width
                        }}>
                    <StyledDataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: customTheme === 'dark' ? colors.backgroundSecondary : colors.lightGray,
                            },
                            '& .MuiDataGrid-columnHeader:focus': {
                                outline: 'none',
                            },
                        }}
                        theme={customTheme}
                        rows={rows}
                        columns={columns}
                        showLightColors={showLightColors}
                        checkboxSelection={checkboxSelection}
                        onPageSizeChange={handlePageSizeChange}
                        onRowSelectionModelChange={handleSelectionChange}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    _id: false,
                                    productId: false,
                                    id: false,
                                },
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: initialPageSize },
                            },
                        }}
                        pageSize={pageSize}
                        pageSizeOptions={pageSizeOptions}
                        disableRowSelectionOnClick
                        rowSpacingType="border"
                        localeText={{
                            noRowsLabel: text?.table?.noDataText,
                            columnMenuManageColumns: text?.table?.columnMenuManageColumns,
                            columnMenuHideColumn: text?.table?.columnMenuHideColumn,
                            columnMenuFilter: text?.table?.columnMenuFilter,
                            columnMenuSortAsc: text?.table?.columnMenuSortAsc,
                            columnMenuSortDesc: text?.table?.columnMenuSortDesc,
                            columnsManagementSearchTitle: text?.table?.columnsManagementSearchTitle,
                            columnsManagementReset: text?.table?.columnsManagementReset,
                            columnsManagementShowHideAllText: text?.table?.columnsManagementShowHideAllText,
                            filterPanelColumns: text?.table?.filterPanelColumns,
                            filterPanelOperator: text?.table?.filterPanelOperator,
                            filterPanelInputLabel: text?.table?.filterPanelInputLabel,
                            filterPanelInputPlaceholder: text?.table?.filterPanelInputPlaceholder,
                            filterOperatorContains: text?.table?.filterOperatorContains,
                            filterOperatorEquals: text?.table?.filterOperatorEquals,
                            filterOperatorStartsWith: text?.table?.filterOperatorStartsWith,
                            filterOperatorEndsWith: text?.table?.filterOperatorEndsWith,
                            filterOperatorIsEmpty: text?.table?.filterOperatorIsEmpty,
                            filterOperatorIsNotEmpty: text?.table?.filterOperatorIsNotEmpty,
                            filterOperatorIsAnyOf: text?.table?.filterOperatorIsAnyOf,
                        }}
                        getRowSpacing={getRowSpacing}
                    />
                </div>
            </ConditionalRender>
        </>
    );
};

export default CustomTable;
