export const disabledNotification = (
    conversationId,
    userId,
    disable,
    ws,
    setConversations,
    setProjectConversations,
    selectedConversationId,
    setSelectedConversation
) => {
    ws.current.emit('toggleNotification', { conversationId, userId, disable }, (response) => {
        if (response.success) {

            // Update regular conversations
            setConversations((prevConversations) =>
                prevConversations.map((conversation) =>
                    conversation._id === conversationId && !conversation.projectId
                        ? {
                            ...conversation,
                            silenceNotifications: disable
                                ? [...conversation.silenceNotifications, { recipient: userId }]
                                : conversation.silenceNotifications.filter(
                                    (notification) => notification.recipient !== userId
                                ),
                        }
                        : conversation
                )
            );

            // Update project conversations
            setProjectConversations((prevProjectConversations) =>
                prevProjectConversations.map((conversation) =>
                    conversation._id === conversationId && conversation.projectId
                        ? {
                            ...conversation,
                            silenceNotifications: disable
                                ? [...conversation.silenceNotifications, { recipient: userId }]
                                : conversation.silenceNotifications.filter(
                                    (notification) => notification.recipient !== userId
                                ),
                        }
                        : conversation
                )
            );

            // Update selected conversation if applicable
            if (selectedConversationId === conversationId) {
                setSelectedConversation((prev) => ({
                    ...prev,
                    silenceNotifications: disable
                        ? [...prev.silenceNotifications, { recipient: userId }]
                        : prev.silenceNotifications.filter(
                            (notification) => notification.recipient !== userId
                        ),
                }));
            }
        } else {
            console.error('Failed to toggle notification:', response.message);
        }
    });
};