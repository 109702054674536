import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useScreenSize from '../context/useScreenSize';
import classnames from 'classnames';

const StyledDiv = styled.div`
    padding: 20px;
    h1 {
        text-align: left;
        font-size: 1.5rem;
    }
        h4, h2, h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
            text-align: left;
        }
        p {
            font-size: 1rem;
            margin-bottom: 10px;
            text-align: left;
        }

        ul {
            li {
                margin-bottom: 10px;
                text-align: left; 
            }
        }s

`


const CookiesPolicy = ({
    isCompanyPremium,
    isLoggedIn
}) => {
    const { language } = useLanguage();
    const { isDesktop } = useScreenSize();

    const translations = {
        en: {
            title: "Cookies Policy",
            lastUpdated: "Last updated: January 31, 2025",
            intro: "This Cookies Policy explains what Cookies are and how We use them. You should read this policy to understand what type of cookies We use, the information We collect, and how that information is used.",
            noSensitiveData: "We do not store sensitive personal information, such as mailing addresses or account passwords, in the Cookies We use.",
            interpretation: "Interpretation and Definitions",
            interpretationDesc: "Words with an initial capital letter have meanings defined under the following conditions. These definitions shall have the same meaning regardless of singular or plural form.",
            definitions: "Definitions",
            company: "Company",
            companyDesc: "Refers to ardidflow.com ('the Company', 'We', 'Us', or 'Our').",
            cookies: "Cookies",
            cookiesDesc: "Small files placed on your device that store browsing history details and help enhance user experience.",
            website: "Website",
            websiteDesc: "Refers to ArdidFlow, accessible from ",
            you: "You",
            youDesc: "Refers to the individual accessing or using the Website, or a company or legal entity on whose behalf that individual is accessing or using the Website.",
            useOfCookies: "Use of Cookies",
            typesOfCookies: "Types of Cookies We Use",
            persistentVsSession: "Cookies can be 'Persistent' or 'Session' Cookies. Persistent Cookies remain on your device when offline, while Session Cookies are deleted as soon as you close your web browser.",
            necessaryCookies: "Necessary / Essential Cookies",
            necessaryCookiesType: "Type: Session Cookies",
            necessaryCookiesAdmin: "Administered by: Us",
            necessaryCookiesPurpose: "These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features, such as authentication and fraud prevention.",
            functionalityCookies: "Functionality Cookies",
            functionalityCookiesType: "Type: Persistent Cookies",
            functionalityCookiesAdmin: "Administered by: Us",
            functionalityCookiesPurpose: "These Cookies allow us to remember preferences like login details or language settings for a better user experience.",
            analyticsCookies: "Analytics and Performance Cookies",
            analyticsCookiesPurpose: "These Cookies allow Us to track visitor behavior and interactions to improve our services.",
            googleAdsCookies: "Google Ads & Tracking Cookies",
            googleAdsCookiesPurpose: "We use Google Ads and Google Analytics cookies to track user interactions, measure ad performance, and improve marketing campaigns. You can manage your Google Ads preferences here:",
            googleAdsLink: "Google Ads Settings",
            stripeCookies: "Stripe Payment Cookies",
            stripeCookiesPurpose: "Stripe uses cookies for secure payment processing, fraud detection, and session management. You can view Stripe's cookie policy here:",
            stripePolicyLink: "Stripe Cookie Policy",
            managingCookies: "Managing Cookies",
            manageDesc: "You can prevent Cookies by adjusting your browser settings. However, some features may not work properly without Cookies.",
            moreInfo: "More Information about Cookies",
            moreInfoDesc: "You can learn more about Cookies here:",
            contactUs: "Contact Us",
            contactDesc: "If you have any questions about this Cookies Policy, you can contact us:",
            contactEmail: "By email: ",
            contactForm: "Through our contact form",
        },
        es: {
            title: "Política de Cookies",
            lastUpdated: "Última actualización: 31 de enero de 2025",
            intro: "Esta Política de Cookies explica qué son las Cookies y cómo las usamos. Debe leer esta política para entender qué tipo de cookies usamos, la información que recopilamos y cómo se usa esa información.",
            noSensitiveData: "No almacenamos información personal sensible, como direcciones postales o contraseñas de cuentas, en las Cookies que usamos.",
            interpretation: "Interpretación y Definiciones",
            interpretationDesc: "Las palabras cuya letra inicial está en mayúscula tienen los significados definidos en las siguientes condiciones. Estas definiciones tendrán el mismo significado independientemente de su singular o plural.",
            definitions: "Definiciones",
            company: "Empresa",
            companyDesc: "Se refiere a ardidflow.com ('la Empresa', 'Nosotros' o 'Nuestro').",
            cookies: "Cookies",
            cookiesDesc: "Pequeños archivos almacenados en su dispositivo que registran detalles de navegación y mejoran la experiencia del usuario.",
            website: "Sitio Web",
            websiteDesc: "Se refiere a ArdidFlow, accesible desde ",
            you: "Usted",
            youDesc: "Se refiere al individuo que accede o usa el Sitio Web, o a una empresa o entidad legal en cuyo nombre dicho individuo está accediendo o usando el Sitio Web.",
            useOfCookies: "Uso de Cookies",
            typesOfCookies: "Tipos de Cookies que Usamos",
            persistentVsSession: "Las Cookies pueden ser 'Persistentes' o 'De Sesión'. Las Cookies Persistentes permanecen en su dispositivo cuando está desconectado, mientras que las Cookies de Sesión se eliminan al cerrar su navegador.",
            necessaryCookies: "Cookies Necesarias / Esenciales",
            necessaryCookiesType: "Tipo: Cookies de Sesión",
            necessaryCookiesAdmin: "Administradas por: Nosotros",
            necessaryCookiesPurpose: "Estas Cookies son esenciales para proporcionar servicios disponibles en el Sitio Web y permitirle usar algunas funciones, como autenticación y prevención de fraude.",
            functionalityCookies: "Cookies de Funcionalidad",
            functionalityCookiesType: "Tipo: Cookies Persistentes",
            functionalityCookiesAdmin: "Administradas por: Nosotros",
            functionalityCookiesPurpose: "Estas Cookies permiten recordar preferencias como detalles de inicio de sesión o configuraciones de idioma para una mejor experiencia de usuario.",
            analyticsCookies: "Cookies de Análisis y Rendimiento",
            analyticsCookiesPurpose: "Estas Cookies nos permiten rastrear el comportamiento de los visitantes y mejorar nuestros servicios.",
            googleAdsCookies: "Cookies de Google Ads y Seguimiento",
            googleAdsCookiesPurpose: "Usamos cookies de Google Ads y Google Analytics para rastrear interacciones de usuarios, medir el rendimiento de anuncios y mejorar campañas de marketing. Puede administrar sus preferencias de Google Ads aquí:",
            googleAdsLink: "Configuración de Google Ads",
            stripeCookies: "Cookies de Pago de Stripe",
            stripeCookiesPurpose: "Stripe utiliza cookies para procesamiento seguro de pagos, detección de fraudes y administración de sesiones. Puede ver la política de cookies de Stripe aquí:",
            stripePolicyLink: "Política de Cookies de Stripe",
            managingCookies: "Administrar Cookies",
            manageDesc: "Puede evitar las Cookies ajustando la configuración de su navegador. Sin embargo, algunas funciones pueden no funcionar correctamente sin Cookies.",
            moreInfo: "Más Información sobre las Cookies",
            moreInfoDesc: "Puede obtener más información sobre las Cookies aquí:",
            contactUs: "Contáctenos",
            contactDesc: "Si tiene alguna pregunta sobre esta Política de Cookies, puede contactarnos:",
            contactEmail: "Por correo electrónico: ",
            contactForm: "A través de nuestro formulario de contacto",
        }
    };

    const t = translations[language] || translations.en;

    return (
        <StyledDiv
            className={classnames({
                'm-center w-80': !isCompanyPremium && isDesktop
            })}
        >
            <h1>{t.title}</h1>
            <p>{t.lastUpdated}</p>
            <p>{t.intro}</p>
            <p>{t.noSensitiveData}</p>

            <h2>{t.interpretation}</h2>
            <p>{t.interpretationDesc}</p>

            <h2>{t.definitions}</h2>
            <ul>
                <li><strong>{t.company}:</strong> {t.companyDesc}</li>
                <li><strong>{t.cookies}:</strong> {t.cookiesDesc}</li>
                <li><strong>{t.website}:</strong> {t.websiteDesc} <a href="https://ardidflow.com" target="_blank">ardidflow.com</a></li>
                <li><strong>{t.you}:</strong> {t.youDesc}</li>
            </ul>

            <h2>{t.useOfCookies}</h2>
            <p>{t.typesOfCookies}</p>
            <p>{t.persistentVsSession}</p>

            <h3>{t.necessaryCookies}</h3>
            <p><strong>{t.necessaryCookiesType}</strong></p>
            <p><strong>{t.necessaryCookiesAdmin}</strong></p>
            <p>{t.necessaryCookiesPurpose}</p>

            <h3>{t.functionalityCookies}</h3>
            <p><strong>{t.functionalityCookiesType}</strong></p>
            <p><strong>{t.functionalityCookiesAdmin}</strong></p>
            <p>{t.functionalityCookiesPurpose}</p>

            <h3>{t.analyticsCookies}</h3>
            <p>{t.analyticsCookiesPurpose}</p>

            <h3>{t.googleAdsCookies}</h3>
            <p>{t.googleAdsCookiesPurpose} <a href="https://adssettings.google.com/" target="_blank">{t.googleAdsLink}</a></p>

            <h3>{t.stripeCookies}</h3>
            <p>{t.stripeCookiesPurpose} <a href="https://stripe.com/privacy" target="_blank">{t.stripePolicyLink}</a></p>

            <h2>{t.managingCookies}</h2>
            <p>{t.manageDesc}</p>

            <h2>{t.moreInfo}</h2>
            <p>{t.moreInfoDesc} <a href="https://www.freeprivacypolicy.com/blog/cookies/" target="_blank" rel="noopener noreferrer">Cookies: What Do They Do?</a></p>

            <h2>{t.contactUs}</h2>
            <p>{t.contactDesc}</p>
            <ul>
                <li><strong>{t.contactEmail}</strong> <a href="mailto:admin@ardidflow.com">admin@ardidflow.com</a></li>
                <li><strong>{t.contactForm}</strong> <Link to="/contact-us">Contact</Link></li>
            </ul>
        </StyledDiv>

    );
};

export default CookiesPolicy;
