export const findSuperAdminConversation = async (
    userId,
    companyId,
    callback,
    ws,
    setMessages,
    setSelectedConversation,
    setSelectedConversationId,
    setConversationIdIsLoading
) => {
    setConversationIdIsLoading(true);
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }
    ws.current.emit('findSuperAdminConversation', { userId, companyId }, (response) => {
        if (response.success) {
            setMessages(response.conversation?.messages);
            setSelectedConversation(response?.conversation);
            setSelectedConversationId(response?.conversation?._id);
            callback?.(response.conversation);
            setConversationIdIsLoading(false);
        } else {
            console.error(response.message);
            callback?.(null, response.message);
            setConversationIdIsLoading(false);
        }
    });
};