import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { pages } from "./pages";
import { pages_sm } from "./pages_sm";
import { pages_es } from "./pages_es";
import { pages_es_sm } from "./pages_es_sm";
import { useLanguage } from "../context/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../globalStyles.scss";
import classnames from "classnames";
import useScreenSize from "../context/useScreenSize";
import ConditionalRender from "../Core/ConditionalRender";
import Arrow, { DIRECTION } from 'react-arrows'
import { isEmpty } from "lodash";
import queryString from "query-string";
import { Helmet } from "react-helmet";



import {
    faCircleDot
} from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  width: 100vw;
  height: ${(props) => (props.isDesktop ? "90vh" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: ${colors.black};
  h1, h2, h3, p, span, ul, li, label {
    color: ${colors.white} !important;
    text-align: left;
  }
    p, li {
        font-size: 0.9em;
        font-family: ${colors.openSans};
        margin-top: 0.5em;
    }
    caret-color: ${colors.black} !important;
`;

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transform: ${(props) => `translate(${props.offsetX}%, ${props.offsetY}%)`};
  opacity: ${(props) => (props.visible ? 1 : 0)};
    .hide {
        opacity: 0;
    }
    .active {
        opacity: 1;
    }
`;

const ArrowDiv = styled.button`
  position: absolute;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
  }
  &.up {
    top: 10px;
  }
  &.down {
    bottom: 10px;
  }
svg {
    color: ${colors.white};
    font-size: 2em;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
`;

const Flowchart = () => {
    const [currentPage, setCurrentPage] = React.useState({});
    const [direction, setDirection] = React.useState({ x: 0, y: 0 });
    const [isScrolling, setIsScrolling] = React.useState(false);
    const { isDesktop, isPhone } = useScreenSize();
    const [isLoading, setIsLoading] = React.useState(true);
    const { language } = useLanguage();
    const [pageLn, setPagesLn] = React.useState([]);

    React.useEffect(() => {
        if (language === 'es') {
            if (isEmpty(pages)) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
            setCurrentPage(pages[0]);
        } else {
            if (isEmpty(pages_es)) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
            setCurrentPage(pages_es[0]);
        }
    }, [language]);

    // Parse query string on mount
    React.useEffect(() => {
        const query = queryString.parse(window.location.search); // Parse query string
        const pageName = query.page; // Get the `page` parameter
        const initialPages = language === "es" ? (isDesktop ? pages_es : pages_es_sm) : (isDesktop ? pages : pages_sm);

        // Find the page matching the `page` query parameter or default to the first page
        const initialPage = initialPages.find((page) => page.url === pageName) || initialPages[0];
        setPagesLn(initialPages);
        setCurrentPage(initialPage);
        setIsLoading(false);
    }, [language, isDesktop]);

    const handleNavigate = (dir) => {
        const nextPageId = currentPage?.neighbors[dir];
        if (nextPageId) {
            const nextPage = pageLn?.find((page) => page?.id === nextPageId);
            if (nextPage) {
                setDirection(() => {
                    switch (dir) {
                        case "left":
                            return { x: 100, y: 0 };
                        case "right":
                            return { x: -100, y: 0 };
                        case "up":
                            return { x: 0, y: 100 };
                        case "down":
                            return { x: 0, y: -100 };
                        default:
                            return { x: 0, y: 0 };
                    }
                });
                setCurrentPage(nextPage);
            }
            const query = queryString.stringify({ page: nextPage.url });
            window.history.pushState(null, null, `/flow?${query}`);
        }
    };

    // Handle browser back/forward navigation
    React.useEffect(() => {
        const handlePopState = () => {
            const query = queryString.parse(window.location.search);
            const pageName = query.page;
            const newPage = pageLn.find((page) => page.url === pageName) || pageLn[0];
            setCurrentPage(newPage);
        };

        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [pageLn]);


    const handleScroll = (e) => {
        if (isScrolling) return;

        const direction = e.deltaY > 0 ? "down" : "up";
        const deltaX = e.deltaX > 0 ? "right" : "left";

        setIsScrolling(true);

        if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
            handleNavigate(deltaX);
        } else {
            handleNavigate(direction);
        }

        setTimeout(() => setIsScrolling(false), 2000); // Buffer time for smooth transitions
    };

    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [isTouching, setIsTouching] = useState(false);

    // --- Handle Mobile Swipe ---
    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
        setIsTouching(true);
    };

    const handleTouchEnd = (e) => {
        if (!isTouching || isScrolling) return;

        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;
        const deltaX = endX - startX;
        const deltaY = endY - startY;

        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            handleNavigate(deltaX < 0 ? "right" : "left");
        } else {
            handleNavigate(deltaY < 0 ? "down" : "up");
        }

        setIsTouching(false);
    };

    React.useEffect(() => {
        if (!isDesktop) {
            return;
        }
        window.addEventListener("wheel", handleScroll);
        return () => window.removeEventListener("wheel", handleScroll);
    }, [currentPage, isScrolling, isDesktop]);

    React.useEffect(() => {
        if (isDesktop) {
            return;
        }
        window.addEventListener("touchstart", handleTouchStart);
        window.addEventListener("touchend", handleTouchEnd);
        return () => {
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, [isTouching, isScrolling, isDesktop]);

    React.useEffect(() => {
        // Ensure the initial page transforms are set properly
        setDirection({ x: 0, y: 0 });
    }, []);

    // Update Helmet dynamically
    const currentTitle = React.useMemo(() => {
        const formattedTitle = currentPage.url
            ? currentPage.url.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
            : "Flowchart";
        return `Ardidflow.com | Flow | ${formattedTitle}`;
    }, [currentPage]);

    return (
        <ConditionalRender renderIf={true} isLoading={isLoading}>
            <Helmet>
                {/* Dynamic Page Title */}
                <title>{currentTitle}</title>

                {/* Corrected Meta Description */}
                <meta name="description" content={`Explore ${currentPage?.url || "dynamic content"} in Flowchart`} />

                {/* Open Graph (OG) Tags for Social Media Sharing */}
                <meta property="og:title" content={currentTitle} />
                <meta property="og:description" content={`Explore ${currentPage?.url || "dynamic content"} in Flowchart`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />

                {/* Twitter Card for Twitter previews */}
                <meta name="twitter:title" content={currentTitle} />
                <meta name="twitter:description" content={`Explore ${currentPage?.url || "dynamic content"} in Flowchart`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <Wrapper
                isDesktop={isDesktop}
            >
                <ArrowDiv
                    id="left"
                    className="left"
                    onClick={() => handleNavigate("left")}
                    visible={!!currentPage?.neighbors?.left}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="right"
                    className="right"
                    onClick={() => handleNavigate("right")}
                    visible={!!currentPage?.neighbors?.right}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="up"
                    className="up"
                    onClick={() => handleNavigate("up")}
                    visible={!!currentPage?.neighbors?.up}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>
                <ArrowDiv
                    id="down"
                    className="down"
                    onClick={() => handleNavigate("down")}
                    visible={!!currentPage?.neighbors?.down}
                >
                    <FontAwesomeIcon icon={faCircleDot} />
                </ArrowDiv>

                {pageLn?.map((page) => {
                    const isCurrent = page.id === currentPage.id;

                    const offset = isCurrent
                        ? { x: 0, y: 0 }
                        : {
                            x: currentPage.id === page?.neighbors.left
                                ? -100
                                : currentPage.id === page?.neighbors.right
                                    ? 100
                                    : 0,
                            y: currentPage.id === page?.neighbors.up
                                ? -100
                                : currentPage.id === page?.neighbors.down
                                    ? 100
                                    : 0,
                        };

                    return (
                        <Page
                            id={page.url}
                            className={classnames({
                                active: isCurrent,
                                'hide': !isCurrent
                            })}
                            key={page.id}
                            visible={isCurrent}
                            offsetX={offset.x}
                            offsetY={offset.y}
                            style={{
                                background: page.background,
                                zIndex: isCurrent ? 1 : 0,
                            }}
                        >
                            {page.content}
                        </Page>
                    );
                })}
                {(currentPage?.neighbors?.right && currentPage?.neighbors?.down) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`right`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`down`),
                            translation: [0, -1],
                        }}
                    />
                )}
                {(currentPage?.neighbors?.down && currentPage?.neighbors?.left) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`down`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`left`),
                            translation: [0, -1],
                        }}
                    />
                )}
                {(currentPage?.neighbors?.left && currentPage?.neighbors?.up) && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`left`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`up`),
                            translation: [0, -1],
                        }}
                    />
                )}


                {currentPage?.up && (
                    <Arrow
                        className="arrow"
                        from={{
                            direction: DIRECTION.BOTTOM,
                            node: () => document.getElementById(`up`),
                            translation: [0, 1],
                        }}
                        to={{
                            direction: DIRECTION.TOP,
                            node: () => document.getElementById(`right`),
                            translation: [0, -1],
                        }}
                    />
                )}

            </Wrapper>
        </ConditionalRender>
    );
};

export default Flowchart;
