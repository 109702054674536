export const handleTyping = (
    isTyping,
    conversationId,
    user,
    ws
) => {
    if (!ws.current) {
        console.error('WebSocket is not connected.');
        return;
    }

    // If the user has started typing:
    if (isTyping) {
        ws.current.emit('startTyping', {
            conversationId,
            userId: user._id
        });
    } else {
        // If the user has stopped typing:
        ws.current.emit('stopTyping', {
            conversationId,
            userId: user._id
        });
    }
};
