import base64ToBlob from '../utils/base64ToBlob';

export const updateMessageFile = async (
    data,
    callback,
    ws,
    setMessages,
    selectedConversationId
) => {
    const { files, message } = data;

    const serializedData = {
        body: {
            messageId: message?._id,
            conversationId: selectedConversationId,
            companyId: message?.companyId,
            oldFiles: [],
        },
        files: { newFiles: [] },
    };

    // Separate old vs. new files
    files.forEach((file) => {
        if (file?.new) {
            // Convert base64 string (dataUrl) to a Blob/Buffer
            const fileBlob = base64ToBlob(file?.dataUrl);
            serializedData.files.newFiles.push({
                buffer: fileBlob,
                mimetype: file.type,
                originalName: file.originalName,
            });
        } else {
            serializedData.body.oldFiles.push(file);
        }
    });

    try {
        ws.current.emit('updateMessageFile', serializedData, (response) => {
            if (response.success) {
                const updatedMessage = response.message;

                // Update local state
                setMessages((prevMessages) => {
                    const updatedMessages = prevMessages.map((msg) => {
                        // Check if this updated message is a main message
                        if (msg._id === updatedMessage._id) {
                            return { ...msg, ...updatedMessage };
                        }

                        // Or check if it's a reply
                        if (msg.replies) {
                            const updatedReplies = msg.replies.map((reply) =>
                                reply._id === updatedMessage._id
                                    ? { ...reply, ...updatedMessage }
                                    : reply
                            );
                            return { ...msg, replies: updatedReplies };
                        }

                        return msg; // Return unchanged
                    });
                    return updatedMessages;
                });

                callback?.(updatedMessage);
            } else {
                console.error('Failed to update message files:', response.message);
                callback?.(null, response.message);
            }
        });
    } catch (error) {
        console.error('Error updating message files:', error);
        callback?.(null, 'Failed to update message files due to an error.');
    }
};
