import React, { useState, useEffect, useContext, useMemo } from 'react'
import ConditionalRender from '../Core/ConditionalRender'
import WelcomeAdminContainer from './WelcomeAdminContainer'
import WelcomeEmployeeContainer from './WelcomeEmployeeContainer'
import { isEmpty } from 'lodash';
import { TimerContext } from '../context/TimeContext';
import HoverPopup from '../Core/HoverPopup';
import {
    getRunningTimesheet,
    updateUserAttributes
} from '../utils/calls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faTable } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components'
import { useLanguage } from '../context/LanguageContext';
import useScreenSize from '../context/useScreenSize';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    form {
        align-self: flex-end;
    }
`;

const WelcomeContainer = ({
    userRole,
    projects,
    user,
    company,
    fetchCompanyDetails,
    fetchCompanyEmployees,
    companyIsLoading,
    projectsIsLoading,
    employees,
    employeesIsLoading,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    handleToggleChat,
    showHelp,
    handleSendMessage,
    setInventory,
    isCompanyPremium,
    fetchUserDetails,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    fetchCompanyProjects,
    attributes
}) => {
    const {
        isRunning,
    } = useContext(TimerContext);
    const { text } = useLanguage();
    const { isDesktop } = useScreenSize();

    const [projectId, setProjectId] = useState('');
    const [initialProject, setInitialProject] = useState();
    const [dashboardLayout, setDashboardLayout] = useState(attributes?.dashboardLayout || 'split');

    useMemo(() => {
        setDashboardLayout(attributes?.dashboardLayout || 'split');
    }, [attributes]);

    useEffect(() => {
        // const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};


        const fetchData = async () => {
            if (isEmpty(storedData)) {
                try {
                    const res = await getRunningTimesheet();
                    if (res.status === 200) {
                        const project = projects?.find(p => p._id === res.data?.projectId);
                        setInitialProject({
                            label: project?.projectName,
                            value: project?._id
                        });
                        setProjectId(project?._id);
                    } else if (res.status === 404) {
                        // Handle 404 status: set to first project if available
                        if (projects && projects?.length > 0) {
                            setInitialProject({
                                label: projects[0]?.projectName,
                                value: projects[0]?._id
                            });
                            setProjectId(projects[0]?._id);
                        }
                    }
                } catch (error) {
                    // Handle other errors or set default project
                    if (projects && projects?.length > 0) {
                        setInitialProject({
                            label: projects[0]?.projectName,
                            value: projects[0]?._id
                        });
                        setProjectId(projects[0]?._id);
                    }
                }
            }
        };

        fetchData();
    }, [projects]);

    useEffect(() => {
        // const storedData = JSON.parse(localStorage.getItem('timeTracker'));
        const storedData = JSON.parse(sessionStorage.getItem('timeTracker')) || {};


        if (storedData?.isRunning && !isEmpty(projects)) {
            const projectId = storedData?.projectId;
            setProjectId(projectId);
            const project = projects?.find(p => p._id === projectId);
            setInitialProject({
                label: project?.projectName,
                value: project?._id
            });
        } else {
            // In case there is no running project, but we still have the projectId stored
            const projectId = storedData?.projectId;
            const project = projects?.find(p => p._id === projectId);

            // If we find the project with the stored projectId, use that, otherwise set to empty
            if (project) {
                setInitialProject({
                    label: project.projectName,
                    value: project._id
                });
                setProjectId(project?._id);

            }
        }
    }, [projects]);

    const handleChange = (e) => {
        setProjectId(e?.value)
    }

    useEffect(() => {
        fetchProjectTasks()
    }, [])

    const handleUpdateUserAttributes = async (attributes) => {
        // Optimistically update state before calling the API
        setDashboardLayout((prev) => (prev === 'split' ? 'full' : 'split'));

        try {
            await updateUserAttributes(attributes);
            fetchAttributes();
        } catch (error) {
            console.error('Failed to update attributes', error);
        }
    };


    return (
        <StyledDiv>
            <ConditionalRender
                isLoading={projectsIsLoading}
                renderIf={true}
            >
                <ConditionalRender renderIf={!isEmpty(projects) && isDesktop}>
                    <div
                        className='flex align-items-end justify-content-end'
                    >
                        <HoverPopup
                            noBG
                            onClick={() => handleUpdateUserAttributes({ dashboardLayout: dashboardLayout === 'split' ? 'full' : 'split' })}
                            placement={'bottom'}
                            id='layout-toggle'
                            controlledTooltipProps={{
                                isDark: true,
                                text: text?.actionTracking?.toogleLayout,
                                placement: 'left',
                                actionParams: {
                                    feature: 'attributes',
                                    action: 'dashboardLayout'
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={dashboardLayout !== 'split' ? faTableColumns : faTable}
                                style={{
                                    fontSize: '1.5em'
                                }} />
                        </HoverPopup>
                    </div>
                </ConditionalRender>
                <ConditionalRender
                    renderIf={userRole === 'Admin'}
                >
                    <WelcomeAdminContainer
                        projects={projects}
                        projectsIsLoading={projectsIsLoading}
                        projectId={projectId}
                        company={company}
                        user={user}
                        employees={employees}
                        employeesIsLoading={employeesIsLoading}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        fetchAttributes={fetchAttributes}
                        setInventoryColumns={setInventoryColumns}
                        initialProject={initialProject}
                        handleChange={handleChange}
                        isRunning={isRunning}
                        fetchUserDetails={fetchUserDetails}
                        isCompanyPremium={isCompanyPremium}
                        handleToggleChat={handleToggleChat}
                        showHelp={showHelp}
                        handleSendMessage={handleSendMessage}
                        setInventory={setInventory}
                        userRole={userRole}
                        tasks={tasks}
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        fetchAllClients={fetchAllClients}
                        clients={clients}
                        clientsIsLoading={clientsIsLoading}
                        proposals={proposals}
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}
                        fetchCompanyProjects={fetchCompanyProjects}
                        dashboardLayout={dashboardLayout}
                    />
                </ConditionalRender>
                <ConditionalRender
                    renderIf={userRole !== 'Admin'}
                >
                    <WelcomeEmployeeContainer
                        projects={projects}
                        projectsIsLoading={projectsIsLoading}
                        projectId={projectId}
                        employees={employees}
                        company={company}
                        user={user}
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        fetchAttributes={fetchAttributes}
                        setInventoryColumns={setInventoryColumns}
                        initialProject={initialProject}
                        handleChange={handleChange}
                        isRunning={isRunning}
                        fetchUserDetails={fetchUserDetails}
                        isCompanyPremium={isCompanyPremium}
                        handleToggleChat={handleToggleChat}
                        showHelp={showHelp}
                        handleSendMessage={handleSendMessage}
                        setInventory={setInventory}
                        userRole={userRole}
                        tasks={tasks}
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        clients={clients}
                        clientsIsLoading={clientsIsLoading}
                        proposals={proposals}
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        dashboardLayout={dashboardLayout}
                    />
                </ConditionalRender>
            </ConditionalRender>
        </StyledDiv >
    )
}

export default WelcomeContainer