import React from 'react';
import { Field } from 'react-final-form';
import ConditionalRender from '../ConditionalRender';
import { useTheme } from '../../context/ThemeContext';
import classnames from 'classnames';
import colors from '../../globalStyles.scss';
import styled from 'styled-components';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledFormGroup = styled.div`
  a {
    &:hover {
      text-decoration: underline !important;
    }
  }
  .form-group {
    .toggle-password-visibility {
      position: absolute;
    right: -15px;
      top: 0;
      cursor: pointer;
    }

    input[type='text'] {
      border: none;
      border-bottom: ${(props) =>
        props.value ? '1px solid' + colors.blue : '1px solid black'};
      border-bottom-color: ${(props) =>
        props.theme === 'dark' ? colors.lightGray : colors.black};
      border-bottom-color: ${(props) => props.showLightColors && colors.black};
      background: ${(props) =>
        props.disabled
            ? props.theme === 'dark'
                ? colors.disabledDark
                : colors.lightBlue
            : props.theme === 'dark'
                ? colors.backgroundSecondary
                : colors.darkGray};
      background: ${(props) => props.showLightColors && colors.white};
      padding: 0.2rem;
      border-radius: 0;
      flex: 1; // Allow the input to grow
      color: ${(props) =>
        props.theme === 'light' ? colors.black : colors.white};
    }
    label {
      display: flex;
      color: ${(props) =>
        props.theme === 'light' ? colors.black : colors.white};
    }
    input.error_message {
      border-bottom: ${(props) =>
        props.value || (props.type === 'number' && props.value === 0)
            ? '1px solid' + colors.red
            : '1px solid' + colors.red};
    }
  }
  input:focus ~ label,
  textarea:focus ~ label {
    font-size: 0.75rem;
    transition: all 0.225s ease;
  }
`;

const LinkField = ({
    className = '',
    name,
    label,
    showLightColors,
    disabled,
    setIsEditing,
    wrapperStyles,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <StyledFormGroup
            className={className}
            theme={theme}
            showLightColors={showLightColors}
            disabled={disabled}
        >
            <label className="mb-sm b flex">{label}</label>
            <div className="flex items-center">
                <Field name={name} {...rest}>
                    {({ input, meta }) => (
                        <div className="form-group wide" style={wrapperStyles}>
                            <div
                                className="input-wrapper"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flex: '1',
                                }}
                            >
                                <input
                                    {...input}
                                    type="text"
                                    className={classnames('', {
                                        error_message: meta.error && meta.touched,
                                    })}
                                    placeholder="Enter link here"
                                    onChange={(event) => {
                                        input.onChange(event);
                                        if (setIsEditing) {
                                            setIsEditing(true);
                                        }
                                    }}
                                />
                                <ConditionalRender renderIf={input.value}>
                                    <a
                                        href={
                                            typeof input.value === 'string' &&
                                                (input.value.startsWith('http://') ||
                                                    input.value.startsWith('https://'))
                                                ? input.value
                                                : `https://${input.value}`
                                        }
                                        className="link-icon"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            marginLeft: '10px',
                                            color: colors.blue,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </a>
                                </ConditionalRender>
                            </div>
                            {meta.error && meta.touched && (
                                <span
                                    className="small error"
                                    style={{ alignSelf: 'end', color: colors.red }}
                                >
                                    {meta.error}
                                </span>
                            )}
                        </div>
                    )}
                </Field>
            </div>
        </StyledFormGroup>
    );
};

export default LinkField;
